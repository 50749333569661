import { useTranslation } from "react-i18next";

export default function CardFile({
  row,
  isActive = true,
  handleCardClick,
  flexRender,
}) {
  const { t } = useTranslation();
  const isLinkToExternalFile = Boolean(row.original.externalLink);

  const cardTitle =
    isActive === false
      ? t("Deactivated")
      : isLinkToExternalFile
      ? t("View")
      : t("Download");

  const handleClick = () => {
    if (!isActive) return;

    if (isLinkToExternalFile) {
      window.open(row.original.externalLink, "_blank");
    } else {
      handleCardClick(row.original);
    }
  };

  return (
    <div
      key={row.id}
      className={`card ${isActive === false ? "inactive" : ""}`}
      title={cardTitle}
      onClick={handleClick}
      tabIndex="0"
    >
      {row.getVisibleCells().map((cell) => {
        if (cell.column.id === "title" || cell.column.id === "name") {
          return (
            <h3
              key={cell.id}
              className={cell.column.id}
              title={cell.column.header}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </h3>
          );
        }
        return (
          <div key={cell.id} className={cell.column.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        );
      })}
    </div>
  );
}
