import { useSelector } from "react-redux";

export default function FormMessage({ id = null }) {
  const formMessage = useSelector((state) => state.modals.formMessage);
  const { id: messageId, content, isSuccess } = formMessage;

  // Every message has unique id, so it's displayed only in its form.
  if (messageId !== id) return;

  return (
    <div className={`form-message ${isSuccess ? "success" : "warning"}`}>
      {content}
    </div>
  );
}
