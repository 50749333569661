import { useNavigate } from "react-router-dom";
import { Icon } from "../";
import "./PageTitle.scss";

export default function PageTitle({
  title,
  isBackButtonVisible = true,
  isBackButtonToDashboard = false,
}) {
  const navigate = useNavigate();

  const handleButtonBackClick = () => {
    if (isBackButtonToDashboard) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  /**
   * Title accepts strings or components.
   */
  const showTitle = () => {
    if (typeof title === "function") {
      return title();
    }
    return title;
  };

  return (
    <div className="page-title">
      {isBackButtonVisible && (
        <button className="btn btn-back" onClick={handleButtonBackClick}>
          <Icon name="arrow-left" />
        </button>
      )}

      <h1>{showTitle()}</h1>
    </div>
  );
}
