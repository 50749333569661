import { type } from "@testing-library/user-event/dist/type";

/**
 * Nodes.
 */
const position = { x: 0, y: 0 };

export const nodeStyle = {
  borderRadius: "6px",
  fontFamily: "Raleway",
  fontSize: "13px",
  padding: "5px",
  background: "var(--white)",
  color: "var(--black-700)",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "var(--black-transparent-600)",
  boxShadow: "none",
  transition: "none",
  outline: "none",
  cursor: "auto",
};

const nodeOptions = {
  position,
  style: nodeStyle,
  draggable: false,
  connectable: false,
  deletable: false,
};

/**
 * Creating default graph nodes.
 */
export function createNode(id, label) {
  return {
    id: String(id),
    data: { label: id + 1 + ". " + label },
    type: "default",
    ...nodeOptions,
  };
}

/**
 * Creating our custom graph nodes.
 */
export function createNodeQuestion(id, label, questionId) {
  return {
    id: String(id),
    data: {
      label: id + 1 + ". " + label,
      questionId,
    },
    type: "question",
    ...nodeOptions,
  };
}

export function createNodeAdvice(
  id,
  advice,
  bacteria,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  isPrevention
) {
  return {
    id: String(id),
    type: "advice",
    data: {
      advice,
      bacteria,
      medicaments,
      medicamentForms,
      doseMeasures,
      timeMeasures,
      applicationMethods,
      isPrevention,
    },
    ...nodeOptions,
  };
}

/**
 * Edges.
 */
const edgeType = "smoothstep";

const labelStyle = {
  fill: "var(--black-100)",
};

const labelBgStyle = {
  fill: "var(--primary-25)",
};

const labelBgPadding = [5, 5];

const edgeOptions = {
  type: edgeType,
  animated: true,
  labelStyle: labelStyle,
  labelBgStyle: labelBgStyle,
  labelBgPadding: labelBgPadding,
  interactionWidth: 30,
};

/**
 * Creating default graph edges.
 */
export function createEdge(sourceId, targetId, label) {
  return {
    id: `e${sourceId}-${targetId}`,
    source: "" + sourceId,
    target: "" + targetId,
    label: label,
    ...edgeOptions,
  };
}

/**
 * Creating our custom graph edges.
 */
export function createCustomEdge(
  sourceId,
  targetId,
  label,
  isAdviceCorrected,
  questionIndex,
  answerId
) {
  return {
    id: `e${sourceId}-${targetId}`,
    source: "" + sourceId,
    target: "" + targetId,
    label: label,
    data: {
      label,
      isAdviceCorrected,
      questionIndex,
      answerId,
    },
    ...{ ...edgeOptions, type: "custom" },
  };
}
