import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ApiRequests, showHttpRequestError } from "../../http";
import { useOnMount } from "../../hooks";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { selectStyles } from "../../styles/selectStyles";
import { DataTable, Modal, Icon, PageTitle } from "../../components";
import { CardNomenclature } from "../../components/Cards";
import FormNomenclature from "../../components/Modal/components/FormNomenclature";
import { nomenclatureTypes } from "./types/nomenclatureTypes";
import { nomenclatureTypesTranslated } from "./types/nomenclatureTypesTranslated";
import {
  showModal,
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
} from "../../redux/reducers";
import { useGetPermissionsForPage } from "../../hooks/hooks";
import {
  sortLanguagesEnBgOther,
  getVisibleTableColumns,
} from "../../utils/utils";
import "./Nomenclatures.scss";

export default function Nomenclatures() {
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userPermissions = useGetPermissionsForPage("nomenclatures");
  const { canCreate, canUpdate, canDelete } = userPermissions;

  /**
   * Fetch languages.
   */
  const [languages, setLanguages] = useState([]);

  useOnMount(() => {
    getAndSetLanguages();
  });

  function getAndSetLanguages() {
    api
      .getNomenclature("LANGUAGE")
      .then((response) => {
        setLanguages(sortLanguagesEnBgOther(response.data));
      })
      .catch((error) => {
        showHttpRequestError(error);
      });
  }

  /**
   * Select and display nomenclature.
   */
  const [selectedNomenclatureType, setSelectedNomenclatureType] =
    useState(null);
  const isNomenclatureTypeSelected = !!selectedNomenclatureType?.label;
  const [selectedNomenclature, setSelectedNomenclature] = useState([]);
  const nomenclaturesForSelect = useRef([]);

  if (!nomenclaturesForSelect.current.length) {
    addNomenclaturesForSelect();
  }

  function addNomenclaturesForSelect() {
    for (const [key, value] of Object.entries(nomenclatureTypes)) {
      if (value.hidden) continue; // Skip hidden nomenclatures.
      nomenclaturesForSelect.current.push({ value: key, label: value.label });
    }
  }

  useEffect(() => {
    if (selectedNomenclatureType) {
      fetchAndSetNomenclature(selectedNomenclatureType.value);
    }
  }, [selectedNomenclatureType]);

  function fetchAndSetNomenclature(nomenclatureType) {
    api
      .getNomenclature(nomenclatureType)
      .then((response) => {
        setSelectedNomenclature(response.data);
      })
      .catch((error) => {
        showHttpRequestError(error);
      });
  }

  /**
   * Non-deleteable nomenclatures.
   */
  const isNomenclatureNonDeletable = (nomenclatureType) => {
    const nonDeletableNomenclatures = ["LANGUAGE"];
    return nonDeletableNomenclatures.includes(nomenclatureType);
  };

  /**
   * Table setup.
   */
  const allColumns = [
    {
      accessorKey: "name",
      isPlaceholder: true,
      header: () => <span>{t("Name")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      isVisible: canUpdate,
      id: "update",
      header: () => <span>{t("Translate")}</span>,
      cell: (info) => showTranslateButton(info),
      footer: (props) => props.column.id,
    },
    {
      isVisible:
        canDelete &&
        !isNomenclatureNonDeletable(selectedNomenclatureType?.value),
      id: "delete",
      header: () => <span>{t("Delete")}</span>,
      cell: (info) => showDeleteButton(info),
      footer: (props) => props.column.id,
    },
  ];

  const visibleColumns = getVisibleTableColumns(allColumns);
  const tableColumns = [
    {
      header: t("Results"),
      footer: (props) => props.column.id,
      columns: visibleColumns,
    },
  ];

  function showTranslateButton(table) {
    const nomenclature = table.row.original;
    const isCompletelyTranslated = isNomenclatureCompletelyTranslated(
      nomenclature,
      selectedNomenclatureType.value
    );
    return isCompletelyTranslated ? (
      <button
        className="td-action secondary edit"
        onClick={() => translateNomenclature(nomenclature)}
        title={t("View translation")}
      >
        <Icon name="magnify" /> {t("View")}
      </button>
    ) : (
      <button
        className="td-action edit"
        onClick={() => translateNomenclature(nomenclature)}
        title={t("Finish translation")}
      >
        <Icon name="pencil" /> {t("Translate")}
      </button>
    );
  }

  function showDeleteButton(table) {
    const nomenclature = table.row.original;
    return (
      <button
        className="td-action secondary delete"
        onClick={(e) => {
          e.stopPropagation();
          deleteNomenclature(nomenclature);
        }}
        title={t("Delete nomenclature item")}
      >
        <Icon name="delete" />
      </button>
    );
  }

  /**
   * Add nomenclature.
   */
  const [nomenclatureForTranslation, setNomenclatureForTranslation] =
    useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalCustomClass, setModalCustomClass] = useState("");

  function addNomenclature() {
    const title =
      nomenclatureTypes?.[selectedNomenclatureType.value]?.label ??
      "New nomenclature";
    setNomenclatureForTranslation(null);
    setModalTitle(title);
    setModalCustomClass("creating");
    dispatch(
      showModal({
        id: "nomenclatureModal",
      })
    );
  }

  /**
   * Edit nomenclature.
   */
  function translateNomenclature(nomenclature) {
    const title = nomenclatureTypes?.[selectedNomenclatureType.value]?.label;
    setModalTitle(title);
    setModalCustomClass("editing");
    setNomenclatureForTranslation(nomenclature);
    dispatch(
      showModal({
        id: "nomenclatureModal",
      })
    );
  }

  /**
   * Delete nomenclature.
   */
  async function deleteNomenclature(nomenclature) {
    const { id, name } = nomenclature;
    const deleteDialog = {
      title: `${t("Delete nomenclature item")} ${name}?`,
      message: t(
        "You shouldn't delete nomenclature items that are used in some workflow. You can safely delete unused nomenclature items (newly created or never used)."
      ),
      buttonConfirmText: t("Delete"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .deleteNomenclature(selectedNomenclatureType.value, id)
      .then((response) => {
        dispatch(
          showSuccessPopup(
            `${t("Successfully deleted nomenclature item")} ${name}.`
          )
        );
        fetchAndSetNomenclature(selectedNomenclatureType.value);
      })
      .catch((error) => showHttpRequestError(error));
  }

  function isNomenclatureCompletelyTranslated(nomenclature, nomenclatureType) {
    if (!nomenclature?.id) return false;

    const type = nomenclatureTypes?.[nomenclatureType];
    const allRequiredFields = type.fields
      .filter((field) => field.required && !(field.type === "checkbox"))
      .map((field) => {
        if (field.name === "body") {
          return "name";
        }
        if (field.type === "select") {
          return field.name + "Id";
        }
        return field.name;
      });

    // filter only fields that need to be translated in EN language
    let filteredNomeFields = Object.keys(nomenclature).filter(
      (key) => key !== "translations" && key !== "id" && !key.endsWith("Name")
    );

    const isEnglishCompletelyTranslated =
      allRequiredFields.every((key) => {
        return (
          nomenclature[key] !== null &&
          nomenclature[key] !== "" &&
          nomenclature[key] !== undefined
        );
      }) && filteredNomeFields.length >= allRequiredFields.length;

    const shouldBeTranslated = type?.translation;
    const hasAnyTranslations = nomenclature?.translations?.length;
    let isTranslationsCompletelyTranslated = true;

    if (shouldBeTranslated && !hasAnyTranslations) {
      isTranslationsCompletelyTranslated = false;
    }

    if (shouldBeTranslated && hasAnyTranslations) {
      const allRequiredFieldsForTranslation = allRequiredFields.filter(
        (field) => {
          return !field.endsWith("Id");
        }
      );

      isTranslationsCompletelyTranslated = nomenclature.translations.every(
        (translation) => {
          const filteredNomeTransFields = Object.keys(translation).filter(
            (key) =>
              key !== "id" && key !== "languageId" && key !== "languageName"
          );

          return (
            allRequiredFieldsForTranslation.every((key) => {
              return (
                translation[key] !== null &&
                translation[key] !== "" &&
                translation[key] !== undefined
              );
            }) &&
            filteredNomeTransFields.length >=
              allRequiredFieldsForTranslation.length &&
            nomenclature.translations.length === languages.length - 1 // minus the english translation
          );
        }
      );
    }

    return isEnglishCompletelyTranslated && isTranslationsCompletelyTranslated;
  }

  return (
    <div className="nomenclatures">
      <PageTitle title={t("Nomenclatures")} />

      <div className="patients-and-advices">
        <div className="flex-grow relative z-10">
          <div className="input-wrapper select-nomenclature">
            <label htmlFor="wardNomName">{t("Select:")}</label>
            <Select
              name="nomenclature"
              options={nomenclaturesForSelect.current}
              isSearchable={true}
              styles={selectStyles}
              value={selectedNomenclatureType ?? null}
              onChange={(e) => setSelectedNomenclatureType(e)}
              placeholder={t("Nomenclature")}
            />
          </div>

          {isNomenclatureTypeSelected && (
            <DataTable
              tableData={selectedNomenclature}
              tableColumns={tableColumns}
              handleAddNew={addNomenclature}
              handleCardClick={(nomenclature) => {
                translateNomenclature(nomenclature);
              }}
              userPermissions={userPermissions}
              CardComponent={(props) => (
                <CardNomenclature languages={languages} {...props} />
              )}
              autoResetPageIndex={true}
            />
          )}
        </div>

        <div className="sidebar">
          <div className="card-wrapper">
            <h2>{t("Instructions")}</h2>
            <p>
              {t(
                "Some nomenclatures are built on top of others. You can follow this sequence during creation."
              )}
            </p>
            <h3>1. {t("Add simple nomenclatures:")}</h3>
            <ul>
              <li>{t("adverse drug reactions")}</li>
              <li>{t("application methods")}</li>
              <li>{t("bacteria")}</li>
              <li>{t("disease groups")}</li>
              <li>{t("frequencies")}</li>
              <li>{t("gender")}</li>
              <li>{t("measures")}</li>
              <li>{t("medicament forms")}</li>
              <li>{t("medicament groups")}</li>
              <li>{t("wards")}</li>
              <li>{t("reject reasons")}</li>
              <li>{t("questions")}</li>
            </ul>
            <h3>2. {t("Add complex nomenclatures:")}</h3>
            <ul>
              <li>{t("diseases")}</li>
              <li>{t("medicaments")}</li>
              <li>{t("answers")}</li>
            </ul>
            <h3>3. {t("Build workflows.")}</h3>
          </div>
        </div>
      </div>

      {(canCreate || canUpdate) && (
        <Modal
          id={"nomenclatureModal"}
          title={modalTitle}
          customClasses={modalCustomClass}
          isLarge
          innerComponent={
            <FormNomenclature
              languages={languages}
              nomenclatureTypes={nomenclatureTypes}
              nomenclatureTypesTranslated={nomenclatureTypesTranslated}
              selectedNomenclatureType={selectedNomenclatureType?.value}
              nomenclatureForTranslation={nomenclatureForTranslation}
              setNomenclatureForTranslation={setNomenclatureForTranslation}
              translateNomenclature={translateNomenclature}
              selectedNomenclature={selectedNomenclature}
              setSelectedNomenclature={setSelectedNomenclature}
              isNomenclatureCompletelyTranslated={
                isNomenclatureCompletelyTranslated
              }
            />
          }
        />
      )}
    </div>
  );
}
