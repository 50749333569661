import { useTranslation } from "react-i18next";
import "./Counter.scss";

export default function Counter({ count, percentage, durationDays }) {
  const { t } = useTranslation();

  return (
    <div className="counter">
      <div className="count">{count}</div>
      {durationDays && (
        <div className="duration">
          {t("last")} {durationDays} {t("days")} ({percentage}%)
        </div>
      )}
    </div>
  );
}
