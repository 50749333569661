import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Icon from "../Icon/Icon";
import Select from "react-select";
import { showDialog, isDialogConfirmed } from "../../redux/reducers";
import { smallSelectStyles } from "../../styles/selectStyles";
import { useTranslation } from "react-i18next";
import "./Medicament.scss";

export default function Medicament({
  isPrevention,
  medicament,
  medicamentIndex,
  medicaments,
  medicamentForms,
  treatmentMedicaments,
  setTreatmentMedicaments,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  treatmentTitle,
  treatmentInfo,
  setIsSaveTreatmentEnabled,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    medicamentId,
    marketFormId,
    dose,
    doseMeasureId,
    frequency,
    applicationMethod,
    whenToApply,
    whenToRepeat,
    durationOfTreatment,
    evaluationPeriod,
  } = medicament;

  const selectedMedicament = (medicament) => medicament.value === medicamentId;

  const selectedMedicamentForm = (medicamentForm) =>
    medicamentForm.value === marketFormId;

  const selectedDoseMeasure = (doseMeasure) =>
    doseMeasure.value === doseMeasureId;

  const selectedTimeMeasure = (time) =>
    time.value === applicationMethod.measureId;

  const selectedApplicationMethod = (method) =>
    method.value === applicationMethod.applicationMethodId;

  async function removeMedicament(index) {
    const dialog = {
      title: t("Remove medicament?"),
      message: t("You will lose all entered data."),
      buttonConfirmText: t("Remove"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(dialog));
    const isActionConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isActionConfirmed) return;

    const newMedicaments = [...treatmentMedicaments];
    newMedicaments.splice(index, 1);
    setTreatmentMedicaments([...newMedicaments]);
  }

  useEffect(enableSaveTreatment, [
    treatmentTitle,
    medicamentId,
    marketFormId,
    dose,
    doseMeasureId,
    frequency.time,
    frequency.quantity,
    frequency.measureId,
    applicationMethod.time,
    applicationMethod.applicationMethodId,
    whenToApply,
    durationOfTreatment,
  ]);

  function enableSaveTreatment() {
    if (isPrevention) {
      if (
        treatmentTitle && // First choice treatment
        medicamentId && // Cefazolin
        marketFormId && // Ampules
        dose && // 2
        doseMeasureId && // g
        applicationMethod.applicationMethodId && // iv
        whenToApply // 30 minutes before the operation
      ) {
        setIsSaveTreatmentEnabled(true);
      } else {
        setIsSaveTreatmentEnabled(false);
      }
    }

    if (!isPrevention) {
      if (
        treatmentTitle && // First choice treatment
        medicamentId && // Cefazolin
        marketFormId && // Ampules
        dose && // 2
        doseMeasureId && // g
        frequency.quantity && // 2 tablets
        frequency.time && // 2 times
        frequency.measureId && // per day
        applicationMethod.applicationMethodId && // peroral
        durationOfTreatment // for 2 days
      ) {
        setIsSaveTreatmentEnabled(true);
      } else {
        setIsSaveTreatmentEnabled(false);
      }
    }
  }

  function updateMedicamentProp(prop, value) {
    const newTreatmentMedicaments = [...treatmentMedicaments];
    newTreatmentMedicaments[medicamentIndex][prop] = value;
    setTreatmentMedicaments([...newTreatmentMedicaments]);
  }

  function updateFrequencyProp(prop, value) {
    const newTreatmentMedicaments = [...treatmentMedicaments];
    newTreatmentMedicaments[medicamentIndex].frequency[prop] = value;
    setTreatmentMedicaments([...newTreatmentMedicaments]);
  }

  function updateApplicationMethodProp(prop, value) {
    const newTreatmentMedicaments = [...treatmentMedicaments];
    newTreatmentMedicaments[medicamentIndex].applicationMethod[prop] = value;
    setTreatmentMedicaments([...newTreatmentMedicaments]);
  }

  /**
   * Insert formula placeholder into the dose input
   */
  const doseRef = useRef(null);
  const whenToApplyRef = useRef(null);

  function insertFormulaPlaceholder(inputRef, formula) {
    const { selectionStart, selectionEnd } = inputRef.current;
    const input = inputRef.current;
    const value = input.value;

    const newValue =
      value.substring(0, selectionStart) +
      formula +
      value.substring(selectionEnd, value.length);

    input.value = newValue;
    input.focus();
    input.setSelectionRange(
      selectionStart + formula.length,
      selectionStart + formula.length
    );
  }

  /**
   * Copy measure id.
   */
  const [areMeasureIdsVisible, setAreMeasureIdsVisible] = useState(false);

  function toggleMeasureIDs() {
    setAreMeasureIdsVisible((prev) => !prev);
  }

  function MeasureId({ measure }) {
    const [isCopied, setIsCopied] = useState(false);

    function copyMeasureId(id) {
      navigator.clipboard.writeText(id);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    return (
      <div
        key={measure.id}
        onClick={(e) => {
          e.preventDefault();
          copyMeasureId(measure.value);
        }}
      >
        <span>{isCopied ? t("ID copied!") : measure.label}</span>
        <button
          className="copy-measure-id secondary smaller"
          title={t("Copy measure ID")}
          onClick={(e) => {
            e.preventDefault();
            copyMeasureId(measure.value);
          }}
        >
          <Icon name="content-duplicate" />
        </button>
      </div>
    );
  }

  return (
    <div className="medicament-form">
      <h3>
        {t("Medicament")} {medicamentIndex + 1}
      </h3>

      <div
        className="modal-close"
        title={t("Remove medicament")}
        onClick={() => removeMedicament(medicamentIndex)}
      >
        <Icon name="cross" />
      </div>

      <div className="flex-input-wrapper">
        <div className="input-wrapper">
          <label htmlFor="medicament">{t("Name:")}</label>
          <Select
            name="medicament"
            options={medicaments}
            isSearchable={true}
            required
            styles={smallSelectStyles}
            openMenuOnClick
            openMenuOnFocus
            value={medicaments.find(selectedMedicament) ?? null}
            onChange={(e) => updateMedicamentProp("medicamentId", e.value)}
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="formId">{t("Form:")}</label>
          <Select
            name="formId"
            options={medicamentForms}
            isSearchable={true}
            required
            styles={smallSelectStyles}
            openMenuOnClick
            openMenuOnFocus
            value={medicamentForms.find(selectedMedicamentForm) ?? null}
            onChange={(e) => updateMedicamentProp("marketFormId", e.value)}
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="dose" className="has-button">
            {t("Dose:")}

            <button
              className="insert-formula secondary smaller"
              title={t("Insert formula placeholder")}
              onClick={(e) => {
                e.preventDefault();
                insertFormulaPlaceholder(doseRef, "#F{{z}}F#");
              }}
            >
              <Icon name="file-multiple" />
            </button>
          </label>
          <input
            type="text"
            name="dose"
            id="dose"
            required
            value={dose}
            onChange={(e) => updateMedicamentProp("dose", e.target.value)}
            placeholder={"200"}
            min={0}
            ref={doseRef}
            className="small"
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="doseMeasure">{t("Measure:")}</label>
          <Select
            name="doseMeasure"
            options={doseMeasures}
            isSearchable={true}
            required
            styles={smallSelectStyles}
            openMenuOnClick
            openMenuOnFocus
            value={doseMeasures.find(selectedDoseMeasure) ?? null}
            onChange={(e) => updateMedicamentProp("doseMeasureId", e.value)}
          />
        </div>
      </div>

      {!isPrevention && (
        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="quantity">{t("Quantity:")}</label>
            <input
              type="number"
              name="quantity"
              id="quantity"
              required
              value={frequency.quantity}
              onChange={(e) => updateFrequencyProp("quantity", e.target.value)}
              placeholder={"2"}
              min={0}
              className="small"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="time">{t("Times:")}</label>
            <input
              type="number"
              name="time"
              id="time"
              required
              value={frequency.time}
              onChange={(e) => updateFrequencyProp("time", e.target.value)}
              placeholder={"2"}
              min={0}
              className="small"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="doseMeasure">{t("Per:")}</label>
            <Select
              name="timeMeasure"
              options={timeMeasures}
              isSearchable={true}
              styles={smallSelectStyles}
              openMenuOnClick
              openMenuOnFocus
              value={timeMeasures.find(selectedTimeMeasure) ?? null}
              onChange={(e) =>
                updateApplicationMethodProp("measureId", e.value)
              }
              menuPlacement="auto"
            />
          </div>

          {!isPrevention && (
            <div className="input-wrapper">
              <label htmlFor="applicationMethod">{t("Application:")}</label>
              <Select
                name="applicationMethod"
                options={applicationMethods}
                isSearchable={true}
                required
                styles={smallSelectStyles}
                openMenuOnClick
                openMenuOnFocus
                value={
                  applicationMethods.find(selectedApplicationMethod) ?? null
                }
                onChange={(e) =>
                  updateApplicationMethodProp("applicationMethodId", e.value)
                }
                menuPlacement="auto"
              />
            </div>
          )}

          <div className="input-wrapper">
            <label htmlFor="durationOfTreatment">{t("Days:")}</label>
            <input
              type="text"
              name="durationOfTreatment"
              id="durationOfTreatment"
              placeholder="7"
              required
              value={durationOfTreatment}
              onChange={(e) =>
                updateMedicamentProp("durationOfTreatment", e.target.value)
              }
              className="small"
            />
          </div>
        </div>
      )}

      {isPrevention && (
        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="applicationMethod">{t("Application:")}</label>
            <Select
              name="applicationMethod"
              options={applicationMethods}
              isSearchable={true}
              required
              styles={smallSelectStyles}
              openMenuOnClick
              openMenuOnFocus
              value={applicationMethods.find(selectedApplicationMethod) ?? null}
              onChange={(e) =>
                updateApplicationMethodProp("applicationMethodId", e.value)
              }
              menuPlacement="auto"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="time">{t("Times:")}</label>
            <input
              type="number"
              name="time"
              id="time"
              value={applicationMethod.time}
              onChange={(e) =>
                updateApplicationMethodProp("time", e.target.value)
              }
              placeholder={"2"}
              min={0}
              className="small"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="timeMeasure">{t("Per:")}</label>
            <Select
              name="timeMeasure"
              options={timeMeasures}
              isSearchable={true}
              styles={smallSelectStyles}
              openMenuOnClick
              openMenuOnFocus
              value={timeMeasures.find(selectedTimeMeasure) ?? null}
              onChange={(e) =>
                updateApplicationMethodProp("measureId", e.value)
              }
              menuPlacement="auto"
            />
          </div>
        </div>
      )}

      <div className="flex-input-wrapper">
        <div className="input-wrapper">
          <label htmlFor="whenToApply" className="has-button">
            {t("When to Apply:")}

            <button
              className="insert-formula secondary smaller"
              title={t("Insert formula placeholder")}
              onClick={(e) => {
                e.preventDefault();
                insertFormulaPlaceholder(whenToApplyRef, "#F{{z}}F##M{}");
              }}
            >
              <Icon name="file-multiple" />
            </button>

            <button
              className="insert-formula secondary smaller"
              title={t("View measure IDs")}
              onClick={(e) => {
                e.preventDefault();
                toggleMeasureIDs();
              }}
            >
              {areMeasureIdsVisible ? (
                <Icon name="cross" />
              ) : (
                <Icon name="menu" />
              )}
            </button>

            {areMeasureIdsVisible && (
              <div className="measure-ids">
                {doseMeasures.map((measure) => (
                  <MeasureId measure={measure} />
                ))}
              </div>
            )}
          </label>

          <textarea
            name="whenToApply"
            id="whenToApply"
            placeholder={
              isPrevention
                ? t("applied 30 minutes before the start of the operation.")
                : t("30 minutes before meal.")
            }
            maxLength="3000"
            value={whenToApply ?? ""}
            onChange={(e) =>
              updateMedicamentProp("whenToApply", e.target.value)
            }
            required={isPrevention ? true : false}
            ref={whenToApplyRef}
            className="small"
            rows="2"
          ></textarea>
        </div>

        {isPrevention && (
          <div className="input-wrapper">
            <label htmlFor="whenToRepeat">{t("When to Repeat:")}</label>
            <textarea
              name="whenToRepeat"
              id="whenToRepeat"
              placeholder={t(
                "Repeat the same dosage 6 hours after the operation."
              )}
              maxLength="3000"
              value={whenToRepeat ?? ""}
              onChange={(e) =>
                updateMedicamentProp("whenToRepeat", e.target.value)
              }
              className="small"
              rows="2"
            ></textarea>
          </div>
        )}
      </div>
    </div>
  );
}
