import { useState } from "react";
import { Handle, Position } from "reactflow";
import { v4 as generateUUID } from "uuid";
import TreatmentSummary from "../../TreatmentSummary/TreatmentSummary";
import { useTranslation } from "react-i18next";
import "./NodeAdvice.scss";

export default function NodeAdvice({ data }) {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const toggleIsOpened = () => setIsOpened(!isOpened);

  const hasReminder = Boolean(data?.advice?.reminder);
  const hasBacteria = Boolean(data?.advice?.bacteriaIds?.length > 1);

  return (
    <div
      key={generateUUID()}
      onClick={toggleIsOpened}
      className={isOpened ? "node-opened" : "node-closed"}
    >
      <Handle type="target" position={Position.Top} />

      {data?.advice?.treatments?.length > 0 &&
        data?.advice?.treatments.map((treatment) => (
          <div className="input-wrapper no-margins" key={generateUUID()}>
            <label>{treatment?.title}</label>

            {treatment?.medicaments?.length > 0 &&
              treatment.medicaments.map((medicament) => {
                return (
                  <TreatmentSummary
                    key={generateUUID()}
                    medicament={medicament}
                    medicaments={data.medicaments}
                    medicamentForms={data.medicamentForms}
                    doseMeasures={data.doseMeasures}
                    timeMeasures={data.timeMeasures}
                    applicationMethods={data.applicationMethods}
                    isPrevention={data.isPrevention}
                  />
                );
              })}

            {treatment?.info && (
              <div className="summary-treatment-info" title={treatment.info}>
                <b>{t("Treatment Info:")}</b> {treatment.info}
              </div>
            )}
          </div>
        ))}

      {(isOpened || hasReminder) && <hr></hr>}

      {isOpened && (hasReminder || hasBacteria) && <label>{t("Other")}</label>}

      {data?.advice?.reminder && (
        <div className="summary-treatment-info" title={data.advice.reminder}>
          <b>{t("Reminders:")}</b> {data.advice.reminder}
        </div>
      )}

      {isOpened && data?.advice?.bacteriaIds?.length > 1 && (
        <div className="summary-treatment-info">
          <b>{t("Bacteria:")}</b>
          {data?.advice?.bacteriaIds.map((bacteriaId) => {
            return (
              <div className="summary-bacteria treatment" key={generateUUID()}>
                {
                  data.bacteria.find(
                    (bacteria) => bacteria.value === bacteriaId
                  ).label
                }
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
