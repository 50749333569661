import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  showModalHospital,
  setEditedHospitalWards,
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
} from "../../redux/reducers";
import { useGetPermissionsForPage, useOnMount } from "../../hooks/hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import {
  DataTable,
  Icon,
  ModalHospital,
  InputSwitch,
  MyHospital,
  PageTitle,
} from "../../components";
import { getVisibleTableColumns } from "../../utils/utils";
import { orderActiveFirst, orderByName } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { CardDefault } from "../../components/Cards";

export default function HospitalManagement() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const userPermissions = useGetPermissionsForPage("hospitalManagement");
  const user = useSelector((state) => state.user);
  const userRole = user.role;
  const isHeadOfHospital = userRole === "HEAD OF HOSPITAL";
  const isHeadOfWard = userRole === "HEAD OF WARD";
  const { canCreate, canUpdate, canDelete, canDeactivate } = userPermissions;

  /**
   * Table with hospitals.
   */
  const [tableData, setTableData] = useState([]);
  useOnMount(() => {
    const hasTableData = !!tableData.length;
    if (!hasTableData) {
      fetchTableData();
    }
  });

  async function fetchTableData() {
    const hospitals = await api
      .getHospitals()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    let hospitalsToShow = [...hospitals];

    /*if (isHeadOfHospital || isHeadOfWard) {
      let filteredHospitals = hospitalsToShow.filter(
        (hospital) => hospital.id === user.hospitalId
      );
      hospitalsToShow = [...filteredHospitals];
    }*/

    const orderedHospitals = orderByName(orderActiveFirst(hospitalsToShow));
    setTableData(orderedHospitals);
  }

  /**
   * All initial columns for the user table (as an admin would see it).
   * - columns with "isRestricted" prop get filtered based on user permissions.
   * - columns without "isRestricted" are visible for everyone.
   *
   * In the columns:
   * - accessorKey - the key from the BE object (name, email, etc)
   * - header - value in the TH cell
   * - cell - value in the TD cell
   * - isVisible - not part of Tanstack table. Added to be able to filter the columns by permissions.
   */
  const allColumns = [
    {
      accessorKey: "name",
      header: () => <span>{t("Hospital")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "countryName",
      header: () => <span>{t("Country")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "cityName",
      header: () => <span>{t("City")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "contactPerson",
      header: () => <span>{t("Contact")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "email",
      header: () => <span>{t("Email")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "phone",
      header: () => <span>{t("Phone")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      isVisible: canDeactivate,
      accessorKey: "active",
      header: () => <span>{t("Active")}</span>,
      cell: (info) => showActivateButton(info),
      footer: (props) => props.column.id,
    },
    {
      isVisible: canUpdate,
      id: "update",
      header: () => <span>{t("Edit")}</span>,
      cell: (info) => showEditButton(info),
      footer: (props) => props.column.id,
    },
    {
      isVisible: canDelete,
      id: "delete",
      header: () => <span>{t("Delete")}</span>,
      cell: (info) => showDeleteButton(info),
      footer: (props) => props.column.id,
    },
  ];

  const visibleColumns = getVisibleTableColumns(allColumns);
  const tableColumns = [
    {
      header: "Results",
      footer: (props) => props.column.id,
      columns: visibleColumns,
    },
  ];

  function showEditButton(table) {
    const hospital = table.row.original;
    const isActive = hospital.active;
    return (
      <button
        className="td-action edit"
        onClick={() => editHospital(hospital)}
        title={t("Edit hospital")}
        disabled={!isActive}
      >
        <Icon name="pencil" /> {t("Edit")}
      </button>
    );
  }

  function showDeleteButton(table) {
    const hospital = table.row.original;
    const isActive = hospital.active;
    return (
      <button
        className="td-action secondary delete"
        onClick={(e) => {
          e.stopPropagation();
          deleteHospital(hospital);
        }}
        title={t("Delete hospital")}
        disabled={!isActive}
      >
        <Icon name="delete" />
      </button>
    );
  }

  /**
   * Toggle hospital active/inactive status.
   */
  function showActivateButton(table) {
    const hospital = table.row.original;
    return (
      <InputSwitch
        id={hospital.id}
        isChecked={hospital.active}
        onChangeFn={() => toggleHospitalActiveStatus(hospital)}
        labelText={
          hospital.active ? t("Deactivate hospital") : t("Activate hospital")
        }
      />
    );
  }

  async function toggleHospitalActiveStatus(hospital) {
    const isHospitalActive = hospital.active;
    const hospitalId = hospital.id;
    const hospitalName = hospital.name;
    const activateOrDeactivate = isHospitalActive
      ? t("Deactivate")
      : t("Activate");

    const message = isHospitalActive
      ? t(
          "This will deactivate all its wards and its users will not be able to login until you reactivate it."
        )
      : t(
          "This will activate all its wards and its users will be able to login again."
        );

    const dialog = {
      title: `${activateOrDeactivate} ${t("hospital")} ${hospitalName}?`,
      message: message,
      buttonConfirmText: activateOrDeactivate,
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(dialog));
    const isActionConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isActionConfirmed) return;

    if (isHospitalActive) {
      deactivateHospital(hospitalId);
    } else {
      activateHospital(hospitalId);
    }

    function deactivateHospital(id) {
      api
        .deactivateHospital({ hospitalId: id })
        .then((response) => {
          dispatch(
            showSuccessPopup(
              `${t("Successfully deactivated hospital")} ${hospitalName}.`
            )
          );
          fetchTableData();
        })
        .catch((error) => showHttpRequestError(error));
    }

    function activateHospital(id) {
      api
        .activateHospital({ hospitalId: id })
        .then((response) => {
          dispatch(
            showSuccessPopup(
              `${t("Successfully activated hospital")} ${hospitalName}.`
            )
          );
          fetchTableData();
        })
        .catch((error) => showHttpRequestError(error));
    }
  }

  /**
   * Add, edit and delete hospitals.
   */
  function addHospital() {
    dispatch(showModalHospital());
  }

  function editHospital(hospital) {
    Promise.allSettled([
      api.getHospital(hospital.id),
      api.getHospitalWards(hospital.id),
    ])
      .then((values) => {
        const fetchedHospital = values[0].value.data;
        const fetchedWards = values[1].value.data;
        dispatch(showModalHospital(fetchedHospital));
        dispatch(setEditedHospitalWards(fetchedWards));
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function deleteHospital(hospital) {
    const { id, name: hospitalName } = hospital;
    const deleteDialog = {
      title: `${t("Delete hospital")} ${hospitalName}?`,
      message: t("This will also delete all wards and users in this hospital."),
      buttonConfirmText: t("Delete"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .deleteHospital(id)
      .then((response) => {
        dispatch(
          showSuccessPopup(
            `${t("Successfully deleted hospital")} ${hospitalName}.`
          )
        );
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error));
  }

  // For all roles except admin, show only the hospital they belong to.
  const canEditOwnHospitalAndNotAdmin =
    (canCreate || canUpdate) && !user.isAdmin;

  const ownHospital = tableData.filter(
    (hospital) => hospital.id === user.hospitalId
  )[0];

  if (canEditOwnHospitalAndNotAdmin) {
    return (
      <>
        <MyHospital hospital={ownHospital} editHospital={editHospital} />
        <ModalHospital fetchTableData={fetchTableData} />
      </>
    );
  }

  return (
    <div className="hospital-management">
      <PageTitle title={t("Hospitals and Wards")} />

      <DataTable
        tableData={tableData}
        tableColumns={tableColumns}
        handleAddNew={addHospital}
        userPermissions={userPermissions}
        handleCardClick={editHospital}
        CardComponent={CardDefault}
      />

      {(canCreate || canUpdate) && (
        <ModalHospital fetchTableData={fetchTableData} />
      )}
    </div>
  );
}
