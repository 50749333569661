import { useDispatch, useSelector } from "react-redux";
import {
  hideMessagePopup,
  hideDialog,
  hideModal,
} from "../../redux/reducers/index";

export default function ModalBackground() {
  const dispatch = useDispatch();
  const isVisible = useSelector(
    (state) => state.modals.modalBackground.isVisible
  );

  return (
    <div
      className={`modal-background ${isVisible ? "toggled" : "hidden"}`}
      onClick={() => {
        dispatch(hideModal());
        dispatch(hideMessagePopup());
        dispatch(hideDialog());
      }}
    ></div>
  );
}
