import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getMenuLinksByPermissions } from "../../config/menuLinks";
import { ApiRequests, showHttpRequestError } from "../../http";
import {
  getPageHrefFromUrl,
  removeLocalStorage,
  getUserDisplayName,
} from "../../utils/utils";
import { setIsMenuOpened, resetUser } from "../../redux/reducers";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";
import "./MenuSidebar.scss";

export default function MenuSidebar() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userName = getUserDisplayName(user);

  /**
   * Display specific menu depending on permissions.
   */
  const menuLinks = getMenuLinksByPermissions(user.permissions);
  const thisPageHref = getPageHrefFromUrl();

  /**
   * Mobile menu should auto close after clicking a link within it.
   */
  const isMenuOpened = useSelector((state) => state.settings.isMenuOpened);

  const closeMenuIfOnMobile = () => {
    if (!isMenuOpened) return;
    const mobileBreakpoint = 1024;

    if (window.innerWidth <= mobileBreakpoint) {
      dispatch(setIsMenuOpened(false));
    }
  };

  /**
   * User logout.
   */
  const handleLogout = async () => {
    removeLocalStorage("isLoggedIn");
    dispatch(setIsMenuOpened(false));
    dispatch(resetUser());

    await api
      .logoutUser()
      .then((response) => {})
      .catch((error) => showHttpRequestError(error));
  };

  /**
   * Handle menu active item in internal pages with different hrefs than those in the menu. For example when on page "/workflow-builder" - menu item "Workflows" should be highlighted.
   */
  const isPageHrefException = (thisPageHref, menuHrefToBeHighlighted) => {
    let isException = false;
    const exceptions = {
      "/workflow-builder": "/workflows",
      "/hospital-workflow-builder": "/hospital-workflows",
      "/patient": "/patients",
    };

    for (const [key, value] of Object.entries(exceptions)) {
      if (key === thisPageHref && value === menuHrefToBeHighlighted) {
        isException = true;
      }
    }

    return isException;
  };

  return (
    <>
      <nav className="nav-sidebar">
        {menuLinks.map((menuLink) => {
          const { text, href, icon } = menuLink;
          const key = icon + href;
          const hrefText = href.slice(1); // remove leading slash

          let isLinkActive = href === thisPageHref;
          if (isPageHrefException(thisPageHref, href)) {
            isLinkActive = true;
          }

          const linkClasses = `nav-link ${hrefText} ${
            isLinkActive ? "current-page" : ""
          }`;

          return (
            <Link
              to={href}
              className={linkClasses}
              key={key}
              title={text}
              onClick={closeMenuIfOnMobile}
            >
              <Icon name={icon}></Icon>
              <span className="text">{text}</span>
            </Link>
          );
        })}
      </nav>

      {user.isLoggedIn && (
        <div className="profile">
          <Link
            to="/profile"
            className="nav-link view-profile"
            title={t("View your profile")}
            onClick={closeMenuIfOnMobile}
          >
            <Icon name="account" />
            <span className="text">{userName}</span>
          </Link>

          <Link
            to="/"
            className="nav-link logout"
            title={t("Logout")}
            onClick={handleLogout}
          >
            <Icon name="logout" />
          </Link>
        </div>
      )}
    </>
  );
}
