import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components";

export default function TermsOfUse() {
  const { t } = useTranslation();

  return (
    <div className="terms-of-use">
      <PageTitle title={t("Terms of Use")} />
    </div>
  );
}
