import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { hideModal } from "../../redux/reducers";
import { showHttpRequestError } from "../../http";
import { Advice } from "..";
import { useTranslation } from "react-i18next";
import "./TestAskForAdvice.scss";

export default function TestAskForAdvice({
  firstStepAdvice,
  nextStepAdvice,
  testWorkflow,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isModalOpened = useSelector(
    (state) =>
      state.modals.openedModal.id ===
      `testAdviceModal-${testWorkflow?.diseaseId}`
  );

  const emptyAdvice = {
    diseaseId: "",
    isConsultation: false,
    adviceId: "",
  };

  const [workflow, setWorkflow] = useState({});
  const [adviceFor, setAdviceFor] = useState({
    ...emptyAdvice,
  });

  useEffect(() => {
    if (!testWorkflow?.diseaseId || !isModalOpened) return;
    setWorkflow(testWorkflow);
    startQuestionnaire(testWorkflow);
  }, [isModalOpened]);

  /**
   * Advice questionnaire.
   */
  const [isQuestionnaireStarted, setIsQuestionnaireStarted] = useState(false);
  const questionsClosed = adviceFor.json?.questions;
  const [questionActive, setQuestionActive] = useState(null);

  const setQuestionInputAnswer = (e) => {
    if (questionActive.answer.type !== "input") {
      return;
    }
    questionActive.answer.value = e.target.value;
  };

  const setQuestionListAnswer = (e) => {
    if (questionActive.answer.type !== "list") {
      return;
    }
    for (let i = 0; i < questionActive.answer.value.length; i++) {
      let answer = questionActive.answer.value[i];
      if (answer.answerId === e.target.value) {
        answer.selected = true;
      } else {
        answer.selected = false;
      }
    }
  };

  const changeActiveQuestion = (e) => {
    if (questionActive.answer.type === "list") {
      setQuestionListAnswer(e);
    }
    if (questionActive.answer.type === "input") {
      setQuestionInputAnswer(e);
    }
    setQuestionActive(cloneDeep(questionActive));
  };

  const fetchQuestionActive = (questionId) => {
    for (let i = 0; i < adviceFor.json.questions.length; i++) {
      let q = adviceFor.json.questions[i];
      q.index = i + 1;
      if (q.questionId === questionId) {
        setQuestionActive(q);
        break;
      }
    }
  };

  async function startQuestionnaire(testWorkflow) {
    startAdvice(testWorkflow)
      .then((response) => {
        if (!response) {
          return;
        }
        const createdAdvice = response.data;

        setAdviceFor((adviceFor) => ({
          ...adviceFor,
          json: createdAdvice.json,
          adviceId: createdAdvice.adviceId,
          status: createdAdvice.status,
          additional: createdAdvice.additional,
        }));

        if (createdAdvice.status === "DRAFT") {
          let q =
            createdAdvice.json?.questions[
              createdAdvice.json?.questions.length - 1
            ];
          q.index = createdAdvice.json?.questions.length;
          setQuestionActive(q);
        } else {
          setQuestionActive(null);
        }

        setIsQuestionnaireStarted(true);
      })
      .catch((error) => showHttpRequestError(error));
  }

  // Starting the advice differs if it's a consultation or not.
  async function startAdvice(workflow) {
    if (!workflow?.diseaseId) {
      return;
    }
    const toSend = {
      workflow: workflow,
    };
    return firstStepAdvice(toSend);
  }

  async function showNextQuestion(e) {
    e.preventDefault();
    if (!workflow.diseaseId) return;

    for (let i = 0; i < adviceFor.json.questions.length; i++) {
      let question = adviceFor.json.questions[i];
      if (question.questionId === questionActive.questionId) {
        question.answer = questionActive.answer;
      }
    }

    const toSend = {
      workflow: workflow,
      json: adviceFor.json,
    };

    nextStepAdvice(toSend)
      .then((response) => {
        const updatedAdvice = response.data;
        setAdviceFor((adviceFor) => ({
          ...adviceFor,
          json: updatedAdvice.json,
          adviceId: updatedAdvice.adviceId,
          status: updatedAdvice.status,
          additional: updatedAdvice.additional,
        }));
        if (updatedAdvice.status === "DRAFT") {
          let q =
            updatedAdvice.json?.questions[
              updatedAdvice.json?.questions.length - 1
            ];
          q.index = updatedAdvice.json?.questions.length;
          setQuestionActive(q);
        } else {
          setQuestionActive(null);
        }
      })
      .catch((error) => showHttpRequestError(error));
  }

  function sortYesAnswersFirst(answers) {
    const sortedAnswers = [...answers].sort(function (a, b) {
      if (a.answerName === "Yes" && b.answerName !== "Yes") {
        return -1;
      }
      if (a.answerName !== "Yes" && b.answerName === "Yes") {
        return 1;
      }
      return 0;
    });
    return sortedAnswers;
  }

  const hasClosedQuestions = !!questionsClosed;
  const hasActiveQuestion = !!questionActive;
  const hasAdvice = Boolean(adviceFor?.json?.advice);

  /**
   * Guide the user to accept/reject the advice.
   */
  const [isAdviceAccepted, setIsAdviceAccepted] = useState(false);
  const [isAdviceRejected, setIsAdviceRejected] = useState(false);
  const isAdviceFinalized = isAdviceAccepted || isAdviceRejected;

  const [isChangingAnswers, setIsChangingAnswers] = useState(false);

  const isAdviceBackgroundVisible =
    !isChangingAnswers &&
    adviceFor.status === "COMPLETED" &&
    !isAdviceFinalized;

  function resetModal() {
    setWorkflow({});
    setQuestionActive(null);
    setAdviceFor({ ...emptyAdvice });
    setIsQuestionnaireStarted(false);
  }

  /**
   * Start new advice from old one.
   */
  function startNewAdvice() {
    setAdviceFor({
      ...emptyAdvice,
    });
    setIsQuestionnaireStarted(false);
    setQuestionActive(null);
    setIsAdviceAccepted(false);
    setIsAdviceRejected(false);
    setIsChangingAnswers(false);
    // setIsRejectAdviceVisible(false);
  }

  function ЕmptyAdvicePlaceholder() {
    return (
      <div className="advice placeholder">
        <div className="advice-header">
          <h2 className="advice-header-title">
            {t("Start the questionnaire...")}
          </h2>
        </div>
        <div className="treatment-wrapper">
          <h2 className="treatment-title">{t("Medicament of first choice")}</h2>
        </div>
        <div className="treatment-wrapper">
          <h2 className="treatment-title">{t("Alternatives")}</h2>
        </div>
        <div className="treatment-wrapper">
          <h2 className="treatment-title">{t("Combinations")}</h2>
        </div>
        <div className="side-effects">
          <h2>{t("Side Effects")}</h2>
        </div>
        <div className="interactions">
          <h2>{t("Drug Interactions")}</h2>
        </div>
        <div className="bacteria">
          <h2>{t("Usual microorganisms")}</h2>
        </div>
        <div className="reminders">
          <h2>{t("Reminders")}</h2>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`ask-for-advice ${
        isQuestionnaireStarted ? "questionnaire-started" : ""
      }`}
    >
      <div className="advice-wrapper">
        <div className="questions">
          <ul className="questions-list">
            {hasClosedQuestions &&
              questionsClosed.map((question, index) => (
                <li
                  key={"question" + index}
                  className="question answered"
                  title={question.questionName}
                  onClick={() => {
                    if (isAdviceFinalized) return;
                    fetchQuestionActive(question.questionId);
                  }}
                >
                  <h3>{question.questionName}</h3>

                  {question.answer.type === "list" &&
                    question.answer.value
                      .filter((a) => a.selected)
                      .map((a) => (
                        <div className="answer" key={a.answerId}>
                          {a.answerName}
                        </div>
                      ))}

                  {question.answer.type === "input" && (
                    <div className="answer">{question.answer.value}</div>
                  )}
                </li>
              ))}

            {hasActiveQuestion && (
              <li className="question active">
                <h3>
                  {questionActive.index}. {questionActive.questionName}
                </h3>

                <p>{questionActive.questionInfo}</p>

                {hasActiveQuestion && (
                  <form className="answers">
                    {questionActive.answer.type === "input" && (
                      <div className="input-wrapper inline">
                        <input
                          type="text"
                          name="answerInput"
                          value={questionActive.answer.value}
                          id="answerInput"
                          onChange={(e) => changeActiveQuestion(e)}
                        />
                      </div>
                    )}

                    {questionActive.answer.type === "list" &&
                      sortYesAnswersFirst(questionActive.answer.value).map(
                        (answer, index) => (
                          <div
                            className="input-wrapper inline"
                            key={answer.answerId}
                          >
                            <input
                              type="radio"
                              name={questionActive.questionId}
                              id={answer.answerId}
                              checked={answer.selected === true}
                              onChange={(e) => changeActiveQuestion(e)}
                              value={answer.answerId}
                            />
                            <label htmlFor={answer.answerId}>
                              {answer.answerName}
                            </label>
                          </div>
                        )
                      )}

                    <button
                      className="next-question"
                      onClick={(e) => showNextQuestion(e)}
                    >
                      {t("Next")}
                    </button>
                  </form>
                )}
              </li>
            )}
          </ul>
        </div>

        <div
          className={`advice-background ${
            isAdviceBackgroundVisible ? "toggled" : ""
          }`}
          onClick={() => {
            dispatch(hideModal());
            resetModal();
          }}
        ></div>

        {!isQuestionnaireStarted && <ЕmptyAdvicePlaceholder />}

        {hasAdvice && (
          <Advice
            adviceFor={adviceFor}
            isTestAdvice={true}
            startNewAdvice={startNewAdvice}
            isAdviceAccepted={isAdviceAccepted}
            isAdviceRejected={isAdviceRejected}
          />
        )}
      </div>
    </div>
  );
}
