import { useState } from "react";
import { useOnMount } from "../../hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { DataTableFiles, PageTitle } from "../../components";
import { useTranslation } from "react-i18next";

export default function AntibioticLeaflets() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const [tableData, setTableData] = useState([]);

  useOnMount(() => {
    const hasTableData = !!tableData.length;
    if (!hasTableData) fetchTableData();
  });

  async function fetchTableData() {
    const statistics = await getAntibioticResistanceStatistics();
    setTableData(statistics);
  }

  async function getAntibioticResistanceStatistics() {
    return api
      .getAntibioticResistanceStatistics()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  return (
    <div className="resistance-statistics files">
      <PageTitle title={t("Bacterial Resistance Statistics")} />

      <DataTableFiles
        tableData={tableData}
        fetchTableData={fetchTableData}
        pageName="abResistanceStatistics"
        showHospitalColumn
      />
    </div>
  );
}
