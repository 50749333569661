import "./InputSwitch.scss";

export default function InputSwitch({ id, isChecked, onChangeFn, labelText }) {
  return (
    <span className="input-switch" onClick={(e) => e.stopPropagation()}>
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={onChangeFn}
      />

      <label
        htmlFor={id}
        className="checkbox-label"
        tabIndex={0}
        title={labelText}
      >
        {labelText}
      </label>
    </span>
  );
}
