export const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: "12px",
    borderColor: state.isHovered
      ? "var(--black-transparent-400)"
      : state.isDisabled
      ? "var(--black-transparent-200)"
      : "var(--black-transparent-300)",
    outline: state.isFocused
      ? "4px solid var(--black-transparent-100);"
      : "none",
    boxShadow: "none",
    transition: "none",
    background: state.isDisabled ? "var(--black-transparent-35)" : "#fff",
    cursor: state.isDisabled ? "not-allowed" : "auto",
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: "6px 14px",
    cursor: "text",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "var(--black-700)" : "var(--black-700)",
    backgroundColor: state.isSelected ? "var(--primary-100)" : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "var(--primary-200)"
        : "var(--black-transparent-50)",
    },
  }),
};

export const smallSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: "0",
    fontSize: "15px",
    outlineWidth: "3px",
    borderRadius: "10px",
    borderColor: state.isHovered
      ? "var(--black-transparent-100)"
      : state.isDisabled
      ? "var(--black-transparent-100)"
      : "var(--black-transparent-100)",
    outline: state.isFocused
      ? "4px solid var(--black-transparent-100);"
      : "none",
    boxShadow: "none",
    transition: "none",
    background: state.isDisabled ? "transparent" : "#fff",
    cursor: state.isDisabled ? "not-allowed" : "auto",
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: "2px 10px",
    fontSize: "15px",
    cursor: "text",
    borderColor: state.isHovered
      ? "var(--black-transparent-100)"
      : state.isDisabled
      ? "var(--black-transparent-100)"
      : "var(--black-transparent-100)",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "var(--black-700)" : "var(--black-700)",
    backgroundColor: state.isSelected ? "var(--primary-100)" : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "var(--primary-200)"
        : "var(--black-transparent-50)",
    },
  }),
};

export const smallSelectStylesWithoutArrow = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: "0",
    fontSize: "15px",
    outlineWidth: "3px",
    borderRadius: "10px",
    borderColor: state.isHovered
      ? "var(--black-transparent-100)"
      : state.isDisabled
      ? "var(--black-transparent-100)"
      : "var(--black-transparent-100)",
    outline: state.isFocused
      ? "4px solid var(--black-transparent-100);"
      : "none",
    boxShadow: "none",
    transition: "none",
    background: state.isDisabled ? "transparent" : "#fff",
    cursor: state.isDisabled ? "not-allowed" : "auto",
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: "2px 10px",
    fontSize: "15px",
    cursor: "text",
    borderColor: state.isHovered
      ? "var(--black-transparent-100)"
      : state.isDisabled
      ? "var(--black-transparent-100)"
      : "var(--black-transparent-100)",
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "var(--black-700)"
      : state.isDisabled
      ? "var(--black-100)"
      : "var(--black-700)",
    textDecoration:
      state.isDisabled && !state.isSelected ? "line-through" : "none",
    backgroundColor: state.isSelected
      ? "var(--primary-100)"
      : state.isFocused
      ? "var(--black-transparent-100)"
      : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "var(--primary-200)"
        : "var(--black-transparent-50)",
    },
    fontSize: "14px",
  }),
};
