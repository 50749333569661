import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  setIsSidebarOpened,
  setIsMenuOpened,
} from "../../redux/reducers/index";
import { Icon } from "../index";
import "./AppSidebar.scss";

export default function AppSidebar({ children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * For opening and closing the sidebar on desktop.
   */
  const isSidebarOpened = useSelector(
    (state) => state.settings.isSidebarOpened
  );
  const toggleSidebar = () => dispatch(setIsSidebarOpened(!isSidebarOpened));
  const btnToggleSidebarText = isSidebarOpened ? t("Collapse") : t("Open");

  /**
   * For opening and closing the mobile menu.
   */
  const isMenuOpened = useSelector((state) => state.settings.isMenuOpened);
  const toggleMenu = () => dispatch(setIsMenuOpened(!isMenuOpened));

  return (
    <>
      <aside>
        <button className="btn-collapse-sidebar" onClick={toggleSidebar}>
          <Icon name="chevron-left"></Icon>
          <span className="text">{btnToggleSidebarText}</span>
        </button>

        <button className="nav-toggler" type="button" onClick={toggleMenu}>
          <div className="nav-toggler-inner">
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </button>

        {children}
      </aside>
    </>
  );
}
