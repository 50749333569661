import { PageTitle, Statistics as StatisticsComponent } from "../../components";
import { useTranslation } from "react-i18next";

export default function Statistics() {
  const { t } = useTranslation();

  return (
    <div className="statistics">
      <PageTitle title={t("Statistics")} />
      <StatisticsComponent />
    </div>
  );
}
