export const nomenclatureTypesTranslated = {
  ADVERSE_DRUG_REACTION_TRANSLATED: {
    label: "Adverse drug reaction translated",
    fields: [
      { name: "body", label: "Name", required: true, type: "text" },
      {
        name: "adverseDrugReaction",
        value: "nomenclatureId",
        type: "id",
      },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  ANSWER_TRANSLATED: {
    label: "Answer translated",
    fields: [
      { name: "body", label: "Name", required: true, type: "text" },
      { name: "answer", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  APPLICATION_METHOD_TRANSLATED: {
    label: "Application method translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "abbreviation", // това трябва да се праща, не applicationMethodAbr
        label: "Abbreviation",
        required: true,
        type: "text",
      },
      { name: "language", value: "languageId", type: "id" },
      {
        name: "applicationMethod",
        value: "nomenclatureId",
        type: "id",
      },
    ],
  },
  DISEASE_TRANSLATED: {
    label: "Disease translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      { name: "disease", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  DISEASE_GROUP_TRANSLATED: {
    label: "Disease group translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "diseaseGroup",
        value: "nomenclatureId",
        type: "id",
      },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  FREQUENCY_TRANSLATED: {
    label: "Frequency translated",
    fields: [
      { name: "body", label: "Name", required: true, type: "text" },
      {
        name: "frequency",
        value: "nomenclatureId",
        type: "id",
      },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  GENDER_TRANSLATED: {
    label: "Gender translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      { name: "gender", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  MEASURE_TRANSLATED: {
    label: "Measure translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "abbreviation",
        label: "Abbreviation",
        required: true,
        type: "text",
      },
      { name: "measure", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  MEDICAMENT_TRANSLATED: {
    label: "Medicament translated",
    fields: [
      {
        name: "interaction",
        label: "Interactions",
        required: true,
        type: "textarea",
      },
      {
        name: "medicament",
        value: "nomenclatureId",
        type: "id",
      },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  MEDICAMENT_FORM_TRANSLATED: {
    label: "Medicament form translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "medicamentForm",
        value: "nomenclatureId",
        type: "id",
      },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  MEDICAMENT_GROUP_TRANSLATED: {
    label: "Medicament group translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "medicamentGroup",
        value: "nomenclatureId",
        type: "id",
      },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  QUESTION_TRANSLATED: {
    label: "Question translated",
    fields: [
      { name: "body", label: "Name", required: true, type: "text" },
      {
        name: "additionalInfo",
        label: "Additional info",
        required: false,
        type: "text",
      },
      { name: "question", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  REJECT_REASON_TRANSLATED: {
    label: "Reject reason translated",
    fields: [
      {
        name: "name",
        label: "Name",
        required: true,
        type: "text",
      },
      { name: "reason", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
  SURVEY_ANSWER_TRANSLATED: {
    label: "Survey answer translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      { name: "answer", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
    hidden: true,
  },
  SURVEY_QUESTION_TRANSLATED: {
    label: "Survey question translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      { name: "question", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
    hidden: true,
  },
  WARD_TRANSLATED: {
    label: "Ward translated",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "abbreviation",
        label: "Abbreviation",
        required: false,
        type: "text",
      },
      { name: "ward", value: "nomenclatureId", type: "id" },
      { name: "language", value: "languageId", type: "id" },
    ],
  },
};
