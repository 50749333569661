import { useNavigate } from "react-router-dom";
import { useGetPermissionsForPage } from "../../hooks/hooks";
import { getDaysSinceDate } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import "./AdviceSummary.scss";

export default function AdviceSummary({
  advice,
  areAllAdvicesVisible,
  userShouldSeeEverything,
  notDeemphasized,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { canCreate: userCanCreateAdvices } =
    useGetPermissionsForPage("askAdvice");

  const {
    adviceId,
    disease,
    date,
    prevention,
    medicaments,
    status,
    ward,
    owner: adviceIsFromMyWard,
    diseaseHistory, // will exist only if is my consultation for other ward
    consultantWardName,
    consultedWardName,
  } = advice;

  const daysSinceAdvice = getDaysSinceDate(date);

  const isMyAdvice = adviceIsFromMyWard && !advice.consultation;
  const isMyConsultation = adviceIsFromMyWard && advice.consultation;
  const isReceivedConsultation =
    !adviceIsFromMyWard && (advice.consultation || consultantWardName);

  const isConsultationWaitingForAction =
    (isReceivedConsultation || isMyConsultation) &&
    (advice.status === null || advice.status === "COMPLETED");

  const isDeemphasized =
    notDeemphasized !== "true"
      ? status === "REJECTED" ||
        status === "DRAFT" ||
        status === "COMPLETED" ||
        isConsultationWaitingForAction
      : false;

  function openAdvice(adviceId) {
    navigate(`/ask-advice?adviceId=${adviceId}`);
  }

  return (
    <div
      className={`advice-summary ${isDeemphasized ? "dimmed" : ""}`}
      key={adviceId}
      onClick={(e) => {
        e.stopPropagation();
        if (userShouldSeeEverything && userCanCreateAdvices) {
          openAdvice(adviceId);
        }
      }}
    >
      {((adviceIsFromMyWard && !diseaseHistory) || !adviceIsFromMyWard) && (
        <div className="disease" title={disease}>
          {disease}
        </div>
      )}

      {adviceIsFromMyWard && diseaseHistory && (
        <>
          <h2 className="diseaseHistory">{diseaseHistory}</h2>
          <div className="disease" title={disease}>
            {disease}
          </div>
        </>
      )}

      {!prevention &&
        medicaments.length > 0 &&
        medicaments.map((m, index) => {
          const text = `${m.medicament} ${m.frequency.time}x${m.frequency.quantity} ${m.dose} ${m.doseMeasureAbr} ${m.applicationMethodAbr}`;

          return (
            <div className="treatment" key={m + index} title={text}>
              {text}
            </div>
          );
        })}

      {prevention &&
        medicaments.length > 0 &&
        medicaments.map((m, index) => {
          const text = `${m.medicament} ${m.dose} ${m.doseMeasureAbr} ${m.applicationMethodAbr}`;

          return (
            <div className="treatment" key={m + index} title={text}>
              {text}
            </div>
          );
        })}

      {!medicaments.length && (
        <div className="treatment text-wrap-normal">
          Antibiotic prophylaxis is not necessary.
        </div>
      )}

      {daysSinceAdvice && (
        <div className="day">
          {t("Day")} {daysSinceAdvice}
        </div>
      )}
      <div className="tags">
        {status === "ACCEPTED" &&
          areAllAdvicesVisible &&
          (isMyAdvice || isMyConsultation) && (
            <span className="tag">{t("Accepted")}</span>
          )}

        {status === "ACCEPTED" &&
          areAllAdvicesVisible &&
          isReceivedConsultation && <span className="tag">{t("Endorse")}</span>}

        {status === "REJECTED" && <span className="tag">{t("Rejected")}</span>}

        {status === "DRAFT" && (
          <span className="tag emphasized">{t("Draft Unfinished")}</span>
        )}

        {status === "COMPLETED" && (isMyAdvice || isMyConsultation) && (
          <span className="tag emphasized">{t("Waiting for Accepted")}</span>
        )}

        {status === "COMPLETED" && isReceivedConsultation && (
          <span className="tag emphasized" emphasized>
            {t("Wainting for Endorse")}
          </span>
        )}

        {(consultantWardName || consultedWardName) && (
          <>
            <span className="tag">{t("Consultation")}</span>
            {consultedWardName && !isReceivedConsultation && (
              <span className="tag">
                {t("To")} {consultedWardName}
              </span>
            )}

            {consultantWardName && (
              <span className="tag">
                {t("From")} {consultantWardName}
              </span>
            )}

            {/* {isConsultationWaitingForAction && (
              <span className="tag emphasized">{t("Pending")}</span>
            )} */}
          </>
        )}

        {prevention && <span className="tag">{t("Perioperative")}</span>}
      </div>
    </div>
  );
}
