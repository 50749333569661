import { PageTitle, PatientsAndAdvices } from "../../components";
import { useTranslation } from "react-i18next";

export default function HomeHeadOfWard() {
  const { t } = useTranslation();

  return (
    <div className="home head-of-ward">
      <PageTitle title={t("Patients & Advices")} isBackButtonVisible={false} />
      <PatientsAndAdvices />
    </div>
  );
}
