import { useState } from "react";
import { Handle, Position } from "reactflow";
import { v4 as generateUUID } from "uuid";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/Icon";

export default function NodeQuestion({ data }) {
  const { t } = useTranslation();
  const { label, questionId } = data;
  const [isIdCopied, setIsIdCopied] = useState(false);

  const handleCopyId = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(questionId);
    setIsIdCopied(true);

    setTimeout(() => {
      setIsIdCopied(false);
    }, 1000);
  };

  return (
    <div key={generateUUID()}>
      <Handle type="target" position={Position.Top} />
      <div className="content">
        {isIdCopied ? t("Question ID copied!") : label}
      </div>

      <button
        className="graph-button copy-id secondary smaller"
        title={t("Copy question ID")}
        onClick={handleCopyId}
      >
        <Icon name="content-duplicate" />
      </button>

      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}
