import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    isSidebarOpened: true,
    isMenuOpened: false,
  },
  reducers: {
    setIsSidebarOpened: (state, action) => {
      state.isSidebarOpened = action.payload;
    },

    setIsMenuOpened: (state, action) => {
      state.isMenuOpened = action.payload;
    },
  },
});

export const { setIsSidebarOpened, setIsMenuOpened } = settingsSlice.actions;
export default settingsSlice.reducer;
