import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components";

export default function Error404() {
  const { t } = useTranslation();

  return (
    <div className="error404">
      <PageTitle title={t("Page Not Found")} />
      <p>
        {t("Go back to the")} <Link to="/">{t("homepage.")}</Link>
      </p>
    </div>
  );
}
