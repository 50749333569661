import { useTranslation } from "react-i18next";
import { inputDefaults } from "../../../config/inputs";

export default function InputPassword({
  value,
  handleChange,
  minLength,
  maxLength,
}) {
  const { t } = useTranslation();
  const { min: minLengthDefault, max: maxLengthDefault } =
    inputDefaults.password;

  if (!minLength) {
    minLength = minLengthDefault;
  }

  if (!maxLength) {
    maxLength = maxLengthDefault;
  }

  return (
    <div className="input-wrapper">
      <label htmlFor="password">{t("Password:")}</label>
      <input
        type="password"
        name="password"
        id="password"
        required
        placeholder=" "
        value={value}
        onChange={handleChange}
        minLength={minLength}
        maxLength={maxLength}
      />
    </div>
  );
}
