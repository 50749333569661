import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiRequests, showHttpRequestError } from "../../../http";
import { useOnMount } from "../../../hooks";
import {
  isFormValid,
  showButtonLoader,
  hideButtonLoader,
} from "../../../utils/utils";
import {
  showSuccessMessage,
  showErrorMessage,
  setEditedHospital,
} from "../../../redux/reducers";
import { FormMessage } from "../../index";
import Select from "react-select";
import FormHead from "./FormHead";
import { copyToClipBoard } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { selectStyles } from "../../../styles/selectStyles";
import "./Form.scss";

export default function FormHospital({
  editedHospital,
  useOnHospitalModalOpen,
  useOnHospitalModalClose,
  fetchTableData,
  countries,
  regions,
  cities,
  setCountries,
  setRegions,
  setCities,
}) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.modals.formMessage);
  const isEditingHospital = Boolean(editedHospital?.id);
  const isCreatingHospital = !isEditingHospital;

  /**
   * Setup data for the hospital form.
   */
  const emptyHospital = {
    id: "",
    name: "",
    countryId: "",
    countryName: "",
    regionId: "",
    regionName: "",
    cityId: "",
    cityName: "",
    address: "",
    email: "",
    phone: "",
    contactPerson: "",
    headOfHospitalId: "",
  };
  const [hospital, setHospital] = useState({ ...emptyHospital });
  const resetHospital = () => setHospital({ ...emptyHospital });

  useOnHospitalModalClose(() => {
    resetHospital();
    resetUsersInHospital();
  });

  useOnHospitalModalOpen(() => setHospitalData());

  function setHospitalData() {
    if (isEditingHospital) {
      setHospital({ ...editedHospital });
      dispatch(setEditedHospital({ ...editedHospital }));
      // ↑ In the ward form we'll use the common fields (city, address, etc).
    } else {
      setHospital({ ...emptyHospital });
    }
  }

  const setHospitalField = (e) => {
    const { name: field, value } = e.target;
    setHospital({
      ...hospital,
      [field]: value,
    });
  };

  const {
    id,
    name,
    countryId,
    countryName,
    regionId,
    regionName,
    cityId,
    cityName,
    address,
    phone,
    email,
    contactPerson,
    headOfHospitalId,
  } = hospital;

  /**
   * Fetch data for select dropdowns.
   */
  useOnMount(() => {
    const hasCountries = !!countries.length;
    if (!hasCountries) fetchAndSetCountries();
  });

  async function fetchAndSetCountries() {
    const fetchedCountries = await api
      .getCountries()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const countriesForSelect = fetchedCountries.map((country) => ({
      value: country.id,
      label: country.name,
    }));

    setCountries(countriesForSelect);
  }

  /**
   * Fetch regions after country is selected.
   */
  const useOnCountrySelect = (fn) => useEffect(fn, [hospital.countryId]);

  useOnCountrySelect(() => {
    fetchAndSetRegions();
    setCities([]);
  });

  async function fetchAndSetRegions() {
    const countryId = hospital.countryId;
    if (!countryId) return;

    const regions = await api
      .getRegions(countryId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const regionsForSelect = regions.map((region) => ({
      value: region.id,
      label: region.name,
    }));

    setRegions(regionsForSelect);
  }

  /**
   * Fetch cities after region is selected.
   */
  const useOnRegionSelect = (fn) => useEffect(fn, [hospital.regionId]);

  useOnRegionSelect(() => {
    fetchAndSetCities();
  });

  async function fetchAndSetCities() {
    const regionId = hospital.regionId;
    if (!regionId) return;

    const cities = await api
      .getCities(regionId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const citiesForSelect = cities.map((city) => ({
      value: city.id,
      label: city.name,
    }));

    setCities(citiesForSelect);
  }

  // Callbacks for setting the value of select fields.
  const selectedCountry = (country) => country.value === countryId;
  const selectedRegion = (region) => region.value === regionId;
  const selectedCity = (city) => city.value === cityId;

  const submitButtonText = isEditingHospital
    ? t("Save hospital")
    : t("Create hospital");

  const hasCountries = Boolean(countries.length);
  const hasRegions = Boolean(regions.length);
  const hasCities = Boolean(cities.length);

  /**
   * Add head of hospital modal.
   */
  const emptyHead = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
  };
  const [head, setHead] = useState({ ...emptyHead });
  const headHasName = head.firstName || head.lastName || head.username;

  const [isFormHeadOpened, setIsFormHeadOpened] = useState(false);
  const toggleFormHead = () => setIsFormHeadOpened((opened) => !opened);
  const addHeadOfHospitalButtonText = headHasName
    ? t("Edit head of hospital")
    : t("Add head of hospital");

  /**
   * Error handling.
   */
  function showContactPersonIsRequired() {
    dispatch(
      showErrorMessage({
        content: t(
          "Either a contact person or a head of hospital is required to be set."
        ),
        id: "hospitalForm",
      })
    );
  }

  function showSomeFieldsAreNotValid() {
    dispatch(
      showErrorMessage({
        content: t("Some fileds are not valid."),
        id: "hospitalForm",
      })
    );
  }

  /**
   * Create new hospital.
   */
  function createHospital(e) {
    e.preventDefault();
    const createHospitalForm = e.target;
    const thereIsNoContactPerson = contactPerson === "" && !head.username;

    if (!isFormValid(createHospitalForm)) {
      showSomeFieldsAreNotValid();
      return;
    }

    if (thereIsNoContactPerson) {
      showContactPersonIsRequired();
      return;
    }

    const hospitalToSend = {
      name: name,
      countryId: countryId,
      regionId: regionId,
      cityId: cityId,
      address: address,
      email: email,
      phone: phone,
      contactPerson: contactPerson,
    };

    const isHeadCreated = head.username && head.password;
    if (isHeadCreated) {
      hospitalToSend.headOfHospital = {
        ...head,
      };
    }

    showButtonLoader();
    api
      .createHospital(hospitalToSend)
      .then((response) => {
        const createdHospital = response.data;
        const { name } = createdHospital;
        dispatch(
          showSuccessMessage({
            content: `${name} ${t("was created successfully.")}`,
            id: "hospitalForm",
          })
        );
        setHospital(createdHospital);
        dispatch(setEditedHospital(createdHospital));
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  }

  /**
   * Change head of hospital.
   *
   * When head of hospital or ward is changed - the admin should decide what to do with the previous head - delete his profile or make his role "doctor".
   * previousHeadAction - DELETE | DOCTOR
   */
  const [usersInHospital, setUsersInHospital] = useState([]);
  const hasUsers = Boolean(usersInHospital.length);
  const useOnHospitalSelect = (fn) => useEffect(fn, [id]);

  useOnHospitalSelect(() => {
    if (!id) return;
    if (!hasUsers) fetchAndSetHospitalUsers(id);
  });

  const resetUsersInHospital = () => setUsersInHospital([]);
  useOnHospitalModalClose(() => resetUsersInHospital());

  const [newHeadOfHospitalId, setNewHeadOfHospitalId] = useState(null);
  const [previousHeadAction, setPreviousHeadAction] = useState("DOCTOR");
  const [isPreviousHeadActionVisible, setIsPreviousHeadActionVisible] =
    useState(false);

  function askWhatHappensWithTheOldHead(e) {
    const chosenHeadId = e.value;
    const headDidntChange = headOfHospitalId === chosenHeadId;
    const oldHeadExists = Boolean(headOfHospitalId);
    setNewHeadOfHospitalId(chosenHeadId);
    if (!oldHeadExists) return;

    if (headDidntChange) {
      setIsPreviousHeadActionVisible(false);
      return;
    }

    setIsPreviousHeadActionVisible(true);
  }

  async function fetchAndSetHospitalUsers(hospitalId) {
    const fetchedUsers = await api
      .getHospitalUsers(hospitalId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const usersForSelect = fetchedUsers.map((user) => ({
      value: user.id,
      label: user.name,
    }));

    setUsersInHospital(usersForSelect);
  }

  // Callbacks for setting the value of select fields.
  const selectedHospitalHead = (user) => user.value === headOfHospitalId;
  const selectedNewHospitalHead = (user) => user.value === newHeadOfHospitalId;
  const currentHeadName = usersInHospital.find(selectedHospitalHead)?.label;

  /**
   * Edit hospital.
   */
  function editHospital(e) {
    e.preventDefault();
    const editHospitalForm = e.target;

    if (!isFormValid(editHospitalForm)) {
      showSomeFieldsAreNotValid();
      return;
    }

    const hospitalToSend = {
      hospitalId: id,
      name: name,
      regionId: regionId,
      cityId: cityId,
      address: address,
      email: email,
      phone: phone,
      contactPerson: contactPerson,
    };

    const isHeadChanged =
      newHeadOfHospitalId && headOfHospitalId !== newHeadOfHospitalId;
    const oldHeadExists = Boolean(headOfHospitalId);

    if (isHeadChanged && !oldHeadExists) {
      hospitalToSend.oldHeadOfHospital = null;
      hospitalToSend.newHeadOfHospital = {
        id: newHeadOfHospitalId,
      };
    }

    if (isHeadChanged && oldHeadExists) {
      hospitalToSend.oldHeadOfHospital = {
        id: headOfHospitalId,
        action: previousHeadAction,
      };

      hospitalToSend.newHeadOfHospital = {
        id: newHeadOfHospitalId,
      };
    }

    showButtonLoader();
    api
      .updateHospital(hospitalToSend)
      .then((response) => {
        const editedHospital = response.data;
        const { name } = editedHospital;
        dispatch(
          showSuccessMessage({
            content: `${name} ${t("was updated successfully.")}`,
            id: "hospitalForm",
          })
        );
        setHospital(editedHospital);
        dispatch(setEditedHospital(editedHospital));
        setIsPreviousHeadActionVisible(false);
        fetchTableData();

        if (previousHeadAction === "DELETE") {
          fetchAndSetHospitalUsers(editedHospital.id);
        }
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  }

  /**
   * Copy the data for head of hospital.
   */
  const [buttonCopyHeadText, setButtonCopyHeadText] = useState(t("Copy"));
  const createdHeadRef = useRef(null);

  function copyHeadData(e) {
    e.preventDefault();
    const createdHeadElement = createdHeadRef.current;
    const createdHeadText = createdHeadElement.textContent;

    createdHeadElement.classList.add("highlight");
    copyToClipBoard(createdHeadText);
    setButtonCopyHeadText(t("Copied!"));

    setTimeout(() => setButtonCopyHeadText(t("Copy")), 5000);
    setTimeout(() => createdHeadElement.classList.remove("highlight"), 1000);
  }

  return (
    <>
      <form
        className="large"
        onSubmit={isEditingHospital ? editHospital : createHospital}
      >
        <div className="input-wrapper">
          <label htmlFor="name">{t("Name:")}</label>
          <input
            type="text"
            name="name"
            value={name}
            id="name"
            required
            onChange={setHospitalField}
          />
        </div>

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="countryId">{t("Country:")}</label>
            <Select
              name="countryId"
              options={countries}
              isSearchable={true}
              required
              styles={selectStyles}
              value={countries.find(selectedCountry) ?? null}
              isDisabled={!hasCountries || !!hospital.id}
              placeholder={hasCountries ? t("Select") : t("No countries found")}
              onChange={(e) => setHospital({ ...hospital, countryId: e.value })}
              menuPlacement="auto"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="regionId">{t("Region:")}</label>
            <Select
              name="regionId"
              options={regions}
              isSearchable={true}
              required
              styles={selectStyles}
              value={regions.find(selectedRegion) ?? null}
              isDisabled={!hasRegions}
              placeholder={hasRegions ? t("Select") : t("No regions found")}
              onChange={(e) => setHospital({ ...hospital, regionId: e.value })}
              menuPlacement="auto"
            />
          </div>
        </div>

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="cityId">{t("City:")}</label>
            <Select
              name="cityId"
              options={cities}
              isSearchable={true}
              required
              styles={selectStyles}
              value={cities.find(selectedCity) ?? null}
              placeholder={hasCities ? t("Select") : t("No cities found")}
              isDisabled={!hasCities}
              onChange={(e) => setHospital({ ...hospital, cityId: e.value })}
              menuPlacement="auto"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="address">{t("Address:")}</label>
            <input
              type="text"
              name="address"
              value={address}
              id="address"
              onChange={setHospitalField}
            />
          </div>
        </div>

        <div className="flex-input-wrapper">
          {isCreatingHospital && (
            <div className="input-wrapper">
              <label>{t("Head of hospital:")}</label>
              <button
                type="button"
                className="secondary no-margins w-100"
                onClick={toggleFormHead}
              >
                {addHeadOfHospitalButtonText}
              </button>
            </div>
          )}

          {isEditingHospital && (
            <>
              <div className="input-wrapper">
                <label htmlFor="headOfHospitalId">
                  {t("Head of hospital:")}
                </label>
                <Select
                  name="headOfHospitalId"
                  options={usersInHospital}
                  isSearchable={true}
                  styles={selectStyles}
                  value={
                    newHeadOfHospitalId
                      ? usersInHospital.find(selectedNewHospitalHead)
                      : headOfHospitalId
                      ? usersInHospital.find(selectedHospitalHead)
                      : null
                  }
                  isDisabled={!hasUsers}
                  placeholder={hasUsers ? t("Select") : t("No users found")}
                  onChange={(e) => askWhatHappensWithTheOldHead(e)}
                  menuPlacement="auto"
                />
              </div>
            </>
          )}

          <div className="input-wrapper">
            <label htmlFor="contactPerson">{t("Contact person:")}</label>
            <input
              type="text"
              name="contactPerson"
              value={contactPerson}
              id="contactPerson"
              onChange={setHospitalField}
            />
          </div>
        </div>

        {isEditingHospital && isPreviousHeadActionVisible && (
          <div className="previous-head">
            <h3>
              {t("What about the previous head of hospital")} -{" "}
              {currentHeadName}?
            </h3>

            <input
              type="radio"
              name="previous-head-action"
              id="previous-head-action-doctor"
              checked={previousHeadAction === "DOCTOR"}
              onChange={() => setPreviousHeadAction("DOCTOR")}
            />
            <label htmlFor="previous-head-action-doctor">
              {t("Change role to doctor")}
            </label>

            <input
              type="radio"
              name="previous-head-action"
              id="previous-head-action-delete"
              checked={previousHeadAction === "DELETE"}
              onChange={() => setPreviousHeadAction("DELETE")}
            />
            <label htmlFor="previous-head-action-delete">
              {t("Delete profile")}
            </label>
          </div>
        )}

        {isCreatingHospital && headHasName && (
          <div className="input-wrapper created-head">
            <span>
              <b>{t("Head of hospital:")}</b>
              <button
                type="button"
                className="text"
                onClick={(e) => copyHeadData(e)}
              >
                {buttonCopyHeadText}
              </button>
            </span>

            <div className="created-head-info" ref={createdHeadRef}>
              <span>
                — {`${head.firstName} ${head.lastName}, ${head.email}`}
              </span>
              <span>— {`${head.username}, ${head.password}`}</span>
            </div>
          </div>
        )}

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="email">{t("Email:")}</label>
            <input
              type="text"
              name="email"
              value={email}
              id="email"
              required
              onChange={setHospitalField}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="phone">{t("Phone:")}</label>
            <input
              type="number"
              name="phone"
              value={phone}
              id="phone"
              onChange={setHospitalField}
            />
          </div>
        </div>

        {message.content && <FormMessage id="hospitalForm" />}

        <button type="submit">{submitButtonText}</button>
      </form>

      <FormHead
        isOpened={isFormHeadOpened}
        isCreatingHeadOfHospital={true}
        head={head}
        setHead={setHead}
        toggleFormHead={toggleFormHead}
      />
    </>
  );
}
