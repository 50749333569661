import { useTranslation } from "react-i18next";
import { inputDefaults } from "../../../config/inputs";

export default function InputUsername({
  value,
  handleChange,
  minLength,
  maxLength,
}) {
  const { t } = useTranslation();
  const { min: minLengthDefault, max: maxLengthDefault } =
    inputDefaults.username;

  if (!minLength) {
    minLength = minLengthDefault;
  }

  if (!maxLength) {
    maxLength = maxLengthDefault;
  }

  return (
    <div className="input-wrapper">
      <label htmlFor="username">{t("Username:")}</label>
      <input
        type="text"
        name="username"
        id="username"
        required
        placeholder=" "
        value={value}
        onChange={handleChange}
        minLength={minLength}
        maxLength={maxLength}
      />
    </div>
  );
}
