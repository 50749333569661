import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetPermissionsForPage, useOnMount } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { ApiRequests, showHttpRequestError } from "../../http";
import { getVisibleTableColumns } from "../../utils/utils";
import { DataTable, PageTitle } from "../../components";
import { CardAdvice } from "../../components/Cards";
import { useTranslation } from "react-i18next";
//import "./Consultations.scss";

export default function Consultations() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [areAllAdvicesVisible, setAreAllAdvicesVisible] = useState(false);
  function toggleAreAllAdvicesVisible() {
    setAreAllAdvicesVisible((visible) => !visible);
  }
  const userPermissions = useGetPermissionsForPage("consultation");
  const { canView } = userPermissions;

  const userShouldSeeEverything =
    user.role === "DOCTOR" || user.role === "HEAD OF WARD";

  const userShouldSeeOnlyPatientsAndFiles =
    user.role === "HEAD OF HOSPITAL" || user.role === "MICROBIOLOGY";

  const allColumns = [
    {
      accessorKey: "diseaseHistory",
      header: () => <span>{t("Patient HD")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "diseases",
      header: () => <span>{t("Disease")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "medicamentNames",
      header: () => <span>{t("Antibiotics")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
  ];

  // Head of hospital and microbiology can filter by ward.
  if (userShouldSeeOnlyPatientsAndFiles) {
    allColumns.push({
      accessorKey: "hospitalWardName",
      header: () => <span>{t("Ward")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    });
  }
  const [tableData, setTableData] = useState([]);

  useOnMount(() => {
    fetchAndSetDashboardData();
  });

  async function fetchAndSetDashboardData() {
    const responseData = await api
      .getConsultations()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const data = convertPatientsToTableData(responseData);
    setTableData(data);
  }

  function convertPatientsToTableData(consultations) {
    return consultations.map((advice) => {
      let diseases = "";
      let medicamentNames = "";
      let hasConsultation = t("Without consultation");
      let consultingWard = "";
      let hasPrevention = t("Without perioperative prophylaxis");

      if (advice.disease) {
        diseases += advice.disease + " ";
      }

      if (advice.medicaments.length) {
        advice.medicaments.forEach((medicament) => {
          const medicamentName = medicament.medicament;
          const medicamentIsNotInMedicaments =
            medicamentNames.indexOf(medicamentName) === -1;

          if (medicamentIsNotInMedicaments) {
            medicamentNames += medicamentName + " ";
          }
        });
      }

      if (advice.ward) {
        hasConsultation = t("With consultation");
        consultingWard += advice.ward + " ";
      }

      if (advice.prevention) {
        hasPrevention = t("With perioperative prophylaxis");
      }

      return {
        ...advice,
        displayClass: "AdviceSummary",
        diseases: diseases,
        medicamentNames: medicamentNames,
        hasConsultation: hasConsultation,
        hasPrevention: hasPrevention,
        consultingWard: consultingWard,
      };
    });
  }

  const visibleColumns = getVisibleTableColumns(allColumns);
  const tableColumns = [
    {
      header: t("Results"),
      footer: (props) => props.column.id,
      columns: visibleColumns,
    },
  ];

  function openAskAdvice() {
    navigate("/ask-advice");
  }

  return (
    <div className="consultations cards-are-single-advices">
      <PageTitle title={t("Consultations for other wards")} />

      <div className="patients-and-advices">
        <DataTable
          tableData={tableData}
          tableColumns={tableColumns}
          addNewButtonLabel={t("New advice")}
          handleAddNew={openAskAdvice}
          CardComponent={CardAdvice}
          userPermissions={userPermissions}
          isShowingPatients={true}
          areAllAdvicesVisible={areAllAdvicesVisible}
          toggleAreAllAdvicesVisible={toggleAreAllAdvicesVisible}
          userShouldSeeEverything={userShouldSeeEverything}
        />
      </div>
    </div>
  );
}
