/**
 * Default doughnut chart config.
 * See: https://www.chartjs.org/docs/latest/
 */
export const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    htmlLegend: {
      containerID: "htmlLegend",
    },
    legend: {
      display: false,
    },
  },
  cutout: "70%",
  rotation: -120,
  animation: {
    x: false,
  },
  responsiveAnimationDuration: 0,
};

export const doughnutChartConfig = {
  backgroundColor: [
    "#f82249",
    "#ff6d84",
    "#ffa2ae",
    "#ffccd3",
    "#ffe3e5",
    "#fff1f2",
  ],
  borderColor: [
    "#f82249",
    "#ff6d84",
    "#ffa2ae",
    "#ffccd3",
    "#ffe3e5",
    "#fff1f2",
  ],
  borderWidth: 0,
  borderRadius: 0,
};
