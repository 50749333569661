import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showModal,
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
} from "../../redux/reducers";
import { useGetPermissionsForPage, useOnMount } from "../../hooks/hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { DataTable, Icon, Modal, PageTitle } from "../../components";
import FormUser from "../../components/Modal/components/FormUser";
import {
  getVisibleTableColumns,
  getUserDisplayName,
  orderByName,
  orderActiveFirst,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { CardUser } from "../../components/Cards";

export default function Users() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const isHeadOfWard = currentUser.role === "HEAD OF WARD";
  const userPermissions = useGetPermissionsForPage("users");
  const { canCreate, canUpdate, canDelete } = userPermissions;
  const [modalTitle, setModalTitle] = useState("New user");
  const [modalCustomClass, setModalCustomClass] = useState("creating");

  /**
   * Table with users.
   */
  const [tableData, setTableData] = useState([]);
  useOnMount(() => {
    const hasTableData = !!tableData.length;
    if (!hasTableData) {
      fetchTableData();
    }
  });

  async function fetchTableData() {
    const users = await getUsers();
    const orderedUsers = orderByName(orderActiveFirst(users));
    setTableData(orderedUsers);
  }

  async function getUsers() {
    return api
      .getUsers()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * All initial columns for the user table (as an admin would see it).
   * - columns with "isRestricted" prop get filtered based on user permissions.
   * - columns without "isRestricted" are visible for everyone.
   *
   * In the columns:
   * - accessorKey - the key from the BE object (name, email, etc)
   * - header - value in the TH cell
   * - cell - value in the TD cell
   * - isVisible - not part of Tanstack table. Added to be able to filter the columns by permissions.
   */
  const allColumns = [
    {
      accessorKey: "firstName",
      header: () => <span>{t("First Name")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "lastName",
      header: () => <span>{t("Last Name")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "username",
      header: () => <span>{t("Username")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "email",
      header: () => <span>{t("Email")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "hospitalName",
      header: () => <span>{t("Hospital")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      isVisible: isHeadOfWard ? false : true,
      accessorKey: "hospitalWardName",
      header: () => <span>{t("Ward")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "roleName",
      header: () => <span>{t("Role")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      isVisible: canUpdate,
      id: "update",
      header: () => <span>{t("Edit")}</span>,
      cell: (info) => showEditButton(info),
      footer: (props) => props.column.id,
    },
    {
      isVisible: canDelete,
      id: "delete",
      header: () => <span>{t("Delete")}</span>,
      cell: (info) => showDeleteButton(info),
      footer: (props) => props.column.id,
    },
  ];

  function showEditButton(table) {
    const user = table.row.original;
    const isActive = user.active;
    return (
      <button
        className="td-action edit"
        onClick={() => editUser(user)}
        title={t("Edit user")}
        disabled={!isActive}
      >
        <Icon name="pencil" /> {t("Edit")}
      </button>
    );
  }

  function showDeleteButton(table) {
    const user = table.row.original;
    const isActive = user.active;
    const isRowForCurrentUser = user.id === currentUser.id;
    if (!isRowForCurrentUser) {
      return (
        <button
          className="td-action secondary delete"
          onClick={(e) => {
            e.stopPropagation();
            deleteUser(user);
          }}
          title={t("Delete user")}
          disabled={!isActive}
        >
          <Icon name="delete" />
        </button>
      );
    }
  }

  const visibleColumns = getVisibleTableColumns(allColumns);
  const tableColumns = [
    {
      header: t("Results"),
      footer: (props) => props.column.id,
      columns: visibleColumns,
    },
  ];

  /**
   * Add, edit and delete users.
   */
  function addUser() {
    setModalTitle(t("New user"));
    setModalCustomClass("creating");
    dispatch(
      showModal({
        id: "userModal",
      })
    );
  }

  function editUser(user) {
    setModalTitle(getUserDisplayName(user));
    setModalCustomClass("editing");
    dispatch(
      showModal({
        id: "userModal",
        data: user,
      })
    );
  }

  async function deleteUser(user) {
    const { id: userId } = user;
    const userDisplayName = getUserDisplayName(user);

    const deleteDialog = {
      title: `${t("Delete user")} ${userDisplayName}?`,
      buttonConfirmText: t("Delete"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .deleteUser(userId)
      .then((response) => {
        dispatch(
          showSuccessPopup(
            `${t("Successfully deleted user")} ${userDisplayName}.`
          )
        );
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error));
  }

  return (
    <div className="users">
      <PageTitle title={t("Users")} />

      <DataTable
        tableData={tableData}
        tableColumns={tableColumns}
        handleAddNew={addUser}
        userPermissions={userPermissions}
        handleCardClick={editUser}
        CardComponent={CardUser}
      />

      {(canCreate || canUpdate) && (
        <Modal
          id={"userModal"}
          title={modalTitle}
          innerComponent={<FormUser fetchTableData={fetchTableData} />}
          isLarge
          customClasses={modalCustomClass}
        />
      )}
    </div>
  );
}
