import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/Icon";

export default function TableActions({ table, itemsPerPageOptions }) {
  const { t } = useTranslation();
  const currentTablePage = table.getState().pagination.pageIndex + 1;

  useEffect(() => {
    const app = document.querySelector(".app");
    if (!app) return;
    app.scrollTo(0, 0);
  }, [currentTablePage]);

  return (
    <div className="table-actions">
      <div className="actions-group">
        <button
          className="action"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="chevron-double-left" />
        </button>

        <button
          className="action"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon name="chevron-left" />
        </button>

        <span className="page-numbers">
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>

        <button
          className="action"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <Icon name="chevron-right" />
        </button>

        <button
          className="action"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <Icon name="chevron-double-right" />
        </button>
      </div>

      <div className="actions-group">
        <div className="page-skip">
          {t("Go to page:")}
          <input
            type="number"
            value={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              if (e.target.value > table.getPageCount()) {
                return;
              }
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            min={1}
            max={table.getPageCount()}
          />
        </div>

        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="rows-show-count"
        >
          {itemsPerPageOptions.map((item) => (
            <option key={item} value={item}>
              {item} {t("per page")}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
