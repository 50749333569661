import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import injectStore from "./redux/injectedStore/injectedStore";
import "./i18n";
import App from "./App";

/**
 * For accessing the redux store in non-component files (API, utils, etc).
 */
injectStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  //</React.StrictMode>
);
