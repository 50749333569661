import { useTranslation } from "react-i18next";
import { ApiRequests, showHttpRequestError } from "../../http";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useOnMount } from "../../hooks";
import Builder from "../../components/Builder/Builder";
import { useGetPermissionsForPage } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { addParamsToUrl } from "../../utils/utils";
import { PageTitle } from "../../components";
import "./WorkflowBuilder.scss";

export default function WorkflowBuilder() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const navigate = useNavigate();

  /**
   * User permissions.
   */
  const { canCreate, canView, canUpdate } =
    useGetPermissionsForPage("adviceBuilder");

  /**
   * Workflow data.
   */
  const emptyWorkflow = {
    diseaseId: "",
    countryId: "",
    wardId: "",
    prevention: true,
    workflowDto: {
      baseAdvice: {
        treatments: [],
        bacteriaIds: [],
        reminder: "",
        info: "",
      },
    },
  };

  const [buildingWorkflow, setBuildingWorkflow] = useState({
    ...emptyWorkflow,
  });
  const [workflow, setWorkflow] = useState({
    ...emptyWorkflow,
  });
  const [draftWorkflow, setDraftWorkflow] = useState(null);
  const [isEditingDraft, setIsEditingDraft] = useState(false);
  const [isComparingVersions, setIsComparingVersions] = useState(false);

  /**
   * Comparing versions.
   */
  const [workflowToCompare, setWorkflowToCompare] = useState(null);
  const [draftWorkflowToCompare, setDraftWorkflowToCompare] = useState(null);

  /**
   * Fetch workflow if there are url params.
   */
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const diseaseId = params.get("did");
  const countryId = params.get("cid");
  const isViewingWorkflow = diseaseId && countryId;

  useOnMount(() => {
    if (isViewingWorkflow) {
      fetchWorkflow(countryId, diseaseId);
    }
  });

  async function fetchWorkflow(countryId, diseaseId) {
    const workflow = await getWorkflow(countryId, diseaseId);

    // There is a completed version.
    if (workflow?.countryId) {
      setWorkflow(workflow);
      setBuildingWorkflow(workflow);
      setIsEditingDraft(false);
      setWorkflowToCompare(workflow);

      if (workflow?.draftVersion) {
        fetchDraftWorkflow(countryId, diseaseId);
      }
      return;
    }

    // There is only a draft version without completed one.
    const draftWorkflow = await getDraftWorkflow(countryId, diseaseId);
    setDraftWorkflow(draftWorkflow);
    setBuildingWorkflow(draftWorkflow);
    setIsEditingDraft(true);
    setDraftWorkflowToCompare(draftWorkflow);
  }

  async function fetchDraftWorkflow(countryId, diseaseId) {
    const draftWorkflow = await getDraftWorkflow(countryId, diseaseId);
    setDraftWorkflow(draftWorkflow);
    setDraftWorkflowToCompare(draftWorkflow);
  }

  async function getWorkflow(countryId, diseaseId) {
    return api
      .getWorkflow(countryId, diseaseId)
      .then((response) => response.data)
      .catch((error) => {});
  }

  async function getDraftWorkflow(countryId, diseaseId) {
    return api
      .getDraftWorkflow(countryId, diseaseId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Workflow actions - create, update, delete, test.
   */
  async function createApiWorkflow(w) {
    const dto = {
      countryId: w.countryId,
      diseaseId: w.diseaseId,
      wardId: w.wardId,
      prevention: w.prevention,
      workflowDto: w.workflowDto,
    };

    // These are returned from this function. If there is an error during creating workflow - we keep the selects unlocked so that the user can change what they selected.
    let data = null;
    let error = null;

    await api
      .createWorkflow(dto)
      .then((response) => {
        setDraftWorkflow(response.data);
        setBuildingWorkflow(response.data);
        setIsEditingDraft(true);
        addParamsToUrl({
          did: response.data.diseaseId,
          cid: response.data.countryId,
        });
        data = response.data;
      })
      .catch((e) => {
        showHttpRequestError(e);
        handleCannotCreateWorkflow(e);
        error = e;
      });

    return { data, error };
  }

  function handleCannotCreateWorkflow(error) {
    const cannotCreateWorkflow =
      error.data.translate === "api.workflow.create.exception.workflow.illegal";
    if (!cannotCreateWorkflow) return;

    setDraftWorkflow(null);
    setBuildingWorkflow(emptyWorkflow);
  }

  async function updateApiWorkflow(w) {
    const dto = {
      countryId: w.countryId,
      diseaseId: w.diseaseId,
      wardId: w.wardId,
      prevention: w.prevention,
      workflowDto: w.workflowDto,
    };

    api
      .updateWorkflow(dto)
      .then((response) => {
        setDraftWorkflow(response.data);
        setBuildingWorkflow(response.data);
        setWorkflow({
          ...workflow,
          draftVersion: response.data.draftVersion,
        });
        setIsEditingDraft(true);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function autoUpdateApiWorkflow(w) {
    const dto = {
      countryId: w.countryId,
      diseaseId: w.diseaseId,
      wardId: w.wardId,
      prevention: w.prevention,
      workflowDto: w.workflowDto,
    };

    api
      .updateWorkflow(dto)
      .then((response) => {
        setDraftWorkflow(response.data);
        setWorkflow({
          ...workflow,
          draftVersion: response.data.draftVersion,
        });
        //setIsEditingDraft(true);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function completeApiWorkflow(w) {
    const dto = {
      countryId: w.countryId,
      diseaseId: w.diseaseId,
    };

    api
      .completeWorkflow(dto)
      .then((response) => {
        setWorkflow(response.data);
        setBuildingWorkflow(response.data);
        setDraftWorkflow(null);
        setIsEditingDraft(false);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function deleteApiWorkflow(w) {
    if (w.draft) {
      await api
        .deleteDraftWorkflow(w.countryId, w.diseaseId)
        .then((response) => {
          if (w.workflowVersion) {
            fetchWorkflow(w.countryId, w.diseaseId);
          } else {
            navigate("/workflows");
          }
        })
        .catch((error) => showHttpRequestError(error));
      return;
    }

    api
      .deleteWorkflow(w.countryId, w.diseaseId)
      .then((response) => {
        navigate("/workflows");
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function firstStepAdvice(dto) {
    return api.testWorkflowFirstStep(
      dto.workflow.countryId,
      dto.workflow.diseaseId,
      dto.workflow.draft ? "draft" : ""
    );
  }

  async function nextStepAdvice(dto) {
    const toSend = {
      countryId: dto.workflow.countryId,
      diseaseId: dto.workflow.diseaseId,
      json: dto.json,
    };
    return api.testWorkflowNextStep(toSend, dto.workflow.draft ? "draft" : "");
  }

  /**
   * Early return on missing data.
   */
  const isViewingUnfetchedWorkflow =
    isViewingWorkflow && !workflow?.countryId && !draftWorkflow?.countryId;

  if (isViewingUnfetchedWorkflow) {
    return null;
  }

  return (
    <div className="workflow-builder">
      {!buildingWorkflow?.workflowVersion &&
        !buildingWorkflow?.draftVersion && (
          <PageTitle title={t("Create Workflow")} />
        )}

      {(buildingWorkflow?.workflowVersion ||
        buildingWorkflow?.draftVersion) && (
        <>
          <PageTitle title={t("Editing Workflow")} />

          <div className="tabs tabs-builder">
            <div className="tabs-header">
              {buildingWorkflow?.workflowVersion && (
                <button
                  className={`${
                    !isEditingDraft && !isComparingVersions
                      ? "secondary active"
                      : ""
                  }`}
                  type="button"
                  onClick={() => {
                    setBuildingWorkflow(workflow);
                    setIsEditingDraft(false);
                    setIsComparingVersions(false);
                  }}
                >
                  v{buildingWorkflow.workflowVersion}.0 {t("completed")}
                </button>
              )}

              {buildingWorkflow?.draftVersion && (
                <button
                  className={`${isEditingDraft ? "secondary active" : ""}`}
                  type="button"
                  onClick={() => {
                    setBuildingWorkflow(draftWorkflow);
                    setIsEditingDraft(true);
                    setIsComparingVersions(false);
                  }}
                >
                  v{buildingWorkflow.draftVersion}.0 {t("draft")}
                </button>
              )}

              {buildingWorkflow?.draftVersion && (
                <button
                  className={`${isComparingVersions ? "secondary active" : ""}`}
                  type="button"
                  onClick={() => {
                    setBuildingWorkflow(workflow);
                    setIsComparingVersions(true);
                    setIsEditingDraft(false);
                  }}
                >
                  {t("Compare")}
                </button>
              )}
            </div>
          </div>
        </>
      )}

      <Builder
        key={"k-" + buildingWorkflow.diseaseId}
        canCreate={canCreate}
        canUpdate={canUpdate}
        canView={canView}
        isEditingDraft={isEditingDraft}
        isComparingVersions={isComparingVersions}
        setWorkflow={setBuildingWorkflow}
        workflow={buildingWorkflow}
        draftWorkflowToCompare={draftWorkflowToCompare}
        setDraftWorkflowToCompare={setDraftWorkflowToCompare}
        workflowToCompare={workflowToCompare}
        setWorkflowToCompare={setWorkflowToCompare}
        createApiWorkflow={createApiWorkflow}
        updateApiWorkflow={updateApiWorkflow}
        autoUpdateApiWorkflow={autoUpdateApiWorkflow}
        completeApiWorkflow={completeApiWorkflow}
        deleteApiWorkflow={deleteApiWorkflow}
        firstStepAdvice={firstStepAdvice}
        nextStepAdvice={nextStepAdvice}
      />
    </div>
  );
}
