import { useState } from "react";
import { useOnMount } from "../../hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { DataTableFiles, PageTitle } from "../../components";
import { useTranslation } from "react-i18next";

export default function AntibioticGuidelines() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const [tableData, setTableData] = useState([]);

  useOnMount(() => {
    const hasTableData = !!tableData.length;
    if (!hasTableData) {
      fetchTableData();
    }
  });

  async function fetchTableData() {
    const guidelines = await getAntibioticGuidelines();
    setTableData(guidelines);
  }

  async function getAntibioticGuidelines() {
    return api
      .getAntibioticGuidelines()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  return (
    <div className="antibiotic-guidelines files">
      <PageTitle title={t("Antibiotic Guidelines")} />

      <DataTableFiles
        tableData={tableData}
        fetchTableData={fetchTableData}
        pageName="abGuidelines"
        showCountryColumn
      />
    </div>
  );
}
