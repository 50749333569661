import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ApiRequests, showHttpRequestError } from "../../http";
import Icon from "../Icon/Icon";
import "./Advice.scss";
import { validate as validateUuid } from "uuid";
import { InputCheckbox, MedicamentAdministration } from "../../components";
import {
  downloadFileAsBlob,
  splitStringAtFirstSymbol,
} from "../../utils/utils";

export default function Advice({
  adviceFor,
  diseaseHistory,
  acceptAdvice,
  showRejectAdvice,
  startNewAdvice,
  isAdviceAccepted,
  isAdviceRejected,
  isTestAdvice,
}) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const advice = adviceFor?.json?.advice;
  const isAdviceCompleted = adviceFor.status === "COMPLETED";
  const isAdviceDraft = adviceFor.status === "DRAFT";

  const adviceMedicaments = adviceFor?.additional?.medicaments;
  const hasAdviceMedicaments =
    adviceMedicaments && adviceMedicaments.length > 0;
  const adviceBacteria = adviceFor?.json?.advice?.bacteriaNames;
  const hasAdviceBacteria = adviceBacteria && adviceBacteria.length > 0;
  const hasAdviceReminder = !!adviceFor?.json?.advice?.reminder;
  const [isAcceptedTreatment, setIsAcceptedTreatment] = useState(null);

  const useIfAdviceIsConsultation = (fn) =>
    useEffect(fn, [isAcceptedTreatment]);

  const handleConsultationChange = (e) => {
    if (e.target.checked) {
      setIsAcceptedTreatment(e.target.value);
    }
  };

  /**
   * Display the survey questions for archived advices.
   */
  const surveyQuestions = adviceFor?.surveyQuestions;
  const hasSurveyQuestions = Boolean(surveyQuestions?.firstQuestion?.id);
  let firstQuestion, secondQuestion, thirdQuestion;

  if (hasSurveyQuestions) {
    firstQuestion = surveyQuestions.firstQuestion;
    secondQuestion = surveyQuestions.secondQuestion;
    thirdQuestion = surveyQuestions.thirdQuestion;
  }

  // for the third question (Did you change the treatment?)
  let isTreatmentChanged = false;

  if (hasSurveyQuestions) {
    isTreatmentChanged = checkIsTreatmentChanged(
      surveyQuestions.thirdQuestion.medicamentOptions,
      advice.treatments
    );
  }

  function checkIsTreatmentChanged(medicamentOptions, treatments) {
    for (const medicamentOption of medicamentOptions) {
      const { medicamentName, dose } = medicamentOption;
      let isMatched = false;

      checkInTreatment: for (const treatment of treatments) {
        const { medicaments } = treatment;

        for (const medicament of medicaments) {
          // Keep comparison as "==" because doses can be numbers or strings
          if (
            medicament.medicamentName == medicamentName &&
            medicament.dose == dose
          ) {
            isMatched = true;
            break checkInTreatment;
          }
        }
        if (isMatched) {
          break;
        }
      }

      // At least one medicamentOption has no match in treatments
      // Treatment was changed
      if (!isMatched) {
        return true;
      }
    }

    // All medicamentOptions have a match in treatments
    // Treatment was not changed
    return false;
  }

  /**
   * For advices "No antibiotics needed".
   *
   * This advice will have 1 treatment with 0 medicaments and filled title.
   */
  const noAntibioticsNeeded =
    adviceFor?.json?.advice?.treatments?.length === 1 &&
    adviceFor?.json?.advice?.treatments?.[0]?.medicaments?.length === 0 &&
    adviceFor?.json?.advice?.treatments?.[0]?.title;

  /**
   * Display the reasons for rejection for rejected advices.
   */
  const rejectReasons = adviceFor?.rejectReasons;
  const hasRejectReasons = Boolean(rejectReasons?.length);

  const shouldSelectAcceptTreatment =
    !isTestAdvice &&
    isAdviceCompleted &&
    !(isAdviceRejected || isAdviceAccepted);

  const adviceClasses = `advice ${isAdviceDraft ? "draft" : ""}${
    isAdviceCompleted ? "completed" : ""
  } ${isAdviceAccepted ? "accepted" : ""} ${
    isAdviceCompleted ? "completed" : ""
  } ${isAdviceRejected ? "rejected" : ""} ${
    shouldSelectAcceptTreatment ? "should-accept-or-reject" : ""
  } ${noAntibioticsNeeded ? "no-antibiotics-needed" : ""}`;

  /**
   * Leaflet download.
   *
   * prop medicamentLink looks like: "075b5b7f-07d7-4f34-8229-5b62d8400f0e,Community-acquired Pneumonia.docx" or null.
   */
  async function downloadFile(fileId, fileName) {
    api
      .getFile(fileId)
      .then((response) => downloadFileAsBlob(response, fileName))
      .catch((error) => showHttpRequestError(error));
  }

  function getMedicamentId(medicamentLink) {
    return splitStringAtFirstSymbol(medicamentLink, ",")[0];
  }

  function getMedicamentName(medicamentLink) {
    return splitStringAtFirstSymbol(medicamentLink, ",")[1];
  }

  return (
    <div className={adviceClasses}>
      <div className="advice-header">
        <h2 className="advice-header-title">
          {isAdviceDraft && t("Generating advice")}

          {isAdviceCompleted &&
            !isAdviceAccepted &&
            !isAdviceRejected &&
            t("Accept this advice?")}

          {isAdviceAccepted && t("Accepted advice")}
          {isAdviceRejected && t("Rejected advice")}
        </h2>

        {!isTestAdvice &&
          isAdviceCompleted &&
          !isAdviceAccepted &&
          !isAdviceRejected && (
            <div className="advice-actions">
              <button
                className="accept-advice"
                type="button"
                onClick={(e) =>
                  isAcceptedTreatment != null
                    ? acceptAdvice(e, isAcceptedTreatment)
                    : {}
                }
                title={t("Accept this advice")}
                disabled={isAcceptedTreatment == null}
              >
                {t("Accept")}
              </button>

              <button
                className="reject-advice secondary"
                type="button"
                onClick={showRejectAdvice}
                title={t("Reject this advice")}
              >
                {t("Reject")}
              </button>
            </div>
          )}

        {(isAdviceRejected || isAdviceAccepted) && (
          <div className="advice-actions">
            <button
              className="new-advice smaller icon-left"
              type="button"
              onClick={startNewAdvice}
              title={t("Start new advice")}
            >
              <Icon name="plus" />
              {t("New advice")}
            </button>
          </div>
        )}
      </div>

      {advice.treatments.map((treatment, index) => (
        <div className="treatment-wrapper" key={"t" + index}>
          <div
            className={`title-advice ${
              shouldSelectAcceptTreatment ? "has-checkbox" : ""
            }`}
          >
            {shouldSelectAcceptTreatment && (
              <InputCheckbox
                id={"acceptedTreatment" + index}
                name="accepted-treatment"
                value={index}
                checked={isAcceptedTreatment == index}
                onChangeFn={(e) => handleConsultationChange(e)}
              />
            )}
            <h2 className="treatment-title">
              {shouldSelectAcceptTreatment && `${t("Accept")}:`}{" "}
              {treatment.title}
            </h2>
          </div>

          {treatment.info && <p className="treatment-info">{treatment.info}</p>}

          {treatment.medicaments.map((medicament, mindex) => {
            const isNotFirstMedicament = mindex > 0;
            const {
              medicamentName,
              dose,
              doseMeasureAbr,
              doseMeasureName,
              marketFormName,
              applicationMethod,
              frequency,
              durationOfTreatment,
            } = medicament;

            return (
              <div className="medicament-wrapper" key={"m" + mindex}>
                <span className="medicament" key={"f" + mindex}>
                  {isNotFirstMedicament && <span className="plus">+</span>}

                  <MedicamentAdministration
                    medicamentName={medicamentName}
                    dose={dose}
                    doseMeasureName={doseMeasureAbr}
                    marketFormName={marketFormName}
                    applicationMethod={applicationMethod}
                    applicationMethodName={
                      applicationMethod.applicationMethodName
                    }
                    applicationMethodMeasureName={applicationMethod.measureAbr}
                    durationOfTreatment={durationOfTreatment}
                  />
                </span>

                {medicament.whenToApply && (
                  <span className="medicament-info when-to-apply">
                    <b>{t("When to apply")}:</b>
                    {medicament.whenToApply}
                  </span>
                )}

                {medicament.whenToRepeat && (
                  <span className="medicament-info when-to-repeat">
                    <b>{t("When to repeat")}:</b>
                    {medicament.whenToRepeat}
                  </span>
                )}

                {medicament.medicamentLink && (
                  <span className="medicament-info leaflet-info">
                    <b>{t("Leaflet")}:</b>

                    {validateUuid(
                      getMedicamentId(medicament.medicamentLink)
                    ) && (
                      <button
                        class="leaflet"
                        onClick={() =>
                          downloadFile(
                            getMedicamentId(medicament.medicamentLink),
                            getMedicamentName(medicament.medicamentLink)
                          )
                        }
                      >
                        {t("Download")} <Icon name="file" />
                      </button>
                    )}

                    {!validateUuid(
                      getMedicamentId(medicament.medicamentLink)
                    ) && (
                      <a
                        href={medicament.medicamentLink}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                        className="leaflet"
                      >
                        {t("Download")} <Icon name="file" />
                      </a>
                    )}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      ))}

      {hasAdviceMedicaments && (
        <div className="side-effects">
          <h2>{t("Side effects")}</h2>

          {adviceMedicaments.map((medicament, index) => (
            <>
              <b>{medicament.medicamentName}:</b>
              <ul key={"me" + index}>
                {medicament.frequencies.map((frequency, index) => (
                  <li key={"fr" + index}>
                    <b>{frequency.name}:</b>
                    {!!frequency &&
                      frequency.reactionName.map((name, index) => (
                        <span key={"rea" + index} className="commas">
                          {name}
                        </span>
                      ))}
                  </li>
                ))}
              </ul>
            </>
          ))}
        </div>
      )}

      {hasAdviceMedicaments && (
        <div className="interactions">
          <h2>{t("Drug interactions")}</h2>

          {adviceMedicaments.map((medicament, index) => (
            <div key={"in" + index} className="mb-2">
              <b>{medicament.medicamentName}:</b>
              <div>{medicament.interaction}</div>
            </div>
          ))}
        </div>
      )}

      {hasAdviceBacteria && (
        <div className="bacteria">
          <h2>{t("Usual bacteria")}</h2>

          <div>
            {adviceBacteria.map((bacteriaName, index) => (
              <span key={"bac" + index} className="commas">
                {bacteriaName}
              </span>
            ))}
          </div>
        </div>
      )}

      {hasAdviceReminder && (
        <div className="reminders">
          <h2>{t("Reminders")}</h2>
          <div>{adviceFor.json.advice.reminder}</div>
        </div>
      )}

      {hasSurveyQuestions && (
        <div className="advice-list items-dashed survey">
          <h2>{t("Results")}</h2>
          <ol>
            <li>
              <span>{firstQuestion.name}</span>
              <span>
                <b>{firstQuestion?.answers?.[0].name}</b>
              </span>
            </li>
            <li>
              <span>{secondQuestion.name}</span>

              {secondQuestion?.reactions.length === 0 && (
                <span>
                  <b>{t("No side effects")}</b>
                </span>
              )}

              {secondQuestion?.reactions.length > 0 && (
                <span>
                  {" "}
                  <b>
                    {secondQuestion.reactions.map((reaction, index) => (
                      <span key={"re" + index} className="commas">
                        {reaction.name}
                      </span>
                    ))}
                  </b>
                </span>
              )}
            </li>
            <li>
              <span>{thirdQuestion.name}</span>
              <span>
                {!isTreatmentChanged && (
                  <span>
                    <b>{t("No")}</b>
                  </span>
                )}

                {isTreatmentChanged && (
                  <span>
                    <b>{t("Тreatment was changed")}</b>
                  </span>
                )}
              </span>
            </li>

            {isTreatmentChanged && (
              <li>
                <span>{t("New treatment")}:</span>
                <span className="new-treatment">
                  {thirdQuestion.medicamentOptions.map((medicament) => (
                    <>
                      <b key={medicament.medicamentId}>
                        {medicament.medicamentName} {medicament.dose}{" "}
                        {medicament.doseMeasureAbr}
                      </b>
                    </>
                  ))}
                </span>
              </li>
            )}
          </ol>
        </div>
      )}

      {hasRejectReasons && (
        <div className="advice-list rejection">
          <h2>{t("Reasons for rejection")}</h2>
          <ol>
            {rejectReasons.map((reason) => (
              <li key={reason.id}>
                <span>{reason.name}</span>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
