import { useDispatch } from "react-redux";
import {
  isDialogConfirmed,
  showSuccessPopup,
  showDialog,
} from "../../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../../http";
import InputSwitch from "../../Forms/InputSwitch/InputSwitch";
import { useTranslation } from "react-i18next";
import "./Ward.scss";

export default function Ward({
  ward,
  wards,
  setWards,
  setEditedWard,
  setUsersInWard,
  setIsWardFormOpen,
  addUpdatedWard,
}) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const { wardNomName: wardName } = ward;

  /**
   * Activate or deactivate ward.
   */
  async function toggleWardActiveStatus(ward) {
    const isWardActive = ward.active;
    const wardId = ward.id;
    const wardName = ward.wardNomName;
    const activateOrDeactivate = isWardActive ? t("Deactivate") : t("Activate");
    const message = isWardActive
      ? t(
          "This will deactivate the ward and its users will not be able to login until you reactivate it."
        )
      : t(
          "This will activate the ward and its users will be able to login again."
        );

    const dialog = {
      title: `${activateOrDeactivate} ${t("ward")} ${wardName}?`,
      message: message,
      buttonConfirmText: activateOrDeactivate,
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(dialog));
    const isActionConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isActionConfirmed) return;

    if (isWardActive) {
      deactivateWard(wardId);
    } else {
      activateWard(wardId);
    }
  }

  function deactivateWard(id) {
    api
      .deactivateWard({ hospitalWardId: id })
      .then((response) => {
        const updatedWard = response.data;
        const wardName = updatedWard.wardNomName;
        addUpdatedWard(updatedWard);
        dispatch(
          showSuccessPopup(`${t("Successfully deactivated ward")} ${wardName}.`)
        );
      })
      .catch((error) => showHttpRequestError(error));
  }

  function activateWard(id) {
    api
      .activateWard({ hospitalWardId: id })
      .then((response) => {
        const updatedWard = response.data;
        const wardName = updatedWard.wardNomName;
        addUpdatedWard(updatedWard);
        dispatch(
          showSuccessPopup(`${t("Successfully activated ward")} ${wardName}.`)
        );
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function deleteWard(ward, e) {
    // Prevent opening the edit ward form.
    e.stopPropagation();

    const { id, wardNomName: wardName } = ward;
    const deleteDialog = {
      title: `${t("Delete ward")} ${wardName}?`,
      message: t("This will also delete all users in this ward."),
      buttonConfirmText: t("Delete"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .deleteWard(id)
      .then((response) => {
        dispatch(
          showSuccessPopup(`${t("Successfully deleted ward")} ${wardName}.`)
        );
        const updatedWards = wards.filter((ward) => {
          if (ward.id === id) return false;
          return true;
        });
        setWards(updatedWards);
      })
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Fetch the full ward data on click.
   */
  function fetchAndEditFullWard(wardId) {
    Promise.allSettled([api.getWard(wardId), api.getWardUsers(wardId)])
      .then((values) => {
        const fetchedWard = values[0].value.data;
        setEditedWard(fetchedWard);

        const fetchedWardUsers = values[1].value.data;
        const usersForSelect = fetchedWardUsers.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        setUsersInWard(usersForSelect);

        setIsWardFormOpen(true);
      })
      .catch((error) => showHttpRequestError(error));
  }

  return (
    <div className="ward">
      <div
        className="ward-header"
        title={t("Edit ward")}
        onClick={() => {
          fetchAndEditFullWard(ward.id);
        }}
      >
        <h3 className="ward-name">{wardName}</h3>

        <InputSwitch
          id={ward.id}
          isChecked={ward.active}
          onChangeFn={() => toggleWardActiveStatus(ward)}
          labelText={ward.active ? t("Deactivate ward") : t("Activate ward")}
        />

        <button
          className="secondary delete-ward"
          title={t("Delete ward")}
          onClick={(e) => deleteWard(ward, e)}
        >
          <span className="icon icon-delete"></span>
        </button>
      </div>
    </div>
  );
}
