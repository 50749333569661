import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiRequests, showHttpRequestError } from "../../../http";
import { useOnMount } from "../../../hooks";
import { isFormValid } from "../../../utils/utils";
import {
  showSuccessMessage,
  showErrorMessage,
  showSuccessPopup,
  showDialog,
  isDialogConfirmed,
} from "../../../redux/reducers";
import { FormMessage } from "../../index";
import {
  getUserDisplayName,
  getRolesLowerThanUser,
  showButtonLoader,
  hideButtonLoader,
} from "../../../utils/utils";
import Select from "react-select";
import { useGetPermissionsForPage } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { selectStyles } from "../../../styles/selectStyles";

export default function FormUser({ fetchTableData }) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const message = useSelector((state) => state.modals.formMessage);
  const { canCreate, canUpdateHospital, canUpdateWard, canResetPassword } =
    useGetPermissionsForPage("users");

  /**
   * Setup the user modal.
   */
  const openedModal = useSelector((state) => state.modals.openedModal);
  const { id: openedModalId, data: editedUser } = openedModal;
  const isEditingUser = Boolean(editedUser?.id);
  const isCreatingUser = !isEditingUser;

  /**
   * Setup user data for the modal form.
   */
  const emptyUser = {
    roleId: "",
    roleName: "",
    username: "",
    email: "",
    password: "",
    hospitalId: "",
    hospitalWardId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
  };
  const [user, setUser] = useState({ ...emptyUser });
  const resetUser = () => setUser({ ...emptyUser });
  const useOnModalToggle = (fn) => useEffect(fn, [openedModalId]);

  useOnModalToggle(() => {
    setUserData();
    return resetUser;
  });

  function setUserData() {
    if (isEditingUser) {
      setUser({ ...editedUser });
      return;
    }

    setUser({
      ...emptyUser,

      // Non-admins (head of hospital) can create users in their hospital.
      // They don't have select for the hospital, so hospitalId is set here.
      ...(!currentUser.isAdmin &&
        canCreate && { hospitalId: currentUser.hospitalId }),
    });
  }

  const setUserField = (e) => {
    const { name: field, value } = e.target;
    setUser({
      ...user,
      [field]: value,
    });
  };

  const {
    roleId,
    roleName,
    username,
    email,
    password,
    hospitalId,
    hospitalWardId,
    firstName,
    middleName,
    lastName,
    phone,
  } = user;

  /**
   * Fetch data for select dropdowns.
   */
  const [userRoles, setUserRoles] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  useOnMount(() => {
    const hasUserRoles = !!userRoles.length;
    const hasHospitals = !!hospitals.length;

    if (!hasUserRoles) {
      fetchAndSetUserRoles();
    }

    if (!hasHospitals && canUpdateHospital) {
      fetchAndSetHospitals();
    }
  });

  async function fetchAndSetUserRoles() {
    const fetchedRoles = await getUserRoles();
    const rolesLowerThanUser = getRolesLowerThanUser(fetchedRoles);

    const rolesForSelect = rolesLowerThanUser.map((role) => ({
      value: role.id,
      label: role.name,
    }));

    setUserRoles(rolesForSelect);
  }

  async function getUserRoles() {
    return api
      .getUserRoles()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  async function fetchAndSetHospitals() {
    const hospitals = await getHospitals();
    const hospitalsForSelect = hospitals.map((hospital) => ({
      value: hospital.id,
      label: hospital.name,
    }));
    setHospitals(hospitalsForSelect);
  }

  async function getHospitals() {
    return api
      .getHospitalFilteredNames(true)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Fetch hospital wards after hospital is selected.
   */
  const [wards, setWards] = useState([]);
  const useOnHospitalSelect = (fn) => useEffect(fn, [user.hospitalId]);

  useOnHospitalSelect(() => {
    if (canUpdateWard) {
      const hospitalId = user.hospitalId;
      if (!hospitalId) return;
      fetchAndSetHospitalWards(hospitalId);
    }
  });

  useOnMount(() => {
    // Some non-admin roles can also create users (head of hospital) but only in their hospital.
    if (!currentUser.isAdmin && canUpdateHospital) {
      fetchAndSetHospitalWards(currentUser.hospitalId);
    }
  });

  async function fetchAndSetHospitalWards(hospitalId) {
    const wards = await getWards(hospitalId);
    const wardsForSelect = wards.map((ward) => ({
      value: ward.id,
      label: ward.name,
    }));
    setWards(wardsForSelect);
  }

  async function getWards(hospitalId) {
    return api
      .getHospitalWardOptions(hospitalId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Set form fields to required depending on selected role.
   *
   * Creating:
   * - admin - requires username, email, password, roleId
   * - head of hospital / microbiology - admin ones + hospitalId
   * - head of ward / doctor - admin ones + hospitalId + hospitalWardId
   */
  const [isHospitalRequired, setIsHospitalRequired] = useState(false);
  const [isWardRequired, setIsWardRequired] = useState(false);
  const useOnRoleSelect = (fn) => useEffect(fn, [user.roleId]);

  useOnRoleSelect(() => {
    setRequiredFormFields();
  });

  function setRequiredFormFields() {
    const role = user.roleName;
    if (!role) return;

    const hospitalIsRequired = role !== "ADMIN";
    const wardIsRequired = role === "HEAD OF WARD" || role === "DOCTOR";

    if (hospitalIsRequired) {
      setIsHospitalRequired(true);
    } else {
      setIsHospitalRequired(false);
    }

    if (wardIsRequired) {
      setIsWardRequired(true);
    } else {
      setIsWardRequired(false);
    }
  }

  /**
   * Create new user.
   */
  const validPhonePattern = /^(\+)?[\d\s-]+$/;

  function createUser(e) {
    e.preventDefault();
    const createUserForm = e.target;

    if (!isFormValid(createUserForm)) {
      dispatch(
        showErrorMessage({
          content: t("Some fields are not valid."),
          id: "userForm",
        })
      );
      return;
    }

    let userToCreate = {
      ...user,
    };

    // Head of ward can only create users in his own ward.
    if (currentUser.role === "HEAD OF WARD") {
      userToCreate = {
        ...userToCreate,
        hospitalWardId: currentUser.hospitalWardId,
      };
    }

    if (phone && !phone.match(validPhonePattern)) {
      dispatch(
        showErrorMessage({
          content: t("Phone number is not valid."),
          id: "userForm",
        })
      );
      return;
    }

    showButtonLoader();
    api
      .createUser(userToCreate)
      .then((response) => {
        const name = getUserDisplayName(user);
        dispatch(
          showSuccessMessage({
            content: `${t("User")} ${name} ${t("created successfully.")}`,
            id: "userForm",
          })
        );
        fetchTableData();
        resetUser();
      })
      .catch((error) => showHttpRequestError(error))
      .finally(hideButtonLoader);
  }

  /**
   * Edit user.
   */
  function editUser(e) {
    e.preventDefault();
    const editUserForm = e.target;

    if (!isFormValid(editUserForm)) {
      dispatch(
        showErrorMessage({
          content: t("Some fields are not valid."),
          id: "userForm",
        })
      );
      return;
    }

    if (phone && !phone.match(validPhonePattern)) {
      dispatch(
        showErrorMessage({
          content: t("Phone number is not valid."),
          id: "userForm",
        })
      );
      return;
    }

    showButtonLoader();
    api
      .updateFullProfileData(user)
      .then((response) => {
        const name = getUserDisplayName(user);
        dispatch(
          showSuccessMessage({
            content: `${name} ${t("updated successfully.")}`,
            id: "userForm",
          })
        );
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error))
      .finally(hideButtonLoader);
  }

  const selectedRole = (role) => role.value === roleId;
  const selectedHospital = (hospital) => hospital.value === hospitalId;
  const selectedWard = (ward) => ward.value === hospitalWardId;

  const submitButtonText = isEditingUser ? t("Save user") : t("Create user");
  const isUsernameReadOnly = isEditingUser;

  /**
   * Reset user password.
   */
  const resetPasswordBtnRef = useRef(null);

  async function resetUserPassword() {
    const name = getUserDisplayName(user);
    const resetPasswordDialog = {
      title: `${t("Reset password for user")} ${name}?`,
      message: t(
        "The user will receive an email with a link for setting their new password."
      ),
      buttonConfirmText: t("Reset password"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(resetPasswordDialog));
    const isResetPasswordConfirmed = await dispatch(
      isDialogConfirmed()
    ).unwrap();
    if (!isResetPasswordConfirmed) return;

    showButtonLoader(resetPasswordBtnRef.current);
    api
      .resetPassword({ id: user.id })
      .then((response) => {
        dispatch(
          showSuccessPopup(
            `${t("Password for user")} ${name} ${t(
              "was reset successfully. They will receive email with further instructions."
            )}`
          )
        );
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader(resetPasswordBtnRef.current));
  }

  return (
    <form onSubmit={isEditingUser ? editUser : createUser}>
      <div className="flex-input-wrapper">
        {canUpdateHospital && (
          <div className="input-wrapper">
            <label htmlFor="hospitalId">{t("Hospital:")}</label>
            <Select
              name="hospitalId"
              options={hospitals}
              isSearchable={true}
              required={isHospitalRequired}
              styles={selectStyles}
              value={hospitals.find(selectedHospital) ?? null}
              onChange={(e) => setUser({ ...user, hospitalId: e.value })}
            />
          </div>
        )}

        {canUpdateWard && (
          <div className="input-wrapper">
            <label htmlFor="hospitalWardId">{t("Ward:")}</label>
            <Select
              name="hospitalWardId"
              options={wards}
              isSearchable={true}
              required={isWardRequired}
              styles={selectStyles}
              value={wards.find(selectedWard) ?? null}
              onChange={(e) => setUser({ ...user, hospitalWardId: e.value })}
            />
          </div>
        )}

        <div className="input-wrapper">
          <label htmlFor="roleId">{t("Role:")}</label>
          <Select
            name="roleId"
            options={userRoles}
            isSearchable={true}
            required
            styles={selectStyles}
            openMenuOnClick
            openMenuOnFocus
            value={userRoles.find(selectedRole) ?? null}
            onChange={(e) =>
              setUser({ ...user, roleId: e.value, roleName: e.label })
            }
          />
        </div>
      </div>

      <div className="flex-input-wrapper">
        <div className="input-wrapper">
          <label htmlFor="username">{t("Username:")}</label>
          <input
            type="text"
            name="username"
            value={username}
            id="username"
            required
            minLength={3}
            maxLength={50}
            onChange={setUserField}
            readOnly={isUsernameReadOnly}
          />
        </div>

        {isCreatingUser && canCreate && (
          <div className="input-wrapper">
            <label htmlFor="password">{t("Password:")}</label>
            <input
              type="text"
              name="password"
              value={password}
              id="password"
              required
              minLength="6"
              maxLength="40"
              onChange={setUserField}
            />
          </div>
        )}

        <div className="input-wrapper">
          <label htmlFor="email">{t("Email:")}</label>
          <input
            type="email"
            name="email"
            value={email}
            id="email"
            required
            onChange={setUserField}
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="phone">{t("Phone:")}</label>
          <input
            type="tel"
            name="phone"
            value={phone ?? ""}
            id="phone"
            onChange={setUserField}
          />
        </div>
      </div>

      <div className="flex-input-wrapper">
        <div className="input-wrapper">
          <label htmlFor="firstName">{t("First name:")}</label>
          <input
            type="text"
            name="firstName"
            value={firstName ?? ""}
            id="firstName"
            onChange={setUserField}
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="middleName">{t("Middle name:")}</label>
          <input
            type="text"
            name="middleName"
            value={middleName ?? ""}
            id="middleName"
            onChange={setUserField}
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="lastName">{t("Last name:")}</label>
          <input
            type="text"
            name="lastName"
            value={lastName ?? ""}
            id="lastName"
            onChange={setUserField}
          />
        </div>
      </div>

      {message.content && <FormMessage id="userForm" />}

      <div className="flex-input-wrapper">
        <button type="submit">{submitButtonText}</button>

        {isEditingUser && canResetPassword && (
          <button
            className="reset-password secondary"
            type="button"
            onClick={resetUserPassword}
            ref={resetPasswordBtnRef}
          >
            {t("Reset password")}
          </button>
        )}
      </div>
    </form>
  );
}
