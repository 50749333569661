import { useTranslation } from "react-i18next";
import "./MyHospital.scss";

export default function MyHospital({ hospital, editHospital }) {
  const { t } = useTranslation();

  if (!hospital) {
    return null;
  }

  return (
    <div className="my-hospital">
      <h1>
        <span>{t("My hospital")}</span> {hospital.name}
      </h1>

      <div className="hospital-info">
        <div className="text">
          <div>
            {hospital.cityName && (
              <p>
                <strong>{t("City:")}</strong> {hospital.cityName}
              </p>
            )}

            {hospital.address && (
              <p>
                <strong>{t("Address:")}</strong> {hospital.address}
              </p>
            )}

            {hospital.contactPerson && (
              <p>
                <strong>{t("Contact person:")}</strong> {hospital.contactPerson}
              </p>
            )}

            {hospital.phone && (
              <p>
                <strong>{t("Phone:")}</strong> {hospital.phone}
              </p>
            )}
          </div>
        </div>
      </div>

      <button className="editHospital" onClick={() => editHospital(hospital)}>
        {t("Edit hospital")}
      </button>
    </div>
  );
}
