import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Logo.scss";

export default function Logo({ isTitleVisible, isLight }) {
  const { t } = useTranslation();
  const logoClasses = `logo ${isTitleVisible ? "has-title" : "no-title"} ${
    isLight ? "light" : "dark"
  }`;

  return (
    <Link to="/" className={logoClasses}>
      <img
        className="image"
        src="/images/logo.svg"
        alt={t("Amira Logo")}
        title={t("Back to Homepage")}
      />

      {isTitleVisible && (
        <span className="text">
          <span>Amira</span>
        </span>
      )}
    </Link>
  );
}
