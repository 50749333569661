import { useTranslation } from "react-i18next";
import { PageTitle, PatientsAndAdvices } from "../../components";

export default function HomeDoctor() {
  const { t } = useTranslation();

  return (
    <div className="home doctor">
      <PageTitle title={t("Patients & Advices")} isBackButtonVisible={false} />
      <PatientsAndAdvices />
    </div>
  );
}
