import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormMessage, Logo } from "../../components";
import { showErrorMessage, showSuccessPopup } from "../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../http";
import { showButtonLoader, hideButtonLoader } from "../../utils/utils";
import { useOnMount } from "../../hooks";
import "./ResetPassword.scss";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.modals.formMessage);
  const [resetPasswordData, setResetPasswordData] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = resetPasswordData;

  const setPasswordField = (e) => {
    const { name: field, value } = e.target;
    setResetPasswordData({
      ...resetPasswordData,
      [field]: value,
    });
  };

  /**
   * Set the token.
   */
  useOnMount(() => setPasswordResetToken());

  function setPasswordResetToken() {
    const resetToken = new URLSearchParams(window.location.search).get("token");
    setResetPasswordData({ ...resetPasswordData, token: resetToken });
  }

  /**
   * Reset password.
   */
  const handleResetPassword = (e) => {
    e.preventDefault();
    const passwordsMatch = password === confirmPassword;
    const passwordIsShort = password.length < 6;

    if (!passwordsMatch) {
      dispatch(
        showErrorMessage({
          content: t("Passwords don't match."),
          id: "resetForm",
        })
      );
      return;
    }

    if (passwordIsShort) {
      dispatch(
        showErrorMessage({
          content: t("Password length should be at least 6 characters."),
          id: "resetForm",
        })
      );
      return;
    }

    showButtonLoader();

    api
      .changePassword(resetPasswordData)
      .then((response) => {
        showSuccessPopup(
          t("New password set successfully. You can now login.")
        );
        navigate("/");
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  };

  return (
    <div className="login login-reset-password">
      <Logo isTitleVisible={true}></Logo>
      <h1>{t("Reset password")}</h1>

      <form onSubmit={handleResetPassword} name="resetForm">
        <div className="input-wrapper">
          <label htmlFor="">{t("New password:")}</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPasswordField(e)}
            minLength="6"
            required
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="">{t("Repeat new password:")}</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setPasswordField(e)}
            minLength="6"
            required
          />
        </div>

        {message.content && <FormMessage id="resetForm" />}

        <button type="submit">{t("Reset password")}</button>
      </form>
    </div>
  );
}
