import { useState, useRef, useEffect } from "react";
import { InputCheckbox } from "../../../components";
import Select from "react-select";
import { selectStyles } from "../../../styles/selectStyles";

export default function TranslationLanguage({
  language,
  nomenclatureFields,
  setIsSaveButtonDisabled,
  setIsEnglishTranslated,
  translation,
  updateEnglishTranslation,
  updateOtherTranslation,
  reactionFrequencies,
  medicamentReactionFrequencies,
  updateReactionFrequencies,
  reactionsAnswers,
  setReactionsAnswers,
  indexTranslation,
  partialTranslations,
  setPartialTranslations,
  selectOptions,
  selectAnswers,
  updateSelectAnswer,
  filledAndUnsavedTranslations,
  setFilledAndUnsavedTranslations,
  selectedNomenclatureType,
}) {
  /**
   * Track whether translation is completed or partial.
   */
  const translationRef = useRef(null);
  const [isTranslationCompleted, setIsTranslationCompleted] = useState(false);
  const [isTranslationPartial, setIsTranslationPartial] = useState(false);
  const [isTranslationFilledAndNotSaved, setIsTranslationFilledAndNotSaved] =
    useState(false);

  /**
   * Effects.
   */
  useEffect(() => {
    updateTranslationsStatus();
  }, [translation?.id]);

  useEffect(() => {
    updatePartialTranslations();
  }, [isTranslationPartial, indexTranslation]);

  useEffect(() => {
    updateFilledAndUnsavedTranslations();
  }, [isTranslationFilledAndNotSaved, indexTranslation]);

  function updateFilledAndUnsavedTranslations() {
    setFilledAndUnsavedTranslations((prev) => {
      const prevCopy = [...prev];
      if (isTranslationFilledAndNotSaved) {
        prevCopy[indexTranslation] = true;
      } else {
        prevCopy[indexTranslation] = false;
      }
      return prevCopy;
    });
  }

  function updatePartialTranslations() {
    setPartialTranslations((prev) => {
      const prevCopy = [...prev];
      if (isTranslationPartial) {
        prevCopy[indexTranslation] = true;
      } else {
        prevCopy[indexTranslation] = false;
      }
      return prevCopy;
    });
  }

  /**
   * All required fields should be either filled out or empty.
   * Partial translations cannot be saved.
   */
  const updateTranslationsStatus = () => {
    const inputs = translationRef.current.querySelectorAll(
      "input[required], textarea[required]"
    );
    const allRequiredFieldsAreFilled = Array.from(inputs).every(
      (input) => input.value
    );
    const noneRequiredFieldsAreFilled = Array.from(inputs).every(
      (input) => !input.value
    );
    const someTranslationIsFilledAndNotSaved = Array.from(inputs).some(
      (input) => input.value && !input.disabled
    );

    if (language.alias === "en" && allRequiredFieldsAreFilled) {
      setIsEnglishTranslated(allRequiredFieldsAreFilled);
    }

    if (allRequiredFieldsAreFilled) {
      setIsTranslationCompleted((prev) => true);
      setIsTranslationPartial((prev) => false);
    }

    if (noneRequiredFieldsAreFilled) {
      if (language.alias === "en") {
        setIsEnglishTranslated((prev) => false);
      }
      setIsTranslationCompleted((prev) => false);
      setIsTranslationPartial((prev) => false);
    }

    if (!allRequiredFieldsAreFilled && !noneRequiredFieldsAreFilled) {
      setIsTranslationCompleted((prev) => false);
      setIsTranslationPartial((prev) => true);
    }

    if (allRequiredFieldsAreFilled || noneRequiredFieldsAreFilled) {
      setIsSaveButtonDisabled((prev) => false);
    } else {
      setIsSaveButtonDisabled((prev) => true);
    }

    if (someTranslationIsFilledAndNotSaved) {
      setIsTranslationFilledAndNotSaved((prev) => true);
    } else {
      setIsTranslationFilledAndNotSaved((prev) => false);
    }
  };

  /**
   * Select fields.
   */

  const getSelectValue = (fieldName) => {
    if (!selectOptions?.[fieldName]) return null;

    const selectedValue = selectOptions[fieldName].find(
      (option) => option.value === selectAnswers?.[fieldName]
    );

    if (!selectedValue) {
      return null;
    } else {
      return selectedValue;
    }
  };

  const getReactionsValue = (frequencyId) => {
    if (reactionsAnswers !== null) {
      return reactionsAnswers?.[frequencyId];
    }

    const reactionsIds = reactionFrequencies.current.find(
      (frequency) => frequency.frequencyId === frequencyId
    )?.reactions;

    let reactionsToDisplay = [];

    if (reactionsIds?.length > 0) {
      reactionsToDisplay = reactionsIds.map((reactionId) => {
        return {
          value: reactionId,
          label: selectOptions.reactions.find(
            (option) => option.value === reactionId
          )?.label,
        };
      });
    }
    return reactionsToDisplay;
  };

  const showFieldWithoutTranslationsOnlyInEnglish = (fieldName, language) => {
    let nonTranslatableFields = [
      "question",
      "atcGroupCode",
      "icdCode",
      "staticAnswer",
      "diseaseGroup",
    ];

    if (selectedNomenclatureType === "MEDICAMENT") {
      nonTranslatableFields = [
        ...nonTranslatableFields,
        "name",
        "medicamentGroup",
        "activeIngredientName",
        "activeIngredientCode",
        "applicationMethod",
        "form",
        "marketCut",
        "summary",
      ];
    }
    if (selectedNomenclatureType === "FREQUENCY"){
      nonTranslatableFields = [
        ...nonTranslatableFields,
        "frequencyOrder"
      ];
    }

    if (language === "en") {
      return true;
    }

    if (language !== "en") {
      if (nonTranslatableFields.includes(fieldName)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const hasMedicamentReactionFrequencies =
    medicamentReactionFrequencies?.length > 0 &&
    selectOptions?.frequencyId?.length;

  /**
   * CSS Classes.
   */
  const languageClasses = `language-name ${
    isTranslationCompleted ? "complete" : ""
  } ${isTranslationPartial ? "partial" : ""} ${
    !isTranslationCompleted && !isTranslationPartial ? "empty" : ""
  } `;

  return (
    <div
      className="translation-language"
      ref={translationRef}
      key={language.id}
    >
      <div className={languageClasses}>{language.nameLocal}</div>

      {nomenclatureFields.map((field, index) => {
        /**
         * Important: When fetching nomenclatures from the API, the text is always in the "name" prop (keyNameForDisplay in the code below).
         *
         * When creating a new nomenclature - it's created with either "name" or "body" prop (get it from the nomenclatureTypes object).
         */
        let keyNameForDisplay = field.name;
        if (keyNameForDisplay === "body") {
          keyNameForDisplay = "name";
        }
        
        const isNonHiddenTextField = field.type === "text" && !field.hidden;
        const isNonHiddenTextarea = field.type === "textarea" && !field.hidden;
        const isNonHiddenCheckboxField =
          field.type === "checkbox" && !field.hidden;
        const isNonHiddenSelectField = field.type === "select" && !field.hidden;
        const isTranslationAlreadySaved = translation?.id;

        const keyTranslation = keyNameForDisplay + indexTranslation;
        const keyField = keyNameForDisplay + indexTranslation + index;

        const inputWrapperClasses = `input-wrapper ${
          field.required ? "required" : ""
        }`;

        return (
          <div className={inputWrapperClasses} key={keyTranslation}>
            {isNonHiddenTextField &&
              showFieldWithoutTranslationsOnlyInEnglish(
                field.name,
                language.alias
              ) && (
                <div className="input-wrapper" key={keyField}>
                  <label htmlFor={keyField}>{field.label}</label>
                  <input
                    type="text"
                    id={keyField}
                    defaultValue={translation?.[keyNameForDisplay] || ""}
                    required={field.required}
                    onChange={(e) => {
                      if (language.alias === "en") {
                        updateEnglishTranslation(field.name, e.target.value);
                      }
                      if (language.alias !== "en") {
                        updateOtherTranslation(
                          field.name,
                          e.target.value,
                          language.id
                        );
                      }
                      updateTranslationsStatus();
                    }}
                    disabled={
                      translation?.[keyNameForDisplay] ||
                      isTranslationAlreadySaved
                    }
                    title={translation?.[keyNameForDisplay] || ""}
                  />
                </div>
              )}

            {isNonHiddenTextarea &&
              showFieldWithoutTranslationsOnlyInEnglish(
                field.name,
                language.alias
              ) && (
                <div className="input-wrapper" key={keyField}>
                  <label htmlFor={keyField}>{field.label}</label>
                  <textarea
                    id={keyField}
                    defaultValue={translation?.[keyNameForDisplay] || ""}
                    required={field.required}
                    onChange={(e) => {
                      if (language.alias === "en") {
                        updateEnglishTranslation(field.name, e.target.value);
                      }
                      if (language.alias !== "en") {
                        updateOtherTranslation(
                          field.name,
                          e.target.value,
                          language.id
                        );
                      }
                      updateTranslationsStatus();
                    }}
                    disabled={
                      translation?.[keyNameForDisplay] ||
                      isTranslationAlreadySaved
                    }
                    title={translation?.[keyNameForDisplay] || ""}
                  ></textarea>
                </div>
              )}

            {isNonHiddenCheckboxField &&
              showFieldWithoutTranslationsOnlyInEnglish(
                field.name,
                language.alias
              ) && (
                <div className={inputWrapperClasses} key={keyField}>
                  <InputCheckbox
                    id={keyField}
                    defaultChecked={translation?.[field.name] ?? false}
                    labelText={field.label}
                    disabled={isTranslationAlreadySaved}
                    onChangeFn={(e) => {
                      if (language.alias === "en") {
                        updateEnglishTranslation(field.name, e.target.checked);
                      }
                      if (language.alias !== "en") {
                        updateOtherTranslation(
                          field.name,
                          e.target.checked,
                          language.id
                        );
                      }
                      updateTranslationsStatus();
                    }}
                  />
                </div>
              )}

            {isNonHiddenSelectField &&
              showFieldWithoutTranslationsOnlyInEnglish(
                field.name,
                language.alias
              ) && (
                <div className={inputWrapperClasses} key={keyField}>
                  <label htmlFor={keyField}>{field.label}</label>
                  <Select
                    name={field.name}
                    options={selectOptions?.[field.name] || []}
                    isSearchable={true}
                    styles={{
                      ...selectStyles,
                      menuList: (state) => ({
                        ...state,
                        maxHeight: "250px",
                      }),
                    }}
                    openMenuOnClick
                    openMenuOnFocus
                    value={getSelectValue(field.name)}
                    onChange={(e) => {
                      updateSelectAnswer(field.name, e.value);

                      if (language.alias === "en") {
                        updateEnglishTranslation(field.name, e.value);
                      }
                      if (language.alias !== "en") {
                        updateOtherTranslation(
                          field.name,
                          e.value,
                          language.id
                        );
                      }
                      updateTranslationsStatus();
                    }}
                    menuPlacement="auto"
                    isDisabled={isTranslationAlreadySaved}
                  />
                </div>
              )}
          </div>
        );
      })}

      {hasMedicamentReactionFrequencies > 0 &&
        language.alias === "en" &&
        selectOptions.frequencyId.map((frequency, index) => {
          const keyField = frequency.label + index;
          const isTranslationAlreadySaved = translation?.id;

          return (
            <div className="input-wrapper" key={keyField}>
              <label htmlFor={keyField}>
                Adverse Drug Reactions - {frequency.label}
              </label>
              <Select
                name="reactions"
                options={selectOptions?.reactions || []}
                isSearchable={true}
                styles={{
                  ...selectStyles,
                  menuList: (state) => ({
                    ...state,
                    maxHeight: "250px",
                  }),
                }}
                openMenuOnClick
                openMenuOnFocus
                value={getReactionsValue(frequency.value)}
                onChange={(options) => {
                  updateReactionFrequencies(frequency.value, options);
                  setReactionsAnswers((prevState) => {
                    return {
                      ...prevState,
                      [frequency.value]: options,
                    };
                  });
                  updateTranslationsStatus();
                }}
                isMulti
                menuPlacement="auto"
                placeholder={
                  isTranslationAlreadySaved &&
                  getReactionsValue(frequency.value).length === 0
                    ? "None selected"
                    : ""
                }
                isDisabled={isTranslationAlreadySaved}
              />
            </div>
          );
        })}
    </div>
  );
}
