import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Card, Counter, ChartDoughnut, Icon } from "..";
import { useOnMount } from "../../hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import Select from "react-select";
import { smallSelectStyles } from "../../styles/selectStyles";
import { doughnutChartConfig } from "../../config/charts/doughnut";
import { useTranslation } from "react-i18next";
import { doughnutOptions } from "../../config/charts/doughnut";
import cloneDeep from "lodash/cloneDeep";
import "./Statistics.scss";

export default function Statistics() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const user = useSelector((state) => state.user);
  const contextType = user.contextType;

  /**
   * Filters are shown depending on contextType (what does the user have access to).
   *
   * ContextType for user roles is:
   * - admin: null
   * - hospital: HOSPITAL
   * - ward: HOSPITAL_WARD
   */
  const canFilterBy = {
    country: contextType === null,
    hospital: contextType === null,
    ward: contextType === null || contextType === "HOSPITAL",
  };

  const areFiltersVisible = Object.values(canFilterBy).some(
    (value) => value === true
  );

  const canOnlyFilterByWard = canFilterBy.ward && !canFilterBy.hospital;

  /**
   * Statistics data.
   */
  const [activePatients, setActivePatients] = useState(0);
  const [acceptedAdvices, setAcceptedAdvices] = useState(0);
  const [rejectedAdvices, setRejectedAdvices] = useState(0);
  const [advicePercentage, setAdvicePercentage] = useState({
    accepted: 0,
    rejected: 0,
  });
  const [mostFrequentDiagnosis, setMostFrequentDiagnosis] = useState([]);
  const [recommendedAntibiotics, setRecommendedAntibiotics] = useState([]);
  const [reasonsAdviceRejection, setReasonsAdviceRejection] = useState([]);

  const hasDiagnosis = mostFrequentDiagnosis.length > 0;
  const hasAntibiotics = recommendedAntibiotics.length > 0;
  const hasReasonsForRejection = reasonsAdviceRejection.length > 0;

  /**
   * @entityId - UUID of the the entity type.
   * @entityType - COUNTRY | HOSPITAL | HOSPITAL_WARD
   */
  async function fetchAndSetDashboardData(entityId, entityType) {
    Promise.allSettled([
      api.getActivePatientsCount(entityId, entityType),
      api.getAcceptedAdviceCount(entityId, entityType),
      api.getRejectedAdviceCount(entityId, entityType),
      api.getMostFrequentDiagnoses(entityId, entityType),
      api.getMostRecommendedMedicaments(entityId, entityType),
      api.getReasonsForAdviceRejection(entityId, entityType),
    ])
      .then((values) => {
        const fetchedActivePatients = values[0].value.data;
        const fetchedAcceptedAdvice = values[1].value.data;
        const fetchedRejectedAdvice = values[2].value.data;
        const fetchedFrequentDiagnoses = values[3].value.data;
        const fetchedMostRecommendedMedicaments = values[4].value.data;
        const fetchedReasonsAdviceRejection = values[5].value.data;

        setActivePatients(fetchedActivePatients);
        setAcceptedAdvices(fetchedAcceptedAdvice);
        setRejectedAdvices(fetchedRejectedAdvice);
        setMostFrequentDiagnosis(fetchedFrequentDiagnoses);
        setRecommendedAntibiotics(fetchedMostRecommendedMedicaments);
        setReasonsAdviceRejection(fetchedReasonsAdviceRejection);

        calculateAdvicePercentages(
          fetchedAcceptedAdvice,
          fetchedRejectedAdvice
        );
      })
      .catch((error) => showHttpRequestError(error));
  }

  function createDataForChart(statistics) {
    const orderedStatistics = sortByCountDescending(statistics);
    const data = orderedStatistics.map((statistic) => statistic.count);
    const dataPercentages = convertAbsoluteCountsToPercentages(data);
    const labels = orderedStatistics.map((statistic, index) => {
      return `${dataPercentages[index]}% ${statistic.name} `;
    });
    const labelOnHover = "";

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: labelOnHover,
          data: dataPercentages,
          ...doughnutChartConfig,
        },
      ],
    };
    return chartData;
  }

  function convertAbsoluteCountsToPercentages(counts) {
    const totalCount = counts.reduce((sum, current) => sum + current, 0);
    const percentages = counts.map((count) =>
      Math.round((count / totalCount) * 100)
    );
    return percentages;
  }

  function sortByCountDescending(arr) {
    const orderedDescArr = [...arr].sort((a, b) => b.count - a.count);
    return orderedDescArr;
  }

  function calculateAdvicePercentages(acceptedAdvices, rejectedAdvices) {
    const totalAdvices = acceptedAdvices + rejectedAdvices;

    if (totalAdvices === 0) {
      setAdvicePercentage({
        accepted: 0,
        rejected: 0,
      });
      return;
    }

    const acceptedPercentage = Math.round(
      (acceptedAdvices / totalAdvices) * 100
    );
    const rejectedPercentage = Math.round(
      (rejectedAdvices / totalAdvices) * 100
    );

    setAdvicePercentage({
      accepted: acceptedPercentage,
      rejected: rejectedPercentage,
    });
  }

  /**
   * Filter: Countries.
   */
  const [countries, setCountries] = useState([]);
  const hasCountries = Boolean(countries.length);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const selectedCountry = (country) => country.value === selectedCountryId;

  async function fetchAndSetCountries() {
    const fetchedCountries = await api
      .getCountries()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const countriesForSelect = fetchedCountries.map((country) => ({
      value: country.id,
      label: country.name,
    }));

    const countriesForSelectWithAllOption = [
      { value: "all", label: t("All countries") },
      ...countriesForSelect,
    ];

    setCountries(countriesForSelectWithAllOption);

    if (countries.length > 0) {
      setIsCountryDisabled(false);
    }
  }

  /**
   * Filter: Hospitals.
   */
  const [hospitals, setHospitals] = useState([]);
  const hasHospitals = Boolean(hospitals.length);
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const selectedHospital = (hospital) => hospital.value === selectedHospitalId;

  async function fetchAndSetHospitals() {
    const fetchedHospitals = await api
      .getHospitalsInCountry(selectedCountryId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const hospitalsForSelect = fetchedHospitals.map((hospital) => ({
      value: hospital.id,
      label: hospital.name,
    }));

    const hospitalsForSelectWithAllOption = [
      { value: "all", label: t("All hospitals") },
      ...hospitalsForSelect,
    ];

    setHospitals(hospitalsForSelectWithAllOption);
  }

  /**
   * Filter: Ward.
   */
  const [wards, setWards] = useState([]);
  const hasWards = Boolean(wards.length);
  const [selectedWardId, setSelectedWardId] = useState(null);
  const selectedWard = (ward) => ward.value === selectedWardId;

  async function fetchAndSetHospitalWards(hospitalId) {
    const wards = await getWards(hospitalId);

    const wardsForSelect = wards.map((ward) => ({
      value: ward.id,
      label: ward.wardNomName,
    }));

    const wardsForSelectWithAllOption = [
      { value: "all", label: t("All wards") },
      ...wardsForSelect,
    ];

    setWards(wardsForSelectWithAllOption);

    if (wardsForSelect.length > 0) {
      setIsWardDisabled(false);
    }
  }

  async function getWards(hospitalId) {
    return api
      .getHospitalWards(hospitalId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }
  /**
   * Unlock the filters one by one.
   */
  const [isCountryDisabled, setIsCountryDisabled] = useState(false);
  const [isHospitalDisabled, setIsHospitalDisabled] = useState(true);
  const [isWardDisabled, setIsWardDisabled] = useState(true);

  const isCountrySelected = Boolean(selectedCountryId);
  const isHospitalSelected = Boolean(selectedHospitalId);
  const isWardSelected = Boolean(selectedWardId);

  /**
   * Filters logic.
   */
  useOnMount(() => {
    fetchAndSetDashboardData();
    fetchAndSetCountries();

    if (canOnlyFilterByWard) {
      fetchAndSetHospitalWards(user.hospitalId);
    }
  });

  useOnCountrySelect(() => {
    if (!isCountrySelected) return;

    if (selectedCountryId === "all") {
      setHospitals([]);
      setIsHospitalDisabled(true);
      fetchAndSetDashboardData();
      return;
    }

    fetchAndSetDashboardData(selectedCountryId, "COUNTRY");

    setHospitals([]);
    fetchAndSetHospitals(selectedCountryId);
    setIsHospitalDisabled(false);

    setWards([]);
    setIsWardDisabled(true);
  });

  useOnHospitalSelect(() => {
    if (!isHospitalSelected) return;

    if (selectedHospitalId === "all") {
      setWards([]);
      setIsWardDisabled(true);
      fetchAndSetDashboardData(selectedCountryId, "COUNTRY");
      return;
    }

    fetchAndSetDashboardData(selectedHospitalId, "HOSPITAL");

    setWards([]);
    fetchAndSetHospitalWards(selectedHospitalId);
    setIsWardDisabled(false);
  });

  useOnWardSelect(() => {
    if (!isWardSelected) return;

    if (selectedWardId === "all") {
      if (selectedHospitalId === "all") return;

      if (canOnlyFilterByWard) {
        fetchAndSetDashboardData();
        return;
      }

      fetchAndSetDashboardData(selectedHospitalId, "HOSPITAL");
      return;
    }

    fetchAndSetDashboardData(selectedWardId, "HOSPITAL_WARD");
  });

  function useOnCountrySelect(callback) {
    useEffect(callback, [selectedCountryId]);
  }

  function useOnHospitalSelect(callback) {
    useEffect(callback, [selectedHospitalId]);
  }

  function useOnWardSelect(callback) {
    useEffect(callback, [selectedWardId]);
  }

  /**
   * Set custom html legend container id for each chart.
   */
  function createOptionsForChart(legendContainerId) {
    const chartOptions = cloneDeep(doughnutOptions);
    chartOptions.plugins.htmlLegend.containerID = legendContainerId;
    return chartOptions;
  }

  return (
    <div className="statistics">
      {areFiltersVisible && (
        <div className="filters-statistics">
          {canFilterBy.country && (
            <div className="input-wrapper">
              <label htmlFor="countryId">{t("Country")}</label>
              <Select
                name="countryId"
                options={countries}
                isSearchable={true}
                required
                styles={smallSelectStyles}
                value={countries.find(selectedCountry) ?? countries[0]} // All is the default value.
                isDisabled={!hasCountries || isCountryDisabled}
                placeholder={
                  hasCountries ? t("Select...") : t("No countries found")
                }
                onChange={(e) => setSelectedCountryId(e.value)}
              />
            </div>
          )}

          {canFilterBy.hospital && (
            <div className="input-wrapper">
              <label htmlFor="hospitalId">{t("Hospital")}</label>
              <Select
                name="hospitalId"
                options={hospitals}
                isSearchable={true}
                required
                styles={smallSelectStyles}
                value={hospitals.find(selectedHospital) ?? hospitals[0]} // All is the default value.
                isDisabled={!hasHospitals || isHospitalDisabled}
                placeholder={
                  hasHospitals ? t("Select...") : t("No hospitals found")
                }
                onChange={(e) => setSelectedHospitalId(e.value)}
              />
            </div>
          )}

          {canFilterBy.ward && (
            <div className="input-wrapper">
              <label htmlFor="wardId">{t("Ward")}</label>
              <Select
                name="wardId"
                options={wards}
                isSearchable={true}
                required
                styles={smallSelectStyles}
                value={wards.find(selectedWard) ?? wards[0]} // All is the default value.
                isDisabled={!hasWards || isWardDisabled}
                placeholder={hasWards ? t("Select...") : t("No wards found")}
                onChange={(e) => setSelectedWardId(e.value)}
              />
            </div>
          )}
        </div>
      )}

      <Container>
        <Card title={t("Active patients")}>
          <Counter count={activePatients}></Counter>
        </Card>

        <Card title={t("Accepted advices")}>
          <Counter
            count={acceptedAdvices}
            percentage={advicePercentage.accepted}
            durationDays={"30"}
          ></Counter>
        </Card>

        <Card title={t("Rejected advices")}>
          <Counter
            count={rejectedAdvices}
            percentage={advicePercentage.rejected}
            durationDays={"30"}
          ></Counter>
        </Card>
      </Container>

      <Container>
        <Card title={t("Most common diagnoses")}>
          {hasDiagnosis && (
            <div className="chart-external">
              <ChartDoughnut
                data={createDataForChart(mostFrequentDiagnosis)}
                options={createOptionsForChart("diagnosesChart")}
              />
              <div id="diagnosesChart" className="htmlLegend"></div>
            </div>
          )}

          {!hasDiagnosis && <Counter count={"-"}></Counter>}
        </Card>

        <Card title={t("Most recommended antibiotics")}>
          {hasAntibiotics && (
            <div className="chart-external">
              <ChartDoughnut
                data={createDataForChart(recommendedAntibiotics)}
                options={createOptionsForChart("antibioticsChart")}
              />
              <div id="antibioticsChart" className="htmlLegend"></div>
            </div>
          )}

          {!hasAntibiotics && <Counter count={"-"}></Counter>}
        </Card>

        <Card title={t("Reasons for advice rejection")}>
          {hasReasonsForRejection && (
            <div className="chart-external">
              <ChartDoughnut
                data={createDataForChart(reasonsAdviceRejection)}
                options={createOptionsForChart("rejectionChart")}
              />
              <div id="rejectionChart" className="htmlLegend"></div>
            </div>
          )}

          {!hasReasonsForRejection && <Counter count={"-"}></Counter>}
        </Card>
      </Container>

      <Container>
        <Card title={t("Treatment guidelines")}>
          <p>{t("Up to date antimicrobial therapy.")}</p>
          <Link to="/guidelines" className="btn secondary">
            {t("View guidelines")} <Icon name="arrow-right"></Icon>
          </Link>
        </Card>

        <Card title={t("Antibiotic leaflets")}>
          <p>{t("Dosage, administration, side effects and more.")}</p>
          <Link to="/leaflets" className="btn secondary">
            {t("View leaflets")} <Icon name="arrow-right"></Icon>
          </Link>
        </Card>

        <Card title={t("Bacterial resistance")}>
          <p>{t("Local and national bacterial resistance statistics.")}</p>
          <Link to="/resistance" className="btn secondary">
            {t("View statistics")} <Icon name="arrow-right"></Icon>
          </Link>
        </Card>
      </Container>
    </div>
  );
}
