import { useDispatch } from "react-redux";
import {
  showModal,
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
} from "../../redux/reducers";
import { useGetPermissionsForPage } from "../../hooks/hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { DataTable, Modal, Icon } from "../../components";
import FormFile from "../Modal/components/FormFile";
import { useTranslation } from "react-i18next";
import { getVisibleTableColumns, downloadFileAsBlob } from "../../utils/utils";
import { CardFile } from "../Cards";

export default function DataTableFiles({
  tableData,
  fetchTableData,
  pageName,
  showCountryColumn = false,
  showHospitalColumn = false,
}) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const userPermissions = useGetPermissionsForPage(pageName);
  const { canUpload, canDownload, canDelete } = userPermissions;

  /**
   * Table columns.
   * "isVisible" columns are shown depending on user permissions.
   */
  const allTableColumns = [
    {
      id: "title",
      accessorKey: "title",
      header: () => <span>{t("Title")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      id: "countryName",
      isVisible: showCountryColumn,
      accessorKey: "countryName",
      header: () => <span>{t("Country")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      id: "hospitalName",
      isVisible: showHospitalColumn,
      accessorKey: "hospitalName",
      header: () => <span>{t("Hospital")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      id: "fileType",
      accessorKey: "fileType",
      header: () => <span>{t("Type")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      id: "fileSize",
      accessorKey: "fileSize",
      header: () => <span>{t("Size")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      isVisible: canDownload,
      id: "download",
      header: () => <span>{t("Download")}</span>,
      cell: (info) => {
        if (info.row.original.downloadable) {
          return (
            <button
              className="td-action"
              onClick={() => downloadFile(info.row.original)}
              title={`${t("Download")} ${info.row.original.name}`}
            >
              <Icon name="download" /> {t("Download")}
            </button>
          );
        } else {
          return (
            <a
              href={info.row.original.externalLink}
              target="_blank"
              rel="noreferrer noopener nofollow"
              className="btn td-action"
              title={`${t("View external link")} ${info.row.original.name}`}
            >
              <Icon name="fullscreen" /> {t("View")}
            </a>
          );
        }
      },
      footer: (props) => props.column.id,
    },
    {
      isVisible: canDelete,
      id: "delete",
      header: () => <span>{t("Delete")}</span>,
      cell: (info) => (
        <button
          className="td-action secondary delete"
          onClick={(e) => {
            e.stopPropagation();
            deleteFile(info.row.original);
          }}
          title={t("Delete file")}
        >
          <Icon name="delete" />
        </button>
      ),
      footer: (props) => props.column.id,
    },
  ];

  const visibleColumns = getVisibleTableColumns(allTableColumns);
  const finalTableColumns = [
    {
      id: "filter",
      header: t("Filter:"),
      footer: (props) => props.column.id,
      columns: visibleColumns,
    },
  ];

  /**
   * Table actions.
   */
  function addNewFile() {
    dispatch(
      showModal({
        id: "fileForm",
      })
    );
  }

  async function downloadFile(file) {
    const fileName = file.name;

    api
      .getFile(file.id)
      .then((response) => downloadFileAsBlob(response, fileName))
      .catch((error) => showHttpRequestError(error));
  }

  async function deleteFile(file) {
    const deleteDialog = {
      title: `${t("Delete")} ${file.title}?`,
      buttonConfirmText: t("Delete"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .deleteFile(file.id)
      .then((response) => {
        dispatch(
          showSuccessPopup(`${t("Successfully deleted")} ${file.title}.`)
        );
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * "Add new" modal title.
   */
  let modalTitle = "Add file";
  if (pageName === "abGuidelines") modalTitle = t("New guideline");
  if (pageName === "abLeaflets") modalTitle = t("New leaflet");
  if (pageName === "abResistanceStatistics") modalTitle = t("New statistics");

  return (
    <>
      <DataTable
        tableData={tableData}
        tableColumns={finalTableColumns}
        handleAddNew={addNewFile}
        userPermissions={userPermissions}
        handleCardClick={downloadFile}
        CardComponent={CardFile}
      />

      {canUpload && (
        <Modal
          id="fileForm"
          title={modalTitle}
          innerComponent={<FormFile fetchTableData={fetchTableData} />}
          customClasses="uploading"
        />
      )}
    </>
  );
}
