import axios from "axios";
import { showErrorPopup, hideMessagePopup, resetUser } from "../redux/reducers";
import {
  removeLocalStorage,
  getLocalStorage,
  capitalizeFirstLetter,
} from "../utils/utils";
import { injectedStore } from "../redux/injectedStore/injectedStore";
import i18next from "../i18n.js";

/**
 * For dynamic API base url (development, production, etc).
 * Change the urls in the .env files in the root folder.
 */
const API_URL = process.env.REACT_APP_API_URL;

/**
 * For custom axios instance with predefined setttings.
 */
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

/**
 * For doing something before sending request.
 */
axiosInstance.interceptors.request.use(
  (request) => {
    // Do something here.
    return request;
  },

  (error) => {
    // Do something here.
    return error;
  }
);

/**
 * For doing something after receiving response.
 */
let isRefreshingToken = false;
let failedRequests = [];

axiosInstance.interceptors.response.use(
  (response) => {
    // Do something here.
    return response;
  },

  async (error) => {
    const statusCode = error.response.status;
    const lastFailedRequestConfig = error.config;
    const wasRequestingUserInfoAndFailed =
      lastFailedRequestConfig.url.includes("auth/");
    const isLoggedIn = getLocalStorage("isLoggedIn");

    // For not logged in users - reject and don't try refreshing token.
    if (!isLoggedIn) {
      return Promise.reject(error.response);
    }

    // For logged in users that fail a request.
    const lastRequestFailed = statusCode === 401;

    if (lastRequestFailed && !isRefreshingToken) {
      isRefreshingToken = true;
      try {
        // Refresh the token.
        const response = await axiosInstance.post("auth/refresh-token");
        isRefreshingToken = false;

        // Retry all failed requests.
        failedRequests.forEach((request) => {
          axiosInstance(request.config)
            .then(request.resolve)
            .catch(request.reject);
        });
        failedRequests = [];

        // Make the original request again with the new token.
        return axiosInstance(lastFailedRequestConfig);
      } catch (err) {
        isRefreshingToken = false;
        showSessionExpiredAndLogoutUser();
        return Promise.reject(error.response);
      }
    }

    if (lastRequestFailed && isRefreshingToken) {
      // Tried to refresh the token and failed? Logout.
      isRefreshingToken = false;
      if (wasRequestingUserInfoAndFailed) {
        showSessionExpiredAndLogoutUser();
      }

      // Any failed request that isn't for token - add to the queue.
      const retryRequest = new Promise((resolve, reject) => {
        failedRequests.push({ resolve, reject, config: error.config });
      });
      return retryRequest;
    }

    return Promise.reject(error.response);

    function showSessionExpiredAndLogoutUser() {
      injectedStore.dispatch(resetUser());
      removeLocalStorage("isLoggedIn");
      showExactErrorPopup(
        i18next.t("Your session has expired. Please log in.")
      );
      hideErrorPopupAfterDelay(6000);
    }
  }
);

/**
 * For handling errors in catch statements.
 */
export function showHttpRequestError(axiosError) {
  const errorMessage =
    axiosError?.data?.message || getErrorsFromResponse(axiosError?.data);

  if (errorMessage) {
    showExactErrorPopup(errorMessage);
    hideErrorPopupAfterDelay(6000);
  } else {
    showGenericErrorPopup();
    hideErrorPopupAfterDelay(6000);
  }
}

function getErrorsFromResponse(response) {
  const errors = [];

  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const value = response[key].message;
      const message = `${key} ${value}`;
      errors.push(message);
    }
  }

  const capitalizedErrors = errors.map((error) => capitalizeFirstLetter(error));
  const errorsAsString = capitalizedErrors.join(" ");
  return errors.length ? errorsAsString : null;
}

function showExactErrorPopup(errorMessage) {
  injectedStore.dispatch(showErrorPopup(errorMessage));
}

function showGenericErrorPopup() {
  injectedStore.dispatch(
    showErrorPopup(
      i18next.t(
        "An error occured while sending your request. Please reload this page and try again."
      )
    )
  );
}

function hideErrorPopupAfterDelay(delayMs) {
  setTimeout(() => injectedStore.dispatch(hideMessagePopup()), delayMs);
}

export default axiosInstance;
