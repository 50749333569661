import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components";

export default function EventLog() {
  const { t } = useTranslation();

  return (
    <div className="event-log">
      <PageTitle title={t("Event Log")} />
    </div>
  );
}
