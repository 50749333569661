import { ApiRequests } from "../http";
import { injectedStore } from "../redux/injectedStore/injectedStore";
import { setUser, showErrorPopup } from "../redux/reducers";
import i18next from "../i18n.js";
import cloneDeep from "lodash/cloneDeep";

export const getPageHrefFromUrl = () => {
  return window.location.pathname;
};

/**
 * For form validation and submiting.
 */
export function isFormValid(form) {
  const formElements = Array.from(form.elements);
  const formName = form.name;
  return formElements.every((input) => isInputValid(input, formName));
}

export function isInputValid(input, formName) {
  const inputValue = input.value.trim();
  const inputLength = inputValue.length;
  const inputName = input.name;
  const inputType = input.type;
  const isInputUsername = inputName === "username";
  const isInputPassword = inputType === "password" || inputName === "password";
  const isInputFile = inputType === "file";

  // Note: Minimum input length is enforced only when creating user, not on login.
  const isFormLogin = formName === "loginForm";

  // Test for valid names.
  if (isInputUsername && !isFormLogin) {
    return inputLength >= 3 && inputLength <= 50;
  }

  if (isInputUsername && isFormLogin) {
    return inputLength >= 1 && inputLength <= 50;
  }

  // Test for valid passwords.
  if (isInputPassword && !isFormLogin) {
    return inputLength >= 6 && inputLength <= 40;
  }

  if (isInputPassword && isFormLogin) {
    return inputLength >= 1 && inputLength <= 40;
  }

  if (isInputFile) {
    const file = input.files?.[0];
    if (!file) return false;
    return isFileValid(file);
  }

  return true;
}

export function isFileValid(file) {
  const fileName = file.name;
  const fileExtension = fileName
    .split(".")
    [fileName.split(".").length - 1].toLowerCase();

  const isAllowedFileType =
    fileExtension === "pdf" ||
    fileExtension === "doc" ||
    fileExtension === "docx" ||
    fileExtension === "xls" ||
    fileExtension === "xlsx";
  const fileLimit = 10_485_760;

  if (file.size > fileLimit || !isAllowedFileType) {
    return false;
  }

  return true;
}

export function downloadFileAsBlob(response, fileName) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

/**
 * Generate object to send to BE with proper snake-case keys.
 */
export function convertToBackendObject(form) {
  const parsedForm = new FormData(form);
  const formObject = {};

  parsedForm.forEach((value, key) => {
    let backendKey = key;
    formObject[backendKey] = value.trim();
  });

  return formObject;
}

export function convertToFrontendObject(object) {
  const frontendObject = {};

  for (const [key, value] of Object.entries(object)) {
    let frontendKey = key;

    if (key === "name") frontendKey = "firstName";
    if (key === "surname") frontendKey = "middleName";
    if (key === "familyName") frontendKey = "lastName";

    frontendObject[frontendKey] = value;
  }

  return frontendObject;
}

/**
 * Loader inside clicked button for async functions.
 */
export function showButtonLoader(btnTarget) {
  const button = btnTarget ?? document.activeElement;
  if (!button) return;
  button.classList.add("loading");
}

export function hideButtonLoader(btnTarget) {
  const button = btnTarget ?? document.querySelector("button.loading");
  if (!button) return;
  button.classList.remove("loading");
}

/**
 * Users.
 */
export function getUserDisplayName(user) {
  const { firstName, lastName, username } = user;

  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (!firstName && lastName) return lastName;
  if (firstName && !lastName) return firstName;
  if (!firstName && !lastName) return username;
  return "User";
}

/**
 * LocalStorage.
 */
export function getLocalStorage(item) {
  let savedItem = localStorage.getItem(item);
  return JSON.parse(savedItem);
}

export function setLocalStorage(itemName, item) {
  let strigifiedItem = JSON.stringify(item);
  localStorage.setItem(itemName, strigifiedItem);
}

export function removeLocalStorage(itemName) {
  localStorage.removeItem(itemName);
}

export async function getUserData() {
  const api = new ApiRequests();
  api
    .getCurrentUser()
    .then((response) => {
      const user = response.data.user;
      injectedStore.dispatch(setUser(user));
    })
    .catch((error) =>
      injectedStore.dispatch(
        showErrorPopup(
          i18next.t("Your session has expired. Please log in again.")
        )
      )
    );
}

/**
 * Data tables.
 */
export function getVisibleTableColumns(allColumns) {
  const isColumnVisible = (column) => {
    if (!column.hasOwnProperty("isVisible")) {
      return true;
    }
    return column.isVisible;
  };
  return allColumns.filter(isColumnVisible);
}

export function getFileCategory(pageHref) {
  let category = null;
  if (pageHref === "/guidelines") category = "FILE_GUIDELINES";
  if (pageHref === "/leaflets") category = "FILE_SPS";
  if (pageHref === "/resistance") category = "FILE_AMRS";
  return category;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Copy text to clipboard.
 */
export function copyToClipBoard(text) {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}

/**
 * Order table data - first by active/inactive, then by name.
 */
export function orderActiveFirst(items) {
  const activeFirst = (x, y) => Number(y.active) - Number(x.active);
  return items.sort(activeFirst);
}

export function orderByName(items) {
  const isActive = (item) => item.active;
  const isInactive = (item) => !item.active;
  const activeItems = items.filter(isActive);
  const inactiveItems = items.filter(isInactive);
  let nameKey = null;

  if (items[0]?.name) nameKey = "name"; // For hospitals.
  if (items[0]?.wardNomName) nameKey = "wardNomName"; // For wards.
  if (items[0]?.username) nameKey = "username"; // For users

  const sortAlphabetically = (a, b) => {
    if (!a[nameKey] && !b[nameKey]) {
      return 0;
    }
    if (!a[nameKey]) {
      return 1; // Place null at the end
    }
    if (!b[nameKey]) {
      return -1; // Place null at the end
    }
    const lowerA = a[nameKey].toLowerCase();
    const lowerB = b[nameKey].toLowerCase();
    return lowerA.localeCompare(lowerB, undefined, { sensitivity: "base" });
  };

  const activeItemsSorted = activeItems.sort(sortAlphabetically);
  const inactiveItemsSorted = inactiveItems.sort(sortAlphabetically);

  return [...activeItemsSorted, ...inactiveItemsSorted];
}

export function orderActiveFirstThenByNameAtoZ(items) {
  return orderByName(orderActiveFirst(items));
}

/**
 * Dates.
 */
export function getDaysSinceDate(date) {
  if (!date) return null;

  const today = new Date();
  const dateObj = new Date(date);
  const timeDiff = Math.abs(today.getTime() - dateObj.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
}

/**
 * User Roles
 */
export function getRolesLowerThanUser(roles) {
  const userRole = injectedStore.getState().user.role;
  const roleOrder = [
    "ADMIN",
    "HEAD OF HOSPITAL",
    "MICROBIOLOGY",
    "HEAD OF WARD",
    "DOCTOR",
  ];

  const isRoleLower = (role) =>
    roleOrder.indexOf(role.name) > roleOrder.indexOf(userRole);

  return roles.filter(isRoleLower);
}

/**
 * React Select.
 */
export function convertToSelectOptions(data) {
  return data.map((item) => ({
    value: item.id,
    label: item.name,
  }));
}

/**
 * Other.
 */
export function sortLanguagesEnBgOther(languages) {
  const copyLanguages = cloneDeep(languages);
  const sortedLanguages = copyLanguages.sort((a, b) => {
    if (a.alias === "en") {
      return -1;
    } else if (b.alias === "en") {
      return 1;
    } else if (a.alias === "bg") {
      return -1;
    } else if (b.alias === "bg") {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedLanguages;
}

export function addRequiredClassToInputWrappers() {
  const inputWrappers = document.querySelectorAll(".input-wrapper");
  inputWrappers.forEach((inputWrapper) => {
    const input = inputWrapper.querySelector("input, select, textarea");
    const inputIsRequired =
      (input && input.required) || (input && input.ariaRequired);

    if (inputIsRequired) {
      inputWrapper.classList.add("required");
    }
  });
}

export function splitStringAtFirstSymbol(string, symbol) {
  const index = string.indexOf(symbol);
  if (index === -1) {
    return [string];
  }
  const part1 = string.substring(0, index);
  const part2 = string.substring(index + 1);
  return [part1, part2];
}

/**
 * Animations.
 */
export function toggleHtmlElementClass(element, cssClass, timeout) {
  if (!element) return;
  element.classList.add(cssClass);
  setTimeout(() => {
    element.classList.remove(cssClass);
  }, timeout);
}

/**
 * URL
 */
export function addParamsToUrl(params) {
  const urlParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    urlParams.append(key, value);
  });

  const newUrl = `${window.location.origin}${
    window.location.pathname
  }?${urlParams.toString()}`;

  window.history.pushState({ path: newUrl }, "", newUrl);
}

/**
 * Browsers.
 */
export function isUsingSafariBrowser() {
  const isUsingSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent
  );
  return isUsingSafari;
}
