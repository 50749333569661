import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetPermissionsForPage, useOnMount } from "../../hooks/hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { DataTable, Icon, PageTitle } from "../../components";
import { useTranslation } from "react-i18next";
import {
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
} from "../../redux/reducers";
import { CardDefault } from "../../components/Cards";
import "./Workflows.scss";

export default function Workflows() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const [tableData, setTableData] = useState([]);
  const userPermissions = useGetPermissionsForPage("adviceBuilder");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { canUpdate, canDelete } = userPermissions;

  useOnMount(() => {
    const hasTableData = !!tableData.length;
    if (!hasTableData) fetchTableData();
  });

  async function fetchTableData() {
    const statistics = await getWorkflows();
    setTableData(statistics);
  }

  async function getWorkflows() {
    return api
      .getWorkflows()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  const tableColumns = [
    {
      header: "Results",
      footer: (props) => props.column.id,
      columns: [
        {
          accessorKey: "diseaseName",
          id: "name",
          header: () => <span>{t("Disease")}</span>,
          cell: (info) => info.getValue(),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "countryName",
          header: () => <span>{t("Country")}</span>,
          cell: (info) => info.getValue(),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "workflowVersion",
          header: () => <span>{t("Workflow Version")}</span>,
          cell: (info) => showWorkflowVersion(info),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "draftVersion",
          header: () => <span>{t("Draft Version")}</span>,
          cell: (info) => showDraftVersion(info),
          footer: (props) => props.column.id,
        },
        {
          isVisible: canUpdate,
          id: "update",
          header: () => <span>{t("Edit")}</span>,
          cell: (info) => showEditButton(info),
          footer: (props) => props.column.id,
        },
        {
          isVisible: canDelete,
          id: "delete",
          header: () => <span>{t("Delete")}</span>,
          cell: (info) => showDeleteButton(info),
          footer: (props) => props.column.id,
        },
      ],
    },
  ];

  function showWorkflowVersion(info) {
    const workflow = info.row.original;
    return workflow.workflowVersion ? (
      <>
        v{workflow.workflowVersion}.0 <span>Active</span>
      </>
    ) : null;
  }

  function showDraftVersion(info) {
    const workflow = info.row.original;
    return workflow.draftVersion ? (
      <>
        v{workflow.draftVersion}.0 <span>Draft</span>
      </>
    ) : null;
  }

  function showEditButton(table) {
    const workflow = table.row.original;
    return (
      <button
        className="td-action edit"
        onClick={() => editWorkflow(workflow.diseaseId, workflow.countryId)}
        title={t("Edit Workflow")}
      >
        <Icon name="pencil" /> {t("Edit")}
      </button>
    );
  }

  function editWorkflow(diseaseId, countryId) {
    navigate(`/workflow-builder?did=${diseaseId}&cid=${countryId}`);
  }

  async function deleteWorkflow(workflow) {
    const { diseaseName, countryName } = workflow;
    const deleteDialog = {
      title: `${t("Delete workflow")} ${diseaseName} , ${countryName}?`,
      message: t("You will lose all entered questions and treatments."),
      buttonConfirmText: t("Delete"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    // If this is a draft.
    if (!workflow.workflowVersion) {
      await api
        .deleteDraftWorkflow(workflow.countryId, workflow.diseaseId)
        .then((response) => {
          fetchTableData();
          showSuccessPopup(t("Workflow deleted successfully"));
        })
        .catch((error) => showHttpRequestError(error));
      return;
    }

    // If this is a published workflow.
    api
      .deleteWorkflow(workflow.countryId, workflow.diseaseId)
      .then((response) => {
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error));
  }

  function showDeleteButton(table) {
    const workflow = table.row.original;
    return (
      <button
        className="td-action secondary delete"
        onClick={(e) => {
          e.stopPropagation();
          deleteWorkflow(workflow);
        }}
        title={t("Delete Workflow")}
      >
        <Icon name="delete" />
      </button>
    );
  }

  return (
    <div className="advice-builder">
      <PageTitle title={t("Workflows")} />

      <DataTable
        pageName="adviceBuilder"
        tableColumns={tableColumns}
        userPermissions={userPermissions}
        tableData={tableData}
        handleCardClick={(workflow) =>
          editWorkflow(workflow.diseaseId, workflow.countryId)
        }
        handleAddNew={() => navigate("/workflow-builder")}
        CardComponent={CardDefault}
      />
    </div>
  );
}
