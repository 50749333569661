import { useState, useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import { Icon } from "..";
import { getLocalStorage, setLocalStorage } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CardDefault } from "../Cards";
import {
  DebouncedInput,
  Filter,
  FilterWrapper,
  TableActions,
} from "./components";
import "./DataTable.scss";

export default function DataTable({
  tableData,
  tableColumns,
  handleAddNew = () => {},
  addNewButtonLabel,
  userPermissions = {},
  handleCardClick = () => {},
  CardComponent = CardDefault,
  isShowingPatients = false,
  toggleAreAllAdvicesVisible = () => {},
  areAllAdvicesVisible = false,
  userShouldSeeEverything = false,
  isAddNewButtonVisible = true,
  itemsPerPage = 10,
  itemsPerPageOptions = [10, 20, 50],
  hiddenFilters = [
    "fileSize",
    "delete",
    "download",
    "active",
    "update",
    "workflowInstance",
    "missingWorkflow",
    "workflowVersion",
    "draftVersion",
    "updatedWorkflow",
  ],
  autoResetPageIndex = false,
}) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  /**
   * Table data.
   */
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);

  /**
   * Table filters.
   */
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const isFilteredGlobally = Boolean(globalFilter.length);
  const isFilteredByColumn = Boolean(columnFilters.length);
  const isTableFiltered = isFilteredGlobally || isFilteredByColumn;

  const clearAllFilters = () => {
    setColumnFilters([]);
    setGlobalFilter("");
  };

  const clearFilter = (id) => {
    const newFilters = columnFilters.filter((filter) => filter.id !== id);
    setColumnFilters(newFilters);
  };

  // Non-admins always see files from their country only.
  if (user.role !== "ADMIN") {
    hiddenFilters.push("countryName");
  }

  /**
   * Table setup.
   */
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    autoResetPageIndex: autoResetPageIndex,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    initialState: {
      pagination: {
        pageSize: itemsPerPage,
      },
    },
  });

  /**
   * Permissions.
   */
  const { canView, canUpload, canCreate } = userPermissions;

  /**
   * Toggling results layout (cards | list).
   */
  const [resultsLayout, setResultsLayout] = useState("list");

  const toggleResultsLayout = () => {
    if (resultsLayout === "cards") {
      setResultsLayout("list");
      setLocalStorage("ascp-layout", "list");
    }

    if (resultsLayout === "list") {
      setResultsLayout("cards");
      setLocalStorage("ascp-layout", "cards");
    }
  };

  const savedResultsLayout = getLocalStorage("ascp-layout");
  const userSavedDifferentLayout =
    savedResultsLayout && savedResultsLayout !== resultsLayout;

  if (userSavedDifferentLayout) {
    setResultsLayout(savedResultsLayout);
  }

  /**
   * Element display conditions.
   */
  const isAddButtonVisible = (canUpload || canCreate) && isAddNewButtonVisible;
  const addButtonLabel = !addNewButtonLabel ? t("Add new") : addNewButtonLabel;

  if (!canView) {
    return <p>{t("You don't have permissions to view this page.")}</p>;
  }

  return (
    <div className="table">
      <div className="table-header">
        {isAddButtonVisible && (
          <div className="create">
            <button onClick={handleAddNew}>
              <Icon name="plus"></Icon>
              {addButtonLabel}
            </button>
          </div>
        )}

        <div className="search">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="search-input"
            placeholder={`${t("Search")} ${
              table.getPrePaginationRowModel().rows.length
            } ${t("results...")}`}
          />
        </div>

        <div className="layout-toggle">
          <button className={`btn secondary`} onClick={toggleResultsLayout}>
            {resultsLayout === "cards" ? (
              <>
                <Icon name="format-list-bulleted"></Icon> {t("Table")}
              </>
            ) : (
              <>
                <Icon name="grid-large"></Icon> {t("Cards")}
              </>
            )}
          </button>
        </div>

        {isShowingPatients && (
          <div className="layout-toggle advices-toggle">
            <button
              className={`btn secondary`}
              onClick={toggleAreAllAdvicesVisible}
            >
              {areAllAdvicesVisible
                ? t("Only accepted advices")
                : t("View all advices")}
            </button>
          </div>
        )}
      </div>

      <div className="table-results">
        <div className="filters">
          {table.getHeaderGroups().map((headerGroup) => (
            <div className="filter-group" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const isFilterHidden =
                  hiddenFilters.includes(header.id) || header.isPlaceholder;

                if (isFilterHidden) {
                  return null;
                }

                return (
                  <FilterWrapper
                    key={header.id}
                    header={header}
                    flexRender={flexRender}
                  >
                    <Filter column={header.column} table={table} />
                  </FilterWrapper>
                );
              })}
            </div>
          ))}
        </div>

        {isTableFiltered && (
          <div className="clear-filters">
            <button className="secondary" onClick={clearAllFilters}>
              <Icon name="cross"></Icon> {t("Clear filters")}
            </button>

            {globalFilter && (
              <button
                className="secondary"
                onClick={() => setGlobalFilter("")}
                title={t("Remove this filter")}
              >
                <Icon name="cross"></Icon> {globalFilter}
              </button>
            )}

            {columnFilters.map((filter) => {
              return (
                <button
                  className="secondary"
                  key={filter.id}
                  onClick={() => clearFilter(filter.id)}
                  title={t("Remove this filter")}
                >
                  <Icon name="cross"></Icon> {filter.value}
                </button>
              );
            })}
          </div>
        )}

        <div
          className={`cards layout-${resultsLayout} ${
            areAllAdvicesVisible ? "all-advices-visible" : ""
          }`}
        >
          {table.getRowModel().rows.map((row) => {
            const isActive = row.original?.active;

            return (
              <CardComponent
                key={row.id}
                row={row}
                isActive={isActive}
                handleCardClick={handleCardClick}
                areAllAdvicesVisible={areAllAdvicesVisible}
                userShouldSeeEverything={userShouldSeeEverything}
                notDeemphasized="true"
                flexRender={flexRender}
              />
            );
          })}
        </div>
      </div>

      <TableActions table={table} itemsPerPageOptions={itemsPerPageOptions} />
    </div>
  );
}

function fuzzyFilter(row, columnId, value, addMeta) {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
}
