import {
  HomeAdmin,
  HomeHeadHospital,
  HomeMicrobiology,
  HomeHeadOfWard,
  HomeDoctor,
} from "../pages/index";

const homePageByRole = {
  ADMIN: <HomeAdmin />,
  "HEAD OF HOSPITAL": <HomeHeadHospital />,
  MICROBIOLOGY: <HomeMicrobiology />,
  "HEAD OF WARD": <HomeHeadOfWard />,
  DOCTOR: <HomeDoctor />,
};

export const getHomePageByRole = (userRole) => {
  const homepage = homePageByRole?.[userRole];

  if (homepage) {
    return homepage;
  } else {
    return homePageByRole.DOCTOR; // Default homepage.
  }
};
