import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiRequests, showHttpRequestError } from "../../../http";
import { showErrorMessage, showSuccessMessage } from "../../../redux/reducers";
import FormMessage from "../../FormMessage/FormMessage";
import Icon from "../../Icon/Icon";
import { useOnMount } from "../../../hooks";
import { useTranslation } from "react-i18next";
import cloneDeep from "lodash/cloneDeep";
import TranslationLanguage from "../../../pages/Nomenclatures/components/TranslationLanguage";
import {
  convertToSelectOptions,
  showButtonLoader,
  hideButtonLoader,
} from "../../../utils/utils";

export default function FormNomenclature({
  languages = [],
  nomenclatureTypes,
  nomenclatureTypesTranslated,
  selectedNomenclatureType,
  nomenclatureForTranslation,
  setNomenclatureForTranslation,
  translateNomenclature,
  selectedNomenclature,
  setSelectedNomenclature,
  isNomenclatureCompletelyTranslated,
}) {
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const message = useSelector((state) => state.modals.formMessage);
  const openedModalId = useSelector((state) => state.modals.openedModal.id);

  const [isSavingNomenclature, setIsSavingNomenclature] = useState(false);
  const [isCompletelyTranslated, setIsCompletelyTranslated] = useState(false);

  useEffect(() => {
    setIsCompletelyTranslatedStatus();
  }, [
    openedModalId,
    nomenclatureForTranslation?.id,
    nomenclatureForTranslation?.translations?.length,
  ]);

  function setIsCompletelyTranslatedStatus() {
    if (!nomenclatureForTranslation?.id) return;
    setIsCompletelyTranslated(
      isNomenclatureCompletelyTranslated(
        nomenclatureForTranslation,
        selectedNomenclatureType
      )
    );
  }

  /**
   * Keep track of translations in refs.
   */
  const englishTranslation = useRef({});
  const otherTranslations = useRef([]);
  const reactionFrequencies = useRef([]);

  useEffect(() => {
    resetTranslationRefs();
  }, [selectedNomenclatureType, nomenclatureForTranslation?.id, openedModalId]);

  /**
   * Set and reset translation refs.
   */
  function setInitialTranslationRefs(updatedNomenclature) {
    // set otherTranslations
    let translationsCopy = null;

    if (!updatedNomenclature) {
      // get the edited nomenclature's translations
      translationsCopy = cloneDeep(nomenclatureForTranslation?.translations);
    } else {
      // nome was just updated - get its translations from the response
      translationsCopy = cloneDeep(updatedNomenclature?.translations);
    }

    // if there are any translations, set them in the ref
    if (translationsCopy?.length) {
      translationsCopy.forEach((translation) => {
        translation.language = translation.languageId;
        translation.isAlreadySaved = true;

        delete translation.id;
        delete translation.languageId;
        delete translation.languageName;
      });
      otherTranslations.current = translationsCopy;
    }

    // set englishTranslation
    const nomenclatureCopy = cloneDeep(nomenclatureForTranslation);
    delete nomenclatureCopy.translations;

    // we receive propId and propName but have to return prop (which === propId)
    // so we'll just remove the "Id" from the propName
    for (const key of Object.keys(nomenclatureCopy)) {
      if (key.endsWith("Id")) {
        nomenclatureCopy[key.replace("Id", "")] = nomenclatureCopy[key];
      }
    }
    englishTranslation.current = nomenclatureCopy;

    // medicaments have reactionFrequencies, along with fields
    if (selectedNomenclatureType === "MEDICAMENT") {
      const frequencies = nomenclatureForTranslation?.reactionFrequencies ?? [];
      reactionFrequencies.current = cloneDeep(frequencies);
    }
  }

  function resetTranslationRefs() {
    otherTranslations.current = [];
    englishTranslation.current = {};
    reactionFrequencies.current = [];
  }

  /**
   * Update the translation refs on input.
   */
  function updateEnglishTranslation(field, value) {
    englishTranslation.current = {
      ...englishTranslation.current,
      [field]: value,
    };

    // cleanup any empty translations props
    englishTranslation.current = Object.entries(
      englishTranslation.current
    ).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  function updateOtherTranslation(field, value, languageId) {
    const indexOfUpdatedTranslation = otherTranslations.current.findIndex(
      (translation) => translation.language === languageId
    );

    // get the key to search in the nomenclatureTypesTranslated object
    const translationNomenclatureKey =
      nomenclatureTypes[selectedNomenclatureType].translation;

    // get all fields that should be ids. Without "language", because we'll set it manually.
    const idFields = nomenclatureTypesTranslated[
      translationNomenclatureKey
    ].fields.filter(
      (field) => field.type === "id" && field.name !== "language"
    );

    // find ids for id fields
    const idFieldsWithValues = idFields.map((field) => {
      const idFieldName = field.name;
      const idFieldValueType = field.value;
      let idFieldValue = null;

      if (idFieldValueType === "nomenclatureId") {
        idFieldValue = nomenclatureForTranslation?.id;
      }

      return { [idFieldName]: idFieldValue };
    });

    // we'll spread the id fields in the translation object
    const idFieldsObject = {
      ...idFieldsWithValues.filter((obj) =>
        Object.values(obj).every((value) => value !== undefined)
      ),
    };

    const idFieldsObjectToSpread = Object.assign(
      {},
      ...Object.values(idFieldsObject)
    );

    // update other translation (non-english)
    if (indexOfUpdatedTranslation === -1) {
      // create non-existing translation
      otherTranslations.current.push({
        [field]: value,
        language: languageId,
        ...idFieldsObjectToSpread,
      });
    } else {
      // update existing translation
      otherTranslations.current[indexOfUpdatedTranslation] = {
        ...otherTranslations.current[indexOfUpdatedTranslation],
        [field]: value,
        language: languageId,
        ...idFieldsObjectToSpread,
      };
    }

    // cleanup any empty translations props
    otherTranslations.current = otherTranslations.current
      .map((obj) =>
        Object.entries(obj).reduce((acc, [key, value]) => {
          if (value !== undefined && value !== "") {
            acc[key] = value;
          }
          return acc;
        }, {})
      )
      .filter(
        (obj) =>
          Object.keys(obj).includes("name") ||
          Object.keys(obj).includes("body") ||
          Object.keys(obj).includes("interaction") // for medicaments
      );
  }

  /**
   * Enable/disable the save button.
   *
   * English translation is required always (create/edit).
   * For any other translation, all required fields should be filled to be able to save. Partially filled required fields cannot be saved.
   */
  const arrayOfFalseValues = useRef(Array(languages.length).fill(false));

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isEnglishTranslated, setIsEnglishTranslated] = useState(false);
  const [partialTranslations, setPartialTranslations] = useState(
    arrayOfFalseValues.current
  );
  const [filledAndUnsavedTranslations, setFilledAndUnsavedTranslations] =
    useState(arrayOfFalseValues.current);

  const resetFilledAndUnsavedTranslations = () =>
    setFilledAndUnsavedTranslations((prev) => arrayOfFalseValues.current);

  useEffect(() => {
    if (partialTranslations.length && filledAndUnsavedTranslations.length)
      return;

    setPartialTranslations(arrayOfFalseValues.current);
    setFilledAndUnsavedTranslations(arrayOfFalseValues.current);
  }, [languages?.length]);

  useEffect(
    () => handeSaveButtonDisabledState(),
    [
      isSaveButtonDisabled,
      isEnglishTranslated,
      partialTranslations,
      isSavingNomenclature,
      filledAndUnsavedTranslations,
    ]
  );

  function handeSaveButtonDisabledState() {
    const someTranslationIsPartial = partialTranslations.some((t) => !!t);
    const someTranslationIsFilledAndNotSaved =
      filledAndUnsavedTranslations.some((t) => !!t);

    if (someTranslationIsPartial) {
      setIsSaveButtonDisabled(true);
      return;
    }

    if (isSavingNomenclature) {
      setIsSaveButtonDisabled(true);
      return;
    }

    if (!isEnglishTranslated) {
      setIsSaveButtonDisabled(true);
      return;
    }

    if (!someTranslationIsFilledAndNotSaved) {
      setIsSaveButtonDisabled(true);
      return;
    }

    if (someTranslationIsFilledAndNotSaved) {
      setIsSaveButtonDisabled(false);
      return;
    }
  }

  const nomenclatureFields =
    nomenclatureTypes?.[selectedNomenclatureType]?.fields;

  const medicamentReactionFrequencies =
    nomenclatureTypes?.[selectedNomenclatureType]?.reactionFrequencies;

  const translationKey =
    nomenclatureTypes?.[selectedNomenclatureType]?.translation;

  /**
   * If there is no translation key - only EN nomenclature is created.
   * If translation key is present - all languages are available for translation.
   */
  const onlyLanguagesToTranslateTo = (language) => {
    if (!translationKey) {
      return language.alias === "en";
    }
    return true;
  };

  const getTranslationForLanguage = (language) => {
    if (
      !nomenclatureForTranslation &&
      !nomenclatureForTranslation?.translations
    ) {
      return null;
    }

    if (language.alias === "en" || !nomenclatureForTranslation?.translations) {
      const translation = cloneDeep(nomenclatureForTranslation);
      delete translation.translations;
      return nomenclatureForTranslation;
    }

    return nomenclatureForTranslation.translations.find((translation) => {
      return translation.languageId === language.id;
    });
  };

  /**
   * Save nomenclature (create/edit).
   */
  function saveNomenclature() {
    if (nomenclatureForTranslation?.id) {
      updateNomenclature();
    } else {
      createNomenclature();
    }
  }

  /**
   * Data for select fields.
   */
  const [selectAnswers, setSelectAnswers] = useState({});

  useEffect(() => {
    if (!nomenclatureForTranslation?.id) return;
    setSelectAnswers(getPreviousSelectAnswers());
  }, [nomenclatureForTranslation?.id, openedModalId]);

  function getPreviousSelectAnswers() {
    const selectFields = nomenclatureFields.filter(
      (field) => field.type === "select"
    );
    const answers = {};

    selectFields.forEach((field) => {
      const keyWithId = `${field.name}Id`;
      answers[field.name] = nomenclatureForTranslation?.[keyWithId] ?? null;
    });
    return answers;
  }

  const updateSelectAnswer = (field, value) => {
    setSelectAnswers((prev) => {
      const prevCopy = { ...prev };
      prevCopy[field] = value;
      return prevCopy;
    });
  };

  const [selectOptions, setSelectOptions] = useState({});

  useEffect(() => {
    Promise.all([
      fetchQuestions(),
      fetchDiseaseGroups(),
      fetchMedicamentGroups(),
      fetchApplicationMethods(),
      fetchMedicamentForms(),
      fetchReactionFrequencies(),
      fetchReactions(),
    ]).then((response) => {
      // the names of the variables/keys correspond to the "name" prop in nomenclatureTypes, so that we can search for them by [name]
      const questions = response[0].data.questions;
      const diseaseGroups = response[1].data;
      const medicamentGroups = response[2].data;
      const applicationMethods = response[3].data;
      const medicamentForms = response[4].data;
      const frequencyId = response[5].data;
      const reactions = response[6].data;

      setSelectOptions({
        question: convertToSelectOptions(questions),
        diseaseGroup: convertToSelectOptions(diseaseGroups),
        medicamentGroup: convertToSelectOptions(medicamentGroups),
        applicationMethod: convertToSelectOptions(applicationMethods),
        form: convertToSelectOptions(medicamentForms),
        frequencyId: convertToSelectOptions(frequencyId),
        reactions: convertToSelectOptions(reactions),
      });
    });
  }, [selectedNomenclatureType]);

  async function fetchQuestions() {
    return api
      .getWorkflowQuestions()
      .then((response) => response)
      .catch((error) => showHttpRequestError(error));
  }

  async function fetchDiseaseGroups() {
    return api
      .getDiseaseGroups()
      .then((response) => response)
      .catch((error) => showHttpRequestError(error));
  }

  async function fetchMedicamentGroups() {
    return api
      .getMedicamentGroups()
      .then((response) => response)
      .catch((error) => showHttpRequestError(error));
  }

  async function fetchApplicationMethods() {
    return api
      .getWorkflowApplicationMethods()
      .then((response) => response)
      .catch((error) => showHttpRequestError(error));
  }

  async function fetchMedicamentForms() {
    return api
      .getWorkflowMedicamentForms()
      .then((response) => response)
      .catch((error) => showHttpRequestError(error));
  }

  async function fetchReactionFrequencies() {
    return api
      .getNomenclature("FREQUENCY")
      .then((response) => response)
      .catch((error) => showHttpRequestError(error));
  }

  async function fetchReactions() {
    return api
      .getNomenclature("ADVERSE_DRUG_REACTION")
      .then((response) => response)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Reaction frequencies have the following structure:
   * [{"frequencyId": "", "reactions": ["reactionId"] }]
   */
  useEffect(() => {
    const hasReactionFrequencies = reactionFrequencies.current.length > 0;
    const hasFrequencyIds = selectOptions?.frequencyId?.length > 0;
    if (hasReactionFrequencies || !hasFrequencyIds || !openedModalId) return;

    setInitialReactionsFrequencies();
  }, [openedModalId, selectOptions?.frequencyId?.length]);

  function setInitialReactionsFrequencies() {
    const isEditingMedicamentWithSavedReactions =
      nomenclatureForTranslation?.id &&
      nomenclatureForTranslation?.reactionFrequencies?.length > 0;

    // viewing existing medicament
    if (isEditingMedicamentWithSavedReactions) {
      const reactions = cloneDeep(
        nomenclatureForTranslation.reactionFrequencies
      );
      const frequencies = [];

      selectOptions.frequencyId.forEach((frequency) => {
        const obj = {};
        obj.frequencyId = frequency.value;
        obj.reactions = [];
        frequencies.push(obj);
      });

      reactions.forEach((reaction) => {
        const frequency = frequencies.find(
          (frequency) => frequency.frequencyId === reaction.frequencyId
        );
        frequency.reactions.push(reaction.reactionId);
      });
      reactionFrequencies.current = frequencies;
      return;
    }

    // creating empty medicament
    const frequencies = [];
    selectOptions.frequencyId.forEach((frequency) => {
      const obj = {};
      obj.frequencyId = frequency.value;
      obj.reactions = [];
      frequencies.push(obj);
    });
    reactionFrequencies.current = frequencies;
  }

  function updateReactionFrequencies(frequencyId, reactions) {
    const reactionsIds = reactions.map((reaction) => reaction.value);
    const existingFrequency = reactionFrequencies.current.find(
      (frequency) => frequency.frequencyId === frequencyId
    );

    // add missing frequency
    if (!existingFrequency?.frequencyId) {
      reactionFrequencies.current.push({
        frequencyId,
        reactions: reactionsIds,
      });
      return;
    }

    // update existing frequency
    const index = reactionFrequencies.current.findIndex(
      (frequency) => frequency.frequencyId === frequencyId
    );
    reactionFrequencies.current[index].reactions = reactionsIds;
  }

  // these frequencies will be used to update the state
  const [reactionsAnswers, setReactionsAnswers] = useState(null);

  useEffect(() => {
    const reactionsAreSetOrDataIsMissing =
      reactionsAnswers === null || !selectOptions?.frequencyId?.length;
    if (reactionsAreSetOrDataIsMissing) return;

    setInitialReactionsAnswers();
  }, [openedModalId, selectOptions?.frequencyId?.length]);

  function setInitialReactionsAnswers() {
    const initialAnswers = {};
    const isEditingMedicamentWithSavedReactions =
      nomenclatureForTranslation?.id &&
      nomenclatureForTranslation?.reactionFrequencies?.length > 0;

    selectOptions.frequencyId.forEach((frequency) => {
      initialAnswers[frequency.value] = [];
    });

    if (isEditingMedicamentWithSavedReactions) {
      nomenclatureForTranslation.reactionFrequencies.forEach((reaction) => {
        const frequencyId = reaction.frequencyId;
        const reactionId = reaction.reactionId;
        const reactionName = reaction.reactionName;
        const frequency = initialAnswers[frequencyId];
        frequency.push({ value: reactionId, label: reactionName });
      });
    }
    setReactionsAnswers(initialAnswers);
  }

  /**
   * Reset the modal state.
   */
  useEffect(() => resetModalState, [openedModalId]);

  function resetModalState() {
    setIsSaveButtonDisabled(true);
    setIsEnglishTranslated(false);
    setIsCompletelyTranslated(false);
    setSelectAnswers({});
    setReactionsAnswers(null);
    reactionFrequencies.current = [];
  }

  /**
   * Create nomenclature.
   * 
   * BE expects this structure:
  {
    "type": "nomenclature",
    "nomenclature": "ADVERSE_DRUG_REACTION"
    "fields": {
      "body": "Headache",       // EN translation, without prop "language"
    },
    "translations": [           // only on create if there are translations
      {
        "translationFields": {
          "language": "UUID",   // BG language UUID
          "body": "Главоболие",
        }
      },
      {
        "translationFields": {
          "language": "UUID",   // ES language UUID
          "body": "Espana",
        }
      }
    ],
  }
   */
  async function createNomenclature() {
    const isCreatingMedicament = selectedNomenclatureType === "MEDICAMENT";

    // medicaments require at least one drug reaction per frequency
    // if (isCreatingMedicament) {
    //   const isFrequencyMissing = reactionFrequencies.current.some(
    //     (frequency) => frequency.reactions.length === 0
    //   );

    //   if (isFrequencyMissing) {
    //     dispatch(
    //       showErrorMessage({
    //         content: t("All adverse drug reactions are required."),
    //         id: "nomenclatureForm",
    //       })
    //     );
    //     return;
    //   }
    // }

    const newNomenclature = {
      type: isCreatingMedicament ? "medicament" : "nomenclature",
      nomenclature: selectedNomenclatureType,
      fields: {
        ...englishTranslation.current,
      },
      translations: [
        ...otherTranslations.current.map((translation) => ({
          translationFields: translation,
        })),
      ],
      ...(isCreatingMedicament && {
        reactionFrequencies: [...reactionFrequencies.current],
      }),
    };

    setIsSavingNomenclature(true);
    showButtonLoader();

    try {
      const nomenclatureName =
        newNomenclature.fields.name || newNomenclature.fields.body;
      const response = await api.createNomenclature(newNomenclature);
      const { id: newNomenclatureId } = response.data;
      const fetchedNomenclature = await api.getSpecificNomenclature(
        selectedNomenclatureType,
        newNomenclatureId
      );

      setSelectedNomenclature([
        ...selectedNomenclature,
        fetchedNomenclature.data,
      ]);

      setNomenclatureForTranslation(fetchedNomenclature.data);
      resetFilledAndUnsavedTranslations();
      translateNomenclature(fetchedNomenclature.data);

      const createdItem = isCreatingMedicament
        ? t("Medicament")
        : t("Nomenclature");

      dispatch(
        showSuccessMessage({
          content: `${t(createdItem)} ${nomenclatureName} ${t(
            "created successfully."
          )}`,
          id: "nomenclatureForm",
        })
      );
    } catch (error) {
      showHttpRequestError(error);
    } finally {
      setIsSavingNomenclature(false);
      hideButtonLoader();
    }
  }

  /**
   * Update nomenclature.
   * 
   * BE expects this structure:
  {
    "type": "nomenclature",
    "nomenclature": "ADVERSE_DRUG_REACTION_TRANSLATED"
    "fields": {
      body: "Главоболие",           // BG translation
      language: "UUID",             // BG language UUID
      adverseDrugReaction: "UUID",  // id of already created nomenclature
    },
  }

    Note: If during edit, the user adds 2 translations (non-EN), FE should send 1 request for each translation. They can't be sent in 1 request.
  }
   */
  async function updateNomenclature() {
    const nomenclaturesToSave = otherTranslations.current
      .filter((translation) => !translation?.isAlreadySaved)
      .map((translation) => ({
        type: "nomenclature",
        nomenclature: nomenclatureTypes?.[selectedNomenclatureType].translation,
        fields: {
          ...translation,
        },
      }));

    const nomenclatureName =
      nomenclatureForTranslation?.name || nomenclatureForTranslation?.body;

    setIsSavingNomenclature(true);
    showButtonLoader();

    createNomenclatureTranslations(nomenclaturesToSave)
      .then(async () => {
        const fetchedNomenclature = await api.getSpecificNomenclature(
          selectedNomenclatureType,
          nomenclatureForTranslation.id
        );
        translateNomenclature(fetchedNomenclature.data);
        setInitialTranslationRefs(fetchedNomenclature.data);

        setSelectedNomenclature((prev) => {
          const index = prev.findIndex(
            (nomenclature) => nomenclature.id === fetchedNomenclature.data.id
          );
          const updatedNomenclatures = [...prev];
          updatedNomenclatures[index] = fetchedNomenclature.data;
          return updatedNomenclatures;
        });

        setNomenclatureForTranslation(fetchedNomenclature.data);
        setIsSavingNomenclature(false);
        resetFilledAndUnsavedTranslations();

        dispatch(
          showSuccessMessage({
            content: `${t("Nomenclature")} ${nomenclatureName} ${t(
              "updated successfully."
            )}`,
            id: "nomenclatureForm",
          })
        );
      })
      .catch((error) => {
        showHttpRequestError(error);
      })
      .finally(() => {
        hideButtonLoader();
      });
  }

  async function createNomenclatureTranslations(nomenclaturesToSave) {
    for (const nomenclature of nomenclaturesToSave) {
      try {
        const response = await createNomenclatureTranslation(nomenclature);
      } catch (error) {
        showHttpRequestError(error);
      }
    }
  }

  async function createNomenclatureTranslation(nomenclature) {
    return api
      .createNomenclature(nomenclature)
      .then((res) => {})
      .catch((error) => showHttpRequestError(error));
  }

  if (!nomenclatureFields) {
    return null;
  }

  return (
    <div className="nomenclature-form">
      <div className="translation-languages">
        {openedModalId &&
          languages
            .filter(onlyLanguagesToTranslateTo)
            .map((language, index) => (
              <TranslationLanguage
                key={language.id}
                language={language}
                nomenclatureFields={nomenclatureFields}
                setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                setIsEnglishTranslated={setIsEnglishTranslated}
                translation={getTranslationForLanguage(language)}
                updateEnglishTranslation={updateEnglishTranslation}
                updateOtherTranslation={updateOtherTranslation}
                reactionFrequencies={reactionFrequencies}
                medicamentReactionFrequencies={medicamentReactionFrequencies}
                updateReactionFrequencies={updateReactionFrequencies}
                reactionsAnswers={reactionsAnswers}
                setReactionsAnswers={setReactionsAnswers}
                indexTranslation={index}
                partialTranslations={partialTranslations}
                setPartialTranslations={setPartialTranslations}
                selectOptions={selectOptions}
                selectAnswers={selectAnswers}
                updateSelectAnswer={updateSelectAnswer}
                filledAndUnsavedTranslations={filledAndUnsavedTranslations}
                setFilledAndUnsavedTranslations={
                  setFilledAndUnsavedTranslations
                }
                selectedNomenclatureType={selectedNomenclatureType}
              />
            ))}
      </div>

      {message.content && <FormMessage id="nomenclatureForm" />}

      <div className="flex-input-wrapper">
        {!isCompletelyTranslated && (
          <button
            type="submit"
            disabled={isSaveButtonDisabled}
            onClick={saveNomenclature}
          >
            {nomenclatureForTranslation?.id ? t("Update") : t("Create")}
          </button>
        )}

        {isCompletelyTranslated && (
          <div className="form-message success">
            {t("Nomenclature is translated.")}
          </div>
        )}
      </div>

      <div className="mt-4">
        <Icon name="alert-circle" leadingText />
        {t(
          "English is required. Language with partially filled required fields cannot be saved."
        )}
      </div>
    </div>
  );
}
