import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const isDialogConfirmed = createAsyncThunk(
  "isDialogConfirmed",
  async (arg, thunkAPI) => {
    return new Promise((resolve) => {
      let checkConfirmationStatus = setInterval(checkIfUserConfirmed, 300);

      function checkIfUserConfirmed() {
        const dialog = thunkAPI.getState().modals.dialog;

        // Keep as explicit true/false check. isConfirmed is null by default.
        if (dialog.isConfirmed === true) {
          clearInterval(checkConfirmationStatus);
          resolve(true);
        }

        if (dialog.isConfirmed === false) {
          clearInterval(checkConfirmationStatus);
          resolve(false);
        }
      }
    });
  }
);

export const setIsDialogConfirmed = createAsyncThunk(
  "setIsDialogConfirmed",
  async (isConfirmed, thunkAPI) => {
    return new Promise((resolve) => {
      if (isConfirmed === true) resolve(true);
      if (isConfirmed === false) resolve(false);
    });
  }
);

export const modalsSlice = createSlice({
  name: "modals",
  initialState: {
    // Default modal that displays some component.
    openedModal: {
      id: null,
      data: null,
    },
    // Custom modal for creating/editing hospital and wards.
    hospitalModal: {
      isVisible: false,
      hospital: null,
      wards: null,
    },
    modalBackground: {
      isVisible: false,
    },
    // Inline success/error message above the form submt button.
    formMessage: {
      id: "",
      content: "",
      isSuccess: false,
    },
    // Generic success/error popup message.
    messagePopup: {
      isVisible: false,
      isError: false,
      content: "",
    },
    // Confirmation dialog for actions.
    dialog: {
      isVisible: false,
      title: "Confirm",
      message: null,
      isConfirmed: null,
      isWarning: false,
      buttonConfirmText: "Confirm",
      buttonCancelText: "Cancel",
    },
  },
  reducers: {
    showModal: (state, action) => {
      state.openedModal.id = action.payload.id;
      state.openedModal.data = action.payload?.data;
      state.modalBackground.isVisible = true;
    },

    setEditedHospital: (state, action) => {
      state.hospitalModal.hospital = action.payload;
    },

    setEditedHospitalWards: (state, action) => {
      state.hospitalModal.wards = action.payload;
    },

    showModalHospital: (state, action) => {
      state.hospitalModal.isVisible = true;
      state.hospitalModal.hospital = action.payload ?? null;
      state.modalBackground.isVisible = true;
    },

    hideModal: (state, action) => {
      state.openedModal.id = null;
      state.openedModal.data = null;
      state.hospitalModal.isVisible = false;
      state.hospitalModal.hospital = null;
      state.hospitalModal.wards = null;
      state.modalBackground.isVisible = false;
    },

    showSuccessMessage: (state, action) => {
      state.formMessage.id = action.payload.id;
      state.formMessage.content = action.payload.content;
      state.formMessage.isSuccess = true;
    },

    showErrorMessage: (state, action) => {
      state.formMessage.id = action.payload.id;
      state.formMessage.content = action.payload.content;
      state.formMessage.isSuccess = false;
    },

    hideMessage: (state, action) => {
      state.formMessage.id = "";
      state.formMessage.content = "";
      state.formMessage.isSuccess = false;
    },

    showErrorPopup: (state, action) => {
      state.messagePopup.isVisible = true;
      state.messagePopup.isError = true;
      state.messagePopup.content = action.payload;
    },

    showSuccessPopup: (state, action) => {
      state.messagePopup.isVisible = true;
      state.messagePopup.isError = false;
      state.messagePopup.content = action.payload;
    },

    hideMessagePopup: (state, action) => {
      state.messagePopup.isVisible = false;
      state.messagePopup.isError = false;
      state.messagePopup.content = "";
    },

    showDialog: (state, action) => {
      /**
       * Dialog state gets reset on open, instead of close.
       * This avoids flashing changing content on dialog close.
       */
      state.dialog.isVisible = true;
      state.dialog.title = action.payload.title ?? "Confirm";
      state.dialog.message = action.payload.message ?? "";
      state.dialog.isConfirmed = null;
      state.dialog.isWarning = action.payload.isWarning ?? false;
      state.dialog.buttonConfirmText =
        action.payload.buttonConfirmText ?? "Confirm";
      state.dialog.buttonCancelText =
        action.payload.buttonCancelText ?? "Cancel";

      if (action.payload.title) {
        state.dialog.title = action.payload.title;
      }

      if (action.payload.isWarning) {
        state.dialog.isWarning = action.payload.isWarning;
      }

      if (action.payload.buttonConfirmText) {
        state.dialog.buttonConfirmText = action.payload.buttonConfirmText;
      }

      if (action.payload.buttonCancelText) {
        state.dialog.buttonCancelText = action.payload.buttonCancelText;
      }
    },

    hideDialog: (state, action) => {
      state.dialog.isVisible = false;
    },

    hideAllModals: (state, action) => {
      state.dialog.isVisible = false;
      state.modalBackground.isVisible = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setIsDialogConfirmed.fulfilled, (state, action) => {
      state.dialog.isConfirmed = action.payload;
    });
  },
});

export const {
  showModal,
  showModalHospital,
  setEditedHospital,
  setEditedHospitalWards,
  hideModal,
  closeModals,
  showErrorPopup,
  hideMessagePopup,
  showSuccessPopup,
  hideSuccessPopup,
  showSuccessMessage,
  showErrorMessage,
  hideMessage,
  showDialog,
  hideDialog,
} = modalsSlice.actions;

export default modalsSlice.reducer;
