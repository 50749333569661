import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../index";
import FormTreatment from "../Modal/components/FormTreatment";
import TreatmentSummary from "../TreatmentSummary/TreatmentSummary";
import { v4 as generateUUID } from "uuid";
import "./AccordionTreatments.scss";

export default function AccordionTreatments({
  treatments,
  isPrevention,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  selectedBacteria,
  info,
  setTreatments,
  reminder,
  deleteTreatment,
  saveTreatments,
}) {
  const { t } = useTranslation();

  function createEmptyTreatment() {
    return {
      id: generateUUID(),
      order: 0,
      title: t("New Treatment"),
      info: "",
      medicaments: [],
      show: false,
    };
  }

  const toggleTreatment = (treatment) => {
    const newTreats = treatments.map((t) => {
      if (t.id === treatment.id) {
        t.show = !t.show;
        return t;
      } else {
        return t;
      }
    });
    setTreatments(newTreats);
  };

  return (
    <div className="accordeon-treatments">
      <label>{t("Treatment:")}</label>

      {treatments.map((treatment, index) => {
        return (
          <React.Fragment key={treatment.id}>
            <div
              className="accordion-header"
              onClick={() => {
                toggleTreatment(treatment);
              }}
            >
              <div
                className={`input-wrapper no-margins ${
                  treatment.show ? "toggled" : ""
                }`}
              >
                <label>{treatment.title}</label>

                <div className="input-wrapper no-margins">
                  {treatment.medicaments.map((medicament) => (
                    <TreatmentSummary
                      key={generateUUID()}
                      medicament={medicament}
                      medicaments={medicaments}
                      medicamentForms={medicamentForms}
                      doseMeasures={doseMeasures}
                      timeMeasures={timeMeasures}
                      applicationMethods={applicationMethods}
                      isPrevention={isPrevention}
                    />
                  ))}
                </div>
              </div>

              <button
                type="button"
                className="delete-treatment"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTreatment(treatment);
                }}
                title={t("Remove treatment")}
              >
                <Icon name="cross" />
              </button>
            </div>

            {treatment.show && (
              <div className="accordeon-body">
                <FormTreatment
                  treatment={treatment}
                  isPrevention={isPrevention}
                  medicaments={medicaments}
                  medicamentForms={medicamentForms}
                  doseMeasures={doseMeasures}
                  timeMeasures={timeMeasures}
                  applicationMethods={applicationMethods}
                  info={info}
                  selectedBacteria={selectedBacteria}
                  treatments={treatments}
                  setTreatments={setTreatments}
                  reminder={reminder}
                  saveTreatments={saveTreatments}
                  treatmentIndex={index}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}

      <div className="accordion-footer d-flex justify-end">
        <button
          className="smaller icon-left"
          type="button"
          onClick={() => {
            const newTreatment = createEmptyTreatment();
            treatments.push(newTreatment);
            setTreatments(treatments);
            toggleTreatment(newTreatment);
          }}
        >
          <Icon name="plus" />
          {t("Add treatment")}
        </button>
      </div>
    </div>
  );
}
