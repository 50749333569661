import "./Card.scss";

export default function Card({ title, className, children }) {
  const cardClass = className ? className : "";

  return (
    <div className={`card ${cardClass}`}>
      <h3>{title}</h3>
      {children}
    </div>
  );
}
