import { useSelector } from "react-redux";
import { FormMessage, Icon } from "../../index";
import { useTranslation } from "react-i18next";
import "./Form.scss";

export default function FormHead({
  isOpened,
  isCreatingHeadOfHospital = false,
  head,
  setHead,
  toggleFormHead,
}) {
  const { t } = useTranslation();
  const message = useSelector((state) => state.modals.formMessage);
  const { firstName, lastName, username, password, email } = head;

  const formTitle = isCreatingHeadOfHospital
    ? t("Add head of hospital")
    : t("Add head of ward");

  const formSubmitButtonText = isCreatingHeadOfHospital
    ? t("Save head of hospital")
    : t("Save head of ward");

  const setHeadField = (e) => {
    const { name: field, value } = e.target;
    setHead({
      ...head,
      [field]: value,
    });
  };

  const closeFormHead = (e) => {
    e.preventDefault();
    toggleFormHead();
  };

  const isOpenedClass = isOpened ? "toggled" : "hidden";
  const typeOfHeadClass = isCreatingHeadOfHospital
    ? "form-head-hospital"
    : "form-head-ward";

  return (
    <>
      <form
        className={`form-head form-secondary ${isOpenedClass} ${typeOfHeadClass}`}
        onSubmit={(e) => closeFormHead(e)}
      >
        <h3>{formTitle}</h3>

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="firstName">{t("First Name:")}</label>
            <input
              type="text"
              name="firstName"
              value={firstName}
              id="firstName"
              onChange={setHeadField}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="lastName">{t("Last Name:")}</label>
            <input
              type="text"
              name="lastName"
              value={lastName}
              id="lastName"
              onChange={setHeadField}
            />
          </div>
        </div>

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="username">{t("Username:")}</label>
            <input
              type="text"
              name="username"
              value={username}
              id="username"
              onChange={setHeadField}
              minLength={3}
              maxLength={50}
              required
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="password">{t("Password:")}</label>
            <input
              type="text"
              name="password"
              value={password}
              id="password"
              onChange={setHeadField}
              minLength={6}
              maxLength={40}
              required
            />
          </div>
        </div>

        <div className="input-wrapper">
          <label htmlFor="email">{t("Email:")}</label>
          <input
            type="email"
            name="email"
            value={email}
            id="email"
            onChange={setHeadField}
            required
          />
        </div>

        {message.content && <FormMessage id="headForm" />}

        <button type="submit">{formSubmitButtonText}</button>

        <div className="modal-close" onClick={toggleFormHead}>
          <Icon name="cross" />
        </div>
      </form>

      <div
        className={`form-secondary-background form-head-background ${isOpenedClass}`}
        onClick={toggleFormHead}
      ></div>
    </>
  );
}
