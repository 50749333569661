import Logo from "../Logo/Logo";
import { useTranslation } from "react-i18next";
import "./PageLoader.scss";

export default function PageLoader() {
  const { t } = useTranslation();

  return (
    <div className="loader">
      <div className="loader-animation"></div>
      <Logo isTitleVisible={true} />
      <div className="loader-message">{t("Loading...")}</div>
    </div>
  );
}
