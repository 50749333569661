import { useTranslation } from "react-i18next";
import { PageTitle, Statistics } from "../../components";

export default function HomeAdmin() {
  const { t } = useTranslation();

  return (
    <div className="home admin">
      <PageTitle title={t("Statistics")} isBackButtonVisible={false} />
      <Statistics />
    </div>
  );
}
