import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../redux/reducers/index";
import { Icon } from "../index";
import "./Modal.scss";

export default function Modal({
  id,
  title,
  innerComponent,
  isLarge,
  isExtraLarge,
  customClasses,
}) {
  const dispatch = useDispatch();
  const { id: openedModalId } = useSelector(
    (state) => state.modals.openedModal
  );
  const isModalVisible = id === openedModalId;
  const modalClasses = `modal ${isModalVisible ? "toggled" : ""} ${
    isLarge ? "large" : ""
  } ${isExtraLarge ? "extra-large" : ""} ${customClasses}`;

  return (
    <div id={id} className={modalClasses}>
      <h2 className="modal-title">{title}</h2>

      <div className="modal-contents">{innerComponent}</div>

      <div className="modal-close" onClick={() => dispatch(hideModal())}>
        <Icon name="cross" />
      </div>
    </div>
  );
}
