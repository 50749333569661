import { PageTitle, Statistics } from "../../components";
import { useTranslation } from "react-i18next";

export default function HomeHeadHospital() {
  const { t } = useTranslation();

  return (
    <div className="home head-hospital">
      <PageTitle title={t("Statistics")} isBackButtonVisible={false} />
      <Statistics />
    </div>
  );
}
