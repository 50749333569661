import "./InputCheckbox.scss";

export default function InputCheckbox({
  id,
  name,
  value,
  checked,
  defaultChecked,
  required = false,
  disabled = false,
  onChangeFn,
  labelText,
}) {
  return (
    <span
      className="input-checkbox"
      onClick={(e) => e.stopPropagation()}
      key={id}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        required={required}
        onChange={onChangeFn}
      />

      <label
        htmlFor={id}
        className="checkbox-label"
        tabIndex={0}
        title={labelText}
      >
        {labelText}
      </label>
    </span>
  );
}
