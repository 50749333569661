import { useTranslation } from "react-i18next";
import "./MedicamentAdministration.scss";

export default function MedicamentAdministration({
  medicamentName,
  dose,
  doseMeasureName,
  marketFormName,
  applicationMethod,
  applicationMethodName,
  applicationMethodMeasureName,
  durationOfTreatment,
  classNames = "",
}) {
  const { t } = useTranslation();
  const classes = `medicament-administration ${classNames}`;

  return (
    <div className={classes}>
      {medicamentName && <>{medicamentName} </>}
      {dose && <>{dose} </>}
      {doseMeasureName && <span className="lowercase">{doseMeasureName} </span>}
      {marketFormName && <span className="lowercase">{marketFormName} </span>}
      {applicationMethodName && (
        <span className="lowercase">{applicationMethodName} </span>
      )}
      {applicationMethod?.time > 0 && (
        <span className="lowercase">
          {applicationMethod.time} {t("times per")}{" "}
        </span>
      )}
      {applicationMethodMeasureName && (
        <span className="lowercase">{applicationMethodMeasureName} </span>
      )}
      {durationOfTreatment > 0 && (
        <>
          {t("for")} {durationOfTreatment} {t("days")}
        </>
      )}
    </div>
  );
}
