import { useTranslation } from "react-i18next";
import { Icon, AdviceSummary } from "../../index";

export function CardReceivedConsultation({ advice, openAdvice }) {
  const { t } = useTranslation();
  const { diseaseHistory, adviceId } = advice;

  return (
    <div
      className="card card-patient"
      key={adviceId}
      onClick={() => openAdvice(adviceId)}
    >
      <div className="info">
        <div className="title">
          <h2>{diseaseHistory}</h2>

          <div className="buttons">
            <button
              className="continue"
              onClick={() => openAdvice(adviceId)}
              title={t("Open and finish this advice")}
            >
              {t("View")} <Icon name="arrow-right" />
            </button>
          </div>
        </div>
        <AdviceSummary advice={advice} />
      </div>
    </div>
  );
}
