export const nomenclatureTypes = {
  ADVERSE_DRUG_REACTION: {
    label: "Adverse drug reaction",
    fields: [{ name: "body", label: "Name", required: true, type: "text" }],
    translation: "ADVERSE_DRUG_REACTION_TRANSLATED",
  },
  ANSWER: {
    label: "Answer",
    fields: [
      { name: "body", label: "Name", required: true, type: "text" },
      {
        name: "question",
        label: "For Question",
        required: true,
        type: "select",
      },
    ],
    translation: "ANSWER_TRANSLATED",
  },
  APPLICATION_METHOD: {
    label: "Application method",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "abbreviation",
        label: "Abbreviation (i.v, i.m, etc)",
        required: true,
        type: "text",
      },
    ],
    translation: "APPLICATION_METHOD_TRANSLATED",
  },
  BACTERIA: {
    label: "Bacteria",
    fields: [{ name: "name", label: "Name", required: true, type: "text" }],
  },
  DISEASE: {
    label: "Disease",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "diseaseGroup",
        label: "Disease group",
        required: true,
        type: "select",
      },
      { name: "icdCode", label: "ICD code", required: false, type: "text" },
    ],
    translation: "DISEASE_TRANSLATED",
  },
  DISEASE_GROUP: {
    label: "Disease group",
    fields: [{ name: "name", label: "Name", required: true, type: "text" }],
    translation: "DISEASE_GROUP_TRANSLATED",
  },
  FREQUENCY: {
    label: "Frequency",
    fields: [
        { name: "body", label: "Name", required: true, type: "text" },
        {
            name: "frequencyOrder",
            label: "Order",
            required: true,
            type: "text",
        },
    ],
    translation: "FREQUENCY_TRANSLATED",
  },
  GENDER: {
    label: "Gender",
    fields: [{ name: "name", label: "Name", required: true, type: "text" }],
    translation: "GENDER_TRANSLATED",
  },
  LANGUAGE: {
    label: "Language",
    fields: [
      {
        name: "nameEnglish",
        label: "English name (Bulgarian etc.)",
        required: true,
        type: "text",
      },
      {
        name: "nameLocal",
        label: "Local name (Български etc.)",
        required: true,
        type: "text",
      },
      { name: "alias", label: "Alias (bg etc.)", required: true, type: "text" },
      {
        name: "flag",
        label: "Flag",
        required: false,
        type: "text",
        hidden: true,
      },
    ],
  },
  MEASURE: {
    label: "Measure",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "abbreviation",
        label: "Abbreviation",
        required: true,
        type: "text",
      },
    ],
    translation: "MEASURE_TRANSLATED",
  },
  MEDICAMENT: {
    label: "Medicament",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "medicamentGroup",
        label: "Medicament Group",
        required: true,
        type: "select",
      },
      {
        name: "activeIngredientName",
        label: "Active Ingredient Name",
        required: true,
        type: "text",
      },
      {
        name: "activeIngredientCode",
        label: "Active Ingredient Code",
        required: true,
        type: "text",
      },
      {
        name: "applicationMethod",
        label: "Application Method",
        required: true,
        type: "select",
      },
      {
        name: "form",
        label: "Medicament Form",
        required: true,
        type: "select",
      },
      {
        name: "interaction",
        label: "Interactions",
        required: true,
        type: "textarea",
      },
      {
        name: "marketCut",
        label: "Market Cut",
        required: false,
        type: "text",
        hidden: true,
      },
      {
        name: "summary",
        label: "Summary",
        required: false,
        type: "text",
        hidden: true,
      },
    ],
    reactionFrequencies: [
      {
        name: "frequencyId",
        label: "Frequency",
        required: true,
        type: "select",
      },
      {
        name: "reactions",
        label: "Reactions",
        required: true,
        type: "select",
        multiple: true,
      },
    ],
    translation: "MEDICAMENT_TRANSLATED",
  },
  MEDICAMENT_FORM: {
    label: "Medicament form",
    fields: [{ name: "name", label: "Name", required: true, type: "text" }],
    translation: "MEDICAMENT_FORM_TRANSLATED",
  },
  MEDICAMENT_GROUP: {
    label: "Medicament group",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "atcGroupCode",
        label: "ATC group code",
        required: false,
        type: "text",
      },
    ],
    translation: "MEDICAMENT_GROUP_TRANSLATED",
  },
  QUESTION: {
    label: "Question",
    fields: [
      { name: "body", label: "Name", required: true, type: "text" },
      {
        name: "additionalInfo",
        label: "Info (below question)",
        required: false,
        type: "text",
      },
      {
        name: "staticAnswer",
        label: "Static answer",
        required: true,
        type: "checkbox",
      },
    ],
    translation: "QUESTION_TRANSLATED",
  },
  REJECT_REASON: {
    label: "Reject reason",
    fields: [{ name: "name", label: "Name", required: true, type: "text" }],
    translation: "REJECT_REASON_TRANSLATED",
  },
  SURVEY_ANSWER: {
    label: "Survey answer",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "answerOrder",
        label: "Order (among answers: 1, 2...)",
        required: true,
        type: "text",
      },
      {
        name: "surveyQuestion",
        label: "Survey question",
        required: true,
        type: "select",
      },
    ],
    translation: "SURVEY_ANSWER_TRANSLATED",
    hidden: true,
  },
  SURVEY_QUESTION: {
    label: "Survey question",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      { name: "type", label: "Type", required: true, type: "select" },
      {
        name: "questionOrder",
        label: "Order (among questions: 1, 2...)",
        required: true,
        type: "text",
      },
    ],
    translation: "SURVEY_QUESTION_TRANSLATED",
    hidden: true,
  },
  WARD: {
    label: "Ward",
    fields: [
      { name: "name", label: "Name", required: true, type: "text" },
      {
        name: "abbreviation",
        label: "Abbreviation",
        required: false,
        type: "text",
      },
    ],
    translation: "WARD_TRANSLATED",
  },
};
