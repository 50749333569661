import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FormMessage,
  Logo,
  InputUsername,
  InputPassword,
} from "../../components";
import {
  setUser,
  showSuccessPopup,
  showErrorMessage,
} from "../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../http";
import {
  isFormValid,
  convertToBackendObject,
  convertToFrontendObject,
  showButtonLoader,
  hideButtonLoader,
  setLocalStorage,
} from "../../utils/utils";
import "./Login.scss";
import { useTranslation } from "react-i18next";

export default function Login() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.modals.formMessage);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name: field, value } = e.target;
    setLoginData({
      ...loginData,
      [field]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const loginForm = e.target;

    if (!isFormValid(loginForm)) {
      dispatch(
        showErrorMessage({
          content: t("Some fields are not valid."),
          id: "loginForm",
        })
      );
      return;
    }

    showButtonLoader();
    const credentials = convertToBackendObject(loginForm);

    api
      .loginUser(credentials)
      .then((response) => {
        const user = convertToFrontendObject(response.data.user);
        dispatch(setUser(user));
        setLocalStorage("isLoggedIn", true);
        navigate("/");
      })
      .catch((error) => showHttpRequestError(error))
      .finally(hideButtonLoader);
  };

  const showForgottenPasswordMessage = () => {
    dispatch(
      showSuccessPopup(
        t(
          "If you have forgotten your password, please contact your immediate administrative supervisor."
        )
      )
    );
  };

  return (
    <div className="login">
      <Logo isTitleVisible={true}></Logo>
      <h1>{t("Login")}</h1>

      <form onSubmit={handleLogin} name="loginForm">
        <InputUsername
          value={loginData.username}
          handleChange={handleChange}
          minLength="1"
        />

        <InputPassword
          value={loginData.password}
          handleChange={handleChange}
          minLength="1"
        />

        {message.content && <FormMessage id="loginForm" />}

        <button type="submit">{t("Login")}</button>
      </form>

      <div className="forgotten-password">
        <button onClick={showForgottenPasswordMessage}>
          {t("Forgot your password?")}
        </button>
      </div>
    </div>
  );
}
