export default function CardNomenclature({
  row,
  languages,
  flexRender,
  handleCardClick,
}) {
  const nomenclature = row.original;
  const isMedicamentRow =
    nomenclature.hasOwnProperty("medicamentGroupId") &&
    nomenclature.hasOwnProperty("applicationMethodId");

  return (
    <div
      className="card nomenclature"
      key={nomenclature.id}
      onClick={() => handleCardClick(row.original)}
    >
      <div className="translations">
        <div className="translation">
          <div className="translation-language">en</div>
          <h3 className="translation-value" title={nomenclature.name}>
            {nomenclature.name ?? nomenclature.nameEnglish}
            {/* Languages have nameEnglish instead of name */}
          </h3>
        </div>

        {languages
          .filter((language) => language.alias !== "en")
          .map((language) => {
            let hasTranslation = false;
            const translations = nomenclature?.translations;

            if (!translations || isMedicamentRow) {
              return null;
            }

            const translation = translations.find(
              (translation) => translation.languageId === language.id
            );

            if (translation?.id) {
              hasTranslation = true;
            }

            return (
              <div
                className={`translation ${hasTranslation ? "" : "none"}`}
                key={language.id}
              >
                <div className="translation-language">{language.alias}</div>

                {hasTranslation && (
                  <div className="translation-value" title={translation.name}>
                    {translation.name}
                  </div>
                )}

                {!hasTranslation && (
                  <div className="translation-value none">—</div>
                )}
              </div>
            );
          })}
      </div>

      {row.getVisibleCells().map((cell) => {
        // don't display any info other than the two buttons
        if (cell.column.id !== "update" && cell.column.id !== "delete") {
          return null;
        }

        return (
          <div key={cell.id} className={cell.column.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        );
      })}
    </div>
  );
}
