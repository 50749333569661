import HttpRequests from "./httpRequests";
import ApiEndpoints from "./apiEndpoints";

const multipartFormConfig = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

const responseTypeBlobConfig = {
  responseType: "blob",
};
export default class ApiRequests {
  constructor() {
    this.http = new HttpRequests();
    this.apis = new ApiEndpoints();
  }

  /**
   * Login and logout.
   */
  loginUser = (credentials) =>
    this.http.post(this.apis.loginUser(), credentials);

  logoutUser = () => this.http.post(this.apis.logoutUser());
  getCurrentUser = () => this.http.get(this.apis.getCurrentUser());
  changePassword = (data) => this.http.post(this.apis.changePassword(), data);

  /**
   * Users.
   */
  createUser = (user) => this.http.post(this.apis.createUser(), user);

  updateBasicProfileData = (user) =>
    this.http.put(this.apis.updateBasicProfileData(), user);

  updateFullProfileData = (user) =>
    this.http.put(this.apis.updateFullProfileData(), user);

  resetPassword = (id) => this.http.post(this.apis.resetPassword(), id);
  getUsers = () => this.http.get(this.apis.getUsers());
  getUser = (id) => this.http.get(this.apis.getUser(id));
  deleteUser = (id) => this.http.delete(this.apis.deleteUser(id));

  /**
   * Nomenclatures.
   */
  getNomenclature = (type) => this.http.get(this.apis.getNomenclature(type));

  getSpecificNomenclature = (type, id) =>
    this.http.get(this.apis.getSpecificNomenclature(type, id));

  createNomenclature = (nomenclature) =>
    this.http.post(this.apis.createNomenclature(), nomenclature);

  deleteNomenclature = (type, nomenclatureId) =>
    this.http.delete(this.apis.deleteNomenclature(type, nomenclatureId));

  getMedicamentGroups = () => this.http.get(this.apis.getMedicamentGroups());

  getDiseaseGroups = () => this.http.get(this.apis.getDiseaseGroups());

  getUserRoles = () => this.http.get(this.apis.getUserRoles());
  getWardNames = () => this.http.get(this.apis.getWardNames());
  getAdviceRejectReasons = () =>
    this.http.get(this.apis.getAdviceRejectReasons());
  getMedicaments = () => this.http.get(this.apis.getMedicaments());
  getHospitalFilteredNames = (active) =>
    this.http.get(this.apis.getHospitalFilteredNames(active));
  getHospitalWardOptions = (id) =>
    this.http.get(this.apis.getHospitalWardOptions(id));

  /**
   * Files.
   */
  uploadFile = (data) =>
    this.http.post(this.apis.uploadFile(), data, multipartFormConfig);

  getFile = (id) =>
    this.http.get(this.apis.getFile(id), responseTypeBlobConfig);

  getFiles = (purpose, country, hospital) =>
    this.http.get(this.apis.getFiles(purpose, country, hospital));

  deleteFile = (id) => this.http.delete(this.apis.deleteFile(id));

  getAntibioticGuidelines = () =>
    this.http.get(this.apis.getAntibioticGuidelines());

  getAntibioticLeaflets = () =>
    this.http.get(this.apis.getAntibioticLeaflets());

  getAntibioticResistanceStatistics = () =>
    this.http.get(this.apis.getAntibioticResistanceStatistics());

  /**
   * Hospitals.
   */
  getHospitals = () => this.http.get(this.apis.getHospitals());
  getHospital = (id) => this.http.get(this.apis.getHospital(id));

  createHospital = (hospital) =>
    this.http.post(this.apis.createHospital(), hospital);

  updateHospital = (hospital) =>
    this.http.put(this.apis.updateHospital(), hospital);

  deactivateHospital = (id) =>
    this.http.put(this.apis.deactivateHospital(), id);

  activateHospital = (id) => this.http.put(this.apis.activateHospital(), id);
  deleteHospital = (id) => this.http.delete(this.apis.deleteHospital(id));
  getHospitalUsers = (id) => this.http.get(this.apis.getHospitalUsers(id));

  /**
   * Hospital wards.
   */
  getHospitalWards = (id) => this.http.get(this.apis.getHospitalWards(id));
  getActiveHospitalWards = (id) => this.http.get(this.apis.getActiveWards(id));
  getWard = (id) => this.http.get(this.apis.getWard(id));
  createWard = (ward) => this.http.post(this.apis.createWard(), ward);
  updateWard = (ward) => this.http.put(this.apis.updateWard(), ward);
  activateWard = (id) => this.http.put(this.apis.activateWard(), id);
  deactivateWard = (id) => this.http.put(this.apis.deactivateWard(), id);
  deleteWard = (id) => this.http.delete(this.apis.deleteWard(id));
  getWardUsers = (id) => this.http.get(this.apis.getWardUsers(id));

  /**
   * Regions.
   */
  getCountries = () => this.http.get(this.apis.getCountries());
  getRegions = (countryId) => this.http.get(this.apis.getRegions(countryId));
  getCities = (regionId) => this.http.get(this.apis.getCities(regionId));
  importGeo = (file) => this.http.post(this.apis.importGeo(), file);

  /**
   * Workflow.
   */
  getWorkflows = () => this.http.get(this.apis.getWorkflows());
  getWorkflowDiseases = () => this.http.get(this.apis.getWorkflowDiseases());
  getWorkflowQuestions = () => this.http.get(this.apis.getWorkflowQuestions());

  getWorkflowRejectReasons = () =>
    this.http.get(this.apis.getWorkflowRejectReasons());

  getWorkflowMedicamentForms = () =>
    this.http.get(this.apis.getWorkflowMedicamentForms());

  getWorkflowMeasures = () => this.http.get(this.apis.getWorkflowMeasures());
  getWorkflowBacteria = () => this.http.get(this.apis.getWorkflowBacteria());

  getWorkflowApplicationMethods = () =>
    this.http.get(this.apis.getWorkflowApplicationMethods());
  getWorkflow = (cid, did) => this.http.get(this.apis.getWorkflow(cid, did));
  getDraftWorkflow = (cid, did) =>
    this.http.get(this.apis.getDraftWorkflow(cid, did));
  createWorkflow = (dto) => this.http.post(this.apis.createWorkflow(), dto);
  updateWorkflow = (dto) => this.http.put(this.apis.updateWorkflow(), dto);
  completeWorkflow = (dto) => this.http.post(this.apis.completeWorkflow(), dto);
  deleteWorkflow = (cid, did) =>
    this.http.delete(this.apis.deleteWorkflow(cid, did));
  deleteDraftWorkflow = (cid, did) =>
    this.http.delete(this.apis.deleteDraftWorkflow(cid, did));
  testWorkflowFirstStep = (cid, did, draft) =>
    this.http.post(this.apis.testWorkflowFirstStep(cid, did, draft));
  testWorkflowNextStep = (dto, draft) =>
    this.http.put(this.apis.testWorkflowNextStep(draft), dto);

  /**
   * Workflow instance controller.
   */
  getWorkflowInstances = (hospitalId) =>
    this.http.get(this.apis.getWorkflowInstances(hospitalId));
  deleteWorkflowInstance = (id) =>
    this.http.delete(this.apis.deleteWorkflowInstance(id));
  getWorkflowInstance = (id) =>
    this.http.get(this.apis.getWorkflowInstance(id));
  getDraftWorkflowInstance = (id) =>
    this.http.get(this.apis.getDraftWorkflowInstance(id));
  updateWorkflowInstance = (dto) =>
    this.http.put(this.apis.updateWorkflowInstance(), dto);
  completeWorkflowInstance = (dto) =>
    this.http.post(this.apis.completeWorkflowInstance(), dto);
  deleteDraftWorkflowInstance = (id) =>
    this.http.delete(this.apis.deleteDraftWorkflowInstance(id));

  addAllWorkflowInstancesToHospital = (hospitalId) =>
    this.http.post(this.apis.addAllWorkflowInstancesToHospital(), hospitalId);

  testWorkflowInstanceFirstStep = (id, draft) =>
    this.http.post(this.apis.testWorkflowInstanceFirstStep(id, draft));
  testWorkflowInstanceNextStep = (dto, draft) =>
    this.http.put(this.apis.testWorkflowInstanceNextStep(draft), dto);
  upgradeWorkflowInstance = (id) =>
    this.http.put(this.apis.upgradeWorkflowInstance(id));

  requestWorkflow = (hid, wid) =>
    this.http.post(this.apis.requestWorkflow(hid, wid));
  acceptWorkflow = (hid, wid) =>
    this.http.put(this.apis.acceptWorkflow(hid, wid));
  deleteRequestWorkflow = (hid, wid) =>
    this.http.delete(this.apis.deleteRequestWorkflow(hid, wid));

  /**
   * Ask advice.
   */
  createConsultationAdvice = (data) =>
    this.http.post(this.apis.createConsultationAdvice(), data);
  createMainAdvice = (data) =>
    this.http.post(this.apis.createMainAdvice(), data);
  updateAdvice = (data) => this.http.put(this.apis.updateAdvice(), data);
  getDiseasesForAdvice = () => this.http.get(this.apis.getDiseasesForAdvice());
  acceptAdvice = (dto) => this.http.put(this.apis.acceptAdvice(), dto);
  rejectAdvice = (advice) => this.http.put(this.apis.rejectAdvice(), advice);

  deleteAdvice = (adviceId) =>
    this.http.delete(this.apis.deleteAdvice(adviceId));

  archiveAdvice = (dto) => this.http.post(this.apis.archiveAdvice(), dto);

  archiveDiseaseHistory = (diseaseHistoryId) =>
    this.http.post(this.apis.archiveDiseaseHistory(diseaseHistoryId));

  getAdvice = (adviceId) => this.http.get(this.apis.getAdvice(adviceId));

  /**
   * Settings.
   */
  getSettings = () => this.http.get(this.apis.getSettings());
  updateSettings = (settings) =>
    this.http.put(this.apis.updateSettings(), settings);
  restoreSettings = () => this.http.post(this.apis.restoreSettings());

  /**
   * Notifications.
   */
  getNotifications = () => this.http.get(this.apis.getNotifications());
  getNotification = (id) => this.http.get(this.apis.getNotification(id));

  getNotificationByKey = (key) =>
    this.http.get(this.apis.getNotificationByKey(key));

  updateNotification = (notification) =>
    this.http.put(this.apis.updateNotification(), notification);

  /**
   * Notification instance (notifications per hospital).
   */
  getNotificationsForHospital = (hospitalId) =>
    this.http.get(this.apis.getNotificationsForHospital(hospitalId));

  getNotificationForHospital = (id) =>
    this.http.get(this.apis.getNotificationForHospital(id));

  updateNotificationForHospital = (notification) =>
    this.http.put(this.apis.updateNotificationForHospital(), notification);

  /**
   * Statistics
   */
  getActivePatientsCount = (entityId, entityType) =>
    this.http.get(this.apis.getActivePatientsCount(entityId, entityType));

  getAcceptedAdviceCount = (entityId, entityType) =>
    this.http.get(this.apis.getAcceptedAdviceCount(entityId, entityType));

  getRejectedAdviceCount = (entityId, entityType) =>
    this.http.get(this.apis.getRejectedAdviceCount(entityId, entityType));

  getMostFrequentDiagnoses = (entityId, entityType) =>
    this.http.get(this.apis.getMostFrequentDiagnoses(entityId, entityType));

  getMostRecommendedMedicaments = (entityId, entityType) =>
    this.http.get(
      this.apis.getMostRecommendedMedicaments(entityId, entityType)
    );

  getMostFrequentBacteria = (entityId, entityType) =>
    this.http.get(this.apis.getMostFrequentBacteria(entityId, entityType));

  getReasonsForAdviceRejection = (entityId, entityType) =>
    this.http.get(this.apis.getReasonsForAdviceRejection(entityId, entityType));

  getHospitalsInCountry = (countryId) =>
    this.http.get(this.apis.getHospitalsInCountry(countryId));

  /**
   * Dashboard
   */
  getActivePatients = () => this.http.get(this.apis.getActivePatients());
  getAdvicesToFollowUp = () => this.http.get(this.apis.getAdvicesToFollowUp());
  getReceivedConsultations = () =>
    this.http.get(this.apis.getReceivedConsultations());
  getDraftAdvices = () => this.http.get(this.apis.getDraftAdvices());
  getLatestFiles = () => this.http.get(this.apis.getLatestFiles());
  getConsultations = () => this.http.get(this.apis.getConsultations());
  getAllPatientAdvices = (historyOfDiseaseId) =>
    this.http.get(this.apis.getAllPatientAdvices(historyOfDiseaseId));

  /**
   * Aggregated patients.
   */
  getPatientsInHospital = () =>
    this.http.get(this.apis.getPatientsInHospital());
  getArchivedPatients = () => this.http.get(this.apis.getArchivedPatients());

  /**
   * Survey
   */
  getSurveyQuestions = (adviceId) =>
    this.http.get(this.apis.getSurveyQuestions(adviceId));
}
