import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiRequests, showHttpRequestError } from "../../../http";
import { useOnMount } from "../../../hooks";
import { Icon } from "../../index";
import {
  isFormValid,
  showButtonLoader,
  hideButtonLoader,
} from "../../../utils/utils";
import { showSuccessMessage, showErrorMessage } from "../../../redux/reducers";
import FormHead from "./FormHead";
import { FormMessage } from "../../index";
import Select from "react-select";
import { copyToClipBoard } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { selectStyles } from "../../../styles/selectStyles";
import "./Form.scss";

export default function FormWard({
  isOpened,
  editedWard,
  setEditedWard,
  usersInWard,
  setUsersInWard,
  wards,
  setWards,
  addUpdatedWard,
  cities,
  isCreatingWard,
  setIsCreatingWard,
  setIsWardFormOpen,
}) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.modals.formMessage);
  const hospital = useSelector((state) => state.modals.hospitalModal.hospital);
  const isEditingWard = Boolean(editedWard?.id);

  // Wards are created in a hospital, so they inherit the common fields.
  const emptyWard = {
    id: "",
    hospitalId: isCreatingWard ? hospital?.id : "",
    wardNomId: "",
    wardNomName: "",
    cityId: isCreatingWard ? hospital?.cityId : "",
    cityName: "",
    address: isCreatingWard ? hospital?.address : "",
    email: "",
    phone: "",
    contactPerson: "",
    headOfWardId: "",
  };

  const [ward, setWard] = useState({ ...emptyWard });
  const useOnModalOpen = (fn) => useEffect(fn, [isOpened]);

  useOnModalOpen(() => {
    if (isOpened && isEditingWard) setWard({ ...editedWard });
    if (isOpened && isCreatingWard) setWard({ ...emptyWard });
    if (!isOpened) {
      setEditedWard(null);
    }
  });

  const setWardField = (e) => {
    const { name: field, value } = e.target;
    setWard({
      ...ward,
      [field]: value,
    });
  };

  const {
    id,
    hospitalId,
    wardNomId,
    wardNomName,
    cityId,
    cityName,
    address,
    email,
    phone,
    contactPerson,
    headOfWardId,
  } = ward;

  /**
   * Fetch ward names.
   */
  const [wardNames, setWardNames] = useState([]);

  useOnMount(() => {
    const hasWardNames = !!wardNames.length;
    if (!hasWardNames) fetchAndSetWardNames();
  });

  async function fetchAndSetWardNames() {
    const fetchedWardNames = await api
      .getWardNames()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const wardNamesForSelect = fetchedWardNames.map((wardName) => ({
      value: wardName.id,
      label: wardName.name,
    }));

    setWardNames(wardNamesForSelect);
  }

  /**
   * Add head of ward modal.
   */
  const emptyHead = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
  };
  const [head, setHead] = useState({ ...emptyHead });
  const headHasName = head.firstName || head.lastName || head.username;

  const [isFormHeadOpened, setIsFormHeadOpened] = useState(false);
  const toggleFormHead = () => setIsFormHeadOpened((opened) => !opened);
  const addHeadOfHospitalButtonText = headHasName
    ? t("Edit head of ward")
    : t("Add head of ward");

  /**
   * Change head of ward.
   *
   * When head of hospital or ward is changed - the admin should decide what to do with the previous head - delete his profile or make his role "doctor".
   * previousHeadAction - DELETE | DOCTOR
   */
  const [newHeadOfWardId, setNewHeadOfWardId] = useState(null);
  const [previousHeadAction, setPreviousHeadAction] = useState("DOCTOR");
  const [isPreviousHeadActionVisible, setIsPreviousHeadActionVisible] =
    useState(false);

  function askWhatHappensWithTheOldHead(e) {
    const chosenHeadId = e.value;
    const headDidntChange = headOfWardId === chosenHeadId;
    const oldHeadExists = Boolean(headOfWardId);
    setNewHeadOfWardId(chosenHeadId);
    if (!oldHeadExists) return;

    if (headDidntChange) {
      setIsPreviousHeadActionVisible(false);
      return;
    }

    setIsPreviousHeadActionVisible(true);
  }

  /**
   * Fetch users in ward for changing the head.
   */
  const hasUsers = Boolean(usersInWard.length);
  const resetUsersInWard = () => setUsersInWard([]);

  useOnModalOpen(() => {
    if (isOpened) return;
    resetUsersInWard();
  });

  // Callbacks for the value of select fields.
  const selectedWardHead = (user) => user.value === headOfWardId;
  const selectedNewWardHead = (user) => user.value === newHeadOfWardId;

  /**
   * Error handling.
   */
  function showContactPersonIsRequired() {
    dispatch(
      showErrorMessage({
        content: t(
          "Either a contact person or a head of ward is required to be set."
        ),
        id: "wardForm",
      })
    );
  }

  function showSomeFieldsAreNotValid() {
    dispatch(
      showErrorMessage({
        content: t("Some fileds are not valid."),
        id: "wardForm",
      })
    );
  }

  /**
   * Create new ward.
   */
  function createWard(e) {
    e.preventDefault();
    const createWardForm = e.target;
    const thereIsNoContactPerson = contactPerson === "" && !head.username;

    if (!isFormValid(createWardForm)) {
      showSomeFieldsAreNotValid();
      return;
    }

    if (thereIsNoContactPerson) {
      showContactPersonIsRequired();
      return;
    }

    const wardToSend = {
      hospitalId: hospitalId,
      wardNomId: wardNomId,
      cityId: cityId,
      address: address,
      email: email,
      phone: phone,
      contactPerson: contactPerson,
    };

    const isHeadCreated = head.username && head.password;
    if (isHeadCreated) {
      wardToSend.headOfWard = {
        ...head,
      };
    }

    showButtonLoader();
    api
      .createWard(wardToSend)
      .then((response) => {
        const createdWard = response.data;
        const { wardNomName: name } = createdWard;
        setWards([...wards, createdWard]);
        setWard({ ...emptyWard });
        setHead({ ...emptyHead });
        dispatch(
          showSuccessMessage({
            content: `${name} ${t("was created successfully.")}`,
            id: "wardForm",
          })
        );
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  }

  /**
   * Edit ward.
   */
  function editWard(e) {
    e.preventDefault();
    const editWardForm = e.target;

    if (!isFormValid(editWardForm)) {
      showSomeFieldsAreNotValid();
      return;
    }

    const wardToSend = {
      id: id,
      wardNomId: wardNomId,
      cityId: cityId,
      address: address,
      email: email,
      phone: phone,
      contactPerson: contactPerson,
    };

    const isHeadChanged = newHeadOfWardId && headOfWardId !== newHeadOfWardId;
    const oldHeadExists = Boolean(headOfWardId);

    if (isHeadChanged && !oldHeadExists) {
      wardToSend.oldHeadOfWard = null;
      wardToSend.newHeadOfWard = {
        id: newHeadOfWardId,
      };
    }

    if (isHeadChanged && oldHeadExists) {
      wardToSend.oldHeadOfWard = {
        id: headOfWardId,
        action: previousHeadAction,
      };

      wardToSend.newHeadOfWard = {
        id: newHeadOfWardId,
      };
    }

    showButtonLoader();
    api
      .updateWard(wardToSend)
      .then((response) => {
        const updatedWard = response.data;
        const { wardNomName: name } = updatedWard;
        setWard(updatedWard);
        addUpdatedWard(updatedWard);
        dispatch(
          showSuccessMessage({
            content: `${name} ${t("was updated successfully.")}`,
            id: "wardForm",
          })
        );
        setIsPreviousHeadActionVisible(false);
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  }

  /**
   * Callbacks for the select fields and form classes.
   */
  const selectedWardName = (wardName) => wardName.value === wardNomId;
  const selectedCity = (city) => city.value === cityId;

  const isOpenedClass = isOpened ? "toggled" : "hidden";
  const formTitle = isEditingWard ? t("Edit ward") : t("Create ward");
  const submitButtonText = isEditingWard ? t("Save ward") : t("Create ward");
  const currentHeadName = usersInWard.find(selectedWardHead)?.label;

  /**
   * Copy the data for head of hospital.
   */
  const [buttonCopyHeadText, setButtonCopyHeadText] = useState(t("Copy"));
  const createdHeadRef = useRef(null);

  function copyHeadData(e) {
    e.preventDefault();
    const createdHeadElement = createdHeadRef.current;
    const createdHeadText = createdHeadElement.textContent;

    createdHeadElement.classList.add("highlight");
    copyToClipBoard(createdHeadText);
    setButtonCopyHeadText(t("Copied!"));

    setTimeout(() => setButtonCopyHeadText(t("Copy")), 5000);
    setTimeout(() => createdHeadElement.classList.remove("highlight"), 1000);
  }

  return (
    <>
      <form
        className={`form-ward form-secondary ${isOpenedClass}`}
        onSubmit={isEditingWard ? editWard : createWard}
      >
        <h3>{formTitle}</h3>
        <div className="input-wrapper">
          <label htmlFor="wardNomName">{t("Name:")}</label>
          <Select
            name="wardNomName"
            options={wardNames}
            isSearchable={true}
            required
            styles={selectStyles}
            value={wardNames.find(selectedWardName) ?? null}
            onChange={(e) => setWard({ ...ward, wardNomId: e.value })}
            menuPlacement="auto"
          />
        </div>

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="cityId">{t("City:")}</label>
            <Select
              name="cityId"
              options={cities}
              isSearchable={true}
              styles={selectStyles}
              value={cities.find(selectedCity) ?? null}
              onChange={(e) => setWard({ ...ward, cityId: e.value })}
              menuPlacement="auto"
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="address">{t("Address:")}</label>
            <input
              type="text"
              name="address"
              value={address}
              id="address"
              onChange={setWardField}
            />
          </div>
        </div>

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="email">{t("Email:")}</label>
            <input
              type="email"
              name="email"
              value={email}
              id="email"
              onChange={setWardField}
              required
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="phone">{t("Phone:")}</label>
            <input
              type="number"
              name="phone"
              value={phone}
              id="phone"
              onChange={setWardField}
            />
          </div>
        </div>

        <div className="flex-input-wrapper">
          <div className="input-wrapper">
            <label htmlFor="contactPerson">{t("Contact person:")}</label>
            <input
              type="text"
              name="contactPerson"
              value={contactPerson}
              id="contactPerson"
              onChange={setWardField}
            />
          </div>

          {isCreatingWard && (
            <div className="input-wrapper">
              <label htmlFor="contactPerson">{t("Head of ward:")}</label>
              <button
                type="button"
                className="secondary no-margins w-100"
                onClick={toggleFormHead}
              >
                {addHeadOfHospitalButtonText}
              </button>
            </div>
          )}

          {isEditingWard && (
            <>
              <div className="input-wrapper">
                <label htmlFor="headOfWardId">{t("Head of ward:")}</label>
                <Select
                  name="headOfWardId"
                  options={usersInWard}
                  isSearchable={true}
                  styles={selectStyles}
                  value={
                    newHeadOfWardId
                      ? usersInWard.find(selectedNewWardHead)
                      : headOfWardId
                      ? usersInWard.find(selectedWardHead)
                      : null
                  }
                  isDisabled={!hasUsers}
                  placeholder={hasUsers ? t("Select") : t("No users found")}
                  onChange={(e) => askWhatHappensWithTheOldHead(e)}
                  menuPlacement="auto"
                />
              </div>
            </>
          )}
        </div>

        {isPreviousHeadActionVisible && (
          <div className="previous-head">
            <h3>
              {t("What about the previous head of ward")} - {currentHeadName}?
            </h3>

            <input
              type="radio"
              name="previous-head-action"
              id="previous-head-action-doctor"
              checked={previousHeadAction === "DOCTOR"}
              onChange={() => setPreviousHeadAction("DOCTOR")}
            />
            <label htmlFor="previous-head-action-doctor">
              {t("Change role to doctor")}
            </label>

            <input
              type="radio"
              name="previous-head-action"
              id="previous-head-action-delete"
              checked={previousHeadAction === "DELETE"}
              onChange={() => setPreviousHeadAction("DELETE")}
            />
            <label htmlFor="previous-head-action-delete">
              {t("Delete profile")}
            </label>
          </div>
        )}

        {isCreatingWard && headHasName && (
          <div className="input-wrapper created-head">
            <span>
              <b>{t("Head of ward:")}</b>
              <button
                type="button"
                className="text"
                onClick={(e) => copyHeadData(e)}
              >
                {buttonCopyHeadText}
              </button>
            </span>

            <div className="created-head-info" ref={createdHeadRef}>
              <span>
                — {`${head.firstName} ${head.lastName}, ${head.email}`}
              </span>
              <span>— {`${head.username}, ${head.password}`}</span>
            </div>
          </div>
        )}

        <input
          type="text"
          name="hospitalId"
          defaultValue={hospitalId}
          id="hospitalId"
          hidden
        />

        {message.content && <FormMessage id="wardForm" />}

        <button type="submit">{submitButtonText}</button>

        <div
          className="modal-close"
          onClick={() => {
            setIsCreatingWard(false);
            setIsWardFormOpen(false);
          }}
        >
          <Icon name="cross" />
        </div>
      </form>

      <FormHead
        isOpened={isFormHeadOpened}
        isCreatingHeadOfHospital={false}
        head={head}
        setHead={setHead}
        toggleFormHead={toggleFormHead}
      />

      <div
        className={`form-secondary-background ${isOpenedClass}`}
        onClick={() => {
          setIsCreatingWard(false);
          setIsWardFormOpen(false);
        }}
      ></div>
    </>
  );
}
