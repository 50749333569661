import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/reducers";
import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";
import { v4 as generateUUID } from "uuid";
import Select from "react-select";
import FormTreatment from "../Modal/components/FormTreatment";
import TreatmentSummary from "../TreatmentSummary/TreatmentSummary";
import {
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
  hideModal,
} from "../../redux/reducers";
import { selectStyles } from "../../styles/selectStyles";
import { useTranslation } from "react-i18next";
import AccordionTreatments from "../AccordionTreatments/AccordionTreatments";

export default function AdviceForm({
  isPrevention,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  bacteria,
  canCreate,
  canUpdate,
  advice,
  saveAdvice,
  isBaseAdvice,
  isSavingAdvice,
  isEditingDraft,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [info, setInfo] = useState("");
  const [selectedBacteria, setSelectedBacteria] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [reminder, setReminder] = useState("");

  /**
   * Treatment modal.
   */
  const hasTreatments = treatments.length > 0;

  function createEmptyTreatment() {
    return {
      id: generateUUID(),
      order: 0,
      title: t("New Treatment"),
      info: "",
      medicaments: [],
    };
  }

  function showTreatment(id) {
    setTimeout(() => {
      dispatch(
        showModal({
          id: `treatmentModal-${id}`,
        })
      );
    }, 200);
  }

  async function deleteTreatment(treatment) {
    const { title, id: treatmentId } = treatment;

    const dialog = {
      title: `${t("Delete")} ${title}?`,
      message: t("You will lose all entered data."),
      buttonConfirmText: t("Delete"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(dialog));
    const isActionConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isActionConfirmed) return;

    const copyTreatments = [...treatments];
    const treatmentIndex = copyTreatments.findIndex(
      (treatment) => treatment.id === treatmentId
    );

    copyTreatments.splice(treatmentIndex, 1);
    setTreatments(copyTreatments);
    dispatch(showSuccessPopup(t("Treatment deleted.")));
    if (isBaseAdvice) {
      dispatch(hideModal());
    }
  }

  /**
   * Setup the content.
   */
  useEffect(setupContent, [bacteria, isEditingDraft]);

  function setupContent() {
    // Get the bacteria.
    const bacteriaOptions = [];
    advice?.bacteriaIds?.forEach((bacteriaId) => {
      const bacteriaOption = bacteria.find((b) => {
        return b.value === bacteriaId;
      });
      bacteriaOptions.push(bacteriaOption);
    });

    // Get the treatments.
    const treatments = [];
    advice?.treatments.forEach((element) => {
      const treatmentsEmpty = createEmptyTreatment();
      const medicaments = [];
      element.medicaments.forEach((m) => {
        const medicament = {
          id: generateUUID(),
          ...m,
        };
        medicaments.push();
      });
      const treatment = {
        ...treatmentsEmpty,
        ...element,
      };
      treatments.push(treatment);
    });

    // Set everything.
    setSelectedBacteria(bacteriaOptions);
    setReminder(advice?.reminder);
    setInfo(advice?.info);
    setTreatments(treatments);
  }

  /**
   * Save the advice.
   */
  const [isSavingOnMount, setIsSavingOnMount] = useState(true);

  useEffect(() => {
    if (isSavingAdvice.current) return;
    asyncSaveTreatments();
  }, [treatments, info, reminder, selectedBacteria]);

  async function asyncSaveTreatments() {
    isSavingAdvice.current = true;
    const response = await saveTreatments();
    isSavingAdvice.current = false;
  }

  function saveTreatments() {
    if (!isEditingDraft) return;

    if (isSavingOnMount) {
      setIsSavingOnMount(false);
      return;
    }

    const advice = {
      info: info,
      bacteriaIds: selectedBacteria.map((bacteria) => bacteria.value),
      treatments: treatments,
      reminder: reminder,
    };

    if (isBaseAdvice) {
      dispatch(hideModal());
    }

    return saveAdvice(advice);
  }

  return (
    <>
      {isBaseAdvice && (
        <>
          {!hasTreatments && (
            <div className="flex-input-wrapper treatment-summary">
              <>
                <div className="input-wrapper no-margins">
                  <label>{t("Treatments:")}</label>
                  <p>{t("Nothing added yet.")}</p>
                </div>

                <button
                  className="smaller icon-left"
                  type="button"
                  onClick={() => {
                    const firstTreatment = createEmptyTreatment();
                    setTreatments((treatments) => [firstTreatment]);
                    showTreatment(firstTreatment.id);
                  }}
                >
                  <Icon name="plus" />
                  {t("Add treatment")}
                </button>
              </>
            </div>
          )}

          {hasTreatments &&
            (canCreate || canUpdate) &&
            treatments.map((treatment) => {
              return (
                <div
                  className="flex-input-wrapper treatment-summary"
                  key={treatment.id}
                >
                  <div className="input-wrapper no-margins">
                    <label>{treatment.title}</label>

                    {treatment.medicaments.map((medicament) => (
                      <TreatmentSummary
                        key={generateUUID()}
                        medicament={medicament}
                        medicaments={medicaments}
                        medicamentForms={medicamentForms}
                        doseMeasures={doseMeasures}
                        timeMeasures={timeMeasures}
                        applicationMethods={applicationMethods}
                        isPrevention={isPrevention}
                      />
                    ))}
                  </div>

                  <div className="treatment-actions">
                    <button
                      className="secondary smaller icon-left"
                      type="button"
                      onClick={() => showTreatment(treatment.id)}
                    >
                      <Icon name="pencil" />
                      {t("Edit")}
                    </button>

                    <button
                      type="button"
                      className="secondary smaller"
                      onClick={() => deleteTreatment(treatment)}
                    >
                      <Icon name="cross" />
                    </button>
                  </div>

                  <Modal
                    id={`treatmentModal-${treatment.id}`}
                    title={treatment.title}
                    innerComponent={
                      <FormTreatment
                        treatment={treatment}
                        isPrevention={isPrevention}
                        medicaments={medicaments}
                        medicamentForms={medicamentForms}
                        doseMeasures={doseMeasures}
                        timeMeasures={timeMeasures}
                        applicationMethods={applicationMethods}
                        info={info}
                        selectedBacteria={selectedBacteria}
                        treatments={treatments}
                        setTreatments={setTreatments}
                        reminder={reminder}
                        saveTreatments={saveTreatments}
                      />
                    }
                    isLarge
                    customClasses={"editing"}
                  />
                </div>
              );
            })}

          {hasTreatments && (
            <div className="add-treatment d-flex justify-end">
              <button
                className="smaller icon-left"
                type="button"
                onClick={() => {
                  const newTreatment = createEmptyTreatment();
                  setTreatments((treatments) => [...treatments, newTreatment]);
                  showTreatment(newTreatment.id);
                }}
              >
                <Icon name="plus" />
                {t("Add treatment")}
              </button>
            </div>
          )}
        </>
      )}

      {!isBaseAdvice && (
        <AccordionTreatments
          treatments={treatments}
          isPrevention={isPrevention}
          medicaments={medicaments}
          medicamentForms={medicamentForms}
          doseMeasures={doseMeasures}
          timeMeasures={timeMeasures}
          applicationMethods={applicationMethods}
          info={info}
          selectedBacteria={selectedBacteria}
          setTreatments={setTreatments}
          reminder={reminder}
          deleteTreatment={deleteTreatment}
          saveTreatments={saveTreatments}
        />
      )}

      <div className="input-wrapper">
        <label htmlFor="bacteria">{t("Common bacteria")}:</label>
        <Select
          name="bacteria"
          options={bacteria}
          isSearchable={true}
          required
          styles={selectStyles}
          openMenuOnClick
          openMenuOnFocus
          isMulti
          value={selectedBacteria}
          onChange={(options) => setSelectedBacteria(options)}
        />
      </div>

      <div className="input-wrapper">
        <label htmlFor="reminder">{t("Reminders")}:</label>
        <textarea
          name="reminder"
          id="reminder"
          value={reminder ?? ""}
          onChange={(e) => setReminder(e.target.value)}
          placeholder={t(
            "Before initiating treatment, check the patient's kidney function."
          )}
          maxLength="3000"
        ></textarea>
      </div>
    </>
  );
}
