import { useState } from "react";
import { useDispatch } from "react-redux";
import { showSuccessPopup } from "../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../http";
import { useTranslation } from "react-i18next";
import { InputCheckbox } from "../../components";

export default function DialogRejectAdvice({
  adviceId,
  isVisible,
  setIsRejectAdviceVisible,
  setIsAdviceRejected,
  rejectReasons,
}) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const dialogClasses = `modal dialog ${isVisible ? "toggled" : "hidden"}`;
  const dialogBackgroundClasses = `dialog-background ${
    isVisible ? "toggled" : "hidden"
  }`;

  const closeRejectDialog = () => setIsRejectAdviceVisible(false);

  const [selectedRejectReasons, setSelectedRejectReasons] = useState([]);
  const isRejectButtonDisabled = selectedRejectReasons.length === 0;

  const handleRejectReasonChange = (e) => {
    const id = e.target.value;
    const reasonIsChecked = e.target.checked;
    const reasonAlreadySelected = selectedRejectReasons.includes(id);

    if (!reasonIsChecked && reasonAlreadySelected) {
      setSelectedRejectReasons(
        selectedRejectReasons.filter((reason) => reason !== id)
      );
      return;
    }

    if (reasonIsChecked && !reasonAlreadySelected) {
      setSelectedRejectReasons([...selectedRejectReasons, id]);
      return;
    }
  };

  const handleRejectAdvice = async () => {
    const rejectedAdvice = {
      askAdviceId: adviceId,
      rejectReasons: selectedRejectReasons,
    };

    api
      .rejectAdvice(rejectedAdvice)
      .then((response) => {
        setIsAdviceRejected(true);
        dispatch(showSuccessPopup(t("Advice was rejected.")));
      })
      .catch((error) => showHttpRequestError(error));

    closeRejectDialog();
  };

  return (
    <>
      <div className={dialogClasses}>
        <h2 className="modal-title">{t("Reject advice")}</h2>

        <div className="modal-contents">
          <div className="dialog-message">
            {rejectReasons.map((reason) => (
              <div className="input-wrapper checkbox" key={reason.id}>
                <InputCheckbox
                  id={reason.id}
                  name="rejectReason"
                  value={reason.id}
                  checked={selectedRejectReasons.includes(reason.id)}
                  labelText={reason.name}
                  onChangeFn={(e) => handleRejectReasonChange(e)}
                />
              </div>
            ))}
          </div>

          <div className="dialog-actions">
            <button
              className="dialog-cancel secondary"
              onClick={() => {
                closeRejectDialog();
              }}
            >
              {t("Cancel")}
            </button>

            <button
              className="dialog-confirm"
              disabled={isRejectButtonDisabled}
              onClick={() => {
                handleRejectAdvice();
              }}
            >
              {t("Reject advice")}
            </button>
          </div>
        </div>

        <div
          className="modal-close"
          onClick={() => {
            closeRejectDialog();
          }}
        >
          <span className="icon icon-cross"></span>
        </div>
      </div>

      <div
        className={dialogBackgroundClasses}
        onClick={() => {
          closeRejectDialog();
        }}
      ></div>
    </>
  );
}
