import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourceEn from "./i18n/en/resource.json";
import resourceBg from "./i18n/bg/resource.json";

const resources = {
  en: {
    common: resourceEn,
  },
  bg: {
    common: resourceBg,
  },
};

i18n.use(initReactI18next).init({
  defaultNS: "common",
  fallbackNS: "common",
  resources,
  lng: "en",
  fallbackLng: "en",
  debug: false,
  nsSeparator: false, // allows use of . and : in keys
  keySeparator: false, // allows use of . and : in keys
  interpolation: {
    escapeValue: false, // react already escapes values
  },
});

export default i18n;
