import { Routes, Route, useNavigationType, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  AppContent,
  AppSidebar,
  Logo,
  MenuSidebar,
  MessagePopup,
  DialogConfirm,
  PageLoader,
  ModalBackground,
} from "./components/index";
import {
  Login,
  ResetPassword,
  Workflows,
  HospitalWorkflows,
  WorkflowBuilder,
  HospitalWorkflowBuilder,
  AskForAdvice,
  Notifications,
  HospitalManagement,
  Statistics,
  Users,
  Patient,
  Patients,
  PatientsArchived,
  Nomenclatures,
  AntibioticGuidelines,
  AntibioticLeaflets,
  AntibioticResistance,
  EventLog,
  Settings,
  Profile,
  TermsOfUse,
  PrivacyPolicy,
  Error404,
  Consultations,
} from "./pages/index";
import { hideMessage } from "./redux/reducers";
import { useOnNewPage, useOnLanguageChange, useOnMount } from "./hooks/index";
import {
  getLocalStorage,
  getUserData,
  getPageHrefFromUrl,
  addRequiredClassToInputWrappers,
  isUsingSafariBrowser,
} from "./utils/utils";
import { getHomePageByRole } from "./config/homePage";
import { useTranslation } from "react-i18next";
import { showHttpRequestError } from "./http";
import "./App.scss";

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const user = useSelector((state) => state.user);

  /**
   * Setup the app language.
   */
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const userLanguageAlias = user.languageAlias;
  const userLanguage = userLanguageAlias
    ? userLanguageAlias.toLowerCase()
    : "en";

  useOnLanguageChange(() => {
    i18n
      .changeLanguage(userLanguage)
      .then((response) => {
        // Nothing to do or display.
      })
      .catch((error) => {
        showHttpRequestError(t("Error changing language."));
      });
  });

  /**
   * Setup the app.
   */
  const homePage = getHomePageByRole(user.role);
  const isLoggedIn = getLocalStorage("isLoggedIn");
  const userIsLoggedInButNoData = isLoggedIn && !user.id;
  const isUsingSafari = isUsingSafariBrowser();

  useOnNewPage(() => {
    if (userIsLoggedInButNoData) {
      getUserData();
    }
    scrollToTop();
    resetMessage();
  });

  useOnMount(() => {
    addRequiredClassToInputWrappers();
  });

  // Fix router's behavior of keeping the previous scroll position on next page. We keep it for going back.
  function scrollToTop() {
    const isPreviousPage = navigationType === "POP";
    if (isPreviousPage) return;

    const app = document.querySelector(".app");
    if (!app) return;
    app.scrollTo(0, 0);
  }

  // Reset the inline info message that's reused on a lot of pages.
  function resetMessage() {
    dispatch(hideMessage());
  }

  /**
   * CSS classes - open/close the sidebar and menu.
   */
  const isSidebarOpened = useSelector(
    (state) => state.settings.isSidebarOpened
  );
  const isMenuOpened = useSelector((state) => state.settings.isMenuOpened);
  const appClasses = `app ${isSidebarOpened ? "" : "sidebar-collapsed"} ${
    isMenuOpened ? "menu-opened" : ""
  } ${isUsingSafari ? "safari-browser" : ""}`;

  /**
   * Show loader when refetching user info.
   */
  if (userIsLoggedInButNoData) {
    return <PageLoader />;
  }

  /**
   * Redirect non-logged-in users to login page.
   */
  const isNotLoggedIn = !isLoggedIn && !user.id;
  if (isNotLoggedIn) {
    const currentPagePath = getPageHrefFromUrl();

    // Don't redirect when reseting password.
    if (currentPagePath === "/reset-password") {
      return (
        <div className="login-wrapper">
          <ResetPassword></ResetPassword>
          <MessagePopup />
        </div>
      );
    }

    if (currentPagePath !== "/") {
      navigate("/");
    }

    return (
      <div className="login-wrapper">
        <Login></Login>
        <MessagePopup />
      </div>
    );
  }

  return (
    <div className={appClasses} data-lang={currentLanguage}>
      <AppSidebar>
        <Logo isTitleVisible={true} isLight={true} />
        <MenuSidebar></MenuSidebar>
      </AppSidebar>

      <AppContent>
        <Routes>
          <Route path="/" element={homePage}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/workflows" element={<Workflows />}></Route>
          <Route
            path="/hospital-workflows"
            element={<HospitalWorkflows />}
          ></Route>
          <Route path="/workflow-builder" element={<WorkflowBuilder />}></Route>
          <Route
            path="/hospital-workflow-builder"
            element={<HospitalWorkflowBuilder />}
          ></Route>
          <Route path="/ask-advice" element={<AskForAdvice />}></Route>
          <Route path="/statistics" element={<Statistics />}></Route>
          <Route path="/notifications" element={<Notifications />}></Route>
          <Route path="/hospitals" element={<HospitalManagement />}></Route>
          <Route path="/users" element={<Users />}></Route>
          <Route path="/patient" element={<Patient />}></Route>
          <Route path="/patients" element={<Patients />}></Route>
          <Route
            path="/patients-archived"
            element={<PatientsArchived />}
          ></Route>
          <Route path="/nomenclatures" element={<Nomenclatures />}></Route>
          <Route path="/guidelines" element={<AntibioticGuidelines />}></Route>
          <Route path="/leaflets" element={<AntibioticLeaflets />}></Route>
          <Route path="/resistance" element={<AntibioticResistance />}></Route>
          <Route path="/event-log" element={<EventLog />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/consultations" element={<Consultations />}></Route>
          <Route path="*" element={<Error404 />}></Route>
        </Routes>
      </AppContent>

      <DialogConfirm />
      <MessagePopup />
      <ModalBackground />
    </div>
  );
}
