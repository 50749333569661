import { useTranslation } from "react-i18next";
import { Icon, AdviceSummary } from "../../index";

export function CardFollowUpAdvice({ advice, archiveAdvice }) {
  const { t } = useTranslation();
  const { diseaseHistory, adviceId } = advice;

  return (
    <div
      className="card card-patient"
      key={adviceId}
      onClick={() => archiveAdvice(advice)}
    >
      <div className="info">
        <div className="title">
          <h2>{diseaseHistory}</h2>
          <div className="buttons">
            <button
              className="archive"
              onClick={() => archiveAdvice(advice)}
              title={t("Archive this advice")}
            >
              <Icon name="clipboard-check" /> {t("Archive")}
            </button>
          </div>
        </div>

        <AdviceSummary advice={advice} />
      </div>
    </div>
  );
}
