import axiosInstance from "./axiosInstance";

export default class HttpRequests {
  post = (endpoint, data, config) => {
    return axiosInstance.post(endpoint, data, config);
  };

  put = (endpoint, data, config) => {
    return axiosInstance.put(endpoint, data, config);
  };

  get = (endpoint, config) => {
    return axiosInstance.get(endpoint, config);
  };

  delete = (endpoint, config) => {
    return axiosInstance.delete(endpoint, config);
  };
}
