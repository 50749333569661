import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../redux/reducers/index";
import { ApiRequests, showHttpRequestError } from "../../http";
import FormHospital from "./components/FormHospital";
import FormWard from "./components/FormWard";
import Ward from "./components/Ward";
import { Icon } from "../index";
import { orderActiveFirst, orderByName } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import "./ModalHospital.scss";

export default function ModalHospital({ fetchTableData }) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const { isVisible, hospital } = useSelector(
    (state) => state.modals.hospitalModal
  );
  const modalRef = useRef(null);
  useEffect(() => {
    modalRef.current.scrollTo(0, 0);
  }, [isVisible]);

  let editedHospitalId = null;
  if (hospital?.id) {
    editedHospitalId = hospital.id;
  }

  const isEditingHospital = Boolean(editedHospitalId);
  const isCreatingHospital = !isEditingHospital;

  /**
   * Create hooks for callbacks.
   */
  const useOnHospitalModalOpen = (callback) =>
    useEffect(() => {
      if (!isVisible) return;
      callback();
    }, [isVisible]);

  const useOnHospitalModalClose = (callback) =>
    useEffect(() => {
      if (isVisible) return;
      callback();
    }, [isVisible]);

  /**
   * If editing hospital - fetch all wards in it.
   */
  const [wards, setWards] = useState([]);
  const hasWards = Boolean(wards.length);
  const resetWards = () => setWards([]);

  const useOnHospitalChange = (fn) => useEffect(fn, [editedHospitalId]);
  useOnHospitalChange(() => {
    if (!isEditingHospital) return;
    fetchAndSetHospitalWards();
  });

  async function fetchAndSetHospitalWards() {
    const fetchedWards = await api
      .getHospitalWards(editedHospitalId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const orderedWards = orderByName(orderActiveFirst(fetchedWards));
    setWards(orderedWards);
  }

  function addUpdatedWard(updatedWard) {
    setWards(
      wards.map((ward) => {
        if (ward.id === updatedWard.id) {
          return updatedWard;
        }
        return ward;
      })
    );
  }

  /**
   * Setup the create/edit ward modal.
   */
  const [isWardFormOpen, setIsWardFormOpen] = useState(false);
  const [isCreatingWard, setIsCreatingWard] = useState(false);
  const [editedWard, setEditedWard] = useState(null);
  const [usersInWard, setUsersInWard] = useState([]);

  /**
   * Common fields between hospital and ward.
   * They are fetched and set in the hospital form and reused in the ward form.
   */
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);

  /**
   * Cleanup component state on unmount.
   */
  useOnHospitalModalClose(() => {
    resetWards();
    setIsWardFormOpen(false);
  });

  return (
    <div
      ref={modalRef}
      className={`modal modal-hospital ${isVisible ? "toggled" : "hidden"} ${
        isEditingHospital ? "editing" : "creating"
      }`}
    >
      <div className="modal-contents">
        <div className="hospital">
          <h2 className="modal-title">
            {isEditingHospital ? `${hospital.name}` : t("New hospital")}
          </h2>

          <FormHospital
            editedHospital={hospital}
            useOnHospitalModalOpen={useOnHospitalModalOpen}
            useOnHospitalModalClose={useOnHospitalModalClose}
            fetchTableData={fetchTableData}
            countries={countries}
            regions={regions}
            cities={cities}
            setCountries={setCountries}
            setRegions={setRegions}
            setCities={setCities}
          />
        </div>

        <div className="wards">
          <h2 className="modal-title">{t("Wards")}</h2>

          {isCreatingHospital && (
            <p>{t("Create a hospital to be able to add wards.")}</p>
          )}

          {isEditingHospital && !hasWards && <p>{t("No wards found.")}</p>}

          <div className="wards-list">
            {wards.map((ward) => (
              <Ward
                key={ward.id}
                ward={ward}
                wards={wards}
                setWards={setWards}
                setEditedWard={setEditedWard}
                setUsersInWard={setUsersInWard}
                setIsWardFormOpen={setIsWardFormOpen}
                addUpdatedWard={addUpdatedWard}
              />
            ))}
          </div>

          {isEditingHospital && (
            <button
              className="add-new add-ward"
              onClick={() => {
                setIsCreatingWard(true);
                setIsWardFormOpen(true);
              }}
            >
              <span className="icon icon-plus"></span> {t("Add new ward")}
            </button>
          )}

          <FormWard
            isOpened={isWardFormOpen}
            editedWard={editedWard}
            setEditedWard={setEditedWard}
            usersInWard={usersInWard}
            setUsersInWard={setUsersInWard}
            wards={wards}
            setWards={setWards}
            addUpdatedWard={addUpdatedWard}
            cities={cities}
            isCreatingWard={isCreatingWard}
            setIsCreatingWard={setIsCreatingWard}
            setIsWardFormOpen={setIsWardFormOpen}
          />
        </div>
      </div>

      <div className="modal-close" onClick={() => dispatch(hideModal())}>
        <Icon name="cross" />
      </div>
    </div>
  );
}
