import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

export function useOnMount(fn) {
  useEffect(fn, []);
}

export function useOnNewPage(fn) {
  const location = useLocation();
  useEffect(fn, [location.pathname]);
}

export function useOnUserChange(fn) {
  const user = useSelector((state) => state.user);
  useEffect(fn, [user?.id]);
}

export function useOnLanguageChange(fn) {
  const user = useSelector((state) => state.user);
  useEffect(fn, [user?.languageAlias]);
}

export function useGetPermissionsForPage(page) {
  const user = useSelector((state) => state.user);
  const userPermissions = user.permissions?.[page];
  const defaultPermissions = {
    canView: false,
    canCreate: false,
    canUpdate: false,
    canDelete: false,
    canUpload: false,
    canDownload: false,
    canImport: false,
    canResetPassword: false,
    canUpdateHospital: false,
    canUpdateWard: false,
  };
  const pagePermissions = Object.assign(defaultPermissions, userPermissions);
  return pagePermissions;
}
