/**
 * For accessing the redux store outside of react components.
 * Import the injectedStore and use:
 *   - injectedStore.getState().yourState
 *   - injectedStore.dispatch(yourAction)
 *
 * See: https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */

export let injectedStore;

const injectStore = (_store) => {
  injectedStore = _store;
};

export default injectStore;
