import Icon from "../Icon/Icon";
import Select from "react-select";
import { smallSelectStylesWithoutArrow } from "../../styles/selectStyles";
import { useTranslation } from "react-i18next";
import "./MedicamentSimple.scss";

export default function MedicamentSimple({
  isPrevention,
  medicament,
  medicaments,
  doseMeasures,
  prescribedMedicaments,
  setPrescribedMedicaments,
  removePrescribedMedicament,
}) {
  const { t } = useTranslation();
  const { medicamentId, dose, doseMeasureId, frequency, durationOfTreatment } =
    medicament;

  const medicamentIndex = prescribedMedicaments.findIndex(
    (medicament) => medicament.medicamentId === medicamentId
  );

  const selectedMedicament = (medicament) => medicament.value === medicamentId;
  const selectedDoseMeasure = (doseMeasure) =>
    doseMeasure.value === doseMeasureId;

  /**
   * The user can only add medicaments that are not already added to the treatment.
   */
  const isMedicamentDisabledForSelection = (medicament) =>
    prescribedMedicaments.some(
      (prescribedMedicament) =>
        prescribedMedicament.medicamentId === medicament.value
    );

  function updateMedicamentProp(prop, value) {
    const newTreatmentMedicaments = [...prescribedMedicaments];
    newTreatmentMedicaments[medicamentIndex][prop] = value;
    setPrescribedMedicaments([...newTreatmentMedicaments]);
  }

  function updateMedicamentName(medicamentId) {
    const medicamentName = medicaments.find(
      (medicament) => medicament.value === medicamentId
    ).label;
    updateMedicamentProp("medicamentName", medicamentName);
  }

  function updateDoseMeasureAbr(doseMeasureId) {
    const doseMeasureAbr = doseMeasures.find(
      (doseMeasure) => doseMeasure.value === doseMeasureId
    ).label;
    updateMedicamentProp("doseMeasureAbr", doseMeasureAbr);
  }

  function updateFrequencyProp(prop, value) {
    const newTreatmentMedicaments = [...prescribedMedicaments];
    newTreatmentMedicaments[medicamentIndex].frequency[prop] = value;
    setPrescribedMedicaments([...newTreatmentMedicaments]);
  }

  return (
    <div className="medicament-simple">
      {isPrevention && (
        <div className="flex-input-wrapper">
          <div className="input-wrapper iw-name">
            <label htmlFor="medicament">{t("Name:")}</label>
            <Select
              name="medicament"
              options={medicaments.map((medicament) => ({
                ...medicament,
                isDisabled: prescribedMedicaments.some(
                  (prescribedMedicament) =>
                    prescribedMedicament.medicamentId === medicament.value
                ),
              }))}
              isSearchable={true}
              required
              styles={smallSelectStylesWithoutArrow}
              openMenuOnClick
              openMenuOnFocus
              value={medicaments.find(selectedMedicament) ?? null}
              onChange={(e) => updateMedicamentProp("medicamentId", e.value)}
              menuPlacement="auto"
              isOptionDisabled={isMedicamentDisabledForSelection}
            />
          </div>

          <div className="input-wrapper iw-dose">
            <label htmlFor="dose">{t("Dose:")}</label>
            <input
              type="number"
              name="dose"
              id="dose"
              required
              value={dose}
              onChange={(e) => updateMedicamentProp("dose", e.target.value)}
              placeholder={"0"}
              min={0}
              className="small"
            />
          </div>

          <div className="input-wrapper iw-measure">
            <label htmlFor="doseMeasure">{t("Measure:")}</label>
            <Select
              name="doseMeasure"
              options={doseMeasures}
              isSearchable={true}
              required
              styles={smallSelectStylesWithoutArrow}
              openMenuOnClick
              openMenuOnFocus
              value={doseMeasures.find(selectedDoseMeasure) ?? null}
              onChange={(e) => updateMedicamentProp("doseMeasureId", e.value)}
              menuPlacement="auto"
            />
          </div>

          <div
            className="modal-close"
            title={t("Remove medicament")}
            onClick={() => removePrescribedMedicament(medicamentId)}
          >
            <Icon name="cross" />
          </div>
        </div>
      )}

      {!isPrevention && (
        <div className="flex-input-wrapper">
          <div className="input-wrapper iw-name">
            <label htmlFor="medicament">{t("Name:")}</label>
            <Select
              name="medicament"
              options={medicaments.map((medicament) => ({
                ...medicament,
                isDisabled: prescribedMedicaments.some(
                  (prescribedMedicament) =>
                    prescribedMedicament.medicamentId === medicament.value
                ),
              }))}
              isSearchable={true}
              required
              styles={smallSelectStylesWithoutArrow}
              openMenuOnClick
              openMenuOnFocus
              value={medicaments.find(selectedMedicament) ?? null}
              onChange={(e) => {
                updateMedicamentProp("medicamentId", e.value);
                updateMedicamentName(e.value);
              }}
              menuPlacement="auto"
            />
          </div>

          <div className="input-wrapper iw-times">
            <label htmlFor="time">{t("Times:")}</label>
            <input
              type="number"
              name="time"
              id="time"
              required
              value={frequency.time}
              onChange={(e) => updateFrequencyProp("time", e.target.value)}
              placeholder={"0"}
              min={0}
              className="small"
            />
          </div>

          <span>{t("times x")}</span>

          <div className="input-wrapper iw-quantity">
            <label htmlFor="quantity">{t("Quantity:")}</label>
            <input
              type="number"
              name="quantity"
              id="quantity"
              required
              value={frequency.quantity}
              onChange={(e) => updateFrequencyProp("quantity", e.target.value)}
              placeholder={"0"}
              min={0}
              className="small"
            />
          </div>

          <span>{t("per day of")}</span>

          <div className="input-wrapper iw-dose">
            <label htmlFor="dose">{t("Dose:")}</label>
            <input
              type="number"
              name="dose"
              id="dose"
              required
              value={dose}
              onChange={(e) => updateMedicamentProp("dose", e.target.value)}
              placeholder={"200"}
              min={0}
              className="small"
            />
          </div>

          <div className="input-wrapper iw-measure">
            <label htmlFor="doseMeasure">{t("Measure:")}</label>
            <Select
              name="doseMeasure"
              options={doseMeasures}
              isSearchable={true}
              required
              styles={smallSelectStylesWithoutArrow}
              openMenuOnClick
              openMenuOnFocus
              value={doseMeasures.find(selectedDoseMeasure) ?? null}
              onChange={(e) => {
                updateDoseMeasureAbr(e.value);
                updateMedicamentProp("doseMeasureId", e.value);
              }}
              menuPlacement="auto"
            />
          </div>

          <span>{t("for")}</span>

          <div className="input-wrapper iw-duration">
            <label htmlFor="durationOfTreatment">
              {t("Duration in days:")}
            </label>
            <input
              type="text"
              name="durationOfTreatment"
              id="durationOfTreatment"
              placeholder="0"
              required
              value={durationOfTreatment}
              onChange={(e) =>
                updateMedicamentProp("durationOfTreatment", e.target.value)
              }
              className="small"
            />
          </div>

          <span>{t("days")}</span>

          <div
            className="modal-close"
            title={t("Remove medicament")}
            onClick={() => removePrescribedMedicament(medicamentId)}
          >
            <Icon name="cross" />
          </div>
        </div>
      )}
    </div>
  );
}
