import { Container } from "../index";
import "./AppContent.scss";

export default function AppContent({ children }) {
  return (
    <main>
      <Container className="content">{children}</Container>
    </main>
  );
}
