import { useDispatch, useSelector } from "react-redux";
import {
  showSuccessMessage,
  showErrorMessage,
  setUser,
} from "../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../http";
import { FormMessage, PageTitle } from "../../components";
import {
  isFormValid,
  convertToBackendObject,
  showButtonLoader,
  hideButtonLoader,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.modals.formMessage);
  const user = useSelector((state) => state.user);
  const {
    username,
    firstName,
    middleName,
    lastName,
    email,
    phone,
    hospitalName,
    hospitalWardName,
    role,
  } = user;
  const isAdmin = user.role === "ADMIN";

  /**
   * Updating user profile.
   */
  const handleEditProfile = async (e) => {
    e.preventDefault();
    const profileForm = e.target;

    if (!isFormValid(profileForm)) {
      dispatch(showErrorMessage(t("Some fields are invalid.")));
      return;
    }

    showButtonLoader();
    const profileData = convertToBackendObject(profileForm);

    api
      .updateBasicProfileData(profileData)
      .then((response) => {
        const updatedUser = response.data;
        dispatch(setUser({ ...user, ...updatedUser }));
        dispatch(
          showSuccessMessage({
            content: t("Profile updated successfully."),
            id: "profileForm",
          })
        );
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  };

  return (
    <div className="profile">
      <PageTitle title={t("Profile")} />

      <form onSubmit={handleEditProfile}>
        <div className="input-wrapper">
          <label htmlFor="firstName">{t("First Name:")}</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            defaultValue={firstName}
            maxLength="50"
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="middleName">{t("Middle Name:")}</label>
          <input
            type="text"
            name="middleName"
            id="middleName"
            defaultValue={middleName}
            maxLength="50"
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="lastName">{t("Last Name:")}</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            defaultValue={lastName}
            maxLength="50"
          />
        </div>

        <div className="input-wrapper">
          {" "}
          <label htmlFor="username">{t("Username:")}</label>
          <input
            type="text"
            name="username"
            id="username"
            defaultValue={username}
            minLength="3"
            maxLength="50"
            readOnly={true}
            required
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="email">{t("E-mail address:")}</label>
          <input
            type="email"
            name="email"
            id="email"
            defaultValue={email}
            required
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="phone">{t("Phone number:")}</label>
          <input type="number" name="phone" id="phone" defaultValue={phone} />
        </div>

        {!isAdmin && (
          <>
            <div className="input-wrapper">
              <label htmlFor="hospitalName">{t("Hospital:")}</label>
              <input
                type="text"
                name="hospitalName"
                id="hospitalName"
                defaultValue={hospitalName}
                readOnly={true}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="hospitalWardName">{t("Hospital ward:")}</label>
              <input
                type="text"
                name="hospitalWardName"
                id="hospitalWardName"
                defaultValue={hospitalWardName}
                readOnly={true}
              />
            </div>
          </>
        )}

        <div className="input-wrapper">
          <label htmlFor="role">{t("User role:")}</label>
          <input
            type="text"
            name="role"
            id="role"
            defaultValue={role}
            readOnly={true}
          />
        </div>

        {message.content && <FormMessage id="profileForm" />}

        <button type="submit">{t("Save Profile")}</button>
      </form>
    </div>
  );
}
