import { useTranslation } from "react-i18next";
import { ApiRequests, showHttpRequestError } from "../../http";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useOnMount } from "../../hooks";
import Builder from "../../components/Builder/Builder";
import { useGetPermissionsForPage } from "../../hooks/hooks";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../components";
import "./WorkflowBuilder.scss";

export default function HospitalWorkflowBuilder() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const navigate = useNavigate();

  /**
   * User permissions.
   */
  const { canCreate, canView, canUpdate } =
    useGetPermissionsForPage("workflowInstance");

  const [buildingWorkflow, setBuildingWorkflow] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const [draftWorkflow, setDraftWorkflow] = useState(null);
  const [isEditingDraft, setIsEditingDraft] = useState(false);
  const [isComparingVersions, setIsComparingVersions] = useState(false);

  /**
   * Comparing versions.
   */
  const [workflowToCompare, setWorkflowToCompare] = useState(null);
  const [draftWorkflowToCompare, setDraftWorkflowToCompare] = useState(null);

  /**
   * Fetch workflow.
   */
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useOnMount(() => {
    const workflowInstanceId = params.get("id");
    fetchWorkflowInstance(workflowInstanceId);
  });

  async function fetchWorkflowInstance(workflowInstanceId) {
    const workflow = await getWorkflowInstance(workflowInstanceId);
    setWorkflow(workflow);
    setBuildingWorkflow(workflow);
    setIsEditingDraft(false);
    setWorkflowToCompare(workflow);

    if (workflow?.draftVersion) {
      fetchDraftWorkflow(workflowInstanceId);
    }
  }

  async function fetchDraftWorkflow(workflowInstanceId) {
    const draftWorkflow = await getDraftWorkflowInstance(workflowInstanceId);
    setDraftWorkflow(draftWorkflow);
    setDraftWorkflowToCompare(draftWorkflow);
  }

  async function getWorkflowInstance(workflowInstanceId) {
    return api
      .getWorkflowInstance(workflowInstanceId)
      .then((response) => response.data)
      .catch((error) => {});
  }

  async function getDraftWorkflowInstance(workflowInstanceId) {
    return api
      .getDraftWorkflowInstance(workflowInstanceId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Workflow instance actions.
   */
  async function updateApiWorkflow(w) {
    const dto = {
      workflowInstanceId: w.workflowInstanceId,
      workflowDto: w.workflowDto,
    };

    api
      .updateWorkflowInstance(dto)
      .then((response) => {
        setDraftWorkflow(response.data);
        setBuildingWorkflow(response.data);
        setWorkflow({
          ...workflow,
          draftVersion: response.data.draftVersion,
        });
        setIsEditingDraft(true);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function autoUpdateApiWorkflow(w) {
    const dto = {
      workflowInstanceId: w.workflowInstanceId,
      workflowDto: w.workflowDto,
    };

    api
      .updateWorkflowInstance(dto)
      .then((response) => {
        setDraftWorkflow(response.data);
        setWorkflow({
          ...workflow,
          draftVersion: response.data.draftVersion,
        });
        //setIsEditingDraft(true);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function completeApiWorkflow(w) {
    const dto = {
      workflowInstanceId: w.workflowInstanceId,
    };

    api
      .completeWorkflowInstance(dto)
      .then((response) => {
        setWorkflow(response.data);
        setBuildingWorkflow(response.data);
        setDraftWorkflow(null);
        setIsEditingDraft(false);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function deleteApiWorkflow(w) {
    if (w.draft) {
      await api
        .deleteDraftWorkflowInstance(w.workflowInstanceId)
        .then((response) => {
          if (w.workflowVersion) {
            fetchWorkflowInstance(w.workflowInstanceId);
          } else {
            navigate("/workflows");
          }
        })
        .catch((error) => showHttpRequestError(error));
      return;
    }

    api
      .deleteWorkflowInstance(w.workflowInstanceId)
      .then((response) => {
        navigate("/workflows");
      })
      .catch((error) => showHttpRequestError(error));
  }

  function firstStepAdvice(dto) {
    return api.testWorkflowInstanceFirstStep(
      dto.workflow.workflowInstanceId,
      dto.workflow.draft ? "draft" : ""
    );
  }

  function nextStepAdvice(dto) {
    const toSend = {
      workflowInstanceId: dto.workflow.workflowInstanceId,
      json: dto.json,
    };
    return api.testWorkflowInstanceNextStep(
      toSend,
      dto.workflow.draft ? "draft" : ""
    );
  }

  if (workflow === null && draftWorkflow === null) {
    return null;
  }

  return (
    <div className="workflow-builder">
      {(buildingWorkflow?.workflowVersion ||
        buildingWorkflow?.draftVersion) && (
        <>
          <PageTitle title={t("Editing Workflow")} />

          <div className="tabs tabs-builder">
            <div className="tabs-header">
              {buildingWorkflow?.workflowVersion && (
                <button
                  className={`${
                    !isEditingDraft && !isComparingVersions
                      ? "secondary active"
                      : ""
                  }`}
                  type="button"
                  onClick={() => {
                    setBuildingWorkflow(workflow);
                    setIsEditingDraft(false);
                    setIsComparingVersions(false);
                  }}
                >
                  v{buildingWorkflow.workflowVersion} {t("completed")}
                </button>
              )}

              {buildingWorkflow?.draftVersion && (
                <button
                  className={`${isEditingDraft ? "secondary active" : ""}`}
                  type="button"
                  onClick={() => {
                    setBuildingWorkflow(draftWorkflow);
                    setIsEditingDraft(true);
                    setIsComparingVersions(false);
                  }}
                >
                  v{buildingWorkflow.draftVersion} {t("draft")}
                </button>
              )}

              {buildingWorkflow?.draftVersion && (
                <button
                  className={`${isComparingVersions ? "secondary active" : ""}`}
                  type="button"
                  onClick={() => {
                    setBuildingWorkflow(workflow);
                    setIsComparingVersions(true);
                    setIsEditingDraft(false);
                  }}
                >
                  {t("Compare")}
                </button>
              )}
            </div>
          </div>
        </>
      )}

      <Builder
        canCreate={canCreate}
        canUpdate={canUpdate}
        canView={canView}
        isEditingDraft={isEditingDraft}
        isComparingVersions={isComparingVersions}
        setWorkflow={setBuildingWorkflow}
        workflow={buildingWorkflow}
        draftWorkflowToCompare={draftWorkflowToCompare}
        setDraftWorkflowToCompare={setDraftWorkflowToCompare}
        workflowToCompare={workflowToCompare}
        setWorkflowToCompare={setWorkflowToCompare}
        updateApiWorkflow={updateApiWorkflow}
        autoUpdateApiWorkflow={autoUpdateApiWorkflow}
        completeApiWorkflow={completeApiWorkflow}
        deleteApiWorkflow={deleteApiWorkflow}
        firstStepAdvice={firstStepAdvice}
        nextStepAdvice={nextStepAdvice}
      />
    </div>
  );
}
