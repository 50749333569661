import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useOnMount } from "../../hooks";
import cloneDeep from "lodash/cloneDeep";
import {
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
} from "../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../http";
import {
  Advice,
  DialogRejectAdvice,
  InputCheckbox,
  PageTitle,
} from "../../components";
import Select from "react-select";
import { selectStyles, smallSelectStyles } from "../../styles/selectStyles";
import { useTranslation } from "react-i18next";
import "./AskForAdvice.scss";

export default function AskForAdvice() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  /**
   * Setup for whom is this advice.
   */
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const diseaseHistory = params.get("history");

  const emptyAdvice = {
    patientHd: diseaseHistory ? diseaseHistory : "",
    diseaseId: "",
    isConsultation: false,
    adviceId: "",
  };

  const [adviceFor, setAdviceFor] = useState({
    ...emptyAdvice,
  });

  const setAdviceForField = (e) => {
    const { name: field, value } = e.target;
    setAdviceFor({
      ...adviceFor,
      [field]: value,
    });
  };

  /**
   * Consultation advice.
   */
  const [isAdviceConsultation, setIsAdviceConsultaion] = useState(false);
  const [adviceForWardId, setAdviceForWardId] = useState(null);

  const handleConsultationChange = (e) => {
    setIsAdviceConsultaion(e.target.checked);
  };
  const selectedWard = (ward) => ward.value === adviceForWardId;

  /**
   * Fetch hospital wards if this is a consultation.
   */
  const [wards, setWards] = useState([]);
  const hasWards = Boolean(wards.length);

  const useIfAdviceIsConsultation = (fn) =>
    useEffect(fn, [isAdviceConsultation]);

  useIfAdviceIsConsultation(() => {
    if (!isAdviceConsultation || hasWards) return;
    fetchAndSetHospitalWards(user.hospitalId);
  });

  async function fetchAndSetHospitalWards(hospitalId) {
    const wards = await getWards(hospitalId);
    const wardsForSelect = wards
      .filter((ward) => ward.id !== user.hospitalWardId) // Hide user's own ward (can't consult it)
      .map((ward) => ({
        value: ward.id,
        label: ward.name,
      }));
    setWards(wardsForSelect);
  }

  async function getWards(hospitalId) {
    return api
      .getActiveHospitalWards(hospitalId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Selecting advice disease.
   */
  const [diseases, setDiseases] = useState([]);
  const hasDiseases = Boolean(diseases.length);
  const [selectedDiseaseId, setSelectedDiseaseId] = useState(null);
  const selectedDisease = (disease) => disease.value === adviceFor.diseaseId;

  useOnMount(() => {
    fetchAndSetDiseases();
  });

  async function fetchAndSetDiseases(e) {
    const fetchedDiseases = await getDiseasesForAdvice();
    const diseasesForSelect = fetchedDiseases.map((disease) => ({
      value: disease.id,
      label: disease.name,
    }));
    setDiseases(diseasesForSelect);
  }

  async function getDiseasesForAdvice() {
    return api
      .getDiseasesForAdvice()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Advice questionnaire.
   */
  const [isQuestionnaireStarted, setIsQuestionnaireStarted] = useState(false);
  const isStartButtonDisabled = !adviceFor.diseaseId || !adviceFor.patientHd;
  const isAdviceForFieldDisabled = isQuestionnaireStarted;
  const questionsClosed = adviceFor.json?.questions;
  const [questionActive, setQuestionActive] = useState(null);

  const setQuestionInputAnswer = (e) => {
    if (questionActive.answer.type !== "input") {
      return;
    }
    questionActive.answer.value = e.target.value;
  };

  const setQuestionListAnswer = (e) => {
    if (questionActive.answer.type !== "list") {
      return;
    }
    for (let i = 0; i < questionActive.answer.value.length; i++) {
      let answer = questionActive.answer.value[i];
      if (answer.answerId === e.target.value) {
        answer.selected = true;
      } else {
        answer.selected = false;
      }
    }
  };

  const changeActiveQuestion = (e) => {
    if (questionActive.answer.type === "list") {
      setQuestionListAnswer(e);
    }
    if (questionActive.answer.type === "input") {
      setQuestionInputAnswer(e);
    }
    setQuestionActive(cloneDeep(questionActive));
  };

  const fetchQuestionActive = (questionId) => {
    for (let i = 0; i < adviceFor.json.questions.length; i++) {
      let q = adviceFor.json.questions[i];
      q.index = i + 1;
      if (q.questionId === questionId) {
        setQuestionActive(q);
        break;
      }
    }
  };

  async function startQuestionnaire(e) {
    e.preventDefault();

    startAdvice()
      .then((response) => {
        const createdAdvice = response.data;

        setAdviceFor((adviceFor) => ({
          ...adviceFor,
          json: createdAdvice.json,
          adviceId: createdAdvice.adviceId,
          status: createdAdvice.status,
          additional: createdAdvice.additional,
        }));

        if (createdAdvice.status === "DRAFT") {
          let q =
            createdAdvice.json?.questions[
              createdAdvice.json?.questions.length - 1
            ];
          q.index = createdAdvice.json?.questions.length;
          setQuestionActive(q);
        } else {
          setQuestionActive(null);
        }

        setIsQuestionnaireStarted(true);
      })
      .catch((error) => showHttpRequestError(error));
  }

  // Starting the advice differs if it's a consultation or not.
  async function startAdvice() {
    if (isAdviceConsultation) {
      return api.createConsultationAdvice({
        diseaseHistory: adviceFor.patientHd,
        diseaseId: adviceFor.diseaseId,
        hospitalWardId: adviceForWardId,
      });
    }

    return api.createMainAdvice({
      diseaseHistory: adviceFor.patientHd,
      diseaseId: adviceFor.diseaseId,
    });
  }

  async function discardAdvice(e) {
    e.preventDefault();
    const cancelDialog = {
      title: t("Delete this advice?"),
      message: t("Any progress you have made will be lost."),
      buttonConfirmText: t("Delete advice"),
      buttonCancelText: t("Back"),
      isWarning: true,
    };

    dispatch(showDialog(cancelDialog));
    const isCancelConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isCancelConfirmed) return;

    api
      .deleteAdvice(adviceFor.adviceId)
      .then((response) => {
        dispatch(showSuccessPopup(t(`Advice deleted successfully.`)));
        window.setTimeout(() => {
          startNewAdvice();
        }, 1000);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function showNextQuestion(e) {
    e.preventDefault();

    for (let i = 0; i < adviceFor.json.questions.length; i++) {
      let question = adviceFor.json.questions[i];
      if (question.questionId === questionActive.questionId) {
        question.answer = questionActive.answer;
      }
    }

    const toSend = {
      adviceId: adviceFor.adviceId,
      json: adviceFor.json,
    };

    api
      .updateAdvice(toSend)
      .then((response) => {
        const updatedAdvice = response.data;
        setAdviceFor((adviceFor) => ({
          ...adviceFor,
          json: updatedAdvice.json,
          adviceId: updatedAdvice.adviceId,
          status: updatedAdvice.status,
          additional: updatedAdvice.additional,
        }));
        if (updatedAdvice.status === "DRAFT") {
          let q =
            updatedAdvice.json?.questions[
              updatedAdvice.json?.questions.length - 1
            ];
          q.index = updatedAdvice.json?.questions.length;
          setQuestionActive(q);
        } else {
          setQuestionActive(null);
        }
      })
      .catch((error) => showHttpRequestError(error));
  }

  function sortYesAnswersFirst(answers) {
    const sortedAnswers = [...answers].sort(function (a, b) {
      if (a.answerName === "Yes" && b.answerName !== "Yes") {
        return -1;
      }
      if (a.answerName !== "Yes" && b.answerName === "Yes") {
        return 1;
      }
      return 0;
    });
    return sortedAnswers;
  }

  const hasClosedQuestions = !!questionsClosed;
  const hasActiveQuestion = !!questionActive;
  const hasAdvice = Boolean(adviceFor?.json?.advice);

  /**
   * Accept ot reject advice.
   */
  const [isRejectAdviceVisible, setIsRejectAdviceVisible] = useState(false);
  const [rejectReasons, setRejectReasons] = useState([]);

  useOnMount(() => {
    fetchAndSetReasonsForReject();
  });

  function fetchAndSetReasonsForReject() {
    api
      .getAdviceRejectReasons()
      .then((response) => {
        setRejectReasons(response.data);
      })
      .catch((error) => showHttpRequestError(error));
  }

  async function showRejectAdvice(e) {
    e.preventDefault();
    setIsRejectAdviceVisible(true);
  }

  /**
   * Guide the user to accept/reject the advice.
   */
  const [isAdviceAccepted, setIsAdviceAccepted] = useState(false);
  const [isAdviceRejected, setIsAdviceRejected] = useState(false);
  const isAdviceFinalized = isAdviceAccepted || isAdviceRejected;

  const [isChangingAnswers, setIsChangingAnswers] = useState(false);
  const isAdviceBackgroundVisible =
    !isChangingAnswers &&
    adviceFor.status === "COMPLETED" &&
    !isAdviceFinalized;

  async function askIfWantsToChangeQuestions() {
    const dialog = {
      title: t("Advice will be saved as draft"),
      message: t(
        "You can accept or reject this advice later. It will be visible from the main dashboard."
      ),
      buttonConfirmText: t("Finish advice"),
      buttonCancelText: t("Back"),
      isWarning: true,
    };

    dispatch(showDialog(dialog));
    const isCancelConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (isCancelConfirmed) return;

    setIsChangingAnswers(true);
  }

  async function acceptAdvice(e, acceptedIndex) {
    e.preventDefault();
    const dto = {
      adviceId: adviceFor.adviceId,
      treatment: adviceFor?.json?.advice.treatments[acceptedIndex],
    };
    api
      .acceptAdvice(dto)
      .then((response) => {
        setIsAdviceAccepted(true);
        dispatch(showSuccessPopup(t("Advice was accepted.")));
      })
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Start new advice from old one.
   */
  function startNewAdvice() {
    setAdviceFor({
      ...emptyAdvice,
    });
    setIsQuestionnaireStarted(false);
    setQuestionActive(null);
    setIsAdviceAccepted(false);
    setIsAdviceRejected(false);
    setIsChangingAnswers(false);
    setIsRejectAdviceVisible(false);
  }

  /**
   * If coming to this page with "adviceId" URL param - open it.
   */
  const adviceIdToOpen = params.get("adviceId");

  useOnMount(() => {
    if (!adviceIdToOpen) return;
    loadAdviceToComplete(adviceIdToOpen);
  });

  function loadAdviceToComplete(adviceId) {
    api
      .getAdvice(adviceId)
      .then((response) => {
        const fetchedAdvice = response.data;

        //Questionnaire is always started for fetched advices.
        setIsQuestionnaireStarted(true);

        // Display advice data.
        setAdviceFor((adviceFor) => ({
          ...adviceFor,
          diseaseId: fetchedAdvice.diseaseId,
          patientHd: fetchedAdvice.diseaseHistory,
          json: fetchedAdvice.json,
          adviceId: fetchedAdvice.adviceId,
          status: getFetchedAdviceStatus(fetchedAdvice),
          additional: fetchedAdvice.additional,
          isConsultation: fetchedAdvice.consultation,
          owner: fetchedAdvice.owner,
          ...(fetchedAdvice.consultantStatus && {
            consultantStatus: fetchedAdvice.consultantStatus,
          }),
          ...(fetchedAdvice.consultedWardName && {
            consultedWardName: fetchedAdvice.consultedWardName,
          }),
          ...(fetchedAdvice.consultedWardId && {
            consultedWardId: fetchedAdvice.consultedWardId,
          }),
          ...(fetchedAdvice.consultantWardName && {
            consultantWardName: fetchedAdvice.consultantWardName,
          }),
          ...(fetchedAdvice.consultantWardId && {
            consultantWardId: fetchedAdvice.consultantWardId,
          }),
          ...(fetchedAdvice.diseaseName && {
            diseaseName: fetchedAdvice.diseaseName,
          }),
          ...(fetchedAdvice.mainStatus && {
            mainStatus: fetchedAdvice.mainStatus,
          }),
          ...(fetchedAdvice.surveyQuestions && {
            surveyQuestions: fetchedAdvice.surveyQuestions,
          }),
          ...(fetchedAdvice.rejectReasons && {
            rejectReasons: fetchedAdvice.rejectReasons,
          }),
        }));

        setIsAdviceConsultaion(fetchedAdvice.consultation);
        setAdviceForWardId(fetchedAdvice.consultedWardId);

        // If advice is consultation - set the ward.
        if (fetchedAdvice.consultation) {
          setWards([
            {
              value: fetchedAdvice.consultedWardId,
              label: fetchedAdvice.consultedWardName,
            },
          ]);
        }

        // Set whether advice is accepted or rejected.
        const isFetchedAdviceAccepted =
          (fetchedAdvice.consultation &&
            !fetchedAdvice.owner &&
            fetchedAdvice.consultantStatus === "ACCEPTED" &&
            fetchedAdvice.mainStatus === "ACCEPTED") ||
          (!fetchedAdvice.consultation &&
            fetchedAdvice.mainStatus === "ACCEPTED") ||
          (fetchedAdvice.consultation &&
            fetchedAdvice.owner &&
            fetchedAdvice.consultantStatus === "ACCEPTED");

        const isFetchedAdviceRejected =
          (fetchedAdvice.consultation &&
            fetchedAdvice.consultantStatus === "REJECTED") ||
          (!fetchedAdvice.consultation &&
            fetchedAdvice.mainStatus === "REJECTED");

        if (isFetchedAdviceAccepted) {
          setIsAdviceAccepted(true);
        }

        if (isFetchedAdviceRejected) {
          setIsAdviceRejected(true);
        }

        // Set the active question.
        if (fetchedAdvice.status === "DRAFT") {
          let q =
            fetchedAdvice.json?.questions[
              fetchedAdvice.json?.questions.length - 1
            ];
          q.index = fetchedAdvice.json?.questions.length;
          setQuestionActive(q);
        } else {
          setQuestionActive(null);
        }

        // Set the disease
        const isReceivedConsultation =
          fetchedAdvice.consultantStatus === "ACCEPTED";

        if (isReceivedConsultation) {
          setDiseases([
            {
              value: fetchedAdvice.diseaseId,
              label: fetchedAdvice.diseaseName,
            },
          ]);
          setSelectedDiseaseId(fetchedAdvice.diseaseId);
        }
      })
      .catch((error) => showHttpRequestError(error));
  }

  function getFetchedAdviceStatus(advice) {
    const isConsultationWaitingForDecision =
      advice.consultation &&
      advice.consultantStatus === "ACCEPTED" &&
      advice.mainStatus === null;

    // These need to be accepted or rejected.
    if (isConsultationWaitingForDecision) {
      return "COMPLETED";
    }

    if (advice.consultation) {
      return advice.consultantStatus ?? advice.status;
    }

    return advice.mainStatus ?? advice.status;
  }

  function ЕmptyAdvicePlaceholder() {
    return (
      <div className="advice placeholder">
        <div className="advice-header">
          <h2 className="advice-header-title">
            {t("Start the questionnaire...")}
          </h2>
        </div>
        <div className="treatment-wrapper">
          <h2 className="treatment-title">{t("Medicament of first choice")}</h2>
        </div>
        <div className="treatment-wrapper">
          <h2 className="treatment-title">{t("Alternatives")}</h2>
        </div>
        <div className="treatment-wrapper">
          <h2 className="treatment-title">{t("Combinations")}</h2>
        </div>
        <div className="side-effects">
          <h2>{t("Side effects")}</h2>
        </div>
        <div className="interactions">
          <h2>{t("Drug interactions")}</h2>
        </div>
        <div className="bacteria">
          <h2>{t("Usual microorganisms")}</h2>
        </div>
        <div className="reminders">
          <h2>{t("Reminders")}</h2>
        </div>
      </div>
    );
  }

  /**
   * For displaying the correct title and components.
   */
  const viewingReceivedConsultation =
    !adviceFor?.owner && adviceFor?.consultantStatus === "ACCEPTED";

  const viewingOwnConsultation =
    adviceFor?.owner && adviceFor?.isConsultation === true;
  // &&
  // !adviceFor?.consultantStatus === "ACCEPTED";

  const viewingOwnFinishedOldAdvice =
    (adviceFor?.isConsultation === false ||
      adviceFor?.isConsultation === true) &&
    (adviceFor?.status === "ACCEPTED" || adviceFor?.status === "REJECTED") &&
    !adviceFor?.consultantStatus;

  const viewingOwnUnfinishedAdvice =
    !(adviceFor?.status === "ACCEPTED" || adviceFor?.status === "REJECTED") &&
    !(adviceFor?.consultantStatus === "ACCEPTED");

  /**
   * Page title.
   */
  let title = "Ask For Advice";

  if (viewingReceivedConsultation) {
    title =
      t("Received consultation from") + " " + adviceFor.consultantWardName;
  }

  if (viewingOwnConsultation) {
    title = `${t("Consultation for")} ${wards.find(selectedWard)?.label}`;
  }

  if (viewingOwnFinishedOldAdvice) {
    title = t("Advice Review");
  }

  if (viewingOwnUnfinishedAdvice) {
    title = t("Ask For Advice");
  }

  return (
    <div
      className={`ask-for-advice ${
        isQuestionnaireStarted ? "questionnaire-started" : ""
      } ${isAdviceFinalized ? "advice-finalized" : ""} ${
        isAdviceConsultation ? "advice-consultation" : ""
      }`}
    >
      <PageTitle title={title} />

      <div className="advice-wrapper">
        <div className="questions">
          <div className="advice-for">
            <div className="input-wrapper patient-hd">
              <label htmlFor="themeRed">{t("Patient HD:")}</label>
              {!isAdviceFinalized && (
                <input
                  name="patientHd"
                  placeholder={t("HD number")}
                  value={adviceFor.patientHd}
                  onChange={(e) => setAdviceForField(e)}
                  required
                  disabled={isAdviceForFieldDisabled}
                />
              )}

              {isAdviceFinalized && (
                <span className="patient-info">{adviceFor.patientHd}</span>
              )}
            </div>

            <div className="input-wrapper disease">
              <label htmlFor="themeRed">{t("Disease:")}</label>
              {!isAdviceFinalized && (
                <Select
                  name="diseaseId"
                  options={diseases}
                  isSearchable={true}
                  required
                  styles={selectStyles}
                  openMenuOnClick
                  openMenuOnFocus
                  isDisabled={isAdviceForFieldDisabled}
                  value={diseases.find(selectedDisease) ?? null}
                  placeholder={
                    hasDiseases ? t("Select") : t("No diseases found")
                  }
                  onChange={(e) =>
                    setAdviceFor({ ...adviceFor, diseaseId: e.value })
                  }
                />
              )}

              {isAdviceFinalized && (
                <span className="patient-info">
                  {diseases.find(selectedDisease)?.label}
                </span>
              )}
            </div>

            {(!viewingReceivedConsultation || viewingOwnConsultation) &&
              !isAdviceFinalized && (
                <div className="consultation">
                  <div className="input-wrapper checkbox">
                    <InputCheckbox
                      id={"consultation"}
                      name="consultation"
                      value={"consultation"}
                      checked={isAdviceConsultation}
                      disabled={isQuestionnaireStarted}
                      labelText={t("Consultation")}
                      onChangeFn={(e) => handleConsultationChange(e)}
                    />
                  </div>

                  {isAdviceConsultation && (
                    <span className="input-wrapper ward">
                      <label htmlFor="hospitalWardId">{t("for")}</label>
                      <Select
                        name="hospitalWardId"
                        options={wards}
                        isSearchable={true}
                        required={isAdviceConsultation}
                        isDisabled={isQuestionnaireStarted}
                        styles={smallSelectStyles}
                        value={wards.find(selectedWard) || null}
                        onChange={(e) => setAdviceForWardId(e.value)}
                      />
                    </span>
                  )}
                </div>
              )}

            {!isQuestionnaireStarted && !isAdviceFinalized && (
              <button
                type="button"
                onClick={startQuestionnaire}
                disabled={isStartButtonDisabled}
              >
                {t("Start")}
              </button>
            )}

            {isQuestionnaireStarted && !isAdviceFinalized && (
              <button type="button" onClick={discardAdvice}>
                {t("Delete")}
              </button>
            )}
          </div>

          <div className="questions-list-wrapper">
            {hasClosedQuestions && <b>{t("Questions")}:</b>}

            <ul className="questions-list">
              {hasClosedQuestions &&
                questionsClosed.map((question, index) => (
                  <li
                    key={"question" + index}
                    className="question answered"
                    title={question.questionName}
                    onClick={() => {
                      if (isAdviceFinalized) return;
                      fetchQuestionActive(question.questionId);
                    }}
                  >
                    <h3>{question.questionName}</h3>

                    {question.answer.type === "list" &&
                      question.answer.value
                        .filter((a) => a.selected)
                        .map((a) => (
                          <div className="answer" key={a.answerId}>
                            {a.answerName}
                          </div>
                        ))}

                    {question.answer.type === "input" && (
                      <div className="answer">{question.answer.value}</div>
                    )}
                  </li>
                ))}

              {hasActiveQuestion && (
                <li className="question active">
                  <h3>
                    {questionActive.index}. {questionActive.questionName}
                  </h3>

                  <p>{questionActive.questionInfo}</p>

                  {hasActiveQuestion && (
                    <form className="answers">
                      {questionActive.answer.type === "input" && (
                        <div className="input-wrapper inline">
                          <input
                            type="text"
                            name="answerInput"
                            value={questionActive.answer.value}
                            id="answerInput"
                            onChange={(e) => changeActiveQuestion(e)}
                          />
                        </div>
                      )}

                      {questionActive.answer.type === "list" &&
                        sortYesAnswersFirst(questionActive.answer.value).map(
                          (answer, index) => (
                            <div
                              className="input-wrapper inline"
                              key={answer.answerId}
                            >
                              <input
                                type="radio"
                                name={questionActive.questionId}
                                id={answer.answerId}
                                checked={answer.selected === true}
                                onChange={(e) => changeActiveQuestion(e)}
                                value={answer.answerId}
                              />
                              <label htmlFor={answer.answerId}>
                                {answer.answerName}
                              </label>
                            </div>
                          )
                        )}

                      <button
                        className="next-question"
                        onClick={(e) => showNextQuestion(e)}
                      >
                        {t("Next")}
                      </button>
                    </form>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>

        <div
          className={`advice-background ${
            isAdviceBackgroundVisible ? "toggled" : ""
          }`}
          onClick={askIfWantsToChangeQuestions}
        ></div>

        {!isQuestionnaireStarted && <ЕmptyAdvicePlaceholder />}

        {hasAdvice && (
          <Advice
            adviceFor={adviceFor}
            diseaseHistory={diseaseHistory}
            acceptAdvice={acceptAdvice}
            showRejectAdvice={showRejectAdvice}
            startNewAdvice={startNewAdvice}
            isAdviceAccepted={isAdviceAccepted}
            isAdviceRejected={isAdviceRejected}
          />
        )}

        <DialogRejectAdvice
          adviceId={adviceFor.adviceId}
          isVisible={isRejectAdviceVisible}
          setIsRejectAdviceVisible={setIsRejectAdviceVisible}
          setIsAdviceRejected={setIsAdviceRejected}
          rejectReasons={rejectReasons}
        />
      </div>
    </div>
  );
}
