import { PageTitle, PatientsAndAdvices } from "../../components";
import { useTranslation } from "react-i18next";

export default function PatientsArchived() {
  const { t } = useTranslation();

  return (
    <div className="patients">
      <PageTitle title={t("Archived Patients")} />
      <PatientsAndAdvices isShowingArchivedPatients isShowingSidebar={false} />
    </div>
  );
}
