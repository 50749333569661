import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApiRequests, showHttpRequestError } from "../../../http";
import { useOnMount } from "../../../hooks";
import { FormMessage } from "../../index";
import {
  getPageHrefFromUrl,
  getFileCategory,
  isFormValid,
  showButtonLoader,
  hideButtonLoader,
} from "../../../utils/utils";
import { showSuccessMessage, showErrorMessage } from "../../../redux/reducers";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { selectStyles } from "../../../styles/selectStyles";

export default function FormFile({ fetchTableData }) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.modals.formMessage);
  const user = useSelector((state) => state.user);
  const { isAdmin } = user;

  /**
   * Get the uploaded file category and which form fields are required.
   *
   * Uploading:
   * - FILE_GUIDELINES - requires country.
   * - FILE_AMRS (reistance statistics) - requires hospital.
   * - FILE_SPS (leaflets) - requires hospital.
   *
   * Leaflets can be uploaded files or added links.
   */
  const pageHref = getPageHrefFromUrl();
  const uploadedFileCategory = getFileCategory(pageHref);
  const areHospitalsRequired = uploadedFileCategory === "FILE_AMRS";
  const areCountriesRequired =
    uploadedFileCategory === "FILE_GUIDELINES" ||
    uploadedFileCategory === "FILE_SPS";

  /**
   * Fetch the required form fields data.
   */
  const [countries, setCountries] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  useOnMount(() => {
    const hasCountries = !!countries.length;
    const hasHospitals = !!hospitals.length;

    // Admins can select country and hospital when uploading files.
    // Other roles can't select. We get them from their profile.
    if (!hasCountries && areCountriesRequired) {
      if (isAdmin) fetchAndSetCountries();
      if (!isAdmin) setCountryFromCurrentUser();
    }

    if (!hasHospitals && areHospitalsRequired) {
      if (isAdmin) fetchAndSetHospitals();
      if (!isAdmin) setHospitalFromCurrentUser();
    }
  });

  function setCountryFromCurrentUser() {
    setCountries([
      {
        id: user.countryId,
        name: user.countryName,
      },
    ]);
  }

  function setHospitalFromCurrentUser() {
    setHospitals([
      {
        id: user.hospitalId,
        name: user.hospitalName,
      },
    ]);
  }

  async function fetchAndSetCountries() {
    const fetchedCountries = await getCountries();
    setCountries(fetchedCountries);
  }

  async function fetchAndSetHospitals() {
    const fetchedHospitals = await getHospitals();
    setHospitals(fetchedHospitals);
  }

  async function getCountries() {
    return api
      .getCountries()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  async function getHospitals() {
    return api
      .getHospitalFilteredNames(true)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * File upload.
   */
  function uploadFile(e) {
    e.preventDefault();
    const uploadForm = e.target;

    if (!isFormValid(uploadForm)) {
      dispatch(showErrorMessage(t("Some fileds are not valid.")));
      return;
    }

    const formData = new FormData(e.target);

    showButtonLoader();
    api
      .uploadFile(formData)
      .then((response) => {
        dispatch(
          showSuccessMessage({
            content: response.data.message,
            id: "formFile",
          })
        );
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  }

  /**
   * Uploading file or using URL.
   */
  const [activeTab, setActiveTab] = useState("upload");
  const [medicaments, setMedicaments] = useState([]);
  const hasMedicaments = Boolean(medicaments.length);
  const [selectedMedicamentId, setSelectedMedicamentId] = useState(null);
  const selectedMedicament = (med) => med.value === selectedMedicamentId;

  useOnMount(() => {
    const uploadingLeafletsAndDoesntHaveMedicaments =
      uploadedFileCategory === "FILE_SPS" && !hasMedicaments;

    if (uploadingLeafletsAndDoesntHaveMedicaments) {
      fetchAndSetMedicaments();
    }
  });

  async function fetchAndSetMedicaments() {
    const fetchedMedicaments = await api
      .getMedicaments()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const medicamentsForSelect = fetchedMedicaments.map((medicament) => ({
      value: medicament.id,
      label: medicament.name,
    }));

    setMedicaments(medicamentsForSelect);
  }

  return (
    <form onSubmit={uploadFile}>
      <div className="input-wrapper">
        <label htmlFor="title">{t("Title:")}</label>
        <input
          type="text"
          name="title"
          id="title"
          required
          minLength="5"
          maxLength="300"
        />
      </div>

      {uploadedFileCategory !== "FILE_SPS" && (
        // Guidelines and statistics are uploaded files.

        <div className="input-wrapper">
          <label htmlFor="file">
            {t("File (Max size: 10MB. Format: pdf, doc, docx, xls or xlsx):")}
          </label>
          <input
            type="file"
            name="file"
            id="file"
            required
            accept=".doc,.docx,.pdf,.xls,.xlsx"
          />
        </div>
      )}

      {uploadedFileCategory === "FILE_SPS" && (
        // Leaflets can be uploaded files or external urls. Here medicamentId is required field.

        <>
          <div className="input-wrapper">
            <label htmlFor="medicamentId">{t("For Medicament:")}</label>
            <Select
              name="medicamentId"
              options={medicaments}
              isSearchable={true}
              required
              styles={selectStyles}
              value={medicaments.find(selectedMedicament) ?? null}
              isDisabled={!hasMedicaments}
              placeholder={
                hasMedicaments ? t("Select") : t("No medicaments found")
              }
              onChange={(e) => setSelectedMedicamentId(e.value)}
            />
          </div>

          <div className="tabs">
            <div className="tabs-header">
              <button
                type="button"
                onClick={() => setActiveTab("upload")}
                className={activeTab === "upload" ? "active secondary" : ""}
              >
                {t("Upload file")}
              </button>
              <button
                type="button"
                onClick={() => setActiveTab("url")}
                className={activeTab === "url" ? "active secondary" : ""}
              >
                {t("Add URL")}
              </button>
            </div>

            <div className="tabs-content">
              {activeTab === "upload" && (
                <div className="input-wrapper">
                  <label htmlFor="file">
                    {t("Max size: 10MB. Format: pdf, doc, docx, xls or xlsx.")}
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    required
                    accept=".doc,.docx,.pdf,.xls,.xlsx"
                  />
                </div>
              )}

              {activeTab === "url" && (
                <div className="input-wrapper">
                  <label htmlFor="externalLink">{t("Leaflet URL:")}</label>
                  <input
                    type="url"
                    name="externalLink"
                    id="externalLink"
                    required
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {areCountriesRequired && (
        <div className="input-wrapper">
          <label htmlFor="country">{t("Country:")}</label>
          <select name="countryId" id="country" required>
            {countries.map((country) => (
              <option value={country.id} key={country.id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {areHospitalsRequired && (
        <div className="input-wrapper">
          <label htmlFor="hospital">{t("Hospital")}</label>
          <select name="hospitalId" id="hospital" required>
            {hospitals.map((hospital) => (
              <option value={hospital.id} key={hospital.id}>
                {hospital.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <input
        type="text"
        name="purpose"
        defaultValue={uploadedFileCategory}
        hidden
      />

      {message.content && <FormMessage id="formFile" />}

      <button type="submit">{t("Upload")}</button>
    </form>
  );
}
