import { useTranslation } from "react-i18next";
import { MedicamentAdministration } from "../";
import "./TreatmentSummary.scss";

export default function TreatmentSummary({
  medicament,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  isPrevention,
}) {
  const { t } = useTranslation();
  const {
    medicamentId,
    marketFormId,
    dose,
    doseMeasureId,
    frequency,
    applicationMethod,
    whenToApply,
    whenToRepeat,
    durationOfTreatment,
    evaluationPeriod,
  } = medicament;

  const medicamentName = getItemName(medicamentId, medicaments);
  const marketFormName = getItemName(marketFormId, medicamentForms);
  const doseMeasureName = getItemName(doseMeasureId, doseMeasures);

  const frequencyTimeMeasureName = getItemName(
    frequency.measureId,
    timeMeasures
  );

  const applicationMethodTimeMeasureName = getItemName(
    applicationMethod.measureId,
    timeMeasures
  );

  const applicationMethodName = getItemName(
    applicationMethod.applicationMethodId,
    applicationMethods
  );

  const applicationMethodMeasureName = getItemName(
    applicationMethod.measureId,
    timeMeasures
  );

  function getItemName(id, items) {
    return items.find((item) => item.value === id)?.label;
  }

  return (
    <div className="medicament-summary">
      <MedicamentAdministration
        medicamentName={medicamentName}
        dose={dose}
        doseMeasureName={doseMeasureName}
        marketFormName={marketFormName}
        applicationMethod={applicationMethod}
        applicationMethodName={applicationMethodName}
        applicationMethodMeasureName={applicationMethodMeasureName}
        durationOfTreatment={durationOfTreatment}
        classNames="treatment"
      />

      {/* <div className="treatment">
        {medicamentName && <>{medicamentName} </>}
        {dose && <>{dose} </>}
        {doseMeasureName && <>{doseMeasureName} </>}
        {marketFormName && <span className="lowercase">{marketFormName} </span>}
        {applicationMethodName && <>{applicationMethodName} </>}
        {applicationMethod.time > 0 && (
          <>
            {applicationMethod.time} {t("times per")}{" "}
          </>
        )}
        {applicationMethodMeasureName && <>{applicationMethodMeasureName} </>}
        {durationOfTreatment > 0 && (
          <>
            {t("for")} {durationOfTreatment} {t("days")}
          </>
        )}
      </div> */}

      {whenToApply && (
        <div className="whenToApply truncate" title={whenToApply}>
          <b>{t("When to apply")}:</b>
          {whenToApply}
        </div>
      )}

      {whenToRepeat && (
        <div className="whenToRepeat truncate" title={whenToRepeat}>
          <b>{t("When to repeat")}:</b>
          {whenToRepeat}
        </div>
      )}
    </div>
  );
}
