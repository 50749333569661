import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import Answer from "../Answer/Answer";
import "./Question.scss";

export default function Question({
  question,
  questions,
  originalQuestions,
  questionIndex,
  workflow,
  setWorkflow,
  addSelectedQuestion,
  updateSelectedQuestion,
  removeSelectedQuestion,
  saveAdvice,
  zIndex,
  isPrevention,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  bacteria,
  setCenteredQuestionIndex,
  isSavingAdvice,
  isEditingDraft,
}) {
  const { t } = useTranslation();
  const { name: questionName, id: questionId, answers } = question;

  function getParentQuestionName(questionId) {
    const parentQuestion = questions.find(
      (question) => question.value === questionId
    );
    return parentQuestion?.label;
  }

  function getNextQuestion(answer = null) {
    function next(branch) {
      if (branch.questionIndex === question.questionIndex) {
        if (branch.answer?.type === "input") {
          return branch.answer.block?.branch.questionId;
        }

        if (branch.answer?.type === "list") {
          let values = branch?.answer?.value;
          for (let i = 0; i < values.length; i++) {
            let a = values[i];
            if (a.answerId === answer?.id) {
              return a.block?.branch.questionId;
            }
          }
          return;
        }
      } else {
        if (branch.answer?.type === "input") {
          if (branch.answer?.block) {
            let qId = next(branch.answer.block?.branch);
            if (qId) {
              return qId;
            }
          }
        }

        if (branch.answer?.type === "list") {
          let values = branch?.answer?.value;
          for (let i = 0; i < values.length; i++) {
            let a = values[i];
            if (a.block) {
              let qId = next(a.block?.branch);
              if (qId) {
                return qId;
              }
            }
          }
        }
      }
    }

    const block = workflow?.workflowDto?.block;
    if (!block) {
      return;
    }

    let branch = block.branch;
    let q = next(branch);
    return q;
  }

  function handleQuestionClick() {
    setCenteredQuestionIndex(questionIndex);
  }

  return (
    <div
      className="workflow-question question"
      style={{ zIndex: zIndex }}
      data-index={question.questionIndex}
      onClick={handleQuestionClick}
    >
      {question.parentQuestionId && (
        <div className="parent-question">
          <span className="question-text">
            {question.parentQuestionIndex +
              1 +
              ". " +
              getParentQuestionName(question.parentQuestionId)}
          </span>
          <Icon name="arrow-right" />
          <span className="question-answer-text">
            {question.parentAnswerName}
          </span>
        </div>
      )}

      <h2>
        {questionIndex + 1}. {questionName}
      </h2>

      <div
        className="modal-close"
        title={t("Remove this question")}
        onClick={() => removeSelectedQuestion(question)}
      >
        <Icon name="cross" />
      </div>

      <div className="flex-input-wrapper labels">
        <div className="input-wrapper input-wrapper-answer">
          <label>{t("Answer:")}</label>
        </div>

        <div className="input-wrapper leads-to-target">
          <label htmlFor="leads-to">{t("Leads to:")}</label>
        </div>

        <div className="input-wrapper target">
          <label htmlFor="leads-to">&nbsp;</label>
        </div>
      </div>

      {answers.map((answer) => (
        <Answer
          key={answer.id}
          answer={answer}
          parentQuestionId={questionId}
          questionWithAnswers={question}
          questions={questions}
          originalQuestions={originalQuestions}
          workflow={workflow}
          setWorkflow={setWorkflow}
          addSelectedQuestion={addSelectedQuestion}
          updateSelectedQuestion={updateSelectedQuestion}
          oldAnswerTarget={answer.answerTarget}
          oldAdviceTarget={answer.adviceTarget}
          nextQuestionId={getNextQuestion(answer)}
          saveAdvice={saveAdvice}
          isPrevention={isPrevention}
          medicaments={medicaments}
          medicamentForms={medicamentForms}
          doseMeasures={doseMeasures}
          timeMeasures={timeMeasures}
          applicationMethods={applicationMethods}
          bacteria={bacteria}
          question={question}
          isSavingAdvice={isSavingAdvice}
          isEditingDraft={isEditingDraft}
        />
      ))}

      {question.answers.length === 0 && (
        <Answer
          key={question.id}
          parentQuestionId={questionId}
          questionWithAnswers={question}
          questions={questions}
          originalQuestions={originalQuestions}
          workflow={workflow}
          setWorkflow={setWorkflow}
          addSelectedQuestion={addSelectedQuestion}
          updateSelectedQuestion={updateSelectedQuestion}
          oldAnswerTarget={question.answerTarget}
          oldAdviceTarget={question.adviceTarget}
          nextQuestionId={getNextQuestion()}
          saveAdvice={saveAdvice}
          isPrevention={isPrevention}
          medicaments={medicaments}
          medicamentForms={medicamentForms}
          doseMeasures={doseMeasures}
          timeMeasures={timeMeasures}
          applicationMethods={applicationMethods}
          bacteria={bacteria}
          question={question}
          isSavingAdvice={isSavingAdvice}
          isEditingDraft={isEditingDraft}
        />
      )}
    </div>
  );
}
