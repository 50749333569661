import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showSuccessMessage,
  showDialog,
  isDialogConfirmed,
} from "../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../http";
import { FormMessage, PageTitle } from "../../components";
import { showButtonLoader, hideButtonLoader } from "../../utils/utils";
import { useOnMount } from "../../hooks";
import { useTranslation } from "react-i18next";

export default function Settings() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.modals.formMessage);
  const [settings, setSettings] = useState([]);
  const hasSettings = Boolean(settings.length);

  useOnMount(() => {
    getAndSetSettings();
  });

  async function getAndSetSettings() {
    const settings = await api
      .getSettings()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    setSettings(settings);
  }

  /**
   * Update and restore settings.
   */
  async function updateSettings(e) {
    e.preventDefault();
    showButtonLoader();
    const settingsForm = new FormData(e.target);
    const formEntries = settingsForm.entries();
    const settingsAsKeyValuePairs = Object.assign(
      ...Array.from(formEntries, ([name, value]) => ({ [name]: value }))
    );

    api
      .updateSettings(settingsAsKeyValuePairs)
      .then((response) => {
        const updatedSettings = response.data;
        setSettings(updatedSettings);
        showFormSuccessMessage(t(`Settings updated successfully.`));
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  }

  async function restoreSettings(e) {
    e.preventDefault();

    const restoreSettingsDialog = {
      title: t("Restore default settings?"),
      message: t(
        "All system settings will be restored to their default values. Any changes you've made to them will be lost."
      ),
      buttonConfirmText: t("Restore"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(restoreSettingsDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    showButtonLoader();
    api
      .restoreSettings()
      .then((response) => {
        const defaultSettings = response.data;
        setSettings(defaultSettings);
        showFormSuccessMessage(t(`Settings restored successfully.`));
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());
  }

  /**
   * Setting input component.
   */
  function Setting({ setting }) {
    const { key, displayValue, value } = setting;

    return (
      <div className="input-wrapper">
        <label htmlFor={key}>{displayValue}</label>
        <input type="text" name={key} id={key} defaultValue={value} />
      </div>
    );
  }

  /**
   * Form messages.
   */
  function showFormSuccessMessage(message) {
    dispatch(
      showSuccessMessage({
        content: message,
        id: "settingsForm",
      })
    );
  }

  return (
    <div className="settings">
      <PageTitle title={t("Settings")} />

      <form onSubmit={updateSettings}>
        {hasSettings &&
          settings.map((setting) => (
            <Setting setting={setting} key={setting.key} />
          ))}

        {message.content && <FormMessage id="settingsForm" />}

        <button type="submit">{t("Save Settings")}</button>

        <button
          type="button"
          className="secondary w-100"
          onClick={restoreSettings}
        >
          {t("Restore Default Settings")}
        </button>
      </form>
    </div>
  );
}
