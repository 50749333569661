import { useState, useEffect } from "react";
import { showModal } from "../../redux/reducers";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";
import { AdviceForm } from "..";
import Select from "react-select";
import { smallSelectStyles } from "../../styles/selectStyles";
import "./Answer.scss";

export default function Answer({
  answer,
  questions,
  originalQuestions,
  parentQuestionId,
  questionWithAnswers,
  workflow,
  setWorkflow,
  addSelectedQuestion,
  updateSelectedQuestion,
  saveAdvice,
  oldAnswerTarget,
  oldAdviceTarget,
  nextQuestionId,
  isPrevention,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  bacteria,
  question,
  isSavingAdvice,
  isEditingDraft,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Answer target (question or advice).
   */
  const [answerTarget, setAnswerTarget] = useState(null);
  const [answerTargets, setAnswerTargets] = useState([
    {
      value: "question",
      label: t("Question"),
    },
    {
      value: "advice",
      label: t("Advice"),
    },
  ]);
  const selectedAnswer = (option) => option.value === answerTarget;

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const selectedQuestion = (option) => option.value === selectedQuestionId;
  const [isQuestionTargetSet, setIsQuestionTargetSet] = useState(false);

  /**
   * Advice target (base or corrected advice).
   */
  const [adviceTarget, setAdviceTarget] = useState(null);
  const [adviceTargets, setAdviceTargets] = useState([
    {
      value: "base",
      label: t("Base Advice"),
    },
    {
      value: "corrected",
      label: t("Corrected Advice"),
    },
  ]);

  const selectedAdvice = (option) => option.value === adviceTarget;

  useEffect(() => {
    setAdviceTarget(oldAdviceTarget);
    setAnswerTarget(oldAnswerTarget);
    if (nextQuestionId) {
      setSelectedQuestionId(nextQuestionId);
    }
  }, [oldAdviceTarget]);

  useEffect(() => {
    if (adviceTarget === "base" && oldAdviceTarget === "corrected") {
      saveAdvice(null, question, answer);
    }
  }, [adviceTarget]);

  /**
   * Update the workflow when this answer points to:
   * 1. New question.
   * 2. Advice.
   */
  useEffect(() => {
    if (!isQuestionTargetSet) return;

    if (
      answerTarget === "question" &&
      (oldAnswerTarget === "advice" || oldAnswerTarget === "none")
    ) {
      addSelectedQuestion(selectedQuestionId, questionWithAnswers, answer?.id);
    }

    if (answerTarget === "question" && oldAnswerTarget === "question") {
      updateSelectedQuestion(
        selectedQuestionId,
        questionWithAnswers,
        answer?.id
      );
    }
  }, [selectedQuestionId]);

  function showCorrectBaseAdvice(answer) {
    dispatch(
      showModal({
        id: `corectAdviceModal-${question.questionIndex}-${answer?.id}`,
      })
    );
  }

  return (
    <div className="flex-input-wrapper answer" key={answer?.id}>
      <div className="input-wrapper input-wrapper-answer">
        <div className="name">{answer?.name}</div>
      </div>

      <div className="input-wrapper leads-to-target">
        <Select
          name="leads-to"
          options={answerTargets}
          isSearchable={true}
          required
          styles={smallSelectStyles}
          openMenuOnClick
          openMenuOnFocus
          value={answerTargets.find(selectedAnswer) ?? null}
          onChange={(e) => setAnswerTarget(e.value)}
          menuPlacement="auto"
        />
      </div>

      {answerTarget === "question" && (
        <>
          <span className="arrow-target">
            <Icon name="arrow-right" />
          </span>

          <div className="input-wrapper target">
            <Select
              name="question"
              options={questions}
              isSearchable={true}
              required
              styles={smallSelectStyles}
              openMenuOnClick
              openMenuOnFocus
              value={questions.find(selectedQuestion) ?? null}
              onChange={(e) => {
                setIsQuestionTargetSet(true);
                setSelectedQuestionId(e.value);
              }}
              menuPlacement="auto"
            />
          </div>
        </>
      )}

      {answerTarget === "advice" && (
        <>
          <span className="arrow-target">
            <Icon name="arrow-right" />
          </span>

          <div className="input-wrapper target">
            <Select
              name="leads-to"
              options={adviceTargets}
              isSearchable={true}
              required
              styles={smallSelectStyles}
              openMenuOnClick
              openMenuOnFocus
              value={adviceTargets.find(selectedAdvice) ?? null}
              defaultValue={adviceTargets[0]}
              onChange={(e) => setAdviceTarget(e.value)}
              menuPlacement="auto"
            />
          </div>
        </>
      )}

      {((answerTarget === "advice" && adviceTarget === "corrected") ||
        answerTarget === "question") && (
        <>
          <button
            className="correct-advice smaller secondary"
            onClick={() => showCorrectBaseAdvice(answer)}
            title={t("Correct Base Advice")}
          >
            <Icon name="pencil" />
          </button>

          <Modal
            id={`corectAdviceModal-${question.questionIndex}-${answer?.id}`}
            title={`${t("Correct Advice")}`}
            innerComponent={
              <AdviceForm
                isPrevention={isPrevention}
                medicaments={medicaments}
                medicamentForms={medicamentForms}
                doseMeasures={doseMeasures}
                timeMeasures={timeMeasures}
                applicationMethods={applicationMethods}
                bacteria={bacteria}
                workflow={workflow}
                canCreate={true}
                canUpdate={true}
                setWorkflow={setWorkflow}
                isBaseAdvice={false}
                advice={answer?.advice || question.advice}
                saveAdvice={(advice) => {
                  saveAdvice(advice, question, answer);
                }}
                isSavingAdvice={isSavingAdvice}
                isEditingDraft={isEditingDraft}
              />
            }
            isLarge
            customClasses={"modal-advice modal-advice-correct"}
          />
        </>
      )}
    </div>
  );
}
