import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Medicament, Icon } from "../../index";
import { v4 as generateUUID } from "uuid";
import { showSuccessPopup } from "../../../redux/reducers";
import { useTranslation } from "react-i18next";

export default function FormTreatment({
  treatment,
  isPrevention,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  info,
  selectedBacteria,
  treatments,
  setTreatments,
  reminder,
  saveTreatments,
  treatmentIndex,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Form content.
   */
  const {
    id: treatmentId,
    title: initialTreatmentTitle,
    info: initialTreatmentInfo,
    medicaments: initialTreatmentMedicaments,
  } = treatment;

  const [isSaveTreatmentEnabled, setIsSaveTreatmentEnabled] = useState(false);
  const [isAddMedicamentEnabled, setIsAddMedicamentEnabled] = useState(true);

  const [treatmentTitle, setTreatmentTitle] = useState(
    initialTreatmentTitle || ""
  );
  const [treatmentInfo, setTreatmentInfo] = useState(
    initialTreatmentInfo || ""
  );
  const [treatmentMedicaments, setTreatmentMedicaments] = useState(
    initialTreatmentMedicaments || []
  );

  /**
   * All created treatments start without medicaments.
   *
   * In this case the user can save the treatment but has to fill the input "Title" which will be displayed to the user. This case is used to display "No antibiotic prophylaxis required", "No treatment required" etc.
   */
  const hasSetMedicaments = treatmentMedicaments.length > 0;

  useEffect(allowSavingWithTitleAndNoMedicaments, [
    treatmentMedicaments?.length,
    treatmentTitle,
  ]);

  function allowSavingWithTitleAndNoMedicaments() {
    if (hasSetMedicaments) return;

    if (treatmentTitle) {
      setIsSaveTreatmentEnabled(true);
    } else {
      setIsSaveTreatmentEnabled(false);
    }
  }

  /**
   * In case this form is for treatment #2 or more (alternative treatments) - it doesn't make sense for it to be "No treatment is required". Therefore we'll add the first empty medicament to avoid showing the message.
   */
  useEffect(addEmptyMedicamentIfThisIsAlternativeTreatment, [treatmentIndex]);

  function addEmptyMedicamentIfThisIsAlternativeTreatment() {
    if (treatmentIndex > 0) {
      addEmptyMedicament();
    }
  }

  function addEmptyMedicament() {
    setTreatmentMedicaments([...treatmentMedicaments, createEmptyMedicament()]);
  }

  function createEmptyMedicament() {
    return {
      id: generateUUID(),
      medicamentId: "",
      marketFormId: "",
      dose: "",
      doseMeasureId: "",
      frequency: {
        quantity: "",
        time: "",
        measureId: "",
      },
      applicationMethod: {
        applicationMethodId: "",
        time: "",
        measureId: "",
      },
      whenToApply: "",
      durationOfTreatment: "",
      whenToRepeat: "",
      evaluationPeriod: 3, // Default is 3 days after starting the treatment.
    };
  }

  /**
   * Save the treatment and workflow draft.
   */
  function saveTreatment(treatmentId) {
    const newTreatment = {
      id: treatmentId,
      order: 0,
      title: treatmentTitle,
      info: treatmentInfo,
      medicaments: treatmentMedicaments,
    };

    const copyTreatments = [...treatments];
    const treatmentIndex = copyTreatments.findIndex(
      (treatment) => treatment.id === treatmentId
    );

    copyTreatments[treatmentIndex] = {
      ...copyTreatments[treatmentIndex],
      ...newTreatment,
    };

    setTreatments(copyTreatments);
    const message = hasSetMedicaments
      ? t("Treatment saved.")
      : t("Message saved.");
    dispatch(showSuccessPopup(message));
    saveTreatments();
  }

  return (
    <div className="treatment-form">
      <div className="flex-input-wrapper">
        <div className="input-wrapper">
          <label htmlFor="title">
            {hasSetMedicaments
              ? t("Title:")
              : t("Message for the user if medicaments are not required:")}
          </label>
          <input
            type="text"
            name="title"
            id="title"
            required
            placeholder={
              hasSetMedicaments
                ? t("Treatment of First Choice")
                : t("It is not necessary to...")
            }
            value={treatmentTitle}
            onChange={(e) => setTreatmentTitle(e.target.value)}
            minLength="3"
            maxLength="300"
          />
        </div>

        {hasSetMedicaments && (
          <div className="input-wrapper">
            <label htmlFor="info">{t("Info")}:</label>
            <input
              type="text"
              name="info"
              id="info"
              value={treatmentInfo}
              onChange={(e) => setTreatmentInfo(e.target.value)}
            />
          </div>
        )}
      </div>

      {treatmentMedicaments.map((medicament, index) => (
        <Medicament
          key={treatmentId + medicament.id + index}
          isPrevention={isPrevention}
          medicament={medicament}
          medicamentIndex={index}
          medicaments={medicaments}
          medicamentForms={medicamentForms}
          treatmentMedicaments={treatmentMedicaments}
          setTreatmentMedicaments={setTreatmentMedicaments}
          doseMeasures={doseMeasures}
          timeMeasures={timeMeasures}
          applicationMethods={applicationMethods}
          treatmentTitle={treatmentTitle}
          treatmentInfo={treatmentInfo}
          setIsSaveTreatmentEnabled={setIsSaveTreatmentEnabled}
        />
      ))}

      <div className="flex-input-wrapper">
        <div className="input-wrapper d-flex no-margins">
          <button
            type="button"
            className="secondary"
            onClick={addEmptyMedicament}
            disabled={!isSaveTreatmentEnabled && !isAddMedicamentEnabled}
          >
            <Icon name="plus" />
            {t("Add medicament")}
          </button>
        </div>

        <button
          type="button"
          onClick={() => saveTreatment(treatmentId)}
          disabled={!isSaveTreatmentEnabled}
        >
          {hasSetMedicaments && t("Save treatment")}
          {!hasSetMedicaments && t("Save message")}
        </button>
      </div>
    </div>
  );
}
