import { useSelector, useDispatch } from "react-redux";
import { hideDialog, setIsDialogConfirmed } from "../../redux/reducers/index";
import "./DialogConfirm.scss";

export default function DialogConfirm() {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.modals.dialog);
  const {
    title,
    message,
    isVisible,
    isWarning,
    buttonConfirmText,
    buttonCancelText,
  } = dialog;

  const dialogClasses = `modal dialog ${isVisible ? "toggled" : "hidden"} ${
    isWarning ? "warning" : ""
  }`;

  const dialogBackgroundClasses = `dialog-background ${
    isVisible ? "toggled" : "hidden"
  }`;

  return (
    <>
      <div className={dialogClasses}>
        <h2 className="modal-title">{title}</h2>

        <div className="modal-contents">
          <div className="dialog-message">{message}</div>

          <div className="dialog-actions">
            <button
              className="dialog-cancel secondary"
              onClick={() => {
                dispatch(setIsDialogConfirmed(false));
                dispatch(hideDialog());
              }}
            >
              {buttonCancelText}
            </button>

            <button
              className="dialog-confirm"
              onClick={() => {
                dispatch(setIsDialogConfirmed(true));
                dispatch(hideDialog());
              }}
            >
              {buttonConfirmText}
            </button>
          </div>
        </div>

        <div
          className="modal-close"
          onClick={() => {
            dispatch(setIsDialogConfirmed(false));
            dispatch(hideDialog());
          }}
        >
          <span className="icon icon-cross"></span>
        </div>
      </div>

      <div
        className={dialogBackgroundClasses}
        onClick={() => {
          dispatch(setIsDialogConfirmed(false));
          dispatch(hideDialog());
        }}
      ></div>
    </>
  );
}
