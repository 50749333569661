import Icon from "../../Icon/Icon";
import { useTranslation } from "react-i18next";

export default function FilterWrapper({ header, flexRender, children }) {
  const { t } = useTranslation();

  return (
    <div key={header.id}>
      <>
        <div
          title={t("Click to sort")}
          {...{
            className: header.column.getCanSort() ? "sortable" : "",
            onClick: header.column.getToggleSortingHandler(),
          }}
        >
          <span>
            {flexRender(header.column.columnDef.header, header.getContext())}
            {{
              asc: (
                <span className="sorted">
                  {t("A")}
                  <Icon name="arrow-right" />
                  {t("Z")}
                </span>
              ),
              desc: (
                <span className="sorted">
                  {t("Z")}
                  <Icon name="arrow-right" />
                  {t("A")}
                </span>
              ),
            }[header.column.getIsSorted()] ?? null}
          </span>
        </div>
        {header.column.getCanFilter() ? <div>{children}</div> : null}
      </>
    </div>
  );
}
