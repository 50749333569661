import AdviceSummary from "../AdviceSummary/AdviceSummary";

export default function CardAdvice({
  row,
  areAllAdvicesVisible,
  userShouldSeeEverything,
}) {
  return (
    <div className="card" key={row.original.adviceId}>
      <AdviceSummary
        advice={row.original}
        notDeemphasized="true"
        areAllAdvicesVisible={areAllAdvicesVisible}
        userShouldSeeEverything={userShouldSeeEverything}
      />
    </div>
  );
}
