import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ApiRequests, showHttpRequestError } from "../../http";
import { showSuccessMessage } from "../../redux/reducers";
import { FormMessage, PageTitle } from "../../components";
import { useGetPermissionsForPage, useOnMount } from "../../hooks/hooks";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import { selectStyles } from "../../styles/selectStyles";
import {
  copyToClipBoard,
  showButtonLoader,
  hideButtonLoader,
} from "../../utils/utils";
import "./Notifications.scss";
import { useTranslation } from "react-i18next";

export default function Notifications() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const message = useSelector((state) => state.modals.formMessage);

  // TinyMCE Config: https://www.tiny.cloud/docs/tinymce/6/react-pm-host/
  const editorRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const hasNotifications = Boolean(notifications.length);
  const [selectedNotification, setSelectedNotification] = useState({
    id: "",
    title: "",
    htmlText: "",
    notificationKey: "",
    placeholders: [],
  });

  const { title, htmlText, placeholders } = selectedNotification;
  const hasTitle = Boolean(title);
  const hasContent = Boolean(htmlText);
  const hasPlaceholders = Boolean(placeholders.length);
  const isSaveButtonDisabled = !hasTitle || !hasContent;

  /**
   * User permissions.
   */
  const user = useSelector((state) => state.user);
  const userRole = user.role;
  const page = userRole === "ADMIN" ? "notifications" : "notificationInstances";
  const { canView } = useGetPermissionsForPage(page);

  /**
   * Notifications get, set and update.
   */
  const setNotificationField = (e) => {
    const { name: field, value } = e.target;
    setSelectedNotification({
      ...selectedNotification,
      [field]: value,
    });
  };

  const selectedNotificationId = (notification) =>
    notification.value === selectedNotification.id;

  useOnMount(() => {
    if (!hasNotifications) {
      fetchAndSetNotifications();
    }
  });

  async function fetchAndSetNotifications() {
    const fetchedNotifications = await getNotificationsByRole()
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    const notificationsForSelect = fetchedNotifications.map((notification) => ({
      value: notification.id,
      label: notification.key,
    }));

    setNotifications(notificationsForSelect);

    function getNotificationsByRole() {
      if (userRole === "ADMIN") {
        return api.getNotifications();
      }
      return api.getNotificationsForHospital(user.hospitalId);
    }
  }

  async function fetchSelectedNotification(id) {
    const fetchedNotification = await getNotificationByRole(id)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));

    setSelectedNotification({ ...fetchedNotification });
    editorRef.current.setContent(fetchedNotification.htmlText);

    function getNotificationByRole(id) {
      if (userRole === "ADMIN") {
        return api.getNotification(id);
      }
      return api.getNotificationForHospital(id);
    }
  }

  async function editNotification(e) {
    e.preventDefault();

    const notificationToSend = { ...selectedNotification };
    notificationToSend.htmlText = editorRef.current.getContent();
    delete notificationToSend.placeholders;

    showButtonLoader();
    updateNotificationByRole(notificationToSend)
      .then((response) => {
        const updatedNotification = response.data;
        setSelectedNotification({ ...updatedNotification });
        showFormSuccessMessage(t(`Notification updated successfully.`));
      })
      .catch((error) => showHttpRequestError(error))
      .finally(() => hideButtonLoader());

    function updateNotificationByRole(notification) {
      if (userRole === "ADMIN") {
        return api.updateNotification(notification);
      }
      return api.updateNotificationForHospital(notification);
    }
  }

  /**
   * Placeholder component.
   */
  const Placeholder = ({ placeholder }) => {
    const [text, setText] = useState("${" + placeholder + "}");
    const [isCopied, setIsCopied] = useState(false);

    const copyPlaceholder = (text) => {
      copyToClipBoard(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    };

    return (
      <span
        className={`placeholder ${isCopied ? "copied" : ""}`}
        title={t("Click to copy")}
        onClick={() => copyPlaceholder(text)}
      >
        {text}
      </span>
    );
  };

  /**
   * Form messages.
   */
  function showFormSuccessMessage(message) {
    dispatch(
      showSuccessMessage({
        content: message,
        id: "notificationForm",
      })
    );
  }

  if (!canView || !user.role) {
    return <p>{t("You don't have permissions for this page.")}</p>;
  }

  return (
    <div className="notifications">
      <PageTitle title={t("Email Notifications")} />

      <form className="notification-edit" onSubmit={editNotification}>
        <div className="input-wrapper">
          <label htmlFor="notification-select">{t("Notification:")}</label>
          <Select
            id="notification-select"
            name="roleId"
            options={notifications}
            isSearchable={true}
            required
            styles={selectStyles}
            openMenuOnClick
            openMenuOnFocus
            placeholder={
              hasNotifications
                ? t("Select notification")
                : t("No notifications found")
            }
            value={notifications.find(selectedNotificationId) ?? null}
            onChange={(e) => fetchSelectedNotification(e.value)}
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="title">{t("Email title:")}</label>
          <input
            type="title"
            name="title"
            value={title}
            id="title"
            required
            disabled={!selectedNotification.id}
            onChange={setNotificationField}
          />
        </div>

        <div className="input-wrapper">
          <label htmlFor="title">{t("Email content:")}</label>
          <Editor
            tinymceScriptSrc={
              process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
            }
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue=""
            disabled={!selectedNotification.id}
            init={{
              min_height: 300,
              max_height: 1200,
              menubar: false,
              plugins: [
                "autoresize",
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700&display=swap');body { font-family:Raleway,Helvetica,Arial,sans-serif; font-size:16px }",
            }}
          />
        </div>

        {hasPlaceholders && (
          <div className="input-wrapper placeholders">
            <label>
              {t("Placeholders:")}{" "}
              <span className="explanation">
                {t("Click to copy.")}{" "}
                {user.isAdmin && (
                  <span>
                    {t("Edit in")} <Link to="/settings">{t("settings.")}</Link>
                  </span>
                )}
              </span>
            </label>

            {placeholders.map((placeholder) => (
              <Placeholder placeholder={placeholder} key={placeholder} />
            ))}
          </div>
        )}

        {message.content && <FormMessage id="notificationForm" />}

        <button
          type="submit"
          onClick={editNotification}
          disabled={isSaveButtonDisabled}
        >
          {t("Save Notification")}
        </button>
      </form>
    </div>
  );
}
