import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showModal } from "../../redux/reducers";
import { ApiRequests, showHttpRequestError } from "../../http";
import { useGetPermissionsForPage, useOnMount } from "../../hooks/hooks";
import { DataTable, Icon, Modal } from "../../components";
import FormSurveyOnArchiveAdvice from "../Modal/components/FormSurveyOnArchiveAdvice";
import {
  getVisibleTableColumns,
  downloadFileAsBlob,
  convertToSelectOptions,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { CardFollowUpAdvice } from "./components/CardFollowupAdvice";
import { CardReceivedConsultation } from "./components/CardReceivedConsultation";
import { CardUnfinishedAdvice } from "./components/CardUnfinishedAdvice";
import { CardPatient } from "../Cards";
import "./PatientsAndAdvices.scss";

export default function PatientsAndAdvices({
  isShowingArchivedPatients = false,
  isShowingSidebar = true,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const api = new ApiRequests();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  // Note: Due to BE controllers, doctor + head of ward get their permission from askAdvice and not from patients.
  const pageToGetPermissionsFor =
    user.role === "DOCTOR" || user.role === "HEAD OF WARD"
      ? "askAdvice"
      : "patients";
  const userPermissions = useGetPermissionsForPage(pageToGetPermissionsFor);
  const { canView } = userPermissions;

  /**
   * Table - patients with advices.
   */
  const [patients, setPatients] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [areAllAdvicesVisible, setAreAllAdvicesVisible] = useState(false);

  function toggleAreAllAdvicesVisible() {
    setAreAllAdvicesVisible((visible) => !visible);
  }

  /**
   * Sidebar.
   */
  const [followUpAdvices, setFollowUpAdvices] = useState([]);
  const [consultations, setConsultations] = useState([]);
  const [draftAdvices, setDraftAdvices] = useState([]);
  const [latestFiles, setLatestFiles] = useState([]);

  /**
   * Setup the table.
   */
  const userShouldSeeEverything =
    user.role === "DOCTOR" || user.role === "HEAD OF WARD";

  const userShouldSeeOnlyPatientsAndFiles =
    user.role === "HEAD OF HOSPITAL" || user.role === "MICROBIOLOGY";

  useOnMount(() => {
    fetchAndSetDashboardData();
  });

  async function fetchAndSetDashboardData() {
    Promise.allSettled(getDataForCurrentUser())
      .then((values) => {
        // For doctors and head of ward.
        if (userShouldSeeEverything) {
          const fetchedPatientsWithAdvices = values[0].value.data;
          const fetchedAdvicesToFollowUp = values[1].value.data;
          const fetchedReceivedConsultations = values[2].value.data;
          const fetchedDraftAdvices = values[3].value.data;
          const fetchedLatestFiles = values[4].value.data;

          setPatients(fetchedPatientsWithAdvices);
          setFollowUpAdvices(
            sortAdvicesByDateOldestFirst(fetchedAdvicesToFollowUp)
          );
          setConsultations(fetchedReceivedConsultations);
          setDraftAdvices(fetchedDraftAdvices);
          setLatestFiles(fetchedLatestFiles);

          const data = convertPatientsToTableData(fetchedPatientsWithAdvices);
          setTableData(data);
        }

        // For head of hospital and microbiology.
        if (userShouldSeeOnlyPatientsAndFiles) {
          const fetchedPatientsWithAdvices = values[0].value.data;
          const fetchedLatestFiles = values[1].value.data;

          setPatients(fetchedPatientsWithAdvices);
          setLatestFiles(fetchedLatestFiles);

          const data = convertPatientsToTableData(fetchedPatientsWithAdvices);
          setTableData(data);
        }
      })
      .catch((error) => showHttpRequestError(error));
  }

  function getDataForCurrentUser() {
    if (userShouldSeeEverything) {
      return getAllDataForDashboard();
    }

    if (userShouldSeeOnlyPatientsAndFiles) {
      return getMinimalDataForDashboard();
    }

    return [];
  }

  function getAllDataForDashboard() {
    return [
      isShowingArchivedPatients
        ? api.getArchivedPatients()
        : api.getActivePatients(),
      api.getAdvicesToFollowUp(),
      api.getReceivedConsultations(),
      api.getDraftAdvices(),
      api.getLatestFiles(),
    ];
  }

  function getMinimalDataForDashboard() {
    return [
      isShowingArchivedPatients
        ? api.getArchivedPatients()
        : api.getPatientsInHospital(),
      api.getLatestFiles(),
    ];
  }

  function convertPatientsToTableData(patients) {
    return patients.map((patient) => {
      let diseases = "";
      let medicaments = "";
      let hasConsultation = t("Without consultation");
      let consultingWard = "";
      let hasPrevention = t("Without perioperative prophylaxis");

      patient.advices.forEach((advice) => {
        if (advice.disease) {
          diseases += advice.disease + " ";
        }

        if (advice.medicaments.length) {
          advice.medicaments.forEach((medicament) => {
            const medicamentName = medicament.medicament;
            const medicamentIsNotInMedicaments =
              medicaments.indexOf(medicamentName) === -1;

            if (medicamentIsNotInMedicaments) {
              medicaments += medicamentName + " ";
            }
          });
        }

        if (advice?.consultantWardName) {
          hasConsultation = t("With consultation");
          consultingWard += advice.consultantWardName + " ";
        }

        if (advice?.prevention) {
          hasPrevention = t("With perioperative prophylaxis");
        }
      });

      return {
        ...patient,
        diseases: diseases,
        medicaments: medicaments,
        hasConsultation: hasConsultation,
        hasPrevention: hasPrevention,
        consultingWard: consultingWard,
      };
    });
  }

  function sortAdvicesByDateOldestFirst(advices) {
    advices.sort((a, b) => new Date(a.date) - new Date(b.date));
    return advices;
  }

  const allColumns = [
    {
      accessorKey: "diseaseHistory",
      header: () => <span>{t("Patient HD")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "diseases",
      header: () => <span>{t("Disease")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "medicaments",
      header: () => <span>{t("Antibiotics")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "consultingWard",
      header: () => <span>{t("Consulted by")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
  ];

  // Head of hospital and microbiology can filter by ward.
  if (userShouldSeeOnlyPatientsAndFiles) {
    allColumns.push({
      accessorKey: "hospitalWardName",
      header: () => <span>{t("Ward")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    });
  }

  const visibleColumns = getVisibleTableColumns(allColumns);
  const tableColumns = [
    {
      header: t("Results"),
      footer: (props) => props.column.id,
      columns: visibleColumns,
    },
  ];

  /**
   * Table actions.
   */
  function openAskAdvice() {
    navigate("/ask-advice");
  }

  const [surveyQuestions, setSurveyQuestions] = useState({});
  const [archivedAdvice, setArchivedAdvice] = useState(null);
  const [medicaments, setMedicaments] = useState([]);
  const [doseMeasures, setDoseMeasures] = useState([]);
  const [timeMeasures, setTimeMeasures] = useState([]);

  async function fetchSurveyQuestions(adviceId) {
    return await api
      .getSurveyQuestions(adviceId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  async function handleArchiveAdvice(advice) {
    const { adviceId } = advice;
    const questions = await fetchSurveyQuestions(adviceId);

    setSurveyQuestions(questions);
    setArchivedAdvice(advice);

    dispatch(
      showModal({
        id: "surveyForm",
      })
    );

    // No need to wait for these before opening the modal. They are hidden in the 3rd question tab.
    const isMedicamentDataMissing =
      !medicaments.length || !doseMeasures.length || !timeMeasures.length;

    if (isMedicamentDataMissing) {
      Promise.all([api.getMedicaments(), api.getWorkflowMeasures()])
        .then((responses) => {
          setMedicaments(convertToSelectOptions(responses[0].data));
          setDoseMeasures(convertToSelectOptions(responses[1].data));
          setTimeMeasures(convertToSelectOptions(responses[1].data));
        })
        .catch((error) => showHttpRequestError(error));
    }
  }

  function openAdvice(adviceId) {
    navigate(`/ask-advice?adviceId=${adviceId}`);
  }

  async function downloadFile(fileId, fileName) {
    api
      .getFile(fileId)
      .then((response) => {
        downloadFileAsBlob(response, fileName);
      })
      .catch((error) => showHttpRequestError(error));
  }

  if (!canView) {
    return <p>{t("You don't have permissions to view this page.")}</p>;
  }

  return (
    <div className="patients-and-advices">
      <DataTable
        tableData={tableData}
        tableColumns={tableColumns}
        addNewButtonLabel={t("New advice")}
        handleAddNew={openAskAdvice}
        userPermissions={userPermissions}
        CardComponent={CardPatient}
        isShowingPatients={true}
        areAllAdvicesVisible={areAllAdvicesVisible}
        toggleAreAllAdvicesVisible={toggleAreAllAdvicesVisible}
        userShouldSeeEverything={userShouldSeeEverything}
      />

      {isShowingSidebar && (
        <div className="sidebar">
          {userShouldSeeEverything && (
            <>
              {followUpAdvices.length > 0 && (
                <div className="card-wrapper follow-up">
                  <h2>
                    {t("For Archiving")}{" "}
                    <span className="count">({followUpAdvices.length})</span>
                  </h2>

                  {followUpAdvices.map((advice) => (
                    <CardFollowUpAdvice
                      key={advice.adviceId}
                      advice={advice}
                      archiveAdvice={handleArchiveAdvice}
                    />
                  ))}
                </div>
              )}

              {consultations.length > 0 && (
                <div className="card-wrapper consultations">
                  <h2>
                    {t("Received Consultations")}
                    <span className="count">({consultations.length})</span>
                  </h2>

                  {consultations.map((advice) => (
                    <CardReceivedConsultation
                      key={advice.adviceId}
                      advice={advice}
                      openAdvice={openAdvice}
                    />
                  ))}
                </div>
              )}

              {draftAdvices.length > 0 && (
                <div className="card-wrapper unfinished-advices">
                  <h2>
                    {t("Unfinished Advices")}
                    <span className="count">({draftAdvices.length})</span>
                  </h2>

                  {draftAdvices.map((advice) => (
                    <CardUnfinishedAdvice
                      key={advice.adviceId}
                      advice={advice}
                      openAdvice={openAdvice}
                    />
                  ))}
                </div>
              )}
            </>
          )}

          <div className="card-wrapper latest-files">
            <h2 className="mb-4">{t("Latest Files")}</h2>

            {latestFiles.map((file) => {
              const { title, name, fileId } = file;

              return (
                <div className="file" key={fileId}>
                  <div className="name">{title}</div>
                  <button
                    className="donwload secondary"
                    onClick={() => downloadFile(fileId, name)}
                    title={t("Download file")}
                  >
                    <Icon name="download" />
                  </button>
                </div>
              );
            })}

            <div className="links-all">
              <Link to="/guidelines">{t("Guidelines")}</Link>
              <Link to="/leaflets"> {t("Leaflets")}</Link>
              <Link to="/resistance"> {t("Statistics")}</Link>
            </div>
          </div>
        </div>
      )}

      <Modal
        id="surveyForm"
        title={t("Archive advice")}
        innerComponent={
          <FormSurveyOnArchiveAdvice
            questions={surveyQuestions}
            archivedAdvice={archivedAdvice}
            medicaments={medicaments}
            doseMeasures={doseMeasures}
            timeMeasures={timeMeasures}
            fetchAndSetDashboardData={fetchAndSetDashboardData}
          />
        }
        customClasses={"large"}
      />
    </div>
  );
}
