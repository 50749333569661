import React, { useEffect, useCallback, useMemo, useRef } from "react";
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Controls,
  useReactFlow,
} from "reactflow";
import { Icon } from "../index";
import dagre from "dagre";
import { isEqual } from "lodash";
import {
  createNodeQuestion,
  createNodeAdvice,
  createCustomEdge,
} from "../../utils/flowchartUtils";
import { v4 as generateUUID } from "uuid";
import NodeAdvice from "./components/NodeAdvice";
import NodeQuestion from "./components/NodeQuestion";
import EdgeCustom from "./components/EdgeCustom";
import { useTranslation } from "react-i18next";
import { nodeStyle } from "../../utils/flowchartUtils";
import "reactflow/dist/style.css";
import "./Flowchart.scss";

export default function Flowchart({
  workflow,
  originalResponse,
  bacteria,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  scrollToQuestion,
  centeredQuestionIndex,
  highlightAnswer,
  isComparingWorkflows,
}) {
  const { t } = useTranslation();
  const flowchartRef = useRef(null);
  const oldWorkflowRef = useRef(workflow);
  const workflowChangedFlag = useRef(generateUUID());

  /**
   * The workflow is deeply nested object and we need to recalculate the nodes and edges on every change. That's why we use the workflowChangedFlag.
   */
  const workflowChanged = !isEqual(workflow, oldWorkflowRef.current);

  if (workflowChanged) {
    workflowChangedFlag.current = generateUUID();
    oldWorkflowRef.current = workflow;
  }

  /**
   * Chart data should be memoized for performance.
   */
  const nodeTypes = useMemo(
    () => ({ question: NodeQuestion, advice: NodeAdvice }),
    []
  );

  const edgeTypes = useMemo(() => ({ custom: EdgeCustom }), []);

  const cachedChartNodesAndEdges = useMemo(() => {
    return getChartNodesAndEdges();
  }, [workflowChangedFlag.current, isComparingWorkflows]);

  function getChartNodesAndEdges() {
    const nodes = [];
    const edges = [];

    if (!workflow?.workflowDto?.block) {
      return [nodes, edges];
    }

    // Recursively traverse the workflow tree.
    const baseAdvice = workflow?.workflowDto.baseAdvice;
    traverseBlocksAndCreateNodesAndEdges(workflow?.workflowDto);

    function traverseBlocksAndCreateNodesAndEdges(node) {
      // If current block has a branch - create a node for it
      if (node?.block?.branch) {
        nodes.push(
          createNodeQuestion(
            node.block.branch.questionIndex,
            getQuestionName(node.block.branch.questionId),
            node.block.branch.questionId
          )
        );
      }

      /**
       * Here, two paths are possible, depending on the type of the answer (input | list).
       * 
       * List answer (yes/no) looks like:
       * answer: {
          type: "list",
          value: [{ answerId: "", changeAdvice: "", block: "" }],
        };
       * 
       * Input answer (weight number) looks like:
       * answer: {
          type: "input",
          value: 0,
          changeAdvice: {},
          block: "",
       }
       */

      // Path 1 (answer type === list).
      if (node.block?.branch?.answer?.type === "list") {
        if (node.block?.branch?.answer?.value.length) {
          node.block?.branch?.answer?.value.forEach((answer, index) => {
            if (answer.block?.branch?.questionIndex !== undefined) {
              edges.push(
                createCustomEdge(
                  node.block.branch.questionIndex,
                  answer.block.branch.questionIndex,
                  getAnswerName(node.block.branch.questionId, answer.answerId),
                  Boolean(answer?.changeAdvice),
                  node.block.branch.questionIndex,
                  answer.answerId
                )
              );
              traverseBlocksAndCreateNodesAndEdges(answer);
            }

            if (!answer.block) {
              nodes.push(
                createNodeAdvice(
                  node.block.branch.questionIndex + "_" + index,
                  answer.changeAdvice || baseAdvice,
                  bacteria,
                  medicaments,
                  medicamentForms,
                  doseMeasures,
                  timeMeasures,
                  applicationMethods
                )
              );
              edges.push(
                createCustomEdge(
                  node.block.branch.questionIndex,
                  node.block.branch.questionIndex + "_" + index,
                  getAnswerName(node.block.branch.questionId, answer.answerId),
                  Boolean(answer?.changeAdvice),
                  node.block.branch.questionIndex,
                  answer.answerId
                )
              );
            }
          });
        }
      }

      // Path 2 (answer type === input).
      if (node.block?.branch?.answer?.type === "input") {
        if (
          node.block.branch?.answer?.block?.branch?.questionIndex !== undefined
        ) {
          edges.push(
            createCustomEdge(
              node.block.branch.questionIndex,
              node.block.branch?.answer?.block?.branch?.questionIndex,
              "",
              Boolean(node.block.branch.answer),
              node.block.branch.questionIndex,
              node.block.branch.answer.answerId
            )
          );
          traverseBlocksAndCreateNodesAndEdges(node.block.branch.answer);
        }

        if (!node.block.branch.answer.block) {
          nodes.push(
            createNodeAdvice(
              node.block.branch.questionIndex + "_",
              node.block.branch.answer.changeAdvice || baseAdvice,
              bacteria,
              medicaments,
              medicamentForms,
              doseMeasures,
              timeMeasures,
              applicationMethods
            )
          );
          edges.push(
            createCustomEdge(
              node.block.branch.questionIndex,
              node.block.branch.questionIndex + "_",
              "",
              Boolean(node.block.branch.answer),
              node.block.branch.questionIndex,
              node.block.branch.answer.answerId
            )
          );
        }
      }
    }

    return [nodes, edges];
  }

  function getQuestionName(questionId) {
    return originalResponse?.questions.find((q) => q.id === questionId)?.name;
  }

  function getAnswerName(questionId, answerId) {
    return originalResponse?.questions
      .find((q) => q.id === questionId)
      ?.answers.find((a) => a.id === answerId)?.name;
  }

  /**
   * Dagre layout setup.
   */
  const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 140;
    const nodeHeight = 100;
    const isHorizontal = direction === "LR";

    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? "left" : "top";
      node.sourcePosition = isHorizontal ? "right" : "bottom";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes, edges };
  };

  /**
   * React flow setup.
   */
  const { setViewport, fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(updateNodesAndEdges, [
    workflowChangedFlag.current,
    isComparingWorkflows,
  ]);

  function updateNodesAndEdges() {
    const [initialNodes, initialEdges] = cachedChartNodesAndEdges;
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      initialNodes,
      initialEdges
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );

  useEffect(() => {
    fitView();
  }, [workflowChangedFlag.current]);

  /**
   * Center the graph view on clicked question from the list.
   */
  useEffect(centerOnNodeWhenQuestionIsClicked, [centeredQuestionIndex]);

  function centerOnNodeWhenQuestionIsClicked() {
    if (!centeredQuestionIndex) return;
    const node = nodes.find((n) => +n.id === centeredQuestionIndex);
    if (!node) return;

    setTimeout(() => highlightNode(node.id), 800);
    setTimeout(() => unHighlightNode(node.id), 3500);

    setViewport(
      {
        x:
          -node.position.x -
          node.width / 2 +
          flowchartRef.current.offsetWidth / 2,
        y:
          -node.position.y -
          node.height / 2 +
          flowchartRef.current.offsetWidth / 2,
        zoom: 1,
      },
      { duration: 800 }
    );
  }

  function highlightNode(nodeId) {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.style = {
            ...node.style,
            outline: "5px solid var(--primary-200)",
          };
          return node;
        }

        return node;
      })
    );
  }

  function unHighlightNode(nodeId) {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === nodeId) {
          node.style = {
            ...nodeStyle,
          };
          return node;
        }

        return node;
      })
    );
  }

  if (!nodes.length || !edges.length) {
    return (
      <div className="flowchart-no-data">
        <p>
          <Icon name="information" />
          {t("Add the first question to see the graph.")}
        </p>
      </div>
    );
  }

  return (
    <div className="flowchart" ref={flowchartRef}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        preventScrolling={true}
        nodesDraggable={false}
        nodesConnectable={false}
        edgesFocusable={false}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={(event, node) => {
          scrollToQuestion(node.id);
        }}
        onEdgeClick={(event, edge) => {
          scrollToQuestion(edge.source);
          highlightAnswer(edge.source, edge.label);
        }}
      >
        <Controls
          position="bottom-center"
          showInteractive={false}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            borderWidth: "0px",
            borderRadius: "8px",
          }}
        />
      </ReactFlow>
    </div>
  );
}
