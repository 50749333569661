import { useTranslation } from "react-i18next";

export default function CardDefault({
  row,
  isActive = true,
  flexRender,
  handleCardClick,
}) {
  const { t } = useTranslation();

  return (
    <div
      key={row.id}
      className={`card ${isActive === false ? "inactive" : ""}`}
      title={`${isActive === false ? t("Deactivated") : ""}`}
      onClick={!isActive ? () => {} : () => handleCardClick(row.original)}
      tabIndex="0"
    >
      {row.getVisibleCells().map((cell) => {
        if (cell.column.id === "title" || cell.column.id === "name") {
          return (
            <h3
              key={cell.id}
              className={cell.column.id}
              title={cell.column.header}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </h3>
          );
        }

        return (
          <div key={cell.id} className={cell.column.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        );
      })}
    </div>
  );
}
