import { createSlice } from "@reduxjs/toolkit";

const emptyUser = {
  id: "", // uuid
  username: "", // ivan.petrov
  firstName: "",
  middleName: "",
  lastName: "",
  isAdmin: false,
  gender: "",
  email: "",
  phone: "",
  hospitalId: "",
  hospitalName: "",
  hospitalWardName: "",
  role: "",
  languageAlias: "",
  permissions: {},
  token: "",
  tokenType: "",
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: { ...emptyUser },
  reducers: {
    setUser: (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
      };
    },

    resetUser: (state, action) => {
      return { ...emptyUser };
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;
export default userSlice.reducer;
