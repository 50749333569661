import { useTranslation } from "react-i18next";
import { Icon } from "../../index";

export function CardUnfinishedAdvice({ advice, openAdvice }) {
  const { t } = useTranslation();
  const { adviceId, diseaseHistory, disease } = advice;

  return (
    <div
      className="card card-patient"
      key={adviceId}
      onClick={() => openAdvice(adviceId)}
    >
      <div className="info">
        <h2>{diseaseHistory}</h2>
        <div className="disease">{disease}</div>
      </div>

      <div className="buttons">
        <button
          className="continue"
          onClick={() => openAdvice(adviceId)}
          title={t("Open and finish this advice")}
        >
          {t("View")} <Icon name="arrow-right" />
        </button>
      </div>
    </div>
  );
}
