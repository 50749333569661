import React from "react";
import { useDispatch } from "react-redux";
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "reactflow";
import { showModal } from "../../../redux/reducers";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/Icon";
import "./EdgeCustom.scss";

export default function EdgeCustom({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { label, isAdviceCorrected, questionIndex, answerId } = data;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const positionCenterStyles = {
    position: "absolute",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    fontSize: 12,
    pointerEvents: "all",
  };

  const openCorrectedAdvice = (e) => {
    e.stopPropagation();
    dispatch(
      showModal({
        id: `corectAdviceModal-${questionIndex}-${answerId}`,
      })
    );
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer className="nodrag nopan animated">
        <div className="custom-edge" style={positionCenterStyles}>
          {label}

          {isAdviceCorrected && (
            <>
              <button
                className="open-advice secondary smaller"
                title={t("View corrected advice")}
                onClick={openCorrectedAdvice}
              >
                <Icon name="pencil" />
              </button>
            </>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
