import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useOnMount } from "../../hooks";
import { useGetPermissionsForPage } from "../../hooks/hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { DataTable, PageTitle } from "../../components";
import { useTranslation } from "react-i18next";
import {
  showDialog,
  isDialogConfirmed,
  showSuccessPopup,
} from "../../redux/reducers";
import { getVisibleTableColumns } from "../../utils/utils";
import { CardAdvice } from "../../components/Cards";
import "./Patient.scss";

export default function Patient() {
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const pageToGetPermissionFor =
    user.role === "HEAD OF HOSPITAL" || user.role === "MICROBIOLOGY"
      ? "patients"
      : "askAdvice";
  const userPermissions = useGetPermissionsForPage(pageToGetPermissionFor);

  /**
   * Get the patient id from the url.
   */
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const patientId = params.get("id");
  const patientDiseaseHistory = params.get("history");

  /**
   * Table setup.
   */
  const allColumns = [
    {
      accessorKey: "diseases",
      header: () => <span>{t("Disease")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "medicamentNames",
      header: () => <span>{t("Antibiotics")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "consultingWard",
      header: () => <span>{t("Consulted by")}</span>,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    },
  ];

  const visibleColumns = getVisibleTableColumns(allColumns);
  const tableColumns = [
    {
      header: t("Results"),
      footer: (props) => props.column.id,
      columns: visibleColumns,
    },
  ];

  /**
   * Fetch all patient advices.
   */
  const [tableData, setTableData] = useState([]);
  const [patient, setPatient] = useState({
    status: "ACTIVE", // ACTIVE | ARCHIVED
    dateCreated: "",
    dateArchived: "",
  });

  useOnMount(() => {
    fetchAndSetDashboardData();
  });

  async function fetchAndSetDashboardData() {
    const responseData = await api
      .getAllPatientAdvices(patientId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
    const allAdvices = convertPatientsToTableData(responseData.advices);
    setTableData(allAdvices);

    setPatient({
      status: responseData.status,
      dateCreated: responseData.dateCreated,
      dateArchived: responseData.dateArchived,
      id: responseData.id,
    });
  }

  function convertPatientsToTableData(consultations) {
    return consultations.map((advice) => {
      let diseases = "";
      let medicamentNames = "";
      let hasConsultation = t("Without consultation");
      let consultingWard = "";
      let hasPrevention = t("Without perioperative prophylaxis");

      if (advice.disease) {
        diseases += advice.disease + " ";
      }

      if (advice.medicaments.length) {
        advice.medicaments.forEach((medicament) => {
          const medicamentName = medicament.medicament;
          const medicamentIsNotInMedicaments =
            medicamentNames.indexOf(medicamentName) === -1;

          if (medicamentIsNotInMedicaments) {
            medicamentNames += medicamentName + " ";
          }
        });
      }

      if (advice.ward) {
        hasConsultation = t("With consultation");
        consultingWard += advice.ward + " ";
      }

      if (advice.prevention) {
        hasPrevention = t("With perioperative prophylaxis");
      }

      return {
        ...advice,
        displayClass: "AdviceSummary",
        diseases: diseases,
        medicamentNames: medicamentNames,
        hasConsultation: hasConsultation,
        hasPrevention: hasPrevention,
        consultingWard: consultingWard,
      };
    });
  }

  async function archivePatientHd() {
    const deleteDialog = {
      title: `${t("Archive patient")} ${patientDiseaseHistory}?`,
      message: t("This will archive the patient record and all his advices."),
      buttonConfirmText: t("Archive patient"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .archiveDiseaseHistory(patient.id)
      .then((response) => {
        dispatch(
          showSuccessPopup(
            `${t("Patient")} ${patientDiseaseHistory} ${t("was archived.")}`
          )
        );
        fetchAndSetDashboardData();
      })
      .catch((error) => showHttpRequestError(error));
  }

  const [areAllAdvicesVisible, setAreAllAdvicesVisible] = useState(true);

  function toggleAreAllAdvicesVisible() {
    setAreAllAdvicesVisible((visible) => !visible);
  }

  /**
   * Page title.
   */
  let preTitle =
    patient.status === "ARCHIVED" ? <span>{t("Archived patient")}</span> : null;

  let title = `${t("Received advices for patient")} ${patientDiseaseHistory}`;

  const FinalTitle = () => {
    if (preTitle) {
      return (
        <>
          {preTitle} {title}
        </>
      );
    }
    return title;
  };

  if (!patientId) {
    return t("No patient id");
  }

  return (
    <div className="patient cards-are-single-advices">
      <PageTitle title={FinalTitle} />

      <div className="patients-and-advices">
        <DataTable
          tableData={tableData}
          tableColumns={tableColumns}
          userPermissions={userPermissions}
          userShouldSeeEverything={true}
          isShowingPatients={true}
          isAddNewButtonVisible={patient.status === "ACTIVE"}
          handleAddNew={archivePatientHd}
          addNewButtonLabel={t("Archive patient")}
          areAllAdvicesVisible={areAllAdvicesVisible}
          toggleAreAllAdvicesVisible={toggleAreAllAdvicesVisible}
          CardComponent={CardAdvice}
        />
      </div>
    </div>
  );
}
