import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import userReducer from "./reducers/userSlice";
import modalsReducer from "./reducers/modalsSlice";
import settingsReducer from "./reducers/settingsSlice";

/**
 * Auto close form messages and popup messages after delay.
 */
import {
  showSuccessMessage,
  showErrorMessage,
  showSuccessPopup,
  showErrorPopup,
  showModal,
  showModalHospital,
} from "./reducers/modalsSlice";
import { hideMessage, hideMessagePopup } from "./reducers/modalsSlice";
import { addRequiredClassToInputWrappers } from "../utils/utils";

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: showSuccessMessage,
  effect: async (action, listenerApi) => {
    setTimeout(() => listenerApi.dispatch(hideMessage()), 4000);
  },
});

listenerMiddleware.startListening({
  actionCreator: showErrorMessage,
  effect: async (action, listenerApi) => {
    setTimeout(() => listenerApi.dispatch(hideMessage()), 4000);
  },
});

listenerMiddleware.startListening({
  actionCreator: showSuccessPopup,
  effect: async (action, listenerApi) => {
    setTimeout(() => listenerApi.dispatch(hideMessagePopup()), 4000);
  },
});

listenerMiddleware.startListening({
  actionCreator: showErrorPopup,
  effect: async (action, listenerApi) => {
    setTimeout(() => listenerApi.dispatch(hideMessagePopup()), 4000);
  },
});

listenerMiddleware.startListening({
  actionCreator: showModal,
  effect: async (action, listenerApi) => {
    setTimeout(() => addRequiredClassToInputWrappers(), 100);
  },
});

listenerMiddleware.startListening({
  actionCreator: showModalHospital,
  effect: async (action, listenerApi) => {
    setTimeout(() => addRequiredClassToInputWrappers(), 100);
  },
});

export default configureStore({
  reducer: {
    user: userReducer,
    modals: modalsReducer,
    settings: settingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});
