import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { cloneDeep, isEqual } from "lodash";
import { useOnMount } from "../../hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { AdviceForm, Icon, Flowchart, Question } from "..";
import {
  showDialog,
  isDialogConfirmed,
  showModal,
  showSuccessPopup,
} from "../../redux/reducers";
import Select from "react-select";
import { selectStyles, smallSelectStyles } from "../../styles/selectStyles";
import {
  convertToSelectOptions,
  toggleHtmlElementClass,
} from "../../utils/utils";
import { ReactFlowProvider } from "reactflow";
import { JsonEditor, TestAskForAdvice, Modal } from "../../components";
import WorkflowComparison from "./WorkflowComparison";
import "./Builder.scss";

export default function Builder({
  canCreate,
  canView,
  canUpdate,
  workflow,
  isEditingDraft,
  setWorkflow,
  isComparingVersions,
  draftWorkflowToCompare,
  setDraftWorkflowToCompare,
  workflowToCompare,
  setWorkflowToCompare,
  createApiWorkflow,
  updateApiWorkflow,
  autoUpdateApiWorkflow,
  completeApiWorkflow,
  deleteApiWorkflow,
  firstStepAdvice,
  nextStepAdvice,
}) {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const dispatch = useDispatch();

  /**
   * Data for the form fields.
   */
  const [diseases, setDiseases] = useState([]);
  const [countries, setCountries] = useState([]);
  const [wards, setWards] = useState([]);
  const [bacteria, setBacteria] = useState([]);
  const [medicaments, setMedicaments] = useState([]);
  const [medicamentForms, setMedicamentForms] = useState([]);
  const [doseMeasures, setDoseMeasures] = useState([]);
  const [timeMeasures, setTimeMeasures] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [applicationMethods, setApplicationMethods] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  const hasDataForGraph =
    medicaments.length &&
    medicamentForms.length &&
    doseMeasures.length &&
    timeMeasures.length &&
    applicationMethods.length;

  /**
   * Selected values from the form fields.
   */
  const [selectedDiseaseId, setSelectedDiseaseId] = useState(null);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedWardId, setSelectedWardId] = useState(null);
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  /**
   * Callbacks for values of react-select components.
   */
  const selectedDisease = (disease) => disease.value === selectedDiseaseId;
  const diseaseName = () =>
    selectedDiseaseId && diseases && diseases.length > 0
      ? diseases.find(selectedDisease).label
      : "";
  const selectedCountry = (country) => country.value === selectedCountryId;
  const selectedWard = (ward) => ward.value === selectedWardId;
  const selectedHospital = (hospital) => hospital.value === selectedHospitalId;
  const selectedQuestion = (question) => question.value === selectedQuestionId;

  /**
   * Fetch page data on mount.
   */
  const [originalResponse, setOriginalResponse] = useState(null);

  useOnMount(() => {
    fetchAndSetFormData();
  });

  function fetchAndSetFormData() {
    Promise.allSettled([
      api.getWorkflowDiseases(),
      api.getCountries(),
      api.getWardNames(),
      api.getWorkflowBacteria(),
      api.getMedicaments(),
      api.getWorkflowMedicamentForms(),
      api.getWorkflowMeasures(),
      api.getWorkflowQuestions(),
      api.getWorkflowApplicationMethods(),
    ])
      .then((response) => {
        const diseases = response[0].value.data;
        const countries = response[1].value.data;
        const wards = response[2].value.data;
        const bacteria = response[3].value.data;
        const medicaments = response[4].value.data;
        const medicamentForms = response[5].value.data;
        const doseMeasures = response[6].value.data;
        const questions = response[7].value.data.questions;
        const applicationMethods = response[8].value.data;

        // Save the response to use for searching later.
        setOriginalResponse({
          diseases: diseases,
          countries: countries,
          wards: wards,
          bacteria: bacteria,
          medicaments: medicaments,
          medicamentForms: medicamentForms,
          doseMeasures: doseMeasures,
          questions: questions,
          applicationMethods: applicationMethods,
        });

        setDiseases(convertToSelectOptions(diseases));
        setCountries(convertToSelectOptions(countries));
        setWards(convertToSelectOptions(wards));
        setBacteria(convertToSelectOptions(bacteria));
        setMedicaments(convertToSelectOptions(medicaments));
        setMedicamentForms(convertToSelectOptions(medicamentForms));
        setDoseMeasures(convertToSelectOptions(doseMeasures));
        setTimeMeasures(convertToSelectOptions(doseMeasures));
        setQuestions(convertToSelectOptions(questions));
        setApplicationMethods(convertToSelectOptions(applicationMethods));
      })
      .catch((error) => showHttpRequestError(error));
  }

  /**
   * Workflow JSON Editor.
   */
  const [jsonContent, setJsonContent] = useState(null);
  const [isJsonReadOnly, setIsJsonReadOnly] = useState(false);

  useEffect(setJsonEditorProps, [workflow?.draft]);
  useEffect(setJsonEditorContentToWorkflow, [workflow?.workflowDto]);

  function setJsonEditorProps() {
    setIsJsonReadOnly(!workflow?.draft);
    setJsonContent({
      json: {
        ...workflow?.workflowDto,
      },
    });
  }

  function setJsonEditorContentToWorkflow() {
    setJsonContent({
      json: {
        ...workflow?.workflowDto,
      },
    });
  }

  /**
   * Creating workflow.
   * Depending on isPrevention some workflow fields are required or not.
   */
  const [isPrevention, setIsPrevention] = useState(workflow?.prevention);
  const [isEditingWorkflow, setIsEditingWorkflow] = useState(false);
  const [isViewingSource, setIsViewingSource] = useState(false);

  async function setWorkflowInitialData(
    diseaseId,
    countryId,
    wardId,
    prevention
  ) {
    const dto = {
      ...workflow,
      diseaseId: diseaseId,
      countryId: countryId,
      wardId: wardId,
      prevention: prevention,
    };

    const { error } = await createApiWorkflow(dto);
    if (error !== null) {
      setIsEditingWorkflow(false);
    }
  }

  const baseAdviceHasTreatment =
    workflow?.workflowDto.baseAdvice?.treatments?.length > 0 &&
    workflow?.workflowDto.baseAdvice?.treatments?.[0]?.medicaments?.length >
      0 &&
    workflow?.workflowDto.baseAdvice?.bacteriaIds?.length > 0;

  const baseAdviceIsTreatmentNotRequired =
    workflow?.workflowDto.baseAdvice?.treatments?.[0]?.title &&
    workflow?.workflowDto.baseAdvice?.treatments?.[0]?.medicaments?.length ===
      0;

  const workflowHasAtLeastOneQuestion = workflow?.workflowDto?.block !== null;

  const workflowCanBeTested =
    (baseAdviceHasTreatment && workflowHasAtLeastOneQuestion) ||
    baseAdviceIsTreatmentNotRequired;

  const canAddQuestions =
    baseAdviceHasTreatment || baseAdviceIsTreatmentNotRequired;

  /**
   * Setup the page.
   */
  useEffect(setupPage, [
    workflow?.diseaseId,
    originalResponse,
    workflow?.draft,
  ]);

  function setupPage() {
    setWorkflowProps();
    setBaseAdviceProps();
    updateSelectedQuestions(workflow);

    const isWorkflowCreated =
      (workflow?.countryId && workflow?.diseaseId) ||
      workflow?.workflowInstanceId;

    if (isWorkflowCreated) {
      setIsEditingWorkflow(true);
    }
  }

  function setWorkflowProps() {
    const hospitals = [];
    if (workflow?.hospitalId) {
      hospitals.push({
        value: workflow?.hospitalId,
        label: workflow?.hospitalName,
      });
    }
    setHospitals(hospitals);
    setSelectedDiseaseId(workflow?.diseaseId);
    setSelectedCountryId(workflow?.countryId);
    setSelectedWardId(workflow?.wardId);
    setIsPrevention(workflow?.prevention);
    setSelectedHospitalId(workflow?.hospitalId);
    setJsonContent({
      json: {
        ...workflow?.workflowDto,
      },
    });
  }

  function setBaseAdviceProps() {}

  function updateSelectedQuestions(workflow) {
    const questions = generateQuestionsList(workflow);
    setSelectedQuestions(questions);
  }

  /**
   * Finishing the draft workflow.
   */
  const wokflowHasAtLeastOneQuestion =
    workflow.workflowDto?.block?.branch?.questionId;

  const workflowCanBeFinished = workflow?.draft && wokflowHasAtLeastOneQuestion;

  /**
   * Selected questions list.
   */
  function generateQuestionsList(w) {
    const wCopy = cloneDeep(w);
    const block = wCopy?.workflowDto?.block;
    if (!block || !originalResponse) return [];

    let baseAdvice = wCopy?.workflowDto?.baseAdvice;
    let sqs = [];
    let questionIndex = 0;
    prepareSelectedQuestion(sqs, block.branch, questionIndex, baseAdvice);
    let branch = block.branch;

    function addNextQuestion(branch, parentQuestionId, parentQuestionIndex) {
      if (branch == null) return;
      branch.questionIndex = questionIndex;

      if (branch.answer?.hasOwnProperty("block")) {
        if (!branch.answer.block) {
          return;
        }
        questionIndex = questionIndex + 1;

        prepareSelectedQuestion(
          sqs,
          branch.answer.block.branch,
          questionIndex,
          baseAdvice,
          parentQuestionId,
          parentQuestionIndex
        );

        addNextQuestion(
          branch.answer.block.branch,
          branch.questionId,
          branch.questionIndex || questionIndex
        );
      }

      if (branch?.answer?.value.length > 0) {
        branch?.answer?.value.forEach((answer) => {
          if (answer?.hasOwnProperty("block")) {
            if (!answer.block) return;

            questionIndex = questionIndex + 1;

            prepareSelectedQuestion(
              sqs,
              answer.block.branch,
              questionIndex,
              baseAdvice,
              parentQuestionId,
              parentQuestionIndex,
              answer.answerId
            );

            addNextQuestion(
              answer.block.branch,
              answer.block.branch.questionId,
              answer.block.branch.questionIndex || questionIndex
            );
          }
        });
      }
    }

    addNextQuestion(branch, block.branch.questionId, 0);
    setWorkflow(wCopy);
    return sqs;
  }

  function prepareWorkflowForGraph(w) {
    const wCopy = cloneDeep(w);
    const block = wCopy?.workflowDto?.block;
    if (!block || !originalResponse) return [];

    let baseAdvice = wCopy?.workflowDto?.baseAdvice;
    let sqs = [];
    let questionIndex = 0;
    prepareSelectedQuestion(sqs, block.branch, questionIndex, baseAdvice);
    let branch = block.branch;

    function addNextQuestion(branch, parentQuestionId, parentQuestionIndex) {
      if (branch == null) return;
      branch.questionIndex = questionIndex;

      if (branch.answer?.hasOwnProperty("block")) {
        if (!branch.answer.block) {
          return;
        }
        questionIndex = questionIndex + 1;

        prepareSelectedQuestion(
          sqs,
          branch.answer.block.branch,
          questionIndex,
          baseAdvice,
          parentQuestionId,
          parentQuestionIndex
        );

        addNextQuestion(
          branch.answer.block.branch,
          branch.questionId,
          branch.questionIndex || questionIndex
        );
      }

      if (branch?.answer?.value.length > 0) {
        branch?.answer?.value.forEach((answer) => {
          if (answer?.hasOwnProperty("block")) {
            if (!answer.block) return;

            questionIndex = questionIndex + 1;

            prepareSelectedQuestion(
              sqs,
              answer.block.branch,
              questionIndex,
              baseAdvice,
              parentQuestionId,
              parentQuestionIndex,
              answer.answerId
            );

            addNextQuestion(
              answer.block.branch,
              answer.block.branch.questionId,
              answer.block.branch.questionIndex || questionIndex
            );
          }
        });
      }
    }

    addNextQuestion(branch, block.branch.questionId, 0);
    return wCopy;
  }

  function removeQuestionFromWorkflow(question) {
    const workflowCopy = cloneDeep(workflow);
    let block = workflowCopy?.workflowDto?.block;
    if (!block) return;

    let branch = block?.branch;
    const isRemovingFirstQuestion =
      question?.questionIndex ===
      workflowCopy.workflowDto.block?.branch?.questionIndex;

    removeBranch(branch);

    function removeBranch(branch) {
      if (branch == null) return;

      if (isRemovingFirstQuestion) {
        workflowCopy.workflowDto.block = null;
        return;
      }

      if (branch.answer?.hasOwnProperty("block")) {
        if (!branch.answer.block) return;

        if (
          branch.answer.block.branch.questionIndex == question.questionIndex
        ) {
          branch.answer.block = null;
          return;
        }
        removeBranch(branch.answer.block.branch);
      }

      if (branch?.answer?.value.length > 0) {
        branch?.answer?.value.forEach((answer) => {
          if (answer?.hasOwnProperty("block")) {
            if (!answer.block) return;

            if (answer.block.branch.questionIndex == question.questionIndex) {
              answer.block = null;
              return;
            }
            removeBranch(answer.block.branch);
          }
        });
      }
    }

    if (!isRemovingFirstQuestion) {
      workflowCopy.workflowDto = {
        ...workflowCopy.workflowDto,
        block: {
          ...block,
          branch: branch,
        },
      };
    }

    return workflowCopy;
  }

  function addQuestionInWorkflow(
    questionWithAnswers,
    w,
    parentQuestionWithAnswers = null,
    parentAnswerId = null
  ) {
    const workflowCopy = cloneDeep(w);
    if (questionWithAnswers && !questionWithAnswers.questionIndex) {
      questionWithAnswers.questionIndex = 0;
    }
    const newBlock = createWorkflowBlock(
      questionWithAnswers.id,
      questionWithAnswers.questionIndex
    );

    let block = addNewBranchByQuestionId(
      workflowCopy.workflowDto.block,
      newBlock,
      parentQuestionWithAnswers,
      parentAnswerId
    );
    workflowCopy.workflowDto = {
      ...workflowCopy.workflowDto,
      block: block,
    };
    return workflowCopy;
  }

  async function removeSelectedQuestion(question) {
    const dialog = {
      title: t("Remove question?"),
      message: t("This will also remove all its child questions."),
      buttonConfirmText: t("Remove"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    dispatch(showDialog(dialog));
    const isActionConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isActionConfirmed) return;

    const w = removeQuestionFromWorkflow(question);
    setWorkflow(w);
    updateSelectedQuestions(w);
    autoUpdateApiWorkflow(w);
  }

  function addSelectedQuestion(
    id,
    parentQuestionWithAnswers = null,
    parentAnswerId = null
  ) {
    const questionWithAnswers = originalResponse.questions.find(
      (q) => q.id === id
    );

    const parentAnswer = parentQuestionWithAnswers?.answers?.find(
      (a) => a.id === parentAnswerId
    );
    const result = {
      ...questionWithAnswers,
      questionIndex:
        parentQuestionWithAnswers &&
        parentQuestionWithAnswers.questionIndex >= 0
          ? selectedQuestions.length
          : 0,
    };

    const w = addQuestionInWorkflow(
      result,
      workflow,
      parentQuestionWithAnswers,
      parentAnswerId
    );
    setWorkflow(w);
    updateSelectedQuestions(w);
    autoUpdateApiWorkflow(w);
  }

  function updateSelectedQuestion(
    id,
    parentQuestionWithAnswers = null,
    parentAnswerId = null
  ) {
    const workflowCopy = cloneDeep(workflow);
    const block = workflowCopy?.workflowDto?.block;
    if (!block) {
      return;
    }
    let branch = block.branch;

    const oldQuestion = findQuestionBranch(
      parentQuestionWithAnswers,
      parentAnswerId,
      branch
    );

    const w = removeQuestionFromWorkflow(oldQuestion);
    const list = generateQuestionsList(w);

    const questionWithAnswers = originalResponse.questions.find(
      (q) => q.id === id
    );

    const parentAnswer = parentQuestionWithAnswers?.answers?.find(
      (a) => a.id === parentAnswerId
    );
    const result = {
      ...questionWithAnswers,
      questionIndex:
        parentQuestionWithAnswers &&
        parentQuestionWithAnswers.questionIndex >= 0
          ? list.length
          : 0,
    };
    const newW = addQuestionInWorkflow(
      result,
      w,
      parentQuestionWithAnswers,
      parentAnswerId
    );
    setWorkflow(newW);
    updateSelectedQuestions(newW);
    autoUpdateApiWorkflow(newW);
  }

  function findQuestionBranch(question, answerId, branch) {
    let result = null;

    if (branch == null) {
      return result;
    }

    if (branch.answer?.hasOwnProperty("block")) {
      if (
        question.questionIndex == branch.questionIndex &&
        branch.answer.answerId == answerId
      ) {
        result = branch.answer.block.branch;
        return result;
      }

      result = findQuestionBranch(
        question,
        answerId,
        branch.answer.block?.branch
      );

      if (result) {
        return result;
      }
    }
    if (branch?.answer?.value.length > 0) {
      for (let i = 0; i < branch?.answer?.value.length; i++) {
        let answer = branch?.answer?.value[i];
        if (answer?.hasOwnProperty("block")) {
          if (
            question.questionIndex == branch.questionIndex &&
            answer.answerId == answerId
          ) {
            result = answer.block.branch;
            return result;
          } else {
          }

          result = findQuestionBranch(question, answerId, answer.block?.branch);
          if (result) {
            return result;
          }
        }
      }
    }
    return result;
  }

  function updateAdviceAnswerBranch(advice, question, answerId, branch) {
    if (branch == null) return;

    if (branch.answer?.type == "input") {
      if (question.questionIndex == branch.questionIndex) {
        branch.answer.changeAdvice = advice;
        return;
      }

      updateAdviceAnswerBranch(
        advice,
        question,
        answerId,
        branch.answer.block?.branch
      );
    }
    if (branch?.answer?.value.length > 0) {
      for (let i = 0; i < branch?.answer?.value.length; i++) {
        let answer = branch?.answer?.value[i];

        if (
          question.questionIndex == branch.questionIndex &&
          answer.answerId == answerId
        ) {
          answer.changeAdvice = advice;
          return;
        }

        if (!answer.block) {
          continue;
        }

        updateAdviceAnswerBranch(
          advice,
          question,
          answerId,
          answer.block.branch
        );
      }
    }
    return;
  }

  async function deleteWorkflow() {
    let dialog = {
      title: t(`Delete draft workflow?`),
      message: t("You will lose all entered advices and questions."),
      buttonConfirmText: t("Discard"),
      buttonCancelText: t("Cancel"),
      isWarning: true,
    };

    if (!workflow.draft) {
      dialog = {
        title: t(`Delete this workflow?`),
        message: t("You will lose all entered advices and questions."),
        buttonConfirmText: t("Delete"),
        buttonCancelText: t("Cancel"),
        isWarning: true,
      };
    }

    dispatch(showDialog(dialog));
    const isActionConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isActionConfirmed) return;

    await deleteApiWorkflow(workflow);

    if (workflow.draft) {
      dispatch(showSuccessPopup(t("Draft deleted.")));
    } else {
      dispatch(showSuccessPopup(t("Workflow deleted.")));
    }
  }

  /**
   * Saving advices - base and corrected.
   */
  async function saveBaseAdvice(advice) {
    let w = {
      ...workflow,
      workflowDto: {
        ...workflow?.workflowDto,
        baseAdvice: advice,
      },
    };
    setWorkflow(w);
    return autoUpdateApiWorkflow(w);
  }

  async function saveCorrectedAdvice(advice, question, answer) {
    const workflowCopy = cloneDeep(workflow);
    const block = workflowCopy?.workflowDto?.block;
    if (!block) return;

    let branch = block.branch;
    updateAdviceAnswerBranch(advice, question, answer?.id, branch);

    workflowCopy.workflowDto = {
      ...workflowCopy.workflowDto,
      block: {
        ...block,
        branch: branch,
      },
    };

    setWorkflow(workflowCopy);
    updateSelectedQuestions(workflowCopy);
    return autoUpdateApiWorkflow(workflowCopy);
  }

  /**
   * Building the workflow tree - linking question answers to targets:
   * (1) next question (extends the branch)
   * (2) treatment (ends the branch)
   */
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(true);

  useEffect(enableStartButton, [
    selectedDiseaseId,
    selectedCountryId,
    selectedWardId,
    isStartButtonDisabled,
  ]);

  function enableStartButton() {
    if (!isStartButtonDisabled) return;
    if (selectedDiseaseId && selectedCountryId && selectedWardId) {
      setIsStartButtonDisabled(false);
    }
  }

  /**
   * Questions.
   */
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const hasSelectedQuestions = selectedQuestions?.length > 0;

  function prepareSelectedQuestion(
    selectedQuestions,
    branch,
    questionIndex,
    baseAdvice,
    parentQuestionId = null,
    parentQuestionIndex = null,
    parentAnswerId = null
  ) {
    const questionWithAnswers = cloneDeep(
      originalResponse.questions.find((q) => q.id === branch.questionId)
    );
    const parentQuestionWithAnswers = selectedQuestions?.find(
      (q) =>
        q.id === parentQuestionId && q.questionIndex === parentQuestionIndex
    );
    const parentAnswer = parentQuestionWithAnswers?.answers?.find(
      (a) => a.id === parentAnswerId
    );

    const parentAdvice =
      parentAnswer?.advice || parentQuestionWithAnswers?.advice;

    if (branch?.answer.type == "list") {
      questionWithAnswers.answers.forEach((answer) => {
        const a = branch?.answer.value.find((a) => a.answerId == answer.id);
        if (a.answerTarget) answer.answerTarget = a.answerTarget;
        else answer.answerTarget = a.block ? "question" : "advice";
        if (a.adviceTarget) answer.adviceTarget = a.adviceTarget;
        else answer.adviceTarget = a.changeAdvice ? "corrected" : "base";

        answer.advice = a.changeAdvice || parentAdvice || baseAdvice;
      });
    }
    if (branch?.answer.type == "input") {
      if (branch?.answer.answerTarget)
        questionWithAnswers.answerTarget = branch?.answer.answerTarget;
      else
        questionWithAnswers.answerTarget = branch?.answer.block
          ? "question"
          : "advice";
      if (branch?.answer.adviceTarget)
        questionWithAnswers.adviceTarget = branch?.answer.adviceTarget;
      else
        questionWithAnswers.adviceTarget = branch?.answer.changeAdvice
          ? "corrected"
          : "base";

      questionWithAnswers.advice =
        branch?.answer.changeAdvice || parentAdvice || baseAdvice;
    }

    selectedQuestions.push({
      ...questionWithAnswers,
      parentQuestionId: parentQuestionId,
      parentQuestionIndex: parentQuestionIndex,
      parentAnswerId: parentAnswerId,
      parentAnswerName: parentAnswer?.name,
      questionIndex: questionIndex,
    });
    return selectedQuestions;
  }

  function addNewBranchByQuestionId(
    block,
    newBlock,
    parentQuestionWithAnswers = null,
    parentAnswerId = null
  ) {
    if (parentQuestionWithAnswers == null && parentAnswerId == null) {
      block = newBlock;
      return block;
    }

    if (
      block?.branch?.questionIndex === parentQuestionWithAnswers.questionIndex
    ) {
      if (block?.branch.answer.type == "input") {
        block.branch.answer = {
          ...block?.branch.answer,
          answerTarget: "question",
          block: newBlock,
        };
        return block;
      }
      if (block?.branch?.answer?.type == "list") {
        for (let i = 0; i < block?.branch?.answer?.value.length; i++) {
          let answer = block?.branch?.answer?.value[i];
          if (answer.answerId == parentAnswerId) {
            block.branch.answer.value[i] = {
              ...block.branch.answer.value[i],
              answerTarget: "question",
              block: newBlock,
            };
            return block;
          }
        }
      }
    }
    // For input types of answers
    if (block?.branch.answer.type == "input") {
      addNewBranchByQuestionId(
        block.branch.answer.block,
        newBlock,
        parentQuestionWithAnswers,
        parentAnswerId
      );
    }

    // For list types of answers
    if (block?.branch?.answer?.type == "list") {
      block?.branch?.answer?.value.forEach((answer) => {
        if (answer?.hasOwnProperty("block")) {
          addNewBranchByQuestionId(
            answer.block,
            newBlock,
            parentQuestionWithAnswers,
            parentAnswerId
          );
        }
      });
    }

    return block;
  }

  /**
   * Add question the workflow.
   */

  function createWorkflowBlock(questionId, questionIndex) {
    const questionToCreateFor = originalResponse.questions.find(
      (q) => q.id === questionId
    );

    const questionAnswers = questionToCreateFor.answers;
    const answerType = questionAnswers.length > 0 ? "list" : "input";
    let answerObject = null;

    if (answerType === "list") {
      answerObject = createListAnswerObject(questionAnswers);
    }

    if (answerType === "input") {
      answerObject = createInputAnswerObject();
    }

    return {
      info: "",
      branch: {
        questionId: questionId,
        questionIndex: questionIndex,
        answer: answerObject,
      },
    };
  }

  function createListAnswerObject(answers) {
    return {
      type: "list",
      value: answers.map((answer) => ({
        answerId: answer.id,
        changeAdvice: null,
        answerTarget: "none",
        adviceTarget: "none",
      })),
    };
  }

  // TODO: Finish this.
  function createInputAnswerObject(answers) {
    return {
      type: "input",
      value: 0,
      answerTarget: "none",
      adviceTarget: "none",
      changeAdvice: getAdviceForBlock(), // TODO: Get changed advice or don't include this.
    };
  }

  function getAdviceForBlock(answerTarget, adviceTarget) {
    const baseAdvice = cloneDeep(workflow.workflowDto.baseAdvice);

    return baseAdvice;
  }

  function showAskAdvice(workflow) {
    dispatch(
      showModal({
        id: `testAdviceModal-${workflow.diseaseId}`,
      })
    );
  }

  /**
   * Scroll to question when clicking it on the graph.
   */
  const questionsRef = useRef(null);

  function scrollToQuestion(index) {
    const question = questionsRef.current.querySelector(
      `[data-index="${index}"]`
    );
    if (!question) return;

    question.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    toggleHtmlElementClass(question, "highlighted", 2000);
  }

  function highlightAnswer(questionIndex, label) {
    const question = questionsRef.current.querySelector(
      `[data-index="${questionIndex}"]`
    );
    if (!question) return;

    const answers = question.querySelectorAll(".flex-input-wrapper.answer");

    for (const answer of answers) {
      const answerText = answer.querySelector(".name").textContent.trim();
      if (answerText === label) {
        toggleHtmlElementClass(answer, "highlighted", 1700);
      }
    }
  }

  /**
   * Center the graph on clicked question node.
   */
  const [centeredQuestionIndex, setCenteredQuestionIndex] = useState(null);

  /**
   * Saving advice.
   */
  const isSavingAdvice = useRef(false);

  /**
   * Comparing completed and draft workflow versions side by side.
   */
  const areComparedWorkflowsPrepared = useRef(false);

  useEffect(() => {
    if (
      isComparingVersions &&
      !areComparedWorkflowsPrepared.current &&
      workflowToCompare !== null &&
      draftWorkflowToCompare !== null
    ) {
      setDraftWorkflowToCompare(
        prepareWorkflowForGraph(draftWorkflowToCompare)
      );
      setWorkflowToCompare(prepareWorkflowForGraph(workflowToCompare));
      areComparedWorkflowsPrepared.current = true;
    }
  }, [isComparingVersions, draftWorkflowToCompare, workflowToCompare]);

  if (!canView) {
    return <p>{t("You don't have permissions to view this page.")}</p>;
  }

  if (isComparingVersions && hasDataForGraph && baseAdviceHasTreatment) {
    return (
      <WorkflowComparison
        workflowToCompare={workflowToCompare}
        draftWorkflowToCompare={draftWorkflowToCompare}
        originalResponse={originalResponse}
        diseases={diseases}
        bacteria={bacteria}
        medicaments={medicaments}
        medicamentForms={medicamentForms}
        doseMeasures={doseMeasures}
        timeMeasures={timeMeasures}
        applicationMethods={applicationMethods}
        scrollToQuestion={scrollToQuestion}
        centeredQuestionIndex={centeredQuestionIndex}
        highlightAnswer={highlightAnswer}
      />
    );
  }

  return (
    <div className="builder">
      <div className="advice-builder-wrapper">
        <div className="advice-form">
          <div className="workflow-for">
            <div className="flex-input-wrapper">
              <div className="input-wrapper">
                <label htmlFor="diseaseId">{t("Disease:")}</label>
                <Select
                  name="diseaseId"
                  options={diseases}
                  isSearchable={true}
                  required
                  styles={smallSelectStyles}
                  openMenuOnClick
                  openMenuOnFocus
                  value={diseases.find(selectedDisease) ?? null}
                  onChange={(e) => setSelectedDiseaseId(e.value)}
                  isDisabled={isEditingWorkflow}
                />
              </div>

              {!workflow?.workflowInstanceId && (
                <div className="input-wrapper">
                  <label htmlFor="countryId">{t("Country:")}</label>
                  <Select
                    name="countryId"
                    options={countries}
                    isSearchable={true}
                    required
                    styles={smallSelectStyles}
                    openMenuOnClick
                    openMenuOnFocus
                    value={countries.find(selectedCountry) ?? null}
                    onChange={(e) => setSelectedCountryId(e.value)}
                    isDisabled={isEditingWorkflow}
                  />
                </div>
              )}

              {workflow?.workflowInstanceId && (
                <div className="input-wrapper">
                  <label htmlFor="hospitalId">{t("Hospital:")}</label>
                  <Select
                    name="hospital"
                    options={hospitals}
                    required
                    styles={smallSelectStyles}
                    openMenuOnClick
                    openMenuOnFocus
                    value={hospitals.find(selectedHospital) ?? null}
                    onChange={(e) => setSelectedHospitalId(e.value)}
                    isDisabled={true}
                  />
                </div>
              )}

              <div className="input-wrapper">
                <label htmlFor="wardId">{t("Ward:")}</label>
                <Select
                  name="wardId"
                  options={wards}
                  isSearchable={true}
                  required
                  styles={smallSelectStyles}
                  openMenuOnClick
                  openMenuOnFocus
                  value={wards.find(selectedWard) ?? null}
                  onChange={(e) => setSelectedWardId(e.value)}
                  isDisabled={isEditingWorkflow}
                />
              </div>
            </div>

            <div className="flex-input-wrapper">
              <div className="input-wrapper no-margins">
                <label>{t("Type:")}</label>
                <input
                  type="radio"
                  name="prevention"
                  id="prevention"
                  checked={isPrevention}
                  onChange={(e) => setIsPrevention((prev) => !prev)}
                  disabled={isEditingWorkflow}
                />
                <label htmlFor="prevention">{t("Prophylaxis")}</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  name="prevention"
                  id="treatment"
                  checked={!isPrevention}
                  onChange={(e) => setIsPrevention((prev) => !prev)}
                  disabled={isEditingWorkflow}
                />
                <label htmlFor="treatment">{t("Treatment")}</label>
              </div>

              {!isEditingWorkflow && (
                <button
                  type="button"
                  onClick={() => {
                    setIsEditingWorkflow(true);
                    setWorkflowInitialData(
                      selectedDiseaseId,
                      selectedCountryId,
                      selectedWardId,
                      isPrevention
                    );
                  }}
                  disabled={isStartButtonDisabled}
                >
                  {t("Start")}
                </button>
              )}
            </div>
          </div>

          <div className="workflow-actions">
            <div className="action-button">
              {workflow?.workflowVersion &&
                !workflow?.draftVersion &&
                !workflow?.draft && (
                  <button
                    className="update-advice smaller"
                    onClick={() => {
                      updateApiWorkflow(workflow);
                    }}
                  >
                    {t("Create Draft")}
                  </button>
                )}

              {workflowCanBeTested && (
                <button
                  className="test-advice smaller icon-left"
                  onClick={() => {
                    showAskAdvice(workflow);
                  }}
                >
                  <Icon name="lightbulb" />
                  {t("Test")}
                </button>
              )}

              {workflowCanBeFinished && (
                <button
                  className="create-advice smaller icon-left"
                  onClick={() => {
                    completeApiWorkflow(workflow);
                  }}
                >
                  <Icon name="check" />
                  {t("Finish Draft")}
                </button>
              )}

              {isEditingWorkflow && (
                <button
                  className="secondary smaller ml-auto mr-0 icon-left"
                  type="button"
                  onClick={deleteWorkflow}
                >
                  <Icon name="delete" />
                  {workflow?.draft && t("Delete Draft")}
                  {!workflow?.draft && t("Delete Workflow")}
                </button>
              )}
            </div>
          </div>

          {isEditingWorkflow && (
            <>
              <div className={`tabs ${workflow?.draft ? "" : "d-none"}`}>
                <div className="tabs-header">
                  <button
                    className={`${!isViewingSource ? "secondary active" : ""}`}
                    type="button"
                    onClick={() => {
                      setIsViewingSource(false);
                    }}
                  >
                    {t("Design")}
                  </button>

                  <button
                    className={`${isViewingSource ? "secondary active" : ""}`}
                    type="button"
                    onClick={() => {
                      setIsViewingSource(true);
                    }}
                  >
                    {t("Source")}
                  </button>
                </div>
              </div>

              {!isViewingSource && (
                <div
                  className={`${
                    workflow?.draft
                      ? "advice-builder-design"
                      : "advice-builder-design disabled d-none"
                  }`}
                >
                  <div
                    className={`workflow-treatment ${
                      !isEditingWorkflow ? "disabled" : ""
                    }`}
                  >
                    <h2>1. {t("Base Advice")}</h2>

                    <AdviceForm
                      isPrevention={isPrevention}
                      medicaments={medicaments}
                      medicamentForms={medicamentForms}
                      doseMeasures={doseMeasures}
                      timeMeasures={timeMeasures}
                      applicationMethods={applicationMethods}
                      bacteria={bacteria}
                      canCreate={canCreate}
                      canUpdate={canUpdate}
                      workflow={workflow}
                      setWorkflow={setWorkflow}
                      isBaseAdvice={true}
                      advice={workflow?.workflowDto.baseAdvice}
                      saveAdvice={saveBaseAdvice}
                      isSavingAdvice={isSavingAdvice}
                      isEditingDraft={isEditingDraft}
                    />
                  </div>

                  <div
                    className={`workflow-questions questions ${
                      !canAddQuestions ? "disabled" : ""
                    }`}
                    ref={questionsRef}
                  >
                    <h2>2. {t("Questions")}</h2>

                    {!hasSelectedQuestions && (
                      <div className="flex-input-wrapper mb-7">
                        <div className="input-wrapper">
                          <label htmlFor="question">
                            {t("First question")}:
                          </label>
                          <Select
                            name="question"
                            options={questions}
                            isSearchable={true}
                            required
                            styles={selectStyles}
                            openMenuOnClick
                            openMenuOnFocus
                            value={questions.find(selectedQuestion) ?? null}
                            onChange={(e) => setSelectedQuestionId(e.value)}
                            menuPlacement="auto"
                          />
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            addSelectedQuestion(selectedQuestionId);
                          }}
                          disabled={!selectedQuestionId}
                        >
                          <Icon name="plus" />
                          {t("Add question")}
                        </button>
                      </div>
                    )}

                    {hasSelectedQuestions &&
                      selectedQuestions.map((question, index) => (
                        <Question
                          key={index + question.id}
                          question={question}
                          questions={questions}
                          originalQuestions={originalResponse.questions}
                          questionIndex={question.questionIndex}
                          workflow={workflow}
                          setWorkflow={setWorkflow}
                          addSelectedQuestion={addSelectedQuestion}
                          updateSelectedQuestion={updateSelectedQuestion}
                          removeSelectedQuestion={removeSelectedQuestion}
                          saveAdvice={saveCorrectedAdvice}
                          isPrevention={isPrevention}
                          medicaments={medicaments}
                          medicamentForms={medicamentForms}
                          doseMeasures={doseMeasures}
                          timeMeasures={timeMeasures}
                          applicationMethods={applicationMethods}
                          bacteria={bacteria}
                          setCenteredQuestionIndex={setCenteredQuestionIndex}
                          isSavingAdvice={isSavingAdvice}
                          isEditingDraft={isEditingDraft}
                          zIndex="auto" //1050 -index } // + index to make sure parent questions' dropdowns are always visible
                        />
                      ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {hasDataForGraph &&
          !isViewingSource &&
          (baseAdviceHasTreatment || baseAdviceIsTreatmentNotRequired) && (
            <div className="advice-chart">
              <ReactFlowProvider>
                <Flowchart
                  workflow={workflow}
                  originalResponse={originalResponse}
                  bacteria={bacteria}
                  medicaments={medicaments}
                  medicamentForms={medicamentForms}
                  doseMeasures={doseMeasures}
                  timeMeasures={timeMeasures}
                  applicationMethods={applicationMethods}
                  scrollToQuestion={scrollToQuestion}
                  centeredQuestionIndex={centeredQuestionIndex}
                  highlightAnswer={highlightAnswer}
                />
              </ReactFlowProvider>
            </div>
          )}
      </div>

      {isViewingSource && (
        <div className="workflow-actions">
          <JsonEditor
            content={jsonContent}
            readOnly={isJsonReadOnly}
            onChange={(data, previousContent, changeStatus) => {
              if (!workflow?.draft) {
                return;
              }

              if (
                changeStatus.contentErrors &&
                (changeStatus.contentErrors.parseError ||
                  (changeStatus.contentErrors.validationErrors &&
                    changeStatus.contentErrors.validationErrors.length > 0))
              ) {
                return;
              }

              const text = data.text ? JSON.parse(data.text) : undefined;
              const prevContentText = previousContent.text
                ? JSON.parse(previousContent.text)
                : undefined;

              if (data.json) {
                if (
                  (previousContent.json &&
                    isEqual(data.json, previousContent.json)) ||
                  (!previousContent.json && isEqual(data.json, prevContentText))
                ) {
                  return;
                }
              }

              if (text) {
                if (
                  (previousContent.json &&
                    isEqual(text, previousContent.json)) ||
                  (!previousContent.json && isEqual(text, prevContentText))
                ) {
                  return;
                }
              }

              const draftWorkflow = {
                ...workflow,
                workflowDto: text || data.json,
              };

              setJsonContent(data);
              autoUpdateApiWorkflow(draftWorkflow);
            }}
          ></JsonEditor>
        </div>
      )}

      {workflowCanBeTested && (
        <Modal
          id={`testAdviceModal-${workflow.diseaseId}`}
          title={`${t("Workflow for")} ${diseaseName()}`}
          innerComponent={
            <TestAskForAdvice
              testWorkflow={workflow}
              firstStepAdvice={firstStepAdvice}
              nextStepAdvice={nextStepAdvice}
            />
          }
          isExtraLarge
          customClasses={"testing"}
        />
      )}
    </div>
  );
}
