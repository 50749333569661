import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetPermissionsForPage, useOnMount } from "../../hooks/hooks";
import { ApiRequests, showHttpRequestError } from "../../http";
import { DataTable, Icon, PageTitle } from "../../components";
import { useTranslation } from "react-i18next";
import { showDialog, isDialogConfirmed } from "../../redux/reducers";
import { showSuccessPopup } from "../../redux/reducers";
import Modal from "../../components/Modal/Modal";
import { showModal } from "../../redux/reducers";
import { TestAskForAdvice } from "../../components";
import { CardDefault } from "../../components/Cards";
import { v4 as generateUUIDv4 } from "uuid";
import "./HospitalWorkflows.scss";

export default function HospitalWorkflows() {
  const { t } = useTranslation();
  const api = new ApiRequests();
  const [tableData, setTableData] = useState([]);
  const userPermissions = useGetPermissionsForPage("workflowInstance");
  const userWorkflowPermissions = useGetPermissionsForPage("adviceBuilder");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { canUpdate, canDelete, canAccept, canReject, canRequest } =
    userPermissions;
  const { canTest } = userWorkflowPermissions;
  const user = useSelector((state) => state.user);
  const [workflow, setWorkflow] = useState();
  const [diseaseName, setDiseaseName] = useState();

  useOnMount(() => {
    const hasTableData = !!tableData.length;
    if (!hasTableData) fetchTableData();
  });

  async function fetchTableData() {
    const statistics = await getWorkflows();
    setTableData(statistics);
  }

  async function getWorkflows() {
    return api
      .getWorkflowInstances(user.contextId)
      .then((response) => response.data)
      .catch((error) => showHttpRequestError(error));
  }

  async function pushAllWorkflowsInHospital(hospitalWorkflows) {
    const { hospitalId, hospitalName, missingWorkflow } = hospitalWorkflows;

    const deleteDialog = {
      title: `Add all workflows to ${hospitalName}?`,
      message: `${missingWorkflow.length} not-included workflows will be added.`,
      buttonConfirmText: t("Add workflows"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .addAllWorkflowInstancesToHospital({ hospitalId: hospitalId })
      .then((response) => {
        dispatch(
          showSuccessPopup(`${t("All workflows added to")} ${hospitalName}`)
        );
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error));
  }

  const tableColumns = [
    {
      header: "Results",
      footer: (props) => props.column.id,
      columns: [
        {
          accessorKey: "hospitalName",
          id: "name",
          header: () => <span>{t("Hospital")}</span>,
          cell: (info) => info.getValue(),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "workflowInstance",
          header: () => <span></span>,
          cell: (info) =>
            info.getValue().length > 0 && (
              <div>
                <h4 className="mt-2">{t("Active")}</h4>
                {info.getValue().map((w, i) => {
                  return showWorkflowInstanceRow(w, info);
                })}
              </div>
            ),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "updatedWorkflow",
          header: () => <span></span>,
          cell: (info) =>
            info.getValue().length > 0 && (
              <div>
                <h4>{t("With new version")}</h4>
                {info.getValue().map((w, i) => {
                  return showUpdateWorkflowRow(w, info);
                })}
              </div>
            ),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "missingWorkflow",
          header: () => <span></span>,
          cell: (info) =>
            info.getValue().length > 0 && (
              <div>
                <h4>{t("Not included")}</h4>

                {info.getValue().map((w, i) => {
                  let dto = {
                    workflowId: w.workflowId,
                    hospitalId: info.row.original.hospitalId,
                  };
                  return showMissingWorkflowRow(w, dto);
                })}

                {user.isAdmin && (
                  <div className="wi-row push-all">
                    <div className="wi-name">Add all</div>
                    <div>
                      <button
                        className="btn secondary smaller icon-only btn-push-all"
                        title="Add all workflows to this hospital at once"
                        onClick={() =>
                          pushAllWorkflowsInHospital(info.row.original)
                        }
                      >
                        <Icon name="plus" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ),
          footer: (props) => props.column.id,
        },
      ],
    },
  ];

  function showWorkflowInstanceRow(w, info) {
    return (
      <div className="wi-row" key={generateUUIDv4()}>
        <div>
          <div className="wi-name" title={w.diseaseName}>
            {w.diseaseName}{" "}
          </div>
        </div>

        <div className="tags">
          <span className="tag" title="Current workflow version">
            v{w.instanceVersion}
          </span>

          {w.draftVersion && (
            <span className="tag emphasized" title="Draft workflow version">
              v{w.draftVersion}
            </span>
          )}
        </div>

        <div className="wi-inner-row">
          {canUpdate && (
            <button
              className="td-action edit secondary smaller icon-only"
              onClick={() => navigate(`/hospital-workflow-builder?id=${w.id}`)}
              title={t("Edit workflow")}
            >
              <Icon name="pencil" /> {!user.isAdmin && t("Edit")}
            </button>
          )}

          {canDelete && (
            <button
              className="td-action edit secondary smaller icon-only"
              onClick={(e) => {
                e.stopPropagation();
                deleteWorkflowInstance(w, info.row.original);
              }}
              title={t("Delete Workflow")}
            >
              <Icon name="delete" /> {!user.isAdmin && t("Delete")}
            </button>
          )}
        </div>
      </div>
    );
  }

  function showUpdateWorkflowRow(w, info) {
    return (
      <div className="wi-row" key={generateUUIDv4()}>
        <div>
          <div className="wi-name" title={w.diseaseName}>
            {w.diseaseName}{" "}
          </div>
        </div>

        <div className="tags">
          <span className="tag" title="New workflow version">
            v{w.workflowVersion}
          </span>
        </div>

        <div className="wi-inner-row">
          <button
            className="td-action edit smaller icon-only"
            onClick={() => {
              api.upgradeWorkflowInstance(w.id).then(() => {
                fetchTableData();
              });
            }}
            title={t("Upgrade workflow to latest version")}
          >
            <Icon name="plus" /> {!user.isAdmin && t("Upgrade")}
          </button>

          {canTest && (
            <button
              className="td-action edit smaller icon-only"
              onClick={() => {
                showAskAdvice(w);
              }}
              title={t("Test workflow")}
            >
              <Icon name="lightbulb" /> {!user.isAdmin && t("Test")}
            </button>
          )}
        </div>
      </div>
    );
  }

  function showMissingWorkflowRow(w, dto) {
    return (
      <div className="wi-row" key={generateUUIDv4()}>
        <div>
          <div className="wi-name" title={w.diseaseName}>
            {w.diseaseName}{" "}
          </div>
        </div>

        {canRequest && w.requested && (
          <div className="tags">
            <span className="tag emphasized">{t("Requested")}</span>
          </div>
        )}

        <div className="wi-inner-row">
          {canRequest && w.canRequest && !w.requested && (
            <button
              className="td-action edit smaller icon-only"
              onClick={() => {
                api.requestWorkflow(dto.hospitalId, w.workflowId).then(() => {
                  fetchTableData();
                });
              }}
              title={t("Request workflow")}
            >
              <Icon name="plus" />
              {!user.isAdmin && t("Request workflow")}
            </button>
          )}

          {canAccept && w.requested && (
            <button
              className="td-action edit smaller icon-only"
              onClick={() => {
                api.acceptWorkflow(dto.hospitalId, w.workflowId).then(() => {
                  fetchTableData();
                });
              }}
              title={t("Accept workflow")}
            >
              <Icon name="check" />
            </button>
          )}

          {canReject && w.requested && (
            <button
              className="td-action edit smaller icon-only"
              onClick={() => {
                api
                  .deleteRequestWorkflow(dto.hospitalId, w.workflowId)
                  .then(() => {
                    fetchTableData();
                  });
              }}
              title={t("Reject workflow")}
            >
              <Icon name="delete" />
            </button>
          )}

          {canTest && (
            <button
              className="td-action edit smaller icon-only"
              onClick={() => {
                showAskAdvice(w);
              }}
              title={t("Test workflow")}
            >
              <Icon name="lightbulb" /> {!user.isAdmin && t("Test")}
            </button>
          )}
        </div>
      </div>
    );
  }

  async function deleteWorkflowInstance(workflowInstance, hospital) {
    const { hospitalName } = hospital;
    const { id, diseaseName } = workflowInstance;
    const deleteDialog = {
      title: `${t("Delete workflow for")} ${diseaseName} in ${hospitalName}?`,
      message: t("You will lose all corrections added to the base workflow."),
      buttonConfirmText: t("Delete"),
      isWarning: true,
    };

    dispatch(showDialog(deleteDialog));
    const isDeleteConfirmed = await dispatch(isDialogConfirmed()).unwrap();
    if (!isDeleteConfirmed) return;

    api
      .deleteWorkflowInstance(id)
      .then((response) => {
        dispatch(
          showSuccessPopup(
            `${t(
              "Successfully deleted workflow"
            )} ${diseaseName}, ${hospitalName}.`
          )
        );
        fetchTableData();
      })
      .catch((error) => showHttpRequestError(error));
  }

  function showAskAdvice(w) {
    setDiseaseName(w.diseaseName);
    const workflow = {
      countryId: w.countryId,
      diseaseId: w.diseaseId,
      draft: false,
    };
    setWorkflow(workflow);
    dispatch(
      showModal({
        id: `testAdviceModal-${workflow.diseaseId}`,
      })
    );
  }

  function firstStepAdvice(dto) {
    return api.testWorkflowFirstStep(
      dto.workflow.countryId,
      dto.workflow.diseaseId,
      dto.workflow.draft ? "draft" : ""
    );
  }

  function nextStepAdvice(dto) {
    const toSend = {
      countryId: dto.workflow.countryId,
      diseaseId: dto.workflow.diseaseId,
      json: dto.json,
    };
    return api.testWorkflowNextStep(toSend, dto.workflow.draft ? "draft" : "");
  }

  return (
    <div
      className={`hospital-advice-builder ${
        user.role !== "ADMIN" ? "card-big" : ""
      }`}
    >
      <PageTitle title={t("Hospital Workflows")} />

      <DataTable
        pageName="adviceBuilder"
        tableColumns={tableColumns}
        userPermissions={userPermissions}
        tableData={tableData}
        handleCardClick={() => {}}
        CardComponent={CardDefault}
      />

      <Modal
        id={`testAdviceModal-${
          workflow?.diseaseId || workflow?.workflowInstanceId
        }`}
        title={"Workflow for " + diseaseName}
        innerComponent={
          <TestAskForAdvice
            testWorkflow={workflow}
            firstStepAdvice={firstStepAdvice}
            nextStepAdvice={nextStepAdvice}
          />
        }
        isExtraLarge
        customClasses={"testing"}
      />
    </div>
  );
}
