export default class ApiEndpoints {
  /**
   * Login and logout.
   */
  loginUser = () => `/auth/login`;
  logoutUser = () => `/auth/logout`;
  getCurrentUser = () => `/auth/get`;
  changePassword = () => `/auth/change-password-token`;
  refreshToken = () => `auth/refresh-token`;

  /**
   * Users.
   */
  createUser = () => `/user/create`;
  updateBasicProfileData = () => `/user/profile`;
  updateFullProfileData = () => `/user/update`;
  resetPassword = () => `/user/reset-password`;
  getUsers = () => `/user/list`;
  getUser = (id) => `/user/${id}`;
  deleteUser = (id) => `/user/delete/${id}`;

  /**
   * Nomenclatures.
   */
  getNomenclature = (type) => `/nomenclature/list/${type}`;
  getSpecificNomenclature = (type, id) => `/nomenclature/${type}/${id}`;
  createNomenclature = () => `/nomenclature/create`;
  deleteNomenclature = (type, nomenclatureId) =>
    `/nomenclature/delete/${type}/${nomenclatureId}`;
  getDiseaseGroups = () => `/nomenclature/disease-groups`;
  getMedicamentGroups = () => `/nomenclature/medicament-groups`;

  getUserRoles = () => `/user/nome/roles`;
  getWardNames = () => `/hospital/nome/wards`;
  getAdviceRejectReasons = () => `/workflow/nome/reject-reasons`;
  getMedicaments = () => `/medicament/nome/list`;
  getHospitalFilteredNames = (active) =>
    `/hospital/nome/filtered?active=${active}`;
  getHospitalWardOptions = (id) =>
    `/hospital/nome/hospital-wards/active?hospitalId=${id}`;

  /**
   * Files.
   */
  uploadFile = () => `/file/upload`;
  getFile = (id) => `/file/${id}`;
  getFiles = (purpose, country, hospital) => {
    let url = `/file/list?purpose=${purpose}`;
    if (country) url += `&country=${country}`;
    if (hospital) url += `&hospital=${hospital}`;
    return url;
  };
  deleteFile = (id) => `/file/delete/${id}`;
  getAntibioticGuidelines = () => `/file/list/guidelines`;
  getAntibioticLeaflets = () => `/file/list/leaflets`;
  getAntibioticResistanceStatistics = () => `/file/list/resistance-statistics`;

  /**
   * Hospitals.
   */
  getHospitals = () => `/hospital/list`;
  getHospitalsOptions = (inactive, countryId) =>
    `/hospital/nome?inclInactive=${!!inactive}${
      countryId ? `&countryId=${countryId}` : ""
    }`;
  getHospital = (id) => `/hospital/${id}`;
  createHospital = () => `/hospital/create`;
  updateHospital = () => `/hospital/update`;
  deactivateHospital = () => `/hospital/deactivate`;
  activateHospital = () => `/hospital/activate`;
  deleteHospital = (id) => `/hospital/delete/${id}`;
  getHospitalUsers = (id) => `/hospital/${id}/users`;

  /**
   * Hospital wards.
   */
  getHospitalWards = (id) => `/ward/list/${id}`;
  getActiveWards = (hospitalId) => `/ward/list/active/${hospitalId}`;
  getWard = (id) => `/ward/${id}`;
  createWard = () => `/ward/create`;
  updateWard = () => `/ward/update`;
  deactivateWard = () => `/ward/deactivate`;
  activateWard = () => `/ward/activate`;
  deleteWard = (id) => `/ward/delete/${id}`;
  getWardUsers = (id) => `/ward/${id}/users`;

  /**
   * Regions.
   */
  getCountries = () => `/geo/countries/options`;
  getRegions = (countryId) => `/geo/${countryId}/regions/options`;
  getCities = (regionId) => `/geo/${regionId}/cities/options`;
  importGeo = () => `/geo/import`;

  /**
   * Ask advice.
   */
  createMainAdvice = () => `/advice/create/treatment`;
  createConsultationAdvice = () => `/advice/create/consultation`;
  updateAdvice = () => `/advice/update`;
  getDiseasesForAdvice = () => `/advice/nome/diseases/options`;
  acceptAdvice = () => `/advice/accept`;
  rejectAdvice = () => `/advice/reject`;
  deleteAdvice = (id) => `/advice/delete/${id}`;
  archiveAdvice = () => `/advice/archive`;
  archiveDiseaseHistory = (diseaseHistoryId) =>
    `/advice/archive/hd?diseaseHistoryId=${diseaseHistoryId}`;
  getAdvice = (id) => `/advice/${id}`;
  getConsultations = () => `/advice/consultation/issued`;
  getAllPatientAdvices = (historyOfDiseaseId) =>
    `/advice/patient/${historyOfDiseaseId}`;

  /**
   * Settings.
   */
  getSettings = () => `/setting/list`;
  updateSettings = () => `/setting/update`;
  restoreSettings = () => `/setting/restore`;

  /**
   * Notifications.
   */
  getNotifications = () => `/notification/list`;
  getNotification = (id) => `/notification/${id}`;
  getNotificationByKey = (key) => `/notification/key/${key}`;
  updateNotification = () => `/notification/update`;

  /**
   * Notification instance (notifications per hospital).
   */
  getNotificationsForHospital = (hospitalId) =>
    `/notification/instance/list/${hospitalId}`;

  getNotificationForHospital = (notificationdId) =>
    `/notification/instance/${notificationdId}`;

  updateNotificationForHospital = () => `/notification/instance/update`;

  /**
   * Statistics
   */
  getActivePatientsCount = (entityId, entityType) =>
    `/statistics/advice/count/patient${
      entityId ? `?entityId=${entityId}` : ""
    }${entityType ? `&idType=${entityType}` : ""}`;

  getAcceptedAdviceCount = (entityId, entityType) =>
    `/statistics/advice/accepted${entityId ? `?entityId=${entityId}` : ""}${
      entityType ? `&idType=${entityType}` : ""
    }`;

  getRejectedAdviceCount = (entityId, entityType) =>
    `/statistics/advice/rejected${entityId ? `?entityId=${entityId}` : ""}${
      entityType ? `&idType=${entityType}` : ""
    }`;

  getMostFrequentDiagnoses = (entityId, entityType) =>
    `/statistics/advice/diagnoses${entityId ? `?entityId=${entityId}` : ""}${
      entityType ? `&idType=${entityType}` : ""
    }`;

  getMostRecommendedMedicaments = (entityId, entityType) =>
    `/statistics/advice/medicament${entityId ? `?entityId=${entityId}` : ""}${
      entityType ? `&idType=${entityType}` : ""
    }`;

  getMostFrequentBacteria = (entityId, entityType) =>
    `/statistics/advice/bacteria${entityId ? `?entityId=${entityId}` : ""}${
      entityType ? `&idType=${entityType}` : ""
    }`;

  getHospitalsInCountry = (countryId) => `/statistics/hospitals/${countryId}`;

  getReasonsForAdviceRejection = (entityId, entityType) =>
    `/statistics/advice/reject/reason${
      entityId ? `?entityId=${entityId}` : ""
    }${entityType ? `&idType=${entityType}` : ""}`;

  /**
   * Dashboard
   */
  getActivePatients = () => `/dashboard/patients`;
  getAdvicesToFollowUp = () => `/dashboard/follow-up`;
  getReceivedConsultations = () => `/dashboard/consultation`;
  getDraftAdvices = () => `/dashboard/draft`;
  getLatestFiles = () => `/dashboard/files`;

  /**
   * Workflow
   */
  getWorkflows = () => `/workflow/list`;

  getWorkflowDiseases = () => `/workflow/nome/diseases`;
  getWorkflowRejectReasons = () => `/workflow/nome/reject-reasons`;
  getWorkflowQuestions = () => `/workflow/nome/questions`;
  getWorkflowMedicamentForms = () => `/workflow/nome/medicament-forms`;
  getWorkflowMeasures = () => `/workflow/nome/measures`;
  getWorkflowBacteria = () => `/workflow/nome/bacterias`;
  getWorkflowApplicationMethods = () => `/workflow/nome/application-methods`;
  getWorkflow = (cid, did) => `/workflow/${cid}/${did}`;
  getDraftWorkflow = (cid, did) => `/workflow/draft/${cid}/${did}`;
  createWorkflow = () => `/workflow/create`;
  updateWorkflow = () => `/workflow/update`;
  completeWorkflow = () => `/workflow/complete`;
  deleteWorkflow = (cid, did) => `/workflow/delete/${cid}/${did}`;
  deleteDraftWorkflow = (cid, did) => `/workflow/draft/delete/${cid}/${did}`;
  testWorkflowFirstStep = (cid, did, draft) => {
    return draft
      ? `/workflow/draft/test/first-step/${cid}/${did}`
      : `/workflow/test/first-step/${cid}/${did}`;
  };
  testWorkflowNextStep = (draft) => {
    return draft
      ? `/workflow/draft/test/next-step`
      : `/workflow/test/next-step`;
  };

  /**
   * Workflow instance
   */
  getWorkflowInstances = (hospitalId) =>
    `/workflow/instance/list${hospitalId ? `?hospitalId=${hospitalId}` : ""}`;

  deleteWorkflowInstance = (workflowInstanceId) =>
    `/workflow/instance/delete/${workflowInstanceId}`;

  createWorkflowInstance = () => `/workflow/instance/create`;
  getWorkflowInstance = (id) => `/workflow/instance/${id}`;
  getDraftWorkflowInstance = (id) => `/workflow/instance/draft/${id}`;
  updateWorkflowInstance = () => `/workflow/instance/update`;
  completeWorkflowInstance = () => `/workflow/instance/complete`;

  deleteDraftWorkflowInstance = (workflowInstanceId) =>
    `/workflow/instance/delete/draft/${workflowInstanceId}`;

  addAllWorkflowInstancesToHospital = (hospitalId) =>
    `/workflow/instance/create/hospital`;
  testWorkflowInstanceFirstStep = (id, draft) => {
    return draft
      ? `/workflow/instance/draft/test/first-step/${id}`
      : `/workflow/instance/test/first-step/${id}`;
  };
  testWorkflowInstanceNextStep = (draft) => {
    return draft
      ? `/workflow/instance/draft/test/next-step`
      : `/workflow/instance/test/next-step`;
  };
  upgradeWorkflowInstance = (id) => `/workflow/instance/upgrade/${id}`;

  requestWorkflow = (hid, wid) => `/workflow/request/${hid}/${wid}`;
  acceptWorkflow = (hid, wid) => `/workflow/request/accept/${hid}/${wid}`;
  deleteRequestWorkflow = (hid, wid) =>
    `/workflow/request/reject/${hid}/${wid}`;

  /**
   * Aggregated patients.
   */
  getPatientsInHospital = () => `/advice/aggregation/patients`;
  getArchivedPatients = () => `/advice/aggregation/archived-patients`;

  /**
   * Survey
   */
  getSurveyQuestions = (adviceId) => `/survey/questions/${adviceId}`;
}
