import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { showSuccessPopup, hideModal } from "../../../redux/reducers";
import AdviceSummary from "../../AdviceSummary/AdviceSummary";
import MedicamentSimple from "../../MedicamentSimple/MedicamentSimple";
import { useTranslation } from "react-i18next";
import "./FormSurveyOnArchiveAdvice.scss";
import { ApiRequests } from "../../../http";
import cloneDeep from "lodash/cloneDeep";
import { v4 as generateUUID } from "uuid";
import { showHttpRequestError } from "../../../http";
import Select from "react-select";
import { selectStyles } from "../../../styles/selectStyles";
import { convertToSelectOptions } from "../../../utils/utils";

export default function FormSurveyOnArchiveAdvice({
  archivedAdvice,
  questions,
  medicaments,
  doseMeasures,
  timeMeasures,
  fetchAndSetDashboardData,
}) {
  const api = new ApiRequests();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { firstQuestion, secondQuestion, thirdQuestion } = questions;
  const areQuestionsFetched = Boolean(firstQuestion?.id);

  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [hasDrugReactions, setHasDrugReactions] = useState(false);
  const [isPrescribingNewMedicament, setIsPrescribingNewMedicament] =
    useState(false);

  /**
   * Set the survey answers to default when the questions are fetched and every time the advice is changed.
   *
   * Default answers:
   * 1. Did the treatment work? - Yes
   * 2. Did any adverse drug reactions occur? - No (send empty array)
   * 3. Will you be prescribing a new anibiotic? - No (send the same medicamentOptions that came with the questions)
   */
  useEffect(() => {
    if (!areQuestionsFetched) return;
    setSurveyAnswersToDefault();
  }, [firstQuestion?.id, archivedAdvice?.adviceId]);

  function setSurveyAnswersToDefault() {
    // 1st question
    const firstQuestionYes = firstQuestion?.answers.find(
      (answer) => answer.name === "Yes" || answer.name === "Да"
    );

    setHasDrugReactions(false); // 2nd question
    setIsPrescribingNewMedicament(false); // 3rd question

    setSurveyAnswers([
      { questionId: firstQuestion.id, answerId: firstQuestionYes.id },
      { questionId: secondQuestion.id, reactionsAnswer: [] },
      {
        questionId: thirdQuestion.id,
        medicamentsAnswer: [...thirdQuestion.medicamentOptions],
      },
    ]);
  }

  /**
   * First question - Did the treatment work?
   */
  const setFirstQuestionAnswer = (questionId, answerId) => {
    const surveyAnswersCopy = [...surveyAnswers];
    const firstQuestionAnswerIndex = surveyAnswersCopy.findIndex(
      (answer) => answer.questionId === questionId
    );

    const firstQuestionAnswer = {
      questionId,
      answerId,
    };

    if (firstQuestionAnswerIndex !== -1) {
      surveyAnswersCopy[firstQuestionAnswerIndex] = { ...firstQuestionAnswer };
    } else {
      surveyAnswersCopy.push(firstQuestionAnswer);
    }

    setSurveyAnswers(surveyAnswersCopy);
  };

  /**
   * Second question - Did any adverse drug reactions occur?
   */
  const drugReactions = convertToSelectOptions(secondQuestion?.reactions ?? []);
  const [selectedDrugReactions, setSelectedDrugReactions] = useState([]);
  const toggleHasDrugReactions = () => setHasDrugReactions(!hasDrugReactions);

  useEffect(clearDrugReactionsIfAnswerIsNo, [hasDrugReactions]);

  function clearDrugReactionsIfAnswerIsNo() {
    if (hasDrugReactions || !drugReactions?.length) return;
    const surveyAnswersCopy = [...surveyAnswers];
    surveyAnswersCopy[1].reactionsAnswer = [];
    setSelectedDrugReactions([]);
    setSurveyAnswers(surveyAnswersCopy);
  }

  const setSecondQuestionAnswer = (questionId, options) => {
    const surveyAnswersCopy = [...surveyAnswers];
    const secondQuestionAnswerIndex = surveyAnswersCopy.findIndex(
      (answer) => answer.questionId === questionId
    );
    const selectedDrugReactions = options.map((option) => option.value);

    if (secondQuestionAnswerIndex !== -1) {
      surveyAnswersCopy[secondQuestionAnswerIndex].reactionsAnswer =
        selectedDrugReactions;
    } else {
      surveyAnswersCopy.push({
        questionId,
        reactionsAnswer: selectedDrugReactions,
      });
    }

    setSurveyAnswers(surveyAnswersCopy);
  };

  /**
   * Third question - Will you be prescribing a new anibiotic?
   */
  const [prescribedMedicaments, setPrescribedMedicaments] = useState([]);
  const toggleIsPrescribingNewMedicament = () =>
    setIsPrescribingNewMedicament(!isPrescribingNewMedicament);

  /**
   * Add prescribed medicament.
   *
   * medicamentId initially is unique but fake - used as react key. When the medicament is selected from the UI - it's replaced with a real one. Medicaments with placeholder ids are removed on submit.
   */
  function addMedicamentForPrevention() {
    const medicamentForPrevention = {
      medicamentId: "placeholder" + generateUUID(),
      medicamentName: "",
      dose: 0,
      doseMeasureId: "",
      doseMeasureName: "",
      doseMeasureAbr: "",
      frequency: null,
      durationOfTreatment: 0,
      whenToApply: "",
    };

    setPrescribedMedicaments([
      ...prescribedMedicaments,
      { ...medicamentForPrevention },
    ]);
  }

  function addMedicamentForTreatment() {
    const medicamentForTreatment = {
      medicamentId: "placeholder" + generateUUID(),
      medicamentName: "",
      dose: 0,
      doseMeasureId: "",
      doseMeasureName: "",
      doseMeasureAbr: "",
      frequency: {
        quantity: 0,
        time: 0,
        measureId: "",
        measureName: "",
        measureAbr: "",
      },
      durationOfTreatment: 0,
      whenToApply: null,
    };

    setPrescribedMedicaments([
      ...prescribedMedicaments,
      { ...medicamentForTreatment },
    ]);
  }

  /**
   * Remove prescribed medicament.
   */
  function removePrescribedMedicament(medicamentId) {
    const prescribedMedicamentsCopy = [...prescribedMedicaments];
    const medicamentIndex = prescribedMedicamentsCopy.findIndex(
      (medicament) => medicament.medicamentId === medicamentId
    );

    if (medicamentIndex !== -1) {
      prescribedMedicamentsCopy.splice(medicamentIndex, 1);
      setPrescribedMedicaments(prescribedMedicamentsCopy);
    }
  }

  function addPrescribedMedicament() {
    if (archivedAdvice.prevention) {
      addMedicamentForPrevention();
    }

    if (!archivedAdvice.prevention) {
      addMedicamentForTreatment();
    }
  }

  /**
   * Reset the prescribed medicaments.
   */
  useEffect(() => {
    if (!areQuestionsFetched) return;
    addAlreadyPrescribedMedicaments();
  }, [thirdQuestion?.id, isPrescribingNewMedicament]);

  function addAlreadyPrescribedMedicaments() {
    setPrescribedMedicaments([...thirdQuestion?.medicamentOptions]);
  }

  /**
   * Archive advice.
   */
  const archiveAdvice = (e) => {
    e.preventDefault();

    // Remove empty medicaments (their ids start with "placeholder" and the user didn't select a real medicament)
    const medicamentsToSend = prescribedMedicaments.filter((medicament) => {
      return (
        medicament.medicamentId &&
        !medicament.medicamentId.startsWith("placeholder")
      );
    });

    // Delete unnecessary data. We only need selected ids.
    for (let medicament of medicamentsToSend) {
      delete medicament.doseMeasureAbr;
      delete medicament.doseMeasureName;
      delete medicament.medicamentName;
      delete medicament.whenToApply;

      if (!archivedAdvice.prevention) {
        delete medicament.frequency.measureAbr;
        delete medicament.frequency.measureName;
      }

      if (archivedAdvice.prevention) {
        delete medicament.frequency;
      }
    }

    const data = cloneDeep(surveyAnswers);
    data[2].medicamentsAnswer = medicamentsToSend;

    const adviceToArchive = {
      adviceId: archivedAdvice.adviceId,
      surveyAnswers: data,
    };

    api
      .archiveAdvice(adviceToArchive)
      .then((response) => {
        dispatch(showSuccessPopup(`Advice archived successfully.`));
        dispatch(hideModal());
        fetchAndSetDashboardData();
      })
      .catch((error) => showHttpRequestError(error));
  };

  /**
   * Advices that say "No antibiotic needed" don't display the second question (side effects).
   */
  const isAdviceNoAntibioticNeeded =
    secondQuestion?.reactions.length === 0 &&
    thirdQuestion?.medicamentOptions.length === 0;

  if (!firstQuestion?.id) return null;

  return (
    <div className="archive-advice-form">
      <div className="archive-advice-wrapper">
        <div className="archived-advice-summary">
          <h2>
            {t("For patient")} {archivedAdvice.diseaseHistory}
          </h2>
          <AdviceSummary advice={archivedAdvice} />
        </div>

        <form className="archived-advice-questions">
          <div className="input-wrapper">
            <h2>{firstQuestion.name}</h2>

            {firstQuestion.answers.map((answer) => (
              <div key={answer.id}>
                <input
                  type="radio"
                  name="firstQuestion"
                  id={answer.id}
                  value={answer.id}
                  defaultChecked={
                    answer.name === "Yes" || answer.name === "Да" ? true : false
                  }
                  onClick={(e) =>
                    setFirstQuestionAnswer(firstQuestion.id, e.target.value)
                  }
                />

                <label htmlFor={answer.id}>{answer.name}</label>
              </div>
            ))}
          </div>

          {!isAdviceNoAntibioticNeeded && (
            <div className="input-wrapper">
              <h2>{secondQuestion.name}</h2>

              <div className="input-wrapper inline">
                <input
                  type="radio"
                  name="drugReactions"
                  id="noDrugReactions"
                  checked={!hasDrugReactions}
                  onChange={toggleHasDrugReactions}
                />

                <label htmlFor="noDrugReactions">{t("No")}</label>
              </div>

              <div className="input-wrapper inline">
                <input
                  type="radio"
                  name="drugReactions"
                  id="hasDrugReactions"
                  checked={hasDrugReactions}
                  onChange={toggleHasDrugReactions}
                />

                <label htmlFor="hasDrugReactions">{t("Yes")}</label>
              </div>

              {hasDrugReactions && drugReactions.length > 0 && (
                <div className="input-wrapper mb-3">
                  <Select
                    name="drugReactions"
                    options={drugReactions}
                    isSearchable={true}
                    required
                    styles={selectStyles}
                    openMenuOnClick
                    openMenuOnFocus
                    isMulti
                    value={selectedDrugReactions}
                    onChange={(options) => {
                      setSecondQuestionAnswer(secondQuestion.id, options);
                      setSelectedDrugReactions(options);
                    }}
                  />
                </div>
              )}
            </div>
          )}

          <div className="input-wrapper">
            <h2>{thirdQuestion.name}</h2>

            <div className="input-wrapper inline">
              <input
                type="radio"
                name="newAntibiotic"
                id="isNotPrescribingNewMedicament"
                checked={!isPrescribingNewMedicament}
                onChange={toggleIsPrescribingNewMedicament}
              />

              <label htmlFor="isNotPrescribingNewMedicament">{t("No")}</label>
            </div>

            <div className="input-wrapper inline">
              <input
                type="radio"
                name="newAntibiotic"
                id="isPrescribingNewMedicament"
                checked={isPrescribingNewMedicament}
                onChange={toggleIsPrescribingNewMedicament}
              />

              <label htmlFor="isPrescribingNewMedicament">{t("Yes")}</label>
            </div>
          </div>

          {isPrescribingNewMedicament && (
            <>
              <div className="input-wrapper">
                {prescribedMedicaments.map((medicament) => (
                  <MedicamentSimple
                    key={medicament.medicamentId}
                    isPrevention={archivedAdvice.prevention}
                    medicament={medicament}
                    medicaments={medicaments}
                    doseMeasures={doseMeasures}
                    timeMeasures={timeMeasures}
                    prescribedMedicaments={prescribedMedicaments}
                    setPrescribedMedicaments={setPrescribedMedicaments}
                    removePrescribedMedicament={removePrescribedMedicament}
                  />
                ))}

                {prescribedMedicaments.length === 0 && (
                  <p>{t("No antibiotic was prescribed to the patient.")}</p>
                )}
              </div>

              <div className="input-wrapper iw-add">
                <button
                  type="button"
                  className="smaller secondary"
                  onClick={addPrescribedMedicament}
                >
                  {t("Add medicament")}
                </button>
              </div>
            </>
          )}

          <button className="w-100 mb-0" onClick={archiveAdvice}>
            {t("Archive advice")}
          </button>
        </form>
      </div>
    </div>
  );
}
