import { useTranslation } from "react-i18next";

export default function CardUser({
  row,
  isActive,
  handleCardClick,
  flexRender,
}) {
  const { t } = useTranslation();
  let fullName = "";

  return (
    <div
      key={row.id}
      className={`card ${isActive === false ? "inactive" : ""}`}
      title={`${isActive === false ? t("Deactivated") : ""}`}
      onClick={!isActive ? () => {} : () => handleCardClick(row.original)}
      tabIndex="0"
    >
      {row
        .getVisibleCells()
        .filter((cell) => {
          if (cell.column.id === "firstName") {
            const value = cell.getContext().getValue();
            if (!!value) {
              fullName += value;
            }
            return true;
          }

          if (cell.column.id === "lastName") {
            const value = cell.getContext().getValue();
            if (!!value) {
              fullName += ` ${value}`;
            }

            return false;
          }
          if (!fullName && cell.column.id === "username") {
            fullName = cell.getContext().getValue();
          }

          return true;
        })
        .map((cell) => {
          if (!!fullName && cell.column.id === "firstName") {
            return (
              <h3 key={"fullName"} className={"fullName"}>
                {fullName}
              </h3>
            );
          }

          if (cell.column.id === "title" || cell.column.id === "name") {
            return (
              <h3
                key={cell.id}
                className={cell.column.id}
                title={cell.column.header}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </h3>
            );
          }

          return (
            <div key={cell.id} className={cell.column.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          );
        })}
    </div>
  );
}
