import { useMemo } from "react";
import { ReactFlowProvider } from "reactflow";
import { Flowchart } from "../";
import { useTranslation } from "react-i18next";

export default function WorkflowComparison({
  workflowToCompare,
  draftWorkflowToCompare,
  originalResponse,
  diseases,
  bacteria,
  medicaments,
  medicamentForms,
  doseMeasures,
  timeMeasures,
  applicationMethods,
  scrollToQuestion,
  centeredQuestionIndex,
  highlightAnswer,
}) {
  const { t } = useTranslation();

  const diseaseName = useMemo(() => {
    const disease = diseases.find(
      (disease) => disease.value === workflowToCompare.diseaseId
    );
    return disease ? disease.label : "";
  }, [diseases, workflowToCompare.diseaseId]);

  return (
    <>
      {diseaseName && <h2 className="text-center">{diseaseName}</h2>}

      <div className="workflow-comparison">
        <div className="advice-chart">
          <ReactFlowProvider>
            <h2>
              v{workflowToCompare.workflowVersion}.0 {t("completed")}
            </h2>

            <Flowchart
              workflow={workflowToCompare}
              originalResponse={originalResponse}
              bacteria={bacteria}
              medicaments={medicaments}
              medicamentForms={medicamentForms}
              doseMeasures={doseMeasures}
              timeMeasures={timeMeasures}
              applicationMethods={applicationMethods}
              scrollToQuestion={scrollToQuestion}
              centeredQuestionIndex={centeredQuestionIndex}
              highlightAnswer={highlightAnswer}
              isComparingWorkflows={true}
            />
          </ReactFlowProvider>
        </div>

        <div className="advice-chart">
          <h2>
            v{draftWorkflowToCompare.draftVersion}.0 {t("draft")}
          </h2>

          <ReactFlowProvider>
            <Flowchart
              workflow={draftWorkflowToCompare}
              originalResponse={originalResponse}
              bacteria={bacteria}
              medicaments={medicaments}
              medicamentForms={medicamentForms}
              doseMeasures={doseMeasures}
              timeMeasures={timeMeasures}
              applicationMethods={applicationMethods}
              scrollToQuestion={scrollToQuestion}
              centeredQuestionIndex={centeredQuestionIndex}
              highlightAnswer={highlightAnswer}
              isComparingWorkflows={true}
            />
          </ReactFlowProvider>
        </div>
      </div>
    </>
  );
}
