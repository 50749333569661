import i18next from "../i18n.js";
import { injectedStore } from "../redux/injectedStore/injectedStore.js";

const getMenuLinksByPermissions = (userPermissions) => {
  const userRole = injectedStore.getState().user.role;

  const allLinks = {
    home: {
      text: i18next.t("Home"),
      href: "/",
      icon: "home",
      order: 10,
    },
    askAdvice: {
      text: i18next.t("Ask For Advice"),
      href: "/ask-advice",
      icon: "lightbulb",
      order: 20,
    },
    statistics: {
      text: i18next.t("Statistics"),
      href: "/statistics",
      icon: "chart-pie",
      order: 25,
    },
    adviceBuilder: {
      text: i18next.t("Workflows"),
      href: "/workflows",
      icon: "sitemap",
      order: 30,
    },
    workflowInstance: {
      text: i18next.t("Hospital Workflows"),
      href: "/hospital-workflows",
      icon: "format-list-checks",
      order: 35,
    },
    hospitalManagement: {
      text:
        userRole !== "HEAD OF HOSPITAL"
          ? i18next.t("Hospitals and Wards")
          : i18next.t("My Hospital"),
      href: "/hospitals",
      icon: "map-marker",
      order: 40,
    },
    users: {
      text: i18next.t("Users"),
      href: "/users",
      icon: "account",
      order: 50,
    },
    patients: {
      text: i18next.t("Patients"),
      href: "/patients",
      icon: "account-book",
      order: 60,
    },
    consultation: {
      text: i18next.t("Consultations"),
      href: "/consultations",
      icon: "book-plus",
      order: 65,
    },
    archived_patients: {
      text: i18next.t("Archived Patients"),
      href: "/patients-archived",
      icon: "clipboard-check",
      order: 67,
    },
    notifications: {
      text: i18next.t("Email Notifications"),
      href: "/notifications",
      icon: "email",
      order: 70,
    },
    nomenclatures: {
      text: i18next.t("Nomenclatures"),
      href: "/nomenclatures",
      icon: "clipboard-text",
      order: 80,
    },
    abGuidelines: {
      text: i18next.t("Antibiotic Guidelines"),
      href: "/guidelines",
      icon: "file-multiple",
      order: 90,
    },
    abLeaflets: {
      text: i18next.t("Antibiotic Leaflets"),
      href: "/leaflets",
      icon: "file-multiple",
      order: 100,
    },
    abResistanceStatistics: {
      text: i18next.t("Bacterial Resistance"),
      href: "/resistance",
      icon: "chart-histogram",
      order: 110,
    },
    uploadAbResistanceStatistics: {
      text: i18next.t("Upload Resistance Statistics"),
      href: "/resistance",
      icon: "file-plus",
      order: 120,
    },
    // eventLog: {
    //   text: i18next.t("Event Log"),
    //   href: "/event-log",
    //   icon: "clock",
    //   order: 130,
    // },
    settings: {
      text: i18next.t("Settings"),
      href: "/settings",
      icon: "settings",
      order: 140,
    },
  };

  const menuLinks = [];
  menuLinks.push(allLinks.home); // Everyone has home dashboard.
  addMenuLinksThatUserCanView(); // Then menu items depend on permissions.
  orderMenuLinks();
  return menuLinks;

  function addMenuLinksThatUserCanView() {
    for (const [pageName, permissions] of Object.entries(userPermissions)) {
      const canViewPageAndItExists =
        permissions?.canViewInMenu && allLinks?.[pageName];

      if (canViewPageAndItExists) {
        menuLinks.push(allLinks[pageName]);
      }
    }
    addNotificationsInstanceMenuLink();
  }

  // Two types of notifications exist - global "notifications" (for admin, default ones) and "notificationInstance" (for hospitals - head of hospital and microbiology). Both should be accessible from the same menu item "Notifications".
  function addNotificationsInstanceMenuLink() {
    const hasAccessToNotificationInstances =
      !userPermissions?.notifications?.canView &&
      userPermissions?.notificationInstances?.canView;

    if (hasAccessToNotificationInstances) {
      menuLinks.push(allLinks.notifications);
    }
  }

  function orderMenuLinks() {
    return sortAscending(menuLinks);
  }

  function sortAscending(array) {
    return array.sort((a, b) => a.order - b.order);
  }
};

export { getMenuLinksByPermissions };
