import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdviceSummary, Icon } from "../index";
import { useTranslation } from "react-i18next";
import { useOnMount } from "../../hooks";
import "./CardPatient.scss";

export default function CardPatient({
  row,
  areAllAdvicesVisible,
  userShouldSeeEverything,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const patient = row.original;
  const { diseaseHistoryId, diseaseHistory, advices } = patient;

  const [filteredAdvices, setFilteredAdvices] = useState([]);
  const hasFilteredAdvices = filteredAdvices.length > 0;
  const useOnAdvicesFilter = (fn) => useEffect(fn, [areAllAdvicesVisible]);

  useOnMount(() => {
    setFilteredAdvices(sortAdvices(advices));
  });

  useOnAdvicesFilter(() => {
    if (areAllAdvicesVisible) {
      setFilteredAdvices(advices);
    } else {
      setFilteredAdvices(filterOnlyAcceptedAdvices(advices));
    }
  });

  const hasAdvices = advices.length > 0;

  const hasActiveAdvices =
    advices.filter((advice) => advice.status === "ACCEPTED").length > 0;

  const isHasNoActiveAdvicesVisible =
    (!areAllAdvicesVisible && !hasActiveAdvices) ||
    (areAllAdvicesVisible && hasAdvices && !hasActiveAdvices);

  const hasUnfinishedAdvices = advices.some(
    (advice) => advice.status === "DRAFT" || advice.status === "COMPLETED"
  );

  const unfinishedAdvicesCount = advices.filter(
    (advice) => advice.status === "DRAFT" || advice.status === "COMPLETED"
  ).length;

  const isHasUnfinishedAdvicesVisible =
    (!areAllAdvicesVisible && hasUnfinishedAdvices) ||
    (areAllAdvicesVisible && hasUnfinishedAdvices && hasAdvices);

  function askAdviceForPatient(diseaseHistory) {
    navigate(`/ask-advice?history=${diseaseHistory}`);
  }

  function filterOnlyAcceptedAdvices(advices) {
    return advices.filter((advice) => advice.status === "ACCEPTED");
  }

  function sortAdvices(advices) {
    advices.sort((a, b) => {
      // Sort by status from first to last.
      const statusOrder = [
        "ACCEPTED",
        null,
        "COMPLETED",
        "REJECTED",
        "ARCHIVED",
      ];

      const aIndex = statusOrder.indexOf(a.status);
      const bIndex = statusOrder.indexOf(b.status);
      return aIndex - bIndex;
    });
  }

  function viewAllPatientAdvices() {
    navigate(`/patient?id=${diseaseHistoryId}&history=${diseaseHistory}`);
  }

  return (
    <div className="card card-patient" key={diseaseHistoryId}>
      <h2>{diseaseHistory}</h2>

      <div className="advice-summaries">
        {filteredAdvices.map((advice) => (
          <AdviceSummary
            advice={advice}
            key={advice.adviceId}
            areAllAdvicesVisible={areAllAdvicesVisible}
            userShouldSeeEverything={userShouldSeeEverything}
          />
        ))}

        {isHasUnfinishedAdvicesVisible && (
          <div className="has-unfinished-advices">
            {t("Unfinished advices")} ({unfinishedAdvicesCount})
          </div>
        )}

        {isHasNoActiveAdvicesVisible && (
          <div className="no-advices">{t("No active advices")}</div>
        )}
      </div>

      <div className="update">
        <button
          className="td-action edit"
          title={t("View all advices for this patient")}
          onClick={(e) => {
            e.stopPropagation();
            viewAllPatientAdvices();
          }}
        >
          <Icon name="clipboard" /> {t("View")}
        </button>
      </div>

      {userShouldSeeEverything && (
        <div className="delete">
          <button
            className="td-action edit secondary"
            title={t("Ask for new advice")}
            onClick={(e) => {
              e.stopPropagation();
              askAdviceForPatient(diseaseHistory);
            }}
          >
            <span className="icon icon-plus"></span>
          </button>
        </div>
      )}
    </div>
  );
}
