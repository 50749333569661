import { useSelector, useDispatch } from "react-redux";
import { hideMessagePopup } from "../../redux/reducers/index";
import "./MessagePopup.scss";

export default function MessagePopup() {
  const dispatch = useDispatch();
  const messagePopup = useSelector((state) => state.modals.messagePopup);
  const { content, isVisible, isError } = messagePopup;
  const classes = `message-popup ${isVisible ? "toggled" : "hidden"} ${
    isError ? "error" : "success"
  }`;

  return (
    <div className={classes}>
      <div className="message">{content}</div>

      <div
        className="modal-close"
        onClick={() => {
          dispatch(hideMessagePopup());
        }}
      >
        <span className="icon icon-cross"></span>
      </div>
    </div>
  );
}
