export const inputDefaults = {
  username: {
    min: 3,
    max: 50,
  },
  password: {
    min: 6,
    max: 40,
  },
};
