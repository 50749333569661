import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components";

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <PageTitle title={t("Privacy Policy")} />
    </div>
  );
}
